<template>
    <div class="">
        <div class="popup popup-sm" >
            <div class="content">
                <small id="close-popup" @click="close()"><i class="ti ti-close"></i></small>
                <h5 class="text-center">Contract Terminate<hr/></h5>
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <h6>{{ model.client_name }}</h6>
                        <span>{{ model.office_address }}</span><br/>
                        <span>{{ model.email }}</span><br/>
                        <span>{{ model.phone_number }}</span>
                    </div>
                    <div class="col-md-6 p-2 mt-3">
                        <label class="text-secondary bm-2">Contract Period</label><br/>
                        <div class="mt-2"><span class="text-primary">{{ FormatDate(model.start_date)  }}</span> - <span class="text-primary">{{ FormatDate(model.end_date)  }}</span></div>
                    </div>
                    <div class="col-md-6 p-2 mt-3">
                        <div class="form-group">
                            <label>Choose Terminate Date</label>
                            <select class="form-control" v-model="parsing.date">
                                <option value="">-- Choose Date --</option>
                                <option v-for="(items, index) in collection_month" :key="index" :value="items" >{{ items }}</option>
                            </select>
                            <small class="text-danger" v-if="msg_error.date">{{ msg_error.date[0] }}</small>
                        </div>
                    </div>
                    <div class="col-md-12 p-2">
                       <div class="form-group">
                            <label> Write the reason</label>
                            <textarea class="form-control" v-model="parsing.description" rows="3" placeholder="....."></textarea>
                            <small class="text-danger" v-if="msg_error.description">{{ msg_error.description[0] }}</small>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3 mt-4">
                        <h6><i class="ti ti-info-alt text-primary"></i> Information</h6>
                        <p class="text-secondary">- Terminate contract will automatically update the employee's status from "Join in Contract" to "Free / Available".</p>
                        <p class="text-secondary">- Terminate contract will automatically terminate the attendance application process, checklist work area and shift worker on mobile apps.</p>
                    </div>
                    <div class="col-md-12 mb-3">
                        <hr/>    
                        <a href="javascript:;" class="float-right btn btn-danger mt-2" @click="TerminateContract()">Terminate Contract</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    import axios from 'axios';
    import { mapState } from 'vuex';

    export default 
    {
        props:['id_contract'],
        data() {
            return {
                sdate: new Date( Date.now() + -6*24*3600*1000 ).toISOString().slice(0,10),
                model: [],
                collection_month : [],
                parsing : { id_contract: '', date : '', description : ''}
            }
        },
        computed: 
        {
            ...mapState('crud', {
                msg_error: state => state.error,
            }),
        },
        created() {
            this.QueryStartFromPeriod();
            this.DataQuery();
        },
      
        methods: 
        {
            close(initial){
                this.$emit('exit', initial);
            },

            FormatDate(date)
            {
                return this.$moment(date).format("DD/MM/YYYY")
            },
            
            async QueryStartFromPeriod()
            {
                await axios.get('contract/period/'+this.id_contract)
                .then((response) => {
                    let query = response.data;
                    this.collection_month = query.month_period;
                })
                .catch((error => {
                    console.log(error.statusText)
                })).finally(() => {

                    this.$store.state.loading.status = false;
                });
            },
            async DataQuery()
            {
                this.$store.state.loading.msg = 'Query Detail Contract';
                this.$store.state.loading.status = false;

                await axios.get( 'contract/find/'+this.id_contract)
                .then((res) => {
                    let query    = res.data;
                    this.model   = query.data;
                }).finally(() => {
                    this.$store.state.loading.status = false;
                });
            },
            async TerminateContract()
            {
                this.$store.state.loading.msg = 'Updating Data...';
                this.$store.state.loading.status = true;

                this.parsing.id_contract = this.id_contract;
                let data = { url: 'contract/terminate',  form_data: this.parsing };
                let res = await this.$store.dispatch('crud/storeData', data );
                if(res){
                    if(res.result == 'ok'){
                        this.close('refresh');
                    }
                }
            },
        },
    }
</script>

<style scoped>
.popup .content .form-group label{
    display: inline-block;
}
.popup .content .form-group{padding:5px; margin-bottom:0;}
</style>
