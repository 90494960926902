<template>
  <div class="col-md-12 p-0">
    <!-- popup shift wark -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'shiftwork'">
      <div class="content">
        <h5>
          Shift Worker <br /><small class="text-secondary"
            >Edit shift name and hour and then click button "Add New" to
            generate new shift work</small
          >
        </h5>
        <hr />
        <div class="row p-0 mt-5">
          <div class="col-md-5 p-0">
            <div class="form-group">
              <label>Shift Name</label><br />
              <select class="form-control" v-model="sw.name">
                <option value="">-- Choose One --</option>
                <option value="pagi">Pagi</option>
                <option value="siang">Siang</option>
                <option value="malam">Malam</option>
                <option value="middle">Middle</option>
              </select>
              <!-- <input
                type="text"
                class="form-control"
                v-model="sw.name"
                placeholder="..."
              /> -->
            </div>
          </div>
          <div class="col-md-4 p-0">
            <div class="form-group">
              <label>Start Shift Hour </label><br />
              <input
                type="time"
                class="form-control"
                v-model="sw.hour"
                placeholder="..."
              />
            </div>
          </div>
          <div class="col-md-3 p-0">
            <div class="form-group">
              <br />
              <a
                href="javascript:;"
                class="btn btn-secondary"
                @click="AddShiftWork()"
                >Add New</a
              >
            </div>
          </div>
        </div>
        <hr />
        <div class="row p-0">
          <div class="col-md-9">
            <span class="text-danger" v-if="this.sw.msg_error !== ''">{{
              this.sw.msg_error
            }}</span
            ><br />
            <div
              class="list-data"
              v-for="(items, idx) in this.sw.data"
              :key="idx"
            >
              - {{ items.name }} ( {{ items.hour }} )
              <i
                class="ti ti-trash text-danger"
                @click="RemoveShiftWorker(idx)"
              ></i>
            </div>
          </div>
        </div>
        <hr />
        <div class="row p-0 mb-5">
          <div class="col-md-12">
            <a
              href="javascript:;"
              class="btn btn-primary float-right"
              @click="isEnablePopup = false"
              >Finish & Close</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- popup man power -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'manpower'">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <h5>
          Generate Man Power <br /><small class="text-secondary"
            >Click button "Add New" to generate new man power</small
          >
        </h5>
        <hr />
        <div class="row p-0">
          <div class="col-md-2 p-0">
            <div class="form-group">
              <label>Total</label><br />
              <input
                type="number"
                min="1"
                class="form-control"
                placeholder="0"
                v-model="mp.total"
              />
            </div>
          </div>
          <div class="col-md-7 p-0">
            <div class="form-group">
              <label>Job Position</label><br />
              <select class="form-control" v-model="mp.job">
                <option value="">-- Choose One --</option>
                <option v-for="(items, idx) in model_jobpos" :key="idx">
                  {{ items.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 p-0">
            <div class="form-group">
              <br />
              <a
                href="javascript:;"
                class="btn btn-secondary"
                @click="AddMoreManPower()"
                >Add New</a
              >
            </div>
          </div>
        </div>
        <div class="row p-0 mb-5">
          <div class="col-md-11">
            <span class="text-danger" v-if="this.mp.msg_error !== ''">
              {{ this.mp.msg_error }} </span
            ><br />
            <h5></h5>
            <div
              class="list-data"
              v-for="(items, idx) in this.mp.data"
              :key="idx"
            >
              - {{ items.total }} ( {{ items.job }} )
              <i
                class="ti ti-trash text-danger"
                @click="RemoveManPower(idx)"
              ></i>
            </div>
          </div>
        </div>

        <hr />
        <div class="row p-0 mb-5">
          <div class="col-md-12">
            <a
              href="javascript:;"
              class="btn btn-primary float-right"
              @click="isEnablePopup = false"
              >Finish & Close</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- popup section room  -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'section'">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <h5>
          Section & Room <br /><small>Section Room Creator</small>
          <hr />
        </h5>
        <div class="row p-0">
          <div class="col-md-12">
            <span class="text-success float-left" id="add-more-notify"
              >1 slot unit is already created.</span
            >
            <a
              href="javascript:;"
              class="float-right btn btn-execute mb-5"
              @click="AddMoreSlotRoom()"
            >
              <i class="ti ti-plus text-success"></i> Add More Room</a
            >
            <br />
          </div>
        </div>
        <div
          class="row p-0 mb-2"
          v-for="(items, idx) in data_section"
          :key="idx"
          v-if="items.section == no_section && popup_mode == 'insert'"
        >
          <div class="col-md-12">
            <div class="row" v-for="(items, idx) in items.data" :key="idx">
              <div class="col-md-2">
                <span>Floor</span>
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  placeholder="1"
                  v-model="items.area_floor"
                />
              </div>
              <div class="col-md-4">
                <span>Area Type </span>
                <select class="form-control" v-model="items.area_type">
                  <option value="indoor">InDoor</option>
                  <option value="outdoor">Outdoor</option>
                </select>
              </div>
              <div class="col-md-6">
                <span>Area Name</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="...."
                  v-model="items.area_name"
                />
              </div>
              <div class="col-md-12 mt-4">
                <span class="text-secondary">Area Job Description</span>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="........"
                  v-model="items.area_task"
                ></textarea>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <div
          class="row mb-2"
          v-for="(items, idx) in data_section"
          :key="idx"
          v-if="items.section == active_edit_section && popup_mode == 'edit'"
        >
          <div class="col-md-12">
            <div class="row" v-for="(items, idx) in items.data" :key="idx">
              <div class="col-md-2">
                <span>Floor</span>
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  placeholder="1"
                  v-model="items.area_floor"
                />
              </div>
              <div class="col-md-4">
                <span>Area Type </span>
                <select class="form-control" v-model="items.area_type">
                  <option value="indoor">InDoor</option>
                  <option value="outdoor">Outdoor</option>
                </select>
              </div>
              <div class="col-md-6">
                <span>Area Name</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="...."
                  v-model="items.area_name"
                />
              </div>
              <div class="col-md-12 mt-4">
                <span class="text-secondary">Area Job Description</span>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="........"
                  v-model="items.area_task"
                ></textarea>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <div class="row p-0">
          <div class="col-md-12 mt-4 text-center">
            <a
              href="javascript:;"
              class="btn btn-primary"
              @click="SaveSectionRoom()"
              >{{
                popup_mode == "insert"
                  ? "Continue Add Section"
                  : "Update Section"
              }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- popup client  -->
    <PopupClientMaster
      v-on:exit="closeClient"
      v-on:applyData="applyClient"
      :id_branch="model.id_branch"
      v-if="isEnablePopup == 'client'"
    />

    <form class="form-horizontal" @submit="submit">
      <div class="card">
        <div class="card-body">
          <div class="form-group">
            <div class="col-sm-12">
              <label class="text-primary mb-0"
                ><i class="ti ti-info-alt"></i> Detail Information
                Contract</label
              >
              <small class="text-secondary"
                >Please fill completely detail data.</small
              >
              <hr />
            </div>
          </div>
          <div class="form-group" v-if="isEnableSelectBranch">
            <label class="col-sm-3"
              >Branch Station <small class="text-danger">*</small></label
            >
            <div class="col-sm-9">
              <SelectBranch
                v-on:processBranch="processBranch"
                :title="'disable'"
              />
              <small class="text-danger" v-if="msg_error.id_branch">{{
                msg_error.id_branch[0]
              }}</small>
            </div>
          </div>
          <div class="form-group" v-else>
            <label class="col-sm-3"
              >Branch Station <small class="text-danger">*</small></label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                v-model="branch_name"
                readonly
              />
              <small class="text-danger" v-if="msg_error.id_branch">{{
                msg_error.id_branch[0]
              }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3"
              >Number of Contract <small class="text-danger">*</small></label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                placeholder="...."
                v-model="model.contract_number"
              />
              <small class="text-danger" v-if="msg_error.contract_number">{{
                msg_error.contract_number[0]
              }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3"
              >Contract Value (Amount)
              <small class="text-danger">*</small></label
            >
            <div class="col-sm-9">
              <vue-numeric
                separator="."
                output-type="String"
                currency="Rp"
                v-model="model.contract_value"
                class="form-control"
              ></vue-numeric>
              <small class="text-danger" v-if="msg_error.contract_value">{{
                msg_error.contract_value[0]
              }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3"
              >Contract Date <small class="text-danger">*</small></label
            >
            <div class="col-sm-9">
              <input
                type="date"
                class="form-control"
                placeholder="...."
                v-model="sdate"
              />
              <small class="text-danger" v-if="msg_error.contract_date">{{
                msg_error.contract_date[0]
              }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3"
              >Contract Period <small class="text-danger">*</small></label
            >
            <div class="col-sm-4">
              <span>Start Date</span>
              <input
                type="date"
                min="1"
                class="form-control"
                placeholder="0"
                v-model="model.start_date"
                id="startDate"
              />
              <small class="text-danger" v-if="msg_error.start_date">{{
                msg_error.start_date[0]
              }}</small>
            </div>
            <div class="col-sm-4 float-right">
              <span>End Date</span>
              <input
                type="date"
                min="1"
                class="form-control"
                placeholder="0"
                v-model="model.end_date"
                id="endDate"
              />
              <small class="text-danger" v-if="msg_error.end_date">{{
                msg_error.end_date[0]
              }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3">Contract Description</label>
            <div class="col-sm-9">
              <small class="text-secondary"
                >Fill in the description fields as needed</small
              >
              <textarea
                class="form-control"
                rows="3"
                v-model="model.description"
              ></textarea>
              <small class="text-danger" v-if="msg_error.description">{{
                msg_error.description[0]
              }}</small>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-12">
              <label class="text-primary mb-0"
                ><i class="ti ti-info-alt"></i> Detail Information Client</label
              >
              <small class="text-secondary"
                >Please fill completely detail data.</small
              >
              <hr />
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3"
              >Client Base <small class="text-danger">*</small></label
            >
            <div class="col-sm-9">
              <select
                v-model="isBaseClient"
                class="form-control"
                @change="switchClientBase()"
              >
                <option value="new">New Client</option>
                <option value="old">Take From Resource Client</option>
              </select>
            </div>
          </div>
          <div class="form-group" v-if="isBaseClient == 'old'">
            <label class="col-sm-3"></label>
            <div class="col-sm-9">
              <a
                href="javascript:;"
                class="btn btn-execute"
                @click="isEnablePopup = 'client'"
                ><i class="ti ti-server text-primary"></i>&nbsp; Browse Client
                ...</a
              >
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3"
              >Client Name <small class="text-danger">*</small></label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                placeholder="...."
                v-model="model.name"
                :readonly="isBaseClient == 'old' ? true : false"
              />
              <small class="text-danger" v-if="msg_error.name">{{
                msg_error.name[0]
              }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3"
              >Email Address <small class="text-danger">*</small></label
            >
            <div class="col-sm-9">
              <input
                type="email"
                class="form-control"
                placeholder="...."
                v-model="model.email"
                :readonly="isBaseClient == 'old' ? true : false"
              />
              <small class="text-danger" v-if="msg_error.email">{{
                msg_error.email[0]
              }}</small>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-3"
              >Phone Number <small class="text-danger">*</small></label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                placeholder="...."
                v-model="model.phone_number"
                :readonly="isBaseClient == 'old' ? true : false"
              />
              <small class="text-danger" v-if="msg_error.phone_number">{{
                msg_error.phone_number[0]
              }}</small>
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-3"
              >Client Address <small class="text-danger">*</small></label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                v-model="provinces_name"
                readonly
              />
              <small class="text-danger" v-if="msg_error.id_propinsi">{{
                msg_error.id_propinsi[0]
              }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3"></label>
            <div class="col-sm-9">
              <select
                v-model="model.id_kota"
                class="form-control"
                :disabled="model_kota.length > 0 ? false : true"
                :readonly="isBaseClient == 'old' ? true : false"
              >
                <option disabled value="">-- Pilih Kabupaten --</option>
                <option
                  v-for="(items, idx) in model_kota"
                  :value="items.id"
                  :key="idx"
                >
                  {{ items.name ? items.name : items.nama_kota }}
                </option>
              </select>
              <small class="text-danger" v-if="msg_error.id_kota">{{
                msg_error.id_kota[0]
              }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3"></label>
            <div class="col-sm-9">
              <Gmap
                name="example"
                :default_model="markerCoordinates"
                ref="Gmap"
                v-if="isAutoloadMaps"
              ></Gmap>
              <small class="text-danger" v-if="msg_error.office_address">{{
                msg_error.office_address[0]
              }}</small>
              <small class="text-danger" v-if="msg_error.lat">{{
                msg_error.lat[0]
              }}</small>
              <small class="text-danger" v-if="msg_error.lng">{{
                msg_error.lng[0]
              }}</small>
            </div>
          </div>

          <!-- new section client -->
          <div class="form-group">
            <div class="col-sm-12">
              <label class="text-primary mb-0"
                ><i class="ti ti-info-alt"></i> Client Station Area Base</label
              >
              <small class="text-secondary">Describe station area base.</small>
              <hr />
            </div>

            <div class="mt-2">
              <div class="col-sm-3">
                Request Man Power
                <span class="badge badge-primary">{{
                  this.mp.data.length
                }}</span>
                <br />
                <small class="text-secondary"
                  >Setting Total Man Power and Job Position on Contract
                </small>
              </div>
              <div class="col-sm-9">
                <a
                  href="javascript:;"
                  class="btn btn-light mb-1"
                  @click="OpenDialog('manpower')"
                  ><i class="ti ti-plus text-success"></i> Manage Man Power
                  ...</a
                >
                <br />
                <div class="mt-3" v-if="this.mp.data.length > 0">
                  <div
                    class="list-section"
                    v-for="(items, idx) in this.mp.data"
                    :key="idx"
                  >
                    - {{ items.total }} ( {{ items.job }} )
                  </div>
                </div>
                <small class="text-danger" v-if="msg_error.man_power">{{
                  msg_error.man_power[0]
                }}</small>
              </div>
            </div>

            <div class="mt-5">
              <div class="col-sm-3">
                Total Shift Work :
                <span class="badge badge-primary">{{
                  this.sw.data.length
                }}</span
                ><br />
                <small class="text-secondary"
                  >Add more area to listing your unit.
                </small>
              </div>
              <div class="col-sm-9">
                <a
                  href="javascript:;"
                  class="btn btn-light mb-1"
                  @click="OpenDialog('shiftwork')"
                  ><i class="ti ti-plus text-success"></i> Manage Shift Work
                  ...</a
                >
                <br />
                <div class="mt-3" v-if="this.sw.data.length > 0">
                  <div
                    class="list-section"
                    v-for="(items, idx) in this.sw.data"
                    :key="idx"
                  >
                    - {{ items.name }} ( {{ items.hour }} )
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-5">
              <div class="col-sm-3">
                Total Area :
                <span class="badge badge-primary">{{
                  data_section.length
                }}</span
                ><br />
                <small class="text-secondary"
                  >Add more area to listing your unit.
                </small>
              </div>
              <div class="col-sm-5">
                <a
                  href="javascript:;"
                  class="btn btn-light mb-1"
                  @click="AddMoreSection()"
                  ><i class="ti ti-plus text-success"></i> Manage New Section
                  ...</a
                >
              </div>

              <div
                class="row mt-3"
                v-for="(items_parent, index_parent) in data_section"
                :key="index_parent"
              >
                <div class="col-sm-3"></div>
                <div class="col-md-8">
                  <h5>
                    SECTION #{{ index_parent + 1 }}

                    <a
                      href="javascript:;"
                      class="float-right ml-3"
                      @click="EditSection(items_parent.section)"
                      ><i class="ti ti-pencil text-primary"></i
                    ></a>
                    &nbsp;<span class="text-light">|</span> &nbsp;
                    <a
                      href="javascript:;"
                      class="float-right"
                      @click="DeleteSection(index_parent)"
                      ><i class="ti ti-trash text-danger"></i
                    ></a>
                  </h5>
                  <div
                    v-for="(items, idx) in items_parent.data"
                    :key="idx"
                    :class="'form-group mt-2 initial-section'"
                  >
                    <div class="list-section">
                      {{ idx + 1 }} - Floor({{ items.area_floor }}),
                      {{ items.area_type }} - {{ items.area_name }}, Job Task
                      ({{ items.area_taks == null ? "..." : items.area_taks }})
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- new section client -->
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-md-12 text-right">
          <a
            href="javascript:;"
            class="btn btn-secondary mr-3"
            @click="$router.go(-1)"
            >CANCEL</a
          >
          <button type="submit" class="btn btn-primary btn-full-screen">
            Submit Data
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import $ from "jquery";
import Gmap from "../../../services/Gmap";
import PopupClientMaster from "../../ClientBase/Master/@PopupClientMaster";
import SelectBranch from "../../Branch/@SelectBranch";

export default {
  components: {
    Gmap,
    PopupClientMaster,
    SelectBranch,
  },
  data() {
    return {
      meta_url: "contract",
      isAutoloadMaps: true,
      isBaseClient: "old",
      isEnablePopup: false,
      isEnableSelectBranch: false,
      sdate: new Date(Date.now() + -6 * 24 * 3600 * 1000)
        .toISOString()
        .slice(0, 10),
      model: { name: "", id_kota: "" },
      model_kota: [],
      markerCoordinates: [],

      popup_mode: "insert",
      data_store: [],
      no_section: 0,
      active_edit_section: 1,
      data_section: [],
      data_slot: [],
      data_shift: [],
      mp: { total: 1, job: "", msg_error: "", data: [] },
      sw: { name: "", hour: "", msg_error: "", data: [] },
      model_jobpos: [],
      meta_filter: { id_branch: "" },
      provinces_name: "",
      branch_name: "",
    };
  },
  computed: {
    ...mapState("crud", {
      msg_error: (state) => state.error,
    }),
  },
  created() {
    if (localStorage.getItem("userPrivilegesName") == "root") {
      this.isEnableSelectBranch = true;
    } else {
      this.model.id_branch = localStorage.getItem("userBranchId");
      this.branch_name = localStorage.getItem("userBranchName");
    }

    this.model.id_propinsi = localStorage.getItem("id_provinces");
    this.provinces_name = localStorage.getItem("provinces_name");
  },
  mounted() {
    this.fetchKota();
    this.QueryJobPosition();
    this.DataQuery();
  },
  methods: {
    OpenDialog(initial) {
      window.scrollTo(500, 0);
      this.isEnablePopup = initial;
    },
    processBranch(data) {
      this.model.id_branch = data;
    },
    closeClient() {
      this.isEnablePopup = false;
    },
    applyClient(data) {
      this.isAutoloadMaps = false;
      this.model.id_client = data.id;
      this.model.name = data.name;
      this.model.email = data.email;
      this.model.phone_number = data.phone_number;
      this.model.id_propinsi = data.id_propinsi;
      this.model.id_kota = data.id_kota;

      this.markerCoordinates = {
        latitude: parseFloat(data.lat),
        longitude: parseFloat(data.lng),
        address: data.office_address,
      };
      setTimeout(() => {
        this.isAutoloadMaps = true;
      }, 200);
    },
    AddShiftWork() {
      let model = this.sw.data;
      if (this.sw.name !== "" && this.sw.hour !== "") {
        this.sw.msg_error = "";
        model.push({ name: this.sw.name, hour: this.sw.hour });
        this.sw.name = "";
        this.sw.hour = "";
      } else {
        this.sw.msg_error = "Please insert shift name and hour !";
      }
    },
    RemoveShiftWorker(idx) {
      let model = this.sw.data;
      model.splice(idx, 1);
    },
    SaveSectionRoom() {
      let data_section = this.data_section;

      for (let key in data_section) {
        var data_bind = data_section[key].data;

        if (data_bind.length > 0) {
          var temp_data = [];
          for (let items in data_bind) {
            if (data_section[key].data[items].area_name !== "") {
              temp_data.push(data_section[key].data[items]);
            } else {
              alert("Please entry completely all room name");
              return;
            }
          }

          if (data_section[key]) {
            data_section[key].data = temp_data;
          }
        }
      }

      // console.log(data_section);
      this.isEnablePopup = false;
    },
    AddMoreManPower() {
      console.log("add man power");
      // console.log(this.mp.data);
      let model = this.mp.data;
      this.mp.msg_error = "";
      if (this.mp.job !== "") {
        for (let key in model) {
          if (model[key].job == this.mp.job) {
            this.mp.msg_error = "Job position is already exists !";
            return;
          }
        }
        model.push({ total: this.mp.total, job: this.mp.job });
      } else {
        this.mp.msg_error = "Please choose job position !";
      }
      console.log(this.mp.data);
    },
    RemoveManPower(idx) {
      let model = this.mp.data;
      model.splice(idx, 1);
    },
    AddMoreSection() {
      this.popup_mode = "insert";
      this.no_section = this.no_section + 1;
      this.active_edit_section = this.no_section;
      // console.log(this.data_section)
      this.data_section.push({
        section: this.no_section,
        data: [
          {
            section: "",
            area_type: "indoor",
            area_task: "",
            area_name: "",
            area_floor: 1,
          },
        ],
      });

      window.scrollTo(500, 0);
      this.isEnablePopup = "section";
    },
    EditSection(no) {
      this.popup_mode = "edit";
      this.active_edit_section = no;

      this.isEnablePopup = "section";
    },
    DeleteSection(no) {
      if (confirm("Continue remove section #" + (no + 1))) {
        this.data_section.splice(no, 1);
      }
    },
    AddMoreSlotRoom() {
      let model = this.data_slot;
      let data_section = this.data_section;
      for (let idx in data_section) {
        if (data_section[idx].section == this.no_section) {
          data_section[idx].data.push({
            section: 1,
            area_type: "indoor",
            area_task: "",
            area_name: "",
            area_floor: 1,
          });
        }
      }

      $("#add-more-notify").text("1 slot unit is already created.");
      $("#add-more-notify").show();
      setTimeout(() => {
        $("#add-more-notify").fadeOut();
      }, 1000);
    },
    removeSlotRoom(idx) {
      this.data_slot.splice(idx, 1);
    },

    QueryJobPosition() {
      axios
        .post("job_position/data/all")
        .then((res) => {
          this.model_jobpos = res.data;
        })
        .finally(() => {});
    },
    async DataQuery() {
      this.isAutoloadMaps = false;

      await axios
        .get(this.meta_url + "/find/" + localStorage.getItem("QUERY_ID"))
        .then((res) => {
          let query = res.data;

          let dataMapping = query.data;
          let sectionMapping = query.section;

          this.model.id_branch = dataMapping.id_branch;
          this.model.contract_number = dataMapping.contract_number;
          this.model.contract_value = dataMapping.contract_value;
          this.model.contract_date = this.$moment(
            dataMapping.contract_date
          ).format("DD-MM-YYYY");
          this.model.description = dataMapping.description;

          this.model.id_client = dataMapping.id_client;
          this.model.name = dataMapping.client_name;
          this.model.email = dataMapping.email;
          this.model.phone_number = dataMapping.phone_number;
          this.model.id_propinsi = dataMapping.id_propinsi;
          this.model.id_kota = dataMapping.id_kota;
          this.markerCoordinates = {
            latitude: parseFloat(dataMapping.lat),
            longitude: parseFloat(dataMapping.lng),
            address: dataMapping.office_address,
          };

          setTimeout(() => {
            this.isAutoloadMaps = true;
          }, 200);

          // mapping ShiftWork Existing Contract Project
          let shifWorkMapping = JSON.parse(dataMapping.contract_shift);
          if (shifWorkMapping != undefined || shifWorkMapping.length > 0) {
            for (let key in shifWorkMapping) {
              this.sw.data.push({
                name: shifWorkMapping[key].name,
                hour: shifWorkMapping[key].hour,
              });
            }
          }

          // mapping ManPower Existing Contract Project
          let manPower = JSON.parse(dataMapping.man_power);
          // console.log(manPower.length)
          if (manPower != undefined || manPower.length > 0) {
            for (let key in manPower) {
              this.mp.data.push({
                total: manPower[key].total,
                job: manPower[key].job,
              });
            }
          }

          // mapping Section Existing Contract Project
          let counterSection = 0;
          for (let key in sectionMapping) {
            counterSection++;
            let lengthSection = sectionMapping[key].length;
            if (lengthSection > 0) {
              let temp_data = [];
              for (let i = 0; i < lengthSection; i++) {
                if (sectionMapping[key][i].section == key) {
                  temp_data.push(sectionMapping[key][i]);
                }
              }

              this.data_section.push({
                section: key,
                data: temp_data,
              });
            }
          }

          this.no_section = counterSection;

          // console.log(this.data_section);
        })
        .finally(() => {
          // this.$store.state.loading.status = false;
        });
    },
    async fetchKota() {
      this.model_kota = JSON.parse(localStorage.getItem("data_regencies"));
    },
    switchClientBase() {
      if (this.isBaseClient == "new") {
        this.isAutoloadMaps = false;

        // reset default value model from old
        this.model.id_client = "";
        this.model.name = "";
        this.model.email = "";
        this.model.phone_number = "";

        this.model_kota = [];
        this.model.id_kota = "";
        this.model.id_propinsi = "";
        this.model.lat = "";
        this.model.lng = "";
        this.model.office_address = "";
        this.markerCoordinates = [];

        setTimeout(() => {
          this.isAutoloadMaps = true;
        }, 200);
      }
    },
    async submit(e) {
      e.preventDefault();

      this.model.contract_id_old = localStorage.getItem("QUERY_ID");

      this.model.isBaseClient = this.isBaseClient;
      this.model.man_power = this.mp.data;
      this.model.shift = this.sw.data;

      // rewrite data DOM  parsing to model
      this.model.office_address =
        document.getElementById("input_address").value;
      this.model.lat = document.getElementById("input_lat").value;
      this.model.lng = document.getElementById("input_lon").value;
      this.model.data_slot = this.data_section;
      this.model.contract_date = this.$moment(this.sdate).format("DD-MM-YYYY");

      // start date and end date
      var sdate = document.getElementById("startDate").value;
      var edate = document.getElementById("endDate").value;
      this.model.start_date =
        sdate == "" ? "" : this.$moment(sdate).format("DD-MM-YYYY");
      this.model.end_date =
        edate == "" ? "" : this.$moment(edate).format("DD-MM-YYYY");

      // this.$store.state.loading.msg = "Updating Data...";
      // this.$store.state.loading.status = true;

      // console.log(this.model);
      let data = {
        url: this.meta_url + "/store",
        callback_url: "jobcon/contract-data",
        form_data: this.model,
      };

      // console.log(data)

      let res = await this.$store.dispatch("crud/storeData", data);
    },
  },
};
</script>

<style lang="scss" scoped>
#add-more-notify {
  display: none;
}
.area-section {
  background-color: whitesmoke;
}
.list-data {
  position: relative;
  float: left;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  .ti {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}

.list-section {
  width: 100%;
  float: left;
  border-top: 1px solid whitesmoke;
  padding: 10px;
}
</style>