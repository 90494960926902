<template>
    <div class="popup popup-lg">
        <div class="content">
            <small id="close-popup" @click="close()"><i class="ti ti-close"></i></small>
            <h5>Master Data Branch <br/><small>Please select data Branch Station.</small><hr/></h5>
            <div class="row p-0">
                <div class="col-md-12">
                    <div class="table-header">
                        <h5 >Master Data <br/> <small>Total Records : <b>{{ data_model.total | formatPrice }}</b></small></h5>
                        <div class="group-search pull-right">
                            <a class="btn" @click="tableRefresh()" ><i class="ti ti-reload"></i></a>
                            <a class="btn" @click="isActiveSearch()" ><i :class="isEnableTools == false  ? 'ti ti-search' : 'ti ti-close'"></i></a>
                            <div class="select" v-if="isEnableTools" >
                                <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                                    <option value="">-- Choose Field --</option>
                                    <option value="name">Branch Name</option>
                                    <option value="description">Description</option>
                                </select>
                            </div>
                            <div class="group" v-if="isEnableTools">
                                <input type="date" v-model="meta_data.sval" v-if="meta_data.skey == 'created_at' || meta_data.skey == 'updated_at'"/>
                                <input type="text" v-model="meta_data.sval" placeholder="Enter Keyword ...." v-else/>
                                <a class="btn" @click="tableSearch()">
                                    SEARCH DATA 
                                </a>
                            </div> 
                         
                        </div>
                    </div>

                    <div class="table-cover">
                        <table class="table-hover" style="width:1800px;">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th width="70" class="no-filter text-center" >Action</th>
                                    <th :class="meta_data.okey == 'name' ? 'active' : ''" v-on:click="tableOrdering('name')">Branch Station Name</th>
                                    <th :class="meta_data.okey == 'id_provinces' ? 'active' : ''" v-on:click="tableOrdering('id_provinces')">Propinsi</th>
                                    <th :class="meta_data.okey == 'group_regencies' ? 'active' : ''" v-on:click="tableOrdering('group_regencies')">Kota/Kabupaten</th>
                                    <th :class="meta_data.okey == 'description' ? 'active' : ''" v-on:click="tableOrdering('description')">Branch Description</th>
                                    <th :class="meta_data.okey == 'created_at' ? 'active' : ''" v-on:click="tableOrdering('created_at')">Created Date</th>
                                    <th :class="meta_data.okey == 'updated_at' ? 'active' : ''" v-on:click="tableOrdering('updated_at')">Updated Date</th>
                                </tr>
                            </thead>
                            <tbody v-if="data_model.total > 0">
                                <tr  v-for="(items, idx) in data_model.data" :key="idx">
                                    <td>{{ idx + data_model.from }}</td>
                                   
                                    <td width="100" v-if="id_privileges == 12 || id_privileges == 13 || id_privileges == 14 || id_privileges == 15">
                                        <a href="javascript:;" class="btn btn-primary" @click="applyData('single', data_model.data[idx])"><i class="ti ti-check text-light "></i> Choose</a>
                                    </td>
                                     <td class="text-center" v-else> 
                                        <input type="checkbox"  class="form-control" @change="collectGroup(idx)" :checked="validateChecked(items.id)" :id="'checkbox-'+items.id" style="width:15px; height:15px;margin:0 auto;">
                                    </td>
                                    
                                    <td width="250"> {{ items.name }}</td>
                                    <td width="150"> {{ items.nama_provinsi }}</td>
                                    <td width="550"> 
                                        <div v-if="items.applying_regencies == 'selected-area'">
                                            <span class="btn btn-light m-1" v-for="(items_area, idx) in DataDecode(items.group_regencies)" :key="idx" >{{ items_area.name }} </span>
                                        </div>
                                        <div v-else>
                                            <span class="btn btn-light m-1">All Area Regencies</span>
                                        </div>
                                    </td>
                                    <td width="350"> {{ items.description }}</td>
                                    <td>{{ items.created_at | moment("DD/MM/YYYY") }}</td>
                                    <td>{{ items.updated_at | moment("DD/MM/YYYY") }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination" >
                        <small class="info"><i class="ti ti-split-h"></i> Scroll horizontal to view data.</small>
                        <paginate
                        :page-count="parseInt(data_model.last_page)"
                        :click-handler="tableQuery"
                        :container-class="'pagination'"
                        :page-class="'page-item'" >
                        </paginate>
                    </div>

                </div>
                <div class="col-md-12">
                    <hr/>
                    <a href="javascript:;" class="btn btn-primary btn-execute float-right" @click="applyData('multi', model_group)">Continue Assign</a>
                </div>   
            </div> 
        </div>
    </div>
</template>


<script>

    import axios from 'axios';
    import $ from 'jquery';
    import { mapState } from 'vuex';

    export default {
        props:['id_privileges'],
        data() 
        {
            return { 
                isEnableTools : false, 
                isEnableOrder : false,
                meta_url : 'branch',
                
                model_collection:[],
                model_group:[],
                meta_data :{ okey: 'id', oval:'desc', wkey : '', wval : '', skey : '', sval : ''},
                meta_filter :{  id_provinces : 'all', id_regencies : 'all', id_branch : 'all' }
            }
        },
       
        computed: {
            ...mapState('crud', {
                data_model: state => state.data_model,
                msg_error: state => state.error,
            })
        },
        created()
        {
            this.tableQuery();
        },
        methods:
        {
            close(){
                this.$emit('exit');
            },
            applyData(initial, data){

                if(initial == 'single'){
                    this.$emit('applyData', [data]);
                }
                if(initial == 'multi'){
                    this.$emit('applyData', data);
                }

                this.$emit('exit');
            },
            validateChecked(id)
            {
                if( this.model_collection.length > 0 )
                {
                    var model = this.model_collection;
                    return model.includes(id) ? true : false;
                }
              
            },
            collectGroup(idx)
            {
                let model = this.data_model.data[idx];
                if($('#checkbox-'+model.id).is(":checked") )
                {
                    this.model_collection.push(model.id);
                    if( this.model_group.length < 1 ){
                        this.model_group = [];
                    }
                    this.model_group.unshift(model);
                }else{
                    this.disCollecGroup(idx);
                }
            },

            disCollecGroup(idx)
            {
                let model = this.data_model.data[idx];
                var model_collection = this.model_collection;
                var index_collection = model_collection.indexOf(model.id);
                this.model_collection.splice(index_collection, 1);
                
                var model_group = this.model_group;
                var no = 0;
                for(let key in model_group){
                    if(model_group[key].id == model.id){
                        this.model_group.splice(no, 1);
                    }
                    no++;
                }
            },
            isActiveSearch()
            {
                this.isEnableTools =! this.isEnableTools;
                if(this.isEnableTools) this.meta_data.sval = '';
            },
            DataDecode(data)
            {
                if(data)
                return JSON.parse(data);
            },
            
            tableUpdate(id)
            {
                localStorage.setItem('QUERY_ID', id);
                this.$router.push({name : this.meta_url + '/update'});
            },
            tableSearch()
            {
                if(this.meta_data.skey == ''){
                    alert('🙏, Please choose target field');
                    return;
                }
                if(this.meta_data.sval == ''){
                    alert('🙏, Please enter the keyword');
                    return;
                }
                this.tableQuery();
            },
            tableOrdering(key)
            {
                this.isEnableOrder =! this.isEnableOrder;
                this.meta_data.okey = key;
                this.meta_data.oval = this.isEnableOrder ? 'ASC' : 'DESC';
                this.tableQuery(this.page);
            },
            tableQuery(pageNum)
            {
                this.page = pageNum;
                let data = { 
                    url: this.meta_url+'/data',
                    offset : this.page,
                    search: { key : this.meta_data.skey, value : this.meta_data.sval },
                    order: { key : this.meta_data.okey, value : this.meta_data.oval },
                    where : { key : this.meta_data.wkey, value : this.meta_data.wval },
                    filter : [
                        {key : 'id_provinces', val:this.meta_filter.id_provinces},
                        {key : 'id_regencies', val:this.meta_filter.id_regencies},
                    ]
                };

                this.$store.state.loading.msg = 'Processing Fetching Data...';
                this.$store.state.loading.status = true;
                this.$store.dispatch('crud/getData', data );
            },  
            tableRefresh()
            {
                this.meta_data.skey = '';
                this.meta_data.sval = '';
                this.tableQuery();
            },

            async tableRemove(id)
            {
                if( confirm( 'Are you sure want to remove this data ?' ) )
                { 
                    let data = { url: this.meta_url+'/destroy/'+id };
                    let res = await this.$store.dispatch('crud/deleteData', data );
                    if(res)
                    {
                        if( res.result == 'ok' ){
                            this.tableQuery();
                        }
                        if( res.result == 'false' ){
                            alert(res.msg);
                        }
                    }
                }
            }
        }
    }
</script>
