var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 p-0"},[(_vm.isEnableDetail)?_c('div',{staticClass:"popup popup-sm"},[_c('div',{staticClass:"content"},[_c('small',{attrs:{"id":"close-popup"},on:{"click":function($event){_vm.isEnableDetail = false}}},[_c('i',{staticClass:"ti ti-close"})]),_c('h5',[_vm._v("Detail Application User")]),_c('hr'),_c('div',{staticClass:"row p-0"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"col-sm-12"},[_c('span',{staticClass:"text-secondary"},[_vm._v("Privileges Group")]),_vm._v(" : "),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.model_detail.privileges_name))])]),_c('div',{staticClass:"col-sm-12"},[_c('span',{staticClass:"text-secondary"},[_vm._v("Nickname")]),_vm._v(" : "),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.model_detail.name))])]),_c('div',{staticClass:"col-sm-12"},[_c('span',{staticClass:"text-secondary"},[_vm._v("Email Address ")]),_vm._v(" : "),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.model_detail.email))])])]),(
              _vm.model_detail.id_privileges == 11 ||
              _vm.model_detail.id_privileges == 12 ||
              _vm.model_detail.id_privileges == 13 ||
              _vm.model_detail.id_privileges == 15
            )?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"col-sm-12"},[_c('h6',{staticClass:"text-primary"},[_vm._v("( Branch Station )")]),_c('div',{staticClass:"list-station"},[_c('ul',_vm._l((_vm.model_branch),function(items,idx){return _c('li',{key:idx},[_c('span',{},[_vm._v(_vm._s(items.name))]),_c('br'),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(items.description))])])}),0)])])]):_vm._e(),(
              _vm.model_detail.id_privileges == 12 ||
              _vm.model_detail.id_privileges == 13 ||
              _vm.model_detail.id_privileges == 14
            )?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"col-sm-12"},[(_vm.model_detail.id_privileges == 14)?_c('h6',{staticClass:"text-primary"},[_vm._v(" ( Contract Station ) ")]):_c('h6',{staticClass:"text-primary"},[_vm._v("( Client Station )")]),_c('div',{staticClass:"list-station"},[_c('ul',_vm._l((_vm.model_contract),function(items,idx){return _c('li',{key:idx},[_c('span',{},[_vm._v(_vm._s(items.client_name))]),_vm._v(" "),_c('b',[_c('i',[_vm._v(_vm._s(items.contract_number))])]),_vm._v(" "),_c('span',{class:_vm._f("contractStatus")(items.status)},[_vm._v(" "+_vm._s(_vm._f("cleanString")(items.status))+" ")]),_c('br'),_vm._v(" Periode "+_vm._s(items.start_date)+" - "+_vm._s(items.end_date)+" "),_c('br'),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(items.office_address))])])}),0)])])]):_vm._e(),(
              _vm.model_detail.id_privileges == 16 ||
              _vm.model_detail.id_privileges == 17
            )?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"col-sm-12"},[_c('h6',{staticClass:"text-primary"},[_vm._v("( Provinces )")]),_vm._v(" "+_vm._s(_vm.model_detail.nama_provinsi)+" ")])]):_vm._e(),(_vm.model_detail.id_privileges == 16)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"col-sm-12"},[_c('h6',{staticClass:"text-primary"},[_vm._v("( Regencies )")]),_c('ul',{staticClass:"area-list"},_vm._l((_vm.group_regencies),function(items,idx){return _c('li',{key:idx},[_vm._v(" "+_vm._s(items.name)+" ")])}),0)])]):_vm._e()])])])]):_vm._e(),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-header"},[_c('h5',[_vm._v(" Master Data "),_c('br'),_c('small',[_vm._v("Total Records : "),_c('b',[_vm._v(_vm._s(_vm._f("formatPrice")(_vm.data_model.total)))])])]),_c('div',{staticClass:"group-search pull-right"},[_c('a',{staticClass:"btn",on:{"click":function($event){return _vm.tableRefresh()}}},[_c('i',{staticClass:"ti ti-reload"})]),_c('a',{staticClass:"btn",on:{"click":function($event){return _vm.isActiveSearch()}}},[_c('i',{class:_vm.isEnableTools == false ? 'ti ti-search' : 'ti ti-close'})]),(_vm.isEnableTools)?_c('div',{staticClass:"select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.meta_data.skey),expression:"meta_data.skey"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.meta_data, "skey", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.meta_data.sval = ''}]}},[_c('option',{attrs:{"value":""}},[_vm._v("-- Pilih Kolom --")]),_vm._l((_vm.meta_field),function(items,idx){return _c('option',{key:idx,domProps:{"value":items.key}},[_vm._v(" "+_vm._s(items.value)+" ")])})],2)]):_vm._e(),(_vm.isEnableTools)?_c('div',{staticClass:"group"},[(
                _vm.meta_data.skey == 'users.created_at' ||
                _vm.meta_data.skey == 'users.updated_at'
              )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.meta_data.sval),expression:"meta_data.sval"}],attrs:{"type":"date"},domProps:{"value":(_vm.meta_data.sval)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.meta_data, "sval", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.meta_data.sval),expression:"meta_data.sval"}],attrs:{"type":"text","placeholder":"Enter Keyword ...."},domProps:{"value":(_vm.meta_data.sval)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.meta_data, "sval", $event.target.value)}}}),_c('a',{staticClass:"btn",on:{"click":function($event){return _vm.tableSearch()}}},[_vm._v(" SEARCH DATA ")])]):_vm._e(),(_vm.$store.state.app.access.includes('insert'))?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{ name: _vm.meta_url + '/store' }}},[_c('i',{staticClass:"ti ti-plus"}),_vm._v(" Create New")]):_vm._e()],1),(_vm.isRoot)?_c('div',{staticClass:"group-filter"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group float-right"},[_c('SelectBranch',{on:{"processBranch":_vm.processBranch}})],1)])])]):_vm._e()]),_c('div',{staticClass:"table-cover"},[_c('table',{staticClass:"table-hover",staticStyle:{"min-width":"1200px"}},[_c('thead',[_c('tr',[(JSON.parse(_vm.$store.state.app.access).length > 1)?_c('th',[_vm._v(" Action ")]):_vm._e(),_vm._l((_vm.meta_field),function(items,idx){return _c('th',{key:idx,class:_vm.meta_data.okey == items.key ? 'active' : '',on:{"click":function($event){return _vm.tableOrdering(items.key)}}},[_vm._v(" "+_vm._s(items.value)+" ")])})],2)]),(_vm.data_model.total > 0)?_c('tbody',_vm._l((_vm.data_model.data),function(items,idx){return _c('tr',{key:idx},[(JSON.parse(_vm.$store.state.app.access).length > 1)?_c('td',[(
                    _vm.$store.state.app.access.includes('update') ||
                    _vm.$store.state.app.access.includes('delete')
                  )?_c('div',{staticClass:"tools"},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Tools")]),_c('ul',[_c('li',[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.DetailInformation(idx)}}},[_c('i',{staticClass:"text-success ti ti-info-alt"}),_vm._v(" Detail")])]),(_vm.$store.state.app.access.includes('update'))?_c('li',[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.tableUpdate(items.id)}}},[_c('i',{staticClass:"text-success ti ti-pencil"}),_vm._v(" Edit")])]):_vm._e(),(_vm.$store.state.app.access.includes('delete'))?_c('li',[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.tableRemove(items.id)}}},[_c('i',{staticClass:"text-danger ti ti-trash"}),_vm._v(" Remove")])]):_vm._e()])]):_c('div',{staticClass:"text-center"},[_c('i',{staticClass:"ti ti-minus text-danger"})])]):_vm._e(),_c('td',[_c('span',{class:_vm.generateLabelStatus(items.status)},[_vm._v(_vm._s(items.status))])]),_c('td',[_vm._v(_vm._s(items.email))]),_c('td',[_vm._v(_vm._s(items.privileges_name))]),_c('td',[_vm._v(_vm._s(items.name))]),_c('td',[_vm._v(_vm._s(_vm._f("moment")(items.created_at,"DD/MM/YYYY")))]),_c('td',[_vm._v(_vm._s(_vm._f("moment")(items.updated_at,"DD/MM/YYYY")))])])}),0):_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"22"}},[(!_vm.$store.state.loading.status)?_c('div',{staticClass:"no-data"},[_vm._v(" Sorry, data is not available ")]):_vm._e()])])])])]),_c('div',{staticClass:"pagination"},[_vm._m(0),_c('paginate',{attrs:{"page-count":parseInt(_vm.data_model.last_page),"click-handler":_vm.tableQuery,"container-class":'pagination',"page-class":'page-item'}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('small',{staticClass:"info"},[_c('i',{staticClass:"ti ti-split-h"}),_vm._v(" Scroll horizontal to view data.")])}]

export { render, staticRenderFns }