<template>
  <div class="popup popup-lg">
    <div class="content">
      <small id="close-popup" @click="close()"
        ><i class="ti ti-close"></i
      ></small>
      <h5>
        Images Selected <br /><small>Please select Image notes.</small>
        <hr />
      </h5>
      <div class="row p-0">
        <div class="col-md-12">
          <div class="table-header">
            <h5>
              Images Selected Generate <br />
              <small
                >Total Records : <b>{{ model.length | formatPrice }}</b></small
              >
            </h5>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div
          class="col-lg-6 col-md-6 mb-4"
          v-for="(item, index) in model"
          :key="index"
        >
          <div class="container-img bg-white rounded shadow-sm">
            <img
              :src="item.photo"
              class="img-fluid card-img-top"
              style="position: relative"
            />
            <div class="content">
              <a href="javascript:;" class="text-white"
                ><b>{{ item.area }}</b></a
              >

              <p class="mb-1 text-white">
                Section {{ item.section }} ,Floor {{ item.floor }}, type
                {{ item.type }} <br />
                {{ item.task }} <br />
                Notes : {{ item.notes }} <br />
              </p>

              <a
                @click="pickImage(index)"
                class="bg-light btn btn-block rounded"
              >
                <i class="ti ti-hand-point-up mr-2"></i>
                Choose
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-img {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}
.container-img img {
  vertical-align: middle;
}
.container-img .content {
  position: absolute;
  bottom: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.4);
  color: #f1f1f1;
  width: 100%;
  /* height: 100%; */
}
.pick-image-container:hover {
  cursor: pointer;
  transition: 0.5s ease;
  background-color: #008cba !important;
  color: white !important;
  text-decoration: none;
}
#pick-image-container-active {
  background-color: #008cba !important;
  color: white !important;
  text-decoration: none;
}
</style>
<script>
import $ from "jquery";
export default {
  props: ["list_images_selected"],

  data() {
    return {
      isEnableTools: false,
      model: [],
      model_group: [],
      model_collection: [],
      temp_model: [],
      activeBefore: false,
    };
  },

  computed: {},
  created() {
    this.model = this.list_images_selected;
  },

  methods: {
    validateImageSelected(idImageExist) {
      const getExistImage = this.list_check_images_before_after.filter(
        (item) => item.id === idImageExist
      );

      if (getExistImage.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    close() {
      this.$emit("exit");
    },
    pickImage(idx) {
      let model = this.model[idx];
      this.$emit("exit");
      this.$emit("applyData", model);
    },
  },
};
</script>
