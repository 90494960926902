<template>
  <div class="col-md-12 p-0">
    <div class="card">
      <div class="card-body">
        <div class="table-info table-update">
          <form @submit="submit">
            <table>
              <tr>
                <td colspan="2" class="head-title">Personal Info</td>
              </tr>

              <tr>
                <td class="title">
                  Profile Image Employee <small class="text-danger">*</small>
                </td>
                <td>
                  <div style="width: 220px">
                    <UploadSingle
                      :default_image="generatePathImage(data_image, false)"
                      v-on:processFile="processFile"
                      :label="'Image Type / Series'"
                      :msg_error="msg_error"
                    />
                  </div>
                </td>
              </tr>
              <tr v-if="isRoot">
                <td class="title">
                  Branch Station <small class="text-danger">*</small>
                </td>
                <td>
                  <SelectBranch v-on:processBranch="processBranch" />
                  <small class="text-danger" v-if="msg_error.id_branch"
                    ><br />{{ msg_error.id_branch[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Job Position <small class="text-danger">*</small>
                </td>
                <td>
                  <select v-model="model.id_job_position" class="form-control">
                    <option value="" disabled>-- Choose One --</option>
                    <option
                      v-for="(items, idx) in model_job_position"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.title }}
                    </option>
                  </select>
                  <small class="text-danger" v-if="msg_error.id_job_position"
                    ><br />Job Position field is required.</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Firstname <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    v-model="model.firstname"
                    placeholder="....."
                    autofocus
                  />
                  <small class="text-danger" v-if="msg_error.firstname"
                    ><br />{{ msg_error.firstname[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Lastname <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    v-model="model.lastname"
                    placeholder="....."
                  />
                  <small class="text-danger" v-if="msg_error.lastname"
                    ><br />{{ msg_error.lastname[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Gender <small class="text-danger">*</small>
                </td>
                <td>
                  <select v-model="model.gender">
                    <option value="" disabled>-- Choose One --</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <small class="text-danger" v-if="msg_error.gender"
                    ><br />{{ msg_error.gender[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Marital Status <small class="text-danger">*</small>
                </td>
                <td>
                  <select v-model="model.marital_status">
                    <option value="" disabled>-- Choose One --</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                  </select>
                  <small class="text-danger" v-if="msg_error.marital_status"
                    ><br />{{ msg_error.marital_status[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Email Address<small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    v-model="model.email"
                    placeholder="....."
                  />
                  <small class="text-danger" v-if="msg_error.email"
                    ><br />{{ msg_error.email[0] }}</small
                  >
                </td>
              </tr>

              <tr>
                <td class="title">
                  Propinsi <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="provinces_name"
                    readonly
                    style="width: 220px"
                  />
                  <small class="text-danger" v-if="msg_error.id_propinsi">{{
                    msg_error.id_propinsi[0]
                  }}</small>
                </td>
              </tr>
              <tr>
                <td class="title">
                  Kota/Kabupaten <small class="text-danger">*</small>
                </td>
                <td>
                  <select
                    class="form-control col-md-4"
                    v-model="model.id_kota"
                    @change="fetchKecamatan()"
                    :disabled="model_kota.length > 0 ? false : true"
                  >
                    <option value="" disabled>-- Choose One --</option>
                    <option
                      v-for="(items, idx) in model_kota"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.name }}
                    </option>
                  </select>
                  <small class="text-danger" v-if="msg_error.id_kota">{{
                    msg_error.id_kota[0]
                  }}</small>
                </td>
              </tr>
              <tr>
                <td class="title">
                  Kecamatan <small class="text-danger">*</small>
                </td>
                <td>
                  <select
                    class="form-control"
                    v-model="model.id_kecamatan"
                    :disabled="model_kecamatan.length > 0 ? false : true"
                  >
                    <option value="" disabled>-- Choose One --</option>
                    <option
                      v-for="(items, idx) in model_kecamatan"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.nama_kecamatan }}
                    </option>
                  </select>
                  <small class="text-danger" v-if="msg_error.id_kecamatan">{{
                    msg_error.id_kecamatan[0]
                  }}</small>
                </td>
              </tr>
              <tr>
                <td class="title">
                  Address <small class="text-danger">*</small>
                </td>
                <td>
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="model.address"
                    Placeholder="...."
                  ></textarea>
                  <small class="text-danger" v-if="msg_error.address"
                    ><br />{{ msg_error.address[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Individual Skill / Keterampilan
                  <small class="text-danger">*</small>
                </td>
                <td>
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="model.individual_skill"
                    Placeholder="...."
                  ></textarea>
                  <small class="text-danger" v-if="msg_error.individual_skill"
                    ><br />{{ msg_error.individual_skill[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td colspan="2" class="head-title">Identify Info</td>
              </tr>
              <tr>
                <td class="title">
                  Identity Card <small class="text-danger">*</small>
                </td>
                <td>
                  <select v-model="model.identity_card">
                    <option value="" disabled>-- Choose One --</option>
                    <option value="Ktp">KTP</option>
                    <option value="Sim">SIM</option>
                    <option value="Paspor">PASPOR</option>
                  </select>
                  <small class="text-danger" v-if="msg_error.identity_card"
                    ><br />{{ msg_error.identity_card[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Identify Card Number <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="....."
                    v-model="model.identity_number"
                  />
                  <small class="text-danger" v-if="msg_error.identity_number"
                    ><br />{{ msg_error.identity_number[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Nationality <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="....."
                    v-model="model.nationality"
                  />
                  <small class="text-danger" v-if="msg_error.nationality"
                    ><br />{{ msg_error.nationality[0] }}</small
                  >
                </td>
              </tr>

              <tr>
                <td class="title">
                  Birthday <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="date"
                    placeholder="....."
                    v-model="model.birth_date"
                  />
                  <small class="text-danger" v-if="msg_error.birth_date"
                    ><br />{{ msg_error.birth_date[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Birthplace <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="....."
                    v-model="model.birth_place"
                  />
                  <small class="text-danger" v-if="msg_error.birth_place"
                    ><br />{{ msg_error.birth_place[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td colspan="2" class="head-title">Bank Information Account</td>
              </tr>
              <tr>
                <td class="title">
                  Bank Name <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="....."
                    v-model="model.bank_name"
                  />
                  <small class="text-danger" v-if="msg_error.bank_name"
                    ><br />{{ msg_error.bank_name[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Account Name <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="....."
                    v-model="model.bank_account_name"
                  />
                  <small class="text-danger" v-if="msg_error.bank_account_name"
                    ><br />{{ msg_error.bank_account_name[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Account Number <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="....."
                    v-model="model.bank_account_number"
                  />
                  <small
                    class="text-danger"
                    v-if="msg_error.bank_account_number"
                    ><br />{{ msg_error.bank_account_number[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td colspan="2" class="head-title">
                  Mobile Application Access Account<br /><small
                    class="text-secondary"
                    >Please fill default password account</small
                  >
                </td>
              </tr>

              <tr>
                <td class="title">
                  Phone Number <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control col-md-1 float-left mr-2"
                    placeholder="+62"
                    readonly
                  />
                  <input
                    type="number"
                    min="1"
                    class="form-control col-md-10 float-left"
                    v-model="model.phone_number"
                    @keyup="removeLeadingZero"
                    @keypress="onlyNumber"
                    placeholder="....."
                  /><br />
                  <small class="text-danger" v-if="msg_error.phone_number"
                    ><br />{{ msg_error.phone_number[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Password <small class="text-danger">*</small>
                </td>
                <td>
                  <small class="text-secondary"
                    >Please enter numeric for default password !</small
                  >
                  <password
                    v-model="model.password"
                    :toggle="true"
                    defaultClass="form-control"
                    name="password"
                    placeholder="Confirmation Password"
                  />
                  <small class="text-danger" v-if="msg_error.password">{{
                    msg_error.password[0]
                  }}</small>
                </td>
              </tr>
              <tr>
                <td class="title">
                  Confirmation Password <small class="text-danger">*</small>
                </td>
                <td>
                  <password
                    v-model="model.password_confirmation"
                    :toggle="true"
                    defaultClass="form-control"
                    name="password"
                  />
                  <small
                    class="text-danger"
                    v-if="msg_error.password_confirmation"
                    >{{ msg_error.password_confirmation[0] }}</small
                  >
                </td>
              </tr>
            </table>
            <hr />
            <div class="form-group mt-5 mb-5">
              <div class="col-md-12 text-center">
                <span class="text-secondary float-left"
                  >Mandatory (<span class="text-danger">*</span>) must be filled
                  before saving data</span
                >

                <a
                  href="javascript:;"
                  class="btn btn-secondary mr-3"
                  @click="$router.go(-1)"
                  >CANCEL</a
                >
                <button type="submit" class="btn btn-primary btn-full-screen">
                  Submit Data
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import router from "../../router";
import UploadSingle from "../../services/UploadSingle";
import Password from "vue-password-strength-meter";
import SelectBranch from "../Branch/@SelectBranch";

export default {
  components: {
    Password,
    UploadSingle,
    SelectBranch,
  },
  data() {
    return {
      isRoot: false,
      data_image: "",
      model: { id_kota: "", id_kecamatan: "" },
      model_job_position: [],
      provinces_name: "",
      model_kota: [],
      model_kecamatan: [],
    };
  },

  computed: {
    ...mapState("crud", {
      msg_error: (state) => state.error,
    }),
  },
  created() {
    if (localStorage.getItem("userPrivilegesName") == "root") {
      this.isRoot = true;
    } else {
      this.model.id_branch = localStorage.getItem("userBranchId");
      this.branch_name = localStorage.getItem("userBranchName");
    }

    this.model.id_propinsi = localStorage.getItem("id_provinces");
    this.provinces_name = localStorage.getItem("provinces_name");

    this.fetchKota();
    this.QueryJobPosition();
  },
  methods: {
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
      }
    },
    removeLeadingZero() {      
      if(parseInt(this.model.phone_number, 10)){
        let phone_number = parseInt(this.model.phone_number, 10);
        this.model.phone_number = phone_number;
      }
    },
    processBranch(id_branch) {
      this.model.id_branch = id_branch;
    },
    async fetchKota() {
      this.model_kota = JSON.parse(localStorage.getItem("data_regencies"));
    },
    async fetchKecamatan() {
      this.model.id_kecamatan = "";
      return await axios
        .post("location/kecamatan/data", {
          where: { key: "kota_id", value: this.model.id_kota },
          order: { key: "id", value: "asc" },
        })
        .then((response) => {
          this.model_kecamatan = response.data;
          return response.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    generatePathImage(data, thumb) {
      
      if (data !== "") {
        var path = this.$store.state.api_resource + data;
        if (thumb) {
          var sp = data.split(".");
          var format_type = sp[1];
          path =
            this.$store.state.api_resource +
            data.replace("." + format_type, "_thumb." + format_type);
        }
        return path;
      }
    },
    processFile(data) {
      this.data_image = data;
    },
    async QueryJobPosition() {
      await axios
        .get("job_position/all")
        .then((res) => {
          this.model_job_position = res.data;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
          this.isEnableEquipment = true;
        });
    },
    async submit(e) {
      
      e.preventDefault();
      this.model.photo = this.data_image;

      // this.$store.state.loading.msg = "Saving Data...";
      // this.$store.state.loading.status = true;

      let data = {
        url: "employee/store",
        callback_url: "employee/data",
        form_data: this.model,
      };
      // console.log(data.form_data);
      let res = await this.$store.dispatch("crud/storeData", data);
    },
  },
};
</script>

<style lang="scss">
table {
  tr {
    td.title {
      width: 30%;
      small {
        width: auto;
        float: right;
      }
    }
    td {
      select {
        width: 30% !important;
        border: 1px solid red;
      }
      textarea {
        border: 1px solid whitesmoke !important;
        font-style: normal !important;
      }
      small {
        margin-top: 5px !important;
        margin-left: 0px !important;
      }
    }
  }
}
</style>
