<template>
  <div class="popup popup-lg">
    <div class="content">
      <small id="close-popup" @click="close()"
        ><i class="ti ti-close"></i
      ></small>
      <h5>
        Master Data Client Station <br /><small
          >Please select data client station.</small
        >
        <hr />
      </h5>
      <div class="row p-0">
        <div class="col-md-12">
          <div class="table-header">
            <h5>
              Master Data <br />
              <small
                >Total Records : <b>{{ model.total | formatPrice }}</b></small
              >
            </h5>
            <div class="group-search pull-right">
              <a class="btn" @click="tableRefresh()"
                ><i class="ti ti-reload"></i
              ></a>
              <a class="btn" @click="isActiveSearch()"
                ><i
                  :class="
                    isEnableTools == false ? 'ti ti-search' : 'ti ti-close'
                  "
                ></i
              ></a>
              <div class="select" v-if="isEnableTools">
                <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                  <option value="">-- Choose Field --</option>
                  <option value="title">Job Title</option>
                  <option value="description">Description</option>
                </select>
              </div>
              <div class="group" v-if="isEnableTools">
                <input
                  type="date"
                  v-model="meta_data.sval"
                  v-if="
                    meta_data.skey == 'created_at' ||
                    meta_data.skey == 'updated_at'
                  "
                />
                <input
                  type="text"
                  v-model="meta_data.sval"
                  placeholder="Enter Keyword ...."
                  v-else
                />
                <a class="btn" @click="tableSearch()"> SEARCH DATA </a>
              </div>
              <div class="group-filter mb-3"></div>
            </div>
          </div>
          <div class="table-cover">
            <table class="table-hover" style="width: 1600px">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Tools</th>
                  <th
                    :class="meta_data.okey == 'id_propinsi' ? 'active' : ''"
                    v-on:click="tableOrdering('id_propinsi')"
                  >
                    Propinsi
                  </th>
                  <th
                    :class="meta_data.okey == 'name' ? 'active' : ''"
                    v-on:click="tableOrdering('name')"
                  >
                    Client Name
                  </th>
                  <th
                    :class="meta_data.okey == 'email' ? 'active' : ''"
                    v-on:click="tableOrdering('email')"
                  >
                    Email Address
                  </th>
                  <th
                    :class="meta_data.okey == 'phone_number' ? 'active' : ''"
                    v-on:click="tableOrdering('phone_number')"
                  >
                    Phone Number
                  </th>
                  <th
                    :class="meta_data.okey == 'office_address' ? 'active' : ''"
                    v-on:click="tableOrdering('office_address')"
                  >
                    Office Address
                  </th>
                  <th
                    :class="
                      meta_data.okey == 'office_description' ? 'active' : ''
                    "
                    v-on:click="tableOrdering('office_description')"
                  >
                    Description
                  </th>
                  <th
                    :class="meta_data.okey == 'lat' ? 'active' : ''"
                    v-on:click="tableOrdering('lat')"
                  >
                    Google Lat & Lng
                  </th>
                  <th
                    :class="meta_data.okey == 'created_at' ? 'active' : ''"
                    v-on:click="tableOrdering('created_at')"
                  >
                    Created Date
                  </th>
                  <th
                    :class="meta_data.okey == 'updated_at' ? 'active' : ''"
                    v-on:click="tableOrdering('updated_at')"
                  >
                    Updated Date
                  </th>
                </tr>
              </thead>
              <tbody v-if="data_model.total > 0">
                <tr v-for="(items, idx) in data_model.data" :key="idx">
                  <td>{{ idx + data_model.from }}</td>
                  <td width="100">
                    <a
                      href="javascript:;"
                      class="btn btn-primary"
                      @click="ChooseData(data_model.data[idx])"
                      ><i class="ti ti-check text-light"></i> Choose</a
                    >
                  </td>
                  <td width="150">{{ items.nama_provinsi }}</td>
                  <td>{{ items.name }}</td>
                  <td>{{ items.email }}</td>
                  <td>{{ items.phone_number }}</td>
                  <td>{{ items.office_address }}</td>
                  <td>{{ items.office_description }}</td>
                  <td>{{ items.lat }} - {{ items.lng }}</td>
                  <td>{{ items.created_at | moment("DD/MM/YYYY") }}</td>
                  <td>{{ items.updated_at | moment("DD/MM/YYYY") }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="11">
                    <div class="no-data" v-if="!$store.state.loading.status">
                      Sorry, data is not available
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pagination">
            <small class="info"
              ><i class="ti ti-split-h"></i> Scroll horizontal to view
              data.</small
            >
            <paginate
              :page-count="parseInt(model.last_page)"
              :click-handler="tableQuery"
              :container-class="'pagination'"
              :page-class="'page-item'"
            >
            </paginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import $ from "jquery";
import { mapState } from "vuex";

export default {
  props: ["id_branch"],
  data() {
    return {
      isEnableTools: false,
      isEnableOrder: false,
      isEnablePopup: false,
      model_collection: [],
      model_group: [],
      model: [],

      meta_url: "client",
      meta_data: {
        okey: "created_at",
        oval: "desc",
        wkey: "",
        wval: "",
        skey: "",
        sval: "",
      },
      meta_filter: {
        status: "all",
        sd_year: "all",
        sd_month: "all",
        ed_year: "all",
        ed_month: "all",
        id_branch: "all",
        id_provinces: "all",
      },
    };
  },

  computed: {
    ...mapState("crud", {
      data_model: (state) => state.data_model,
      msg_error: (state) => state.error,
    }),
  },
  created() {
    this.meta_filter.id_branch = this.id_branch;
    // sales and sales manager
    this.meta_filter.id_provinces = localStorage.getItem("id_provinces");
    this.tableQuery();
  },

  methods: {
    close() {
      this.$emit("exit");
    },
    ChooseData(data) {
      this.$emit("applyData", data);
      this.$emit("exit");
    },
    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
      if (this.isEnableTools) this.meta_data.sval = "";
    },
    tableSearch() {
      if (this.meta_data.skey == "") {
        alert("🙏, Please choose target field");
        return;
      }
      if (this.meta_data.sval == "") {
        alert("🙏, Please enter the keyword");
        return;
      }
      this.tableQuery();
    },
    tableOrdering(key) {
      this.isEnableOrder = !this.isEnableOrder;
      this.meta_data.okey = key;
      this.meta_data.oval = this.isEnableOrder ? "ASC" : "DESC";
      this.tableQuery(this.page);
    },
    tableQuery(pageNum) {
      this.page = pageNum;
      let data = {
        url: this.meta_url + "/data",
        offset: this.page,
        search: { key: this.meta_data.skey, value: this.meta_data.sval },
        order: { key: this.meta_data.okey, value: this.meta_data.oval },
        where: { key: this.meta_data.wkey, value: this.meta_data.wval },
        filter: [
          { key: "sd_year", val: this.meta_filter.sd_year },
          { key: "sd_month", val: this.meta_filter.sd_month },
          { key: "ed_year", val: this.meta_filter.ed_year },
          { key: "ed_month", val: this.meta_filter.ed_month },
          { key: "client.id_branch", val: this.meta_filter.id_branch },
          {
            key: "branch_station.id_provinces",
            val: this.meta_filter.id_provinces,
          },
        ],
      };

      this.$store.state.loading.msg = "Processing Fetching Data...";
      this.$store.state.loading.status = true;
      this.$store.dispatch("crud/getData", data);
    },
    tableRefresh() {
      this.meta_data.skey = "";
      this.meta_data.sval = "";
      this.meta_filter = {
        status: "all",
        sd_year: "all",
        sd_month: "all",
        ed_year: "all",
        ed_month: "all",
        id_branch: "all",
      };
      this.tableQuery();
    },
  },
};
</script>
