<template>
  <div class="col-md-12 p-0">
    <div class="popup popup-sm" v-if="isEnableDetail">
      <div class="content">
        <small id="close-popup" @click="isEnableDetail = false"
          ><i class="ti ti-close"></i
        ></small>
        <h5>Detail Application User</h5>
        <hr />
        <div class="row p-0">
          <div class="col-md-12">
            <div class="form-group">
              <div class="col-sm-12">
                <span class="text-secondary">Privileges Group</span> :
                <span class="float-right">{{
                  model_detail.privileges_name
                }}</span>
              </div>
              <div class="col-sm-12">
                <span class="text-secondary">Nickname</span> :
                <span class="float-right">{{ model_detail.name }}</span>
              </div>
              <div class="col-sm-12">
                <span class="text-secondary">Email Address </span> :
                <span class="float-right">{{ model_detail.email }}</span>
              </div>
            </div>
            <div
              class="form-group"
              v-if="
                model_detail.id_privileges == 11 ||
                model_detail.id_privileges == 12 ||
                model_detail.id_privileges == 13 ||
                model_detail.id_privileges == 15
              "
            >
              <div class="col-sm-12">
                <h6 class="text-primary">( Branch Station )</h6>
                <div class="list-station">
                  <ul>
                    <li v-for="(items, idx) in model_branch" :key="idx">
                      <span class="">{{ items.name }}</span
                      ><br />
                      <span class="text-muted">{{ items.description }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="form-group"
              v-if="
                model_detail.id_privileges == 12 ||
                model_detail.id_privileges == 13 ||
                model_detail.id_privileges == 14
              "
            >
              <div class="col-sm-12">
                <h6
                  class="text-primary"
                  v-if="model_detail.id_privileges == 14"
                >
                  ( Contract Station )
                </h6>
                <h6 class="text-primary" v-else>( Client Station )</h6>

                <div class="list-station">
                  <ul>
                    <li v-for="(items, idx) in model_contract" :key="idx">
                      <span class="">{{ items.client_name }}</span>
                      &nbsp;
                      <b
                        ><i>{{ items.contract_number }}</i></b
                      >
                      &nbsp;
                      <span :class="items.status | contractStatus">
                        {{ items.status | cleanString }}
                      </span>
                      <br />
                      Periode {{ items.start_date }} - {{ items.end_date }}
                      <br />
                      <span class="text-muted">{{ items.office_address }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Sales Manager -->
            <div
              class="form-group"
              v-if="
                model_detail.id_privileges == 16 ||
                model_detail.id_privileges == 17
              "
            >
              <div class="col-sm-12">
                <h6 class="text-primary">( Provinces )</h6>
                {{ model_detail.nama_provinsi }}
              </div>
            </div>
            <div class="form-group" v-if="model_detail.id_privileges == 16">
              <div class="col-sm-12">
                <h6 class="text-primary">( Regencies )</h6>
                <ul class="area-list">
                  <li v-for="(items, idx) in group_regencies" :key="idx">
                    {{ items.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-header">
          <h5>
            Master Data <br />
            <small
              >Total Records :
              <b>{{ data_model.total | formatPrice }}</b></small
            >
          </h5>
          <div class="group-search pull-right">
            <a class="btn" @click="tableRefresh()"
              ><i class="ti ti-reload"></i
            ></a>
            <a class="btn" @click="isActiveSearch()"
              ><i
                :class="isEnableTools == false ? 'ti ti-search' : 'ti ti-close'"
              ></i
            ></a>
            <div class="select" v-if="isEnableTools">
              <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                <option value="">-- Pilih Kolom --</option>
                <option
                  v-for="(items, idx) in meta_field"
                  v-bind:key="idx"
                  :value="items.key"
                >
                  {{ items.value }}
                </option>
              </select>
            </div>
            <div class="group" v-if="isEnableTools">
              <input
                type="date"
                v-model="meta_data.sval"
                v-if="
                  meta_data.skey == 'users.created_at' ||
                  meta_data.skey == 'users.updated_at'
                "
              />
              <input
                type="text"
                v-model="meta_data.sval"
                placeholder="Enter Keyword ...."
                v-else
              />
              <a class="btn" @click="tableSearch()"> SEARCH DATA </a>
            </div>
            <router-link
              :to="{ name: meta_url + '/store' }"
              class="btn btn-primary"
              v-if="$store.state.app.access.includes('insert')"
              ><i class="ti ti-plus"></i> Create New</router-link
            >
          </div>
          <div class="group-filter" v-if="isRoot">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group float-right">
                  <SelectBranch v-on:processBranch="processBranch" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-cover">
          <table class="table-hover" style="min-width: 1200px">
            <thead>
              <tr>
                <th v-if="JSON.parse($store.state.app.access).length > 1">
                  Action
                </th>
                <th
                  v-for="(items, idx) in meta_field"
                  v-bind:key="idx"
                  :class="meta_data.okey == items.key ? 'active' : ''"
                  v-on:click="tableOrdering(items.key)"
                >
                  {{ items.value }}
                </th>
              </tr>
            </thead>
            <tbody v-if="data_model.total > 0">
              <tr v-for="(items, idx) in data_model.data" :key="idx">
                <td v-if="JSON.parse($store.state.app.access).length > 1">
                  <div
                    class="tools"
                    v-if="
                      $store.state.app.access.includes('update') ||
                      $store.state.app.access.includes('delete')
                    "
                  >
                    <a href="javascript:;">Tools</a>
                    <ul>
                      <li>
                        <a href="javascript:;" @click="DetailInformation(idx)"
                          ><i class="text-success ti ti-info-alt"></i> Detail</a
                        >
                      </li>
                      <li v-if="$store.state.app.access.includes('update')">
                        <a href="javascript:;" @click="tableUpdate(items.id)"
                          ><i class="text-success ti ti-pencil"></i> Edit</a
                        >
                      </li>
                      <li v-if="$store.state.app.access.includes('delete')">
                        <a href="javascript:;" @click="tableRemove(items.id)"
                          ><i class="text-danger ti ti-trash"></i> Remove</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="text-center" v-else>
                    <i class="ti ti-minus text-danger"></i>
                  </div>
                </td>
                <td>
                  <span :class="generateLabelStatus(items.status)">{{
                    items.status
                  }}</span>
                </td>
                <td>{{ items.email }}</td>
                <td>{{ items.privileges_name }}</td>
                <td>{{ items.name }}</td>
                <td>{{ items.created_at | moment("DD/MM/YYYY") }}</td>
                <td>{{ items.updated_at | moment("DD/MM/YYYY") }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="22">
                  <div class="no-data" v-if="!$store.state.loading.status">
                    Sorry, data is not available
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          <small class="info"
            ><i class="ti ti-split-h"></i> Scroll horizontal to view
            data.</small
          >
          <paginate
            :page-count="parseInt(data_model.last_page)"
            :click-handler="tableQuery"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import router from "../../router";
import $ from "jquery";
import axios from "axios";
import SelectBranch from "../Branch/@SelectBranch";

export default {
  components: {
    SelectBranch,
  },
  data() {
    return {
      isRoot: false,
      isEnableTools: false,
      isEnableOrder: false,
      isEnableDetail: false,
      filter_key: "",

      meta_url: "user",
      meta_data: { okey: "id", oval: "desc", skey: "", sval: "" },
      meta_field: [
        { key: "status", value: "Activated Status" },
        { key: "users.email", value: "Email Address / Username" },
        { key: "privileges.name", value: "Privileges Group" },
        { key: "users.name", value: "Nickname" },
        { key: "users.created_at", value: "Created Date" },
        { key: "users.updated_at", value: "Updated Date" },
      ],
      meta_filter: { id_branch: "all" },

      // detail information
      model_detail: [],
      group_regencies: [],
      model_privileges: [],
      model_branch: [],
      model_contract: [],
    };
  },

  computed: {
    ...mapState("crud", {
      data_model: (state) => state.data_model,
    }),
  },
  created() {
    // filter branch
    if (localStorage.getItem("userPrivilegesName") !== "root") {
      this.meta_filter.id_branch = localStorage.getItem("userBranchId");
    } else {
      this.isRoot = true;
    }

    this.tableQuery();
  },

  methods: {
    async DetailInformation(idx) {
      this.model_detail = this.data_model.data[idx];

      // Sales
      if (this.model_detail.id_privileges == 16) {
        this.fetchKota("start");
      }

      // Hrd, Client, Leader
      if (
        this.model_detail.id_privileges == 11 ||
        this.model_detail.id_privileges == 12 ||
        this.model_detail.id_privileges == 13 ||
        this.model_detail.id_privileges == 15 ||
        this.model_detail.id_privileges == 14
      ) {
        axios
          .post("branch/data/all", {
            filter: [
              { key: "branch_station.id", val: this.model_detail.id_branch },
            ],
          })
          .then((res) => {
            this.model_branch = res.data;
          })
          .finally(() => {});
      }

      if (this.model_detail.id_privileges == 14) {
        let userId = this.model_detail.id;
        axios
          .post("user/user-client", {
            user_id: userId,
          })
          .then((res) => {
            let res_user_client = res.data;
            let id_contract = [];
            res_user_client.forEach((data) => {
              id_contract.push(data.contract_id);
            });
            this.QueryContract(id_contract);
          })
          .finally(() => {});
      } else {
        this.QueryContract(JSON.parse(this.model_detail.id_contract));
      }
      this.isEnableDetail = true;
    },
    async QueryContract(collection_id_contract) {
      console.log("data contract");
      console.log(collection_id_contract);
      await axios
        .post("contract/all", {
          id_contract: collection_id_contract,
        })
        .then((res) => {
          this.model_contract = res.data;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    async fetchKota(initial) {
      this.group_regencies = [];
      this.model_regencies = [];
      await axios
        .post("location/kota/data", {
          where: { key: "provinsi_id", value: this.model_detail.id_provinces },
        })
        .then((response) => {
          let model_regencies = response.data;

          // Sales
          if (initial && initial == "start") {
            if (this.model_detail.id_privileges == 16) {
              let m_reg = JSON.parse(this.model_detail.id_regencies);
              for (let key in model_regencies) {
                if (m_reg.includes(model_regencies[key].id)) {
                  this.group_regencies.push({
                    id: model_regencies[key].id,
                    name: model_regencies[key].nama_kota,
                  });
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },

    processBranch(data) {
      this.meta_filter.id_branch = data;
      this.tableQuery();
    },
    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
      if (this.isEnableTools) this.sval = "";
    },
    generateLabelStatus(str) {
      let cl = "";
      switch (str) {
        case "active":
          cl = "badge-success";
          break;
        case "ban":
          cl = "badge-warning";
          break;
        case "remove":
          cl = "badge-remove";
          break;
      }

      return "badge " + cl;
    },

    tableRefresh() {
      this.meta_data.skey = "";
      this.meta_data.sval = "";
      this.$store.state.loading.msg = "Refreshing Data...";
      this.$store.state.loading.status = true;
      this.$store.dispatch("crud/getData", { url: this.meta_url + "/data" });
    },

    tableSearch() {
      if (this.meta_data.skey == "") {
        alert("🙏, Please choose target field");
        return;
      }
      if (this.meta_data.sval == "") {
        alert("🙏, Please enter the keyword");
        return;
      }
      this.tableQuery();
    },
    tableQuery(pageNum) {
      this.page = pageNum;
      let data = {
        url: this.meta_url + "/data",
        offset: this.page,
        search: { key: this.meta_data.skey, value: this.meta_data.sval },
        order: { key: this.meta_data.okey, value: this.meta_data.oval },
        filter: [{ key: "id_branch", val: this.meta_filter.id_branch }],
      };
      this.$store.dispatch("crud/getData", data);
    },
    tableOrdering(key) {
      this.isEnableOrder = !this.isEnableOrder;
      this.meta_data.okey = key;
      this.meta_data.oval = this.isEnableOrder ? "ASC" : "DESC";
      this.tableQuery(this.page);
    },
    tableUpdate(id) {
      localStorage.setItem("QUERY_ID", id);
      router.push({ name: this.meta_url + "/update" });
    },
    async tableRemove(id) {
      if (confirm("Are you sure want to remove this data ?")) {
        let data = {
          url: this.meta_url + "/destroy/" + id,
          callback_url: this.meta_url + "/data",
        };
        let res = await this.$store.dispatch("crud/deleteData", data);
        if (res && res.result == "ok") {
          this.tableQuery();
        }
      }
    },
  },
};
</script>



<style lang="scss" scoped>
.list-station {
  width: 100%;
  float: left;
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      float: left;
      width: 100%;
      border-top: 1px solid whitesmoke;
      padding: 10px 0px 10px 0px;
    }
  }
}

ul.area-list {
  margin: 0;
  padding: 0;
  float: left;
  overflow-y: scroll;
  li {
    width: 100%;
    float: left;
    list-style: none !important;
    border-bottom: 1px solid #ececec;
    position: relative;
    padding: 10px;
    div.form-check {
      float: left;
    }

    i.mdi-chevron-right {
      position: absolute;
      cursor: pointer !important;
      z-index: 2;
      right: 10px;
      top: 2px;
      font-size: 20px;
      &:hover {
        color: gray;
      }
    }
  }
}
</style>