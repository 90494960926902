<template>
  <div class="col-md-12 p-0 mb-4">
    <form class="form-horizontal">
      <div class="card mt-4" v-if="isGenerateReport">
        <div class="row p-4" v-if="$store.state.loading.status">
          <div class="col-md-6">
            <ListLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-6">
            <ListLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-12">
            <ContentLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="2" ry="2" width="400" height="250" />
            </ContentLoader>
          </div>
        </div>
        <div v-else class="view-generate" id="printView">
          <!-- components generate_report -->
          <GenerateReportDetailNew
            :generate_report="resultGenerateReport"
            v-on:pickImage="pickImage"
            v-on:applyData="submitReport"
          ></GenerateReportDetailNew>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Gmap from "../../../services/Gmap";
import { ContentLoader, ListLoader, CodeLoader } from "vue-content-loader";
import router from "../../../router";
// import PopupClientMaster from "../../ClientBase/Master/@PopupClientMaster";
import PopupContract from "./@PopupContract.vue";
import PopupSection from "./@PopupSection.vue";
import PopupSectionTaskGallery from "./@PopupSectionTaskGallery.vue";
import PopupGallerySelected from "./@PopupGallerySelected.vue";
import GenerateReport from "./@GenerateReport";
import GenerateReportDetailNew from "./@GenerateReportDetailNew";
import SelectBranch from "../../Branch/@SelectBranch";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";

export default {
  components: {
    Gmap,
    PopupContract,
    PopupSection,
    PopupSectionTaskGallery,
    PopupGallerySelected,
    GenerateReport,
    GenerateReportDetailNew,
    DatePicker,
    SelectBranch,
    ContentLoader,
    ListLoader,
    CodeLoader,
  },
  data() {
    return {
      isEnablePopup: false,
      idContract: null,
      filter_date: null,
      filter_start_date_period: null,
      filter_end_date_period: null,
      filter_year: null,
      filter_month: null,
      filter_year_start: null,
      filter_year_end: null,
      filter_month_start: null,
      filter_month_end: null,
      d_contract: null,
      d_section_room: [],
      d_id_section_room: [],
      d_images: [],
      typeImage: null,
      isGenerateReport: false,
      ContractShiftMonthly: [],
      SectionChecked: [],
      SectionTask: [],
      idSectionChecked: [],
      listCheckImagesBeforeAfter: [],
      listImagesBefore: [],
      listImagesAfter: [],
      listImagesOther: [],
      listSelectedImagesBeforeAfter: [],
      listSelectedImagesOther: [],
      resultGenerateReport: {},
      listImagesSelectedGenerate: [],
      ImagesSelectedNotes: {
        photo: null,
        notes: null,
      },
      meta_url: "report/generate",
    };
  },
  computed: {
    ...mapState("crud", {
      msg_error: (state) => state.error,
    }),
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    processBranch(data) {
      // console.log(data);
    },

    disabledBeforeAfterFilterDate(date) {
      const start_date = new Date(this.d_contract.contract_start_date);
      start_date.setHours(0, 0, 0, 0);

      const end_date = new Date(this.d_contract.contract_end_date);
      end_date.setHours(0, 0, 0, 0);

      let dateNow = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      let dateStart = new Date(
        start_date.getFullYear(),
        start_date.getMonth(),
        start_date.getDate()
      );

      let dateEnd = new Date(
        end_date.getFullYear(),
        end_date.getMonth(),
        end_date.getDate()
      );

      return dateNow < dateStart || dateNow > dateEnd;
    },
    FilterModelDate() {
      let periodEndSplit = this.filter_date[1].split("/");

      let newEndPeriodLastDay = this.getLastDay(
        periodEndSplit[2],
        periodEndSplit[1]
      );

      const result_end_period =
        newEndPeriodLastDay + "/" + periodEndSplit[1] + "/" + periodEndSplit[2];

      this.filter_start_date_period = this.filter_date[0];
      this.filter_end_date_period = result_end_period;
      this.d_id_section_room = [];
      this.d_section_room = [];
      this.d_images = [];
    },
    getLastDay(y, m) {
      return new Date(y, m, 0).getDate();
    },
    async getData() {
      this.$store.state.loading.msg = "Process Generate Data...";
      this.$store.state.loading.status = true;
      let idCustomReport = localStorage.getItem("QUERY_ID")
        ? localStorage.getItem("QUERY_ID")
        : 0;

      await axios
        .post("report/generate/detail", {
          id: idCustomReport,
        })
        .then((res) => {
          let data = res.data;
          this.dataMapping(data);
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    dataMapping(data) {
      this.d_contract = data.contract_report;
      let getStartDateContract = this.d_contract.contract_start_date.split("-");

      const contractStartDate =
        getStartDateContract[0] +
        "/" +
        getStartDateContract[1] +
        "/" +
        getStartDateContract[2];

      this.filter_date = contractStartDate;

      this.d_section_room = data.contract_section_report;
      this.d_images = data.contract_images_report;

      this.listImagesBefore = [];
      this.listImagesAfter = [];
      this.listImagesOther = [];

      for (let key in this.d_images) {
        if (this.d_images[key].type_img == "before") {
          this.listImagesBefore.push(this.d_images[key]);
        }
        if (this.d_images[key].type_img == "after") {
          this.listImagesAfter.push(this.d_images[key]);
        }
        if (this.d_images[key].type_img == "other") {
          this.listImagesOther.push(this.d_images[key]);
        }
      }

      for (let key in this.d_section_room) {
        this.d_id_section_room.push(this.d_section_room[key].id_section_room);
      }

      this.resultGenerateReport = {
        id: this.d_contract.id,
        params: {
          id_contract: this.d_contract.contract_id,
          id_section_room: this.d_id_section_room,
          create_by: localStorage.getItem("userid"),
          status: "draft",
          date_period: {
            start: this.d_contract.startdate_period,
            end: this.d_contract.enddate_period,
          },
          cleaning_images: {
            images_before: this.listImagesBefore,
            images_after: this.listImagesAfter,
            images_other: this.listImagesOther,
          },
          executive_summary: this.d_contract.executive_summary,
        },
        contract_number: this.d_contract.contract_number,
        project_desc: this.d_contract.contract_description,
        date_period: {
          start: this.d_contract.startdate_period,
          end: this.d_contract.enddate_period,
        },
        contract_start_date: this.d_contract.contract_start_date,
        contract_end_date: this.d_contract.contract_end_date,
        client_name: this.d_contract.client_name,
        client_phonenumber: this.d_contract.client_phonenumber,
        client_email: this.d_contract.client_email,
        client_address: this.d_contract.client_address,
        section_room: this.d_section_room,
        list_quantitative_absen: data.result_report_absen,
        list_quantitative_scan_barcode: data.result_report_scan_barcode,
        images_before: this.listImagesBefore,
        images_after: this.listImagesAfter,
        images_other: this.listImagesOther,
        notes: this.d_contract.executive_summary,
        regards: this.d_contract.regards,
        status_report: this.d_contract.status,
      };
      this.isGenerateReport = true;
    },
    async submitReport(params) {
      let dataParams = {};
      if (params.status == "publish") {
        dataParams = {
          id: params.id,
          notes: params.notes,
          status: params.status,
          regards: params.regards,
          approve_by: localStorage.getItem("userid"),
        };
      } else {
        dataParams = {
          id: params.id,
          notes: params.notes,
          status: params.status,
          regards: params.regards,
        };
      }

      this.$store.state.loading.msg =
        "Update " + dataParams.status + " Report ..";
      this.$store.state.loading.status = true;

      await axios
        .post(this.meta_url + "/update", dataParams)
        .then((res) => {
          let response = res.data;
          // console.log(response);
          if (response.result == "ok") {
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "success";
            this.$store.state.alert.msg = response.msg;
            // if (dataParams.status == "publish") {
            //   router.push({ name: "report/generate-report" });
            // } else {
            //   location.reload();
            // }
          } else {
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "trash";
            this.$store.state.alert.msg = response.msg;
          }
        })
        .catch((error) => {
          console.log(error.statusText);
          location.reload();
        })
        .finally(() => {
          this.$store.state.loading.status = false;
          location.reload();
        });
    },
    async pickImage(item, typeImgSelected) {
      console.log(item, typeImgSelected);
      console.log(this.meta_url + "/update-custom-report-cleaning-image");

      this.$store.state.loading.msg = "Update Cleaning Image ..";
      this.$store.state.loading.status = true;
      await axios
        .post(this.meta_url + "/update-custom-report-cleaning-image", {
          id: item.id,
          time: item.time,
          idqr: item.idqr,
          photo: item.photo,
          notes: item.notes,
          type: typeImgSelected,
        })
        .then((res) => {
          let response = res.data;
          if (response.status) {
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "success";
            this.$store.state.alert.msg = response.message;
          } else {
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "trash";
            this.$store.state.alert.msg = response.message;
          }
        })
        .catch((error) => {
          console.log(error.statusText);
          this.getData();
          // location.reload();
        })
        .finally(() => {
          this.$store.state.loading.status = false;
          this.getData();
          // location.reload();
        });
    },
  },
};
</script>
