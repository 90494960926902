var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('div',[(
        _vm.isRoot ||
        _vm.isControlReport ||
        _vm.isOperationManager ||
        _vm.isBranchManager ||
        _vm.isSupervisor ||
        _vm.isAdminExecutive
      )?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-4"},[_c('div',{staticClass:"notif"},[_c('span',[_c('i',{staticClass:"ti ti-info-alt text-info"}),_vm._v(" Status Contract Station at the "+_vm._s(_vm.meta_filter.name_branch)+" Branch ")])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"box-draft"},[_vm._m(0),_c('h5',[_vm._v(_vm._s(_vm.status_contract.draft))]),_c('div',{staticClass:"layer"})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"box-on_going"},[_vm._m(1),_c('h5',[_vm._v(_vm._s(_vm.status_contract.on_going))]),_c('div',{staticClass:"layer"})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"box-terminate"},[_vm._m(2),_c('h5',[_vm._v(_vm._s(_vm.status_contract.terminate))]),_c('div',{staticClass:"layer"})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"box-finished"},[_vm._m(3),_c('h5',[_vm._v(_vm._s(_vm.status_contract.finished))]),_c('div',{staticClass:"layer"})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mt-4 mb-4"},[_c('div',{staticClass:"table-cover",staticStyle:{"height":"650px"}},[_c('table',{staticClass:"table-hover"},[_vm._m(4),(_vm.model_contract_generate_report.length > 0)?_c('tbody',_vm._l((_vm.model_contract_generate_report),function(item,idx){return _c('tr',{key:idx},[(
                      idx === 0 ||
                      item.client_name !==
                        _vm.model_contract_generate_report[idx - 1].client_name
                    )?[_c('td',{staticStyle:{"vertical-align":"top"},attrs:{"rowspan":_vm.rowCount(item.client_name, idx)}},[_vm._v(" "+_vm._s(item.client_name)+" ")])]:_vm._e(),(
                      idx === 0 ||
                      item.contract_number !==
                        _vm.model_contract_generate_report[idx - 1]
                          .contract_number
                    )?[_c('td',{staticStyle:{"vertical-align":"top"},attrs:{"rowspan":_vm.rowCountContractNumber(item.contract_number, idx)}},[_vm._v(" "+_vm._s(item.contract_number)+" ")])]:_vm._e(),(
                      idx === 0 ||
                      item.start_date !==
                        _vm.model_contract_generate_report[idx - 1].start_date ||
                      item.end_date !==
                        _vm.model_contract_generate_report[idx - 1].end_date
                    )?[_c('td',{staticStyle:{"vertical-align":"top"},attrs:{"rowspan":_vm.rowCountDate(item.start_date, item.end_date, idx)}},[_vm._v(" "+_vm._s(item.start_date)+" - "+_vm._s(item.end_date)+" ")])]:_vm._e(),(item.startdate_period)?[_c('td',[_vm._v(_vm._s(item.startdate_period))]),_c('td',[(item.report_status == 'draft')?_c('span',{staticClass:"badge badge-warning"},[_vm._v(" "+_vm._s(item.report_status)+" ")]):_vm._e(),(item.report_status == 'publish')?_c('span',{staticClass:"badge badge-success"},[_vm._v(" "+_vm._s(item.report_status)+" ")]):_vm._e(),(item.report_status == 'publish')?_c('a',{staticClass:"btn-info btn-sm ml-2 mr-2",attrs:{"title":"Detail Report Generate","href":"javascript:;"},on:{"click":function($event){return _vm.getDetailReport(item.custom_report_id)}}},[_c('i',{staticClass:"ti ti-printer text-light"})]):_vm._e(),(item.report_status == 'revisi')?_c('span',{staticClass:"badge badge-danger"},[_vm._v(" "+_vm._s(item.report_status)+" ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(item.created_by))]),_c('td',[_vm._v(_vm._s(item.approve_by))])]:[_c('td',{staticClass:"text-center",staticStyle:{"background-color":"#ffc107"},attrs:{"colspan":"4"}},[_vm._v(" Belum Membuat Generate Report ")])]],2)}),0):_vm._e()])])])])]):_c('div',{attrs:{"id":"welcome"}},[_c('h5',[_vm._v("Hi, Welcome")]),_c('div',{attrs:{"id":"img-welcome"}}),_c('h6',{staticClass:"blue mt-4"},[_vm._v("TBI Dashboard Station")]),_c('p',[_vm._v("Management project of contract, client base and employee.")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',[_c('i',{staticClass:"ti ti-notepad"}),_vm._v(" Draft")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',[_c('i',{staticClass:"ti ti-control-shuffle"}),_vm._v(" On Going")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',[_c('i',{staticClass:"ti ti-close"}),_vm._v(" Terminate")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',[_c('i',{staticClass:"ti ti-check-box"}),_vm._v(" Finished")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"no-filter text-center",staticStyle:{"cursor":"default","width":"300px"}},[_vm._v(" Client Name ")]),_c('th',{staticClass:"no-filter text-center",staticStyle:{"cursor":"default"}},[_vm._v(" Contract Number ")]),_c('th',{staticClass:"no-filter text-center",staticStyle:{"cursor":"default","width":"165px"}},[_vm._v(" Periode Contract ")]),_c('th',{staticClass:"no-filter text-center",staticStyle:{"cursor":"default"}},[_vm._v(" CreatedAt Report ")]),_c('th',{staticClass:"no-filter text-center",staticStyle:{"cursor":"default","width":"97px"}},[_vm._v(" Status Report ")]),_c('th',{staticClass:"no-filter text-center",staticStyle:{"cursor":"default"}},[_vm._v(" Created By ")]),_c('th',{staticClass:"no-filter text-center",staticStyle:{"cursor":"default"}},[_vm._v(" Approve By ")])])])}]

export { render, staticRenderFns }