
// Your personal API key.
const API_KEY = 'AIzaSyCVfHsi2hgAcWQ9cc-fGyZRy-AoxAJAM-c';
const CALLBACK_NAME = 'gmapsCallback';

let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;

// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

export default function init() {
  // If Google Maps already is initialized
  // the `initPromise` should get resolved
  // eventually.
  if (initialized) return initPromise;

  initialized = true;
  // The callback function is called by
  // the Google Maps script if it is
  // successfully loaded.
  window[CALLBACK_NAME] = () => resolveInitPromise(window.google);



  // inject a new google script tag into
  const gscript = document.createElement('script');
  gscript.async = true;
  gscript.defer = true;
  gscript.src = 'https://maps.googleapis.com/maps/api/js?key='+API_KEY+'&libraries=places&callback='+CALLBACK_NAME;
  gscript.onerror = rejectInitPromise;
  document.querySelector('head').appendChild(gscript);



  // inject markercluster lib
  const mscript = document.createElement('script');
  mscript.async = true;
  mscript.defer = true;
  mscript.src = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js';
  mscript.onerror = rejectInitPromise;
  document.querySelector('head').appendChild(mscript);


  // inject geocomplete
  const gcscript = document.createElement('script');
  gcscript.async = true;
  gcscript.defer = true;
  gcscript.src = '../plugins/geocomplete/jquery.geocomplete.min.js'; // default
  // gcscript.src = 'https://cdnjs.cloudflare.com/ajax/libs/geocomplete/1.7.0/jquery.geocomplete.min.js';
  gcscript.onerror = rejectInitPromise;
  document.querySelector('head').appendChild(gcscript);

  return initPromise;
}


