<template>
  <div class="popup popup-lg">
    <div class="content">
      <small id="close-popup" @click="close()"
        ><i class="ti ti-close"></i
      ></small>
      <h5>
        Master Data Contract Station <br /><small
          >Please select data contract station.</small
        >
        <hr />
      </h5>
      <div class="row p-0">
        <div class="col-md-12">
          <div class="table-header">
            <h5>
              Master Data <br />
              <small
                >Total Records :
                <b>{{ data_model.total | formatPrice }}</b></small
              >
            </h5>
            <div class="group-search pull-right">
              <div
                v-if="isRoot"
                style="
                  position: absolute;
                  width: 250px;
                  right: 75px;
                  bottom: 38px;
                "
              >
                <SelectBranch v-on:processBranch="processBranch" />
              </div>
              <a class="btn" @click="tableRefresh()"
                ><i class="ti ti-reload"></i
              ></a>
              <a class="btn" @click="isActiveSearch()"
                ><i
                  :class="
                    isEnableTools == false ? 'ti ti-search' : 'ti ti-close'
                  "
                ></i
              ></a>

              <div class="select" v-if="isEnableTools">
                <select v-model="filter.skey" @change="filter.sval = ''">
                  <option value="">-- Choose Field --</option>
                  <option value="contract.contract_number">
                    Contract Number
                  </option>
                  <option value="client.name">Client Name</option>
                </select>
              </div>
              <div class="group" v-if="isEnableTools">
                <input
                  type="text"
                  v-model="filter.sval"
                  placeholder="Enter Keyword ...."
                />
                <a class="btn" @click="tableSearch()"> SEARCH DATA </a>
              </div>
              <div class="group-filter mb-3"></div>
            </div>
          </div>
          <div class="table-cover">
            <table class="table-hover" style="min-width: 1900px">
              <thead>
                <tr>
                  <th>No</th>
                  <th width="70" class="no-filter text-center">Action</th>
                  <th>Client Name</th>
                  <th>Contract Number</th>
                  <th>Contract Status</th>
                  <th>Contract Period</th>
                  <th>MP Request</th>
                  <th>Description</th>
                  <th>Contract Date</th>
                  <th>Created Date</th>
                  <th>Updated Date</th>
                </tr>
              </thead>
              <tbody v-if="data_model.total > 0">
                <tr v-for="(items, idx) in data_model.data" :key="idx">
                  <td>{{ idx + data_model.from }}</td>
                  <td width="100" align="center">
                    <a
                      href="javascript:;"
                      class="btn btn-sm btn-primary"
                      @click="applyData('single', data_model.data[idx])"
                      ><i class="ti ti-check text-light"></i> Choose</a
                    >
                  </td>
                  <td>
                    <b>{{ items.client_name }}</b>
                  </td>
                  <td width="160">{{ items.contract_number }}</td>
                  <td>
                    <span :class="items.status | contractStatus">{{
                      items.status | cleanString
                    }}</span>
                  </td>
                  <td width="200">
                    {{ items.start_date | moment("DD/MM/YYYY") }} -
                    {{ items.end_date | moment("DD/MM/YYYY") }}
                  </td>
                  <td class="pr-3" width="400" v-if="items.man_power">
                    <span
                      class=""
                      v-for="(initems, index) in JSON.parse(items.man_power)"
                      :key="index"
                    >
                      {{ initems.total }} ( {{ initems.job }} )
                      <span v-if="index % 2 == 0">,</span>
                    </span>
                  </td>
                  <td width="400">
                    {{
                      items.description !== null ? items.description : "....."
                    }}
                  </td>
                  <td width="120">
                    {{ items.contract_date | moment("DD/MM/YYYY") }}
                  </td>
                  <td>{{ items.created_at | moment("DD/MM/YYYY") }}</td>
                  <td>{{ items.updated_at | moment("DD/MM/YYYY") }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="11">
                    <div class="no-data" v-if="!$store.state.loading.status">
                      Sorry data is not available
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pagination">
            <small class="info"
              ><i class="ti ti-split-h"></i> Scroll horizontal to view
              data.</small
            >
            <paginate
              :page-count="parseInt(data_model.last_page)"
              :click-handler="tableQuery"
              :container-class="'pagination'"
              :page-class="'page-item'"
            >
            </paginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SelectBranch from "../../Branch/@SelectBranch";
import $ from "jquery";
import { mapState } from "vuex";

export default {
  //   props: ["id_privileges", "id_branch"],
  data() {
    return {
      isClient: false,
      isRoot: false,
      branchId: "all",
      filter: {
        skey: "",
        sval: "",
      },
      id_privileges: 10,
      isEnableTools: false,
      isEnableOrder: false,
      isEnablePopup: false,
      model_collection: [],
      model_group: [],
      model: [],
      data_model: [],
      meta_url: "contract",
      meta_data: {
        okey: "created_at",
        oval: "desc",
        wkey: "",
        wval: "",
        skey: "",
        sval: "",
      },
    };
  },
  components: {
    SelectBranch,
  },
  computed: {
    ...mapState("crud", {
      // data_model: (state) => state.data_model,
      msg_error: (state) => state.error,
    }),
  },
  created() {
    if (localStorage.getItem("userPrivilegesName") !== "root") {
      this.branchId = localStorage.getItem("userBranchId");
    } else {
      this.isRoot = true;
    }
    this.tableQuery();
  },

  methods: {
    close() {
      this.$emit("exit");
    },
    applyData(initial, data) {
      if (initial == "single") {
        this.$emit("applyData", [data]);
      }
      if (initial == "multi") {
        this.$emit("applyData", data);
      }

      this.$emit("exit");
    },
    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
      if (this.isEnableTools) this.meta_data.sval = "";
    },

    tableSearch() {
      if (this.filter.skey == "") {
        alert("🙏, Please choose target field");
        return;
      }
      if (this.filter.sval == "") {
        alert("🙏, Please enter the keyword");
        return;
      }

      // console.log(this.filter);
      this.tableQuery();
    },
    tableOrdering(key) {
      this.isEnableOrder = !this.isEnableOrder;
      this.meta_data.okey = key;
      this.meta_data.oval = this.isEnableOrder ? "ASC" : "DESC";
      this.tableQuery(this.page);
    },
    processBranch(data) {
      this.branchId = data;
      this.tableQuery();
    },
    async tableQuery(pageNum) {
      let page = pageNum ? pageNum : 0;
      let params = {
        branch_id: this.branchId,
        filter: this.filter,
      };
      // console.log(data);
      this.$store.state.loading.msg = "Processing Fetching Data...";
      this.$store.state.loading.status = true;
      // this.$store.dispatch("crud/getData", data);
      await axios
        .post(this.meta_url + "/get-contract?page=" + page, params)
        .then((res) => {
          // console.log(res.data);
          this.data_model = res.data;
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.state.loading.status = false;
          }, 1000);
        });
    },
    tableRefresh() {
      this.filter.skey = "";
      this.filter.sval = "";

      this.tableQuery();
    },
  },
};
</script>
