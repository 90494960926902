<template>
 <div class="popup popup-md">
    <div class="content">
        <small id="close-popup" @click="close()"><i class="ti ti-close"></i></small>
        <div class=" mt-5">
             <div class="card">
                <div class="card-body">
                    <div class="table-info">
                        <table >
                            <tr>
                                <td colspan="2" class="head-title">Personal Info</td>
                            </tr>
                            <tr>
                                <td class="title" width="130">
                                    Profile Picture<br/>
                                </td>
                                <td class="p-0">
                                    <div class="detail-picture">
                                        <img :src="$store.state.api_resource + model.photo">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="title">
                                    Activity  Status
                                </td>
                                <td>
                                    <span :class="model.activity_status | activityStatus">{{ model.activity_status | uppercase }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="title" width="200">Job Position </td>
                                <td>{{ model.job_title }}</td>
                            </tr>
                            <tr>
                                <td class="title" width="100">Firstname</td>
                                <td>{{ model.firstname }}</td>
                            </tr>
                            <tr>
                                <td class="title" width="100">Lastname</td>
                                <td>{{ model.lastname }}</td>
                            </tr>
                            <tr>
                                <td class="title" width="100">Gender</td>
                                <td>{{ model.gender }}</td>
                            </tr>
                             <tr>
                                <td class="title" width="100">Marital Status</td>
                                <td>{{ model.marital_status }}</td>
                            </tr>
                            <tr>
                                <td class="title">Phone Number</td>
                                <td>{{ model.phone_number }} </td>
                            </tr>
                            <tr>
                                <td class="title">Email</td>
                                <td>{{ model.email }} </td>
                            </tr>
                            <tr>
                                <td class="title">Propinsi</td>
                                <td>{{ model.nama_provinsi }}</td>
                            </tr>
                             <tr>
                                <td class="title">Kota/Kabupaten</td>
                                <td>{{ model.nama_kota }}</td>
                            </tr>
                             <tr>
                                <td class="title">Kecamatan</td>
                                <td>{{ model.nama_kecamatan }}</td>
                            </tr>
                            <tr>
                                <td class="title">Address</td>
                                <td>{{ model.address }}</td>
                            </tr>
                            <tr>
                                <td class="title">Individual Skill / Keterampilan</td>
                                <td>{{ model.individual_skill }}</td>
                            </tr>
                             <tr>
                                <td colspan="2" class="head-title">Identity Info</td>
                            </tr>
                             <tr>
                                <td class="title">Identity Card</td>
                                <td>{{ model.identity_card }}</td>
                            </tr>
                            <tr>
                                <td class="title">Identity Number</td>
                                <td>{{ model.identity_number }}</td>
                            </tr>
                            <tr>
                                <td class="title">Nationality</td>
                                <td>{{ model.nationality }}</td>
                            </tr>
                            
                            <tr>
                                <td class="title">Birthday</td>
                                <td>{{ model.birth_date | moment("DD/MM/YYYY") }}</td>
                            </tr>
                            <tr>
                                <td class="title">Birthplace</td>
                                <td>{{ model.birth_place }}</td>
                            </tr>
                            
                            <tr>
                                <td colspan="2" class="head-title">Bank Information Account</td>
                            </tr>
                            <tr>
                                <td class="title">Bank Name</td>
                                <td>{{ model.bank_name }}</td>
                            </tr>
                            <tr>
                                <td class="title">Account Name</td>
                                <td>{{ model.bank_account_name }}</td>
                            </tr>
                            <tr>
                                <td class="title">Account Number</td>
                                <td>{{ model.bank_account_number }}</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>



<script>
    import { mapState } from 'vuex';
    import axios from 'axios';
    
    export default 
    {
        data() {
            return {
                model:{},
                model_equipment:[],
                model_agent:[],
                meta_url : 'employee',
            }
        },

        created()
        {
            this.DataQuery();
        },

        methods:
        {
            close(){
                this.$emit('exit');
            },
            
            async DataQuery()
            {
                await axios.get("employee/find/"+localStorage.getItem('QUERY_ID'))
                .then((response) => {
                    this.model = response.data;
                })
                .catch((error => {
                })).finally(() => {
                    this.$store.state.loading.status = false;
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
div.avatar{
    margin:0px !important;
    margin-bottom:20px !important;
    margin-top:20px !important;
}

.detail-picture{
    position:relative;
    width:150px;
    border-radius:3px;
    overflow:hidden;
    margin-top:20px;
    margin-bottom:20px;
    img{
        width:100%;
    }
}
</style>