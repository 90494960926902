<template>
  <div class="col-md-12 p-0">
    <!-- Download / export excel -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'download'">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <h5 class="text-left">
          {{ download.title }}<br />
          <span class="text-secondary mr-4"
            >Please choose available file report format to download.</span
          >
          <hr />
        </h5>
        <div class="text-right">
          <export-excel
            class="float-right mr-2"
            :data="download.data"
            :fields="download.fields"
            type="csv"
            :name="download.name"
            :title="download.title"
          >
            <a href="javascript:;" class="btn btn-light"
              ><i class="ti ti-file text-warning"></i> Format .CSV</a
            >
          </export-excel>
          <export-excel
            class="float-right mr-2"
            :data="download.data"
            :fields="download.fields"
            type="xls"
            :name="download.name"
            :title="download.title"
          >
            <a href="javascript:;" class="btn btn-light"
              ><i class="ti ti-file text-success"></i> Format .XLS</a
            >
          </export-excel>
        </div>
      </div>
    </div>
    <!-- ==================== -->

    <!-- Download / export excel -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'download_by_date'">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <div class="row">
          <div class="col-md-12 text-center">
            <h5 align="center">
              Please choose date range file report format to download.
            </h5>
            <img
              class="img-rounded text-center mt-3"
              :src="'img/uploadfile.png'"
              title="Upload Excel"
            />
          </div>
          <div class="col-md-12 mt-4">
            <date-picker
              v-model="period_contract"
              :disabled-date="disabledBeforeAfterPeriodContract"
              value-type="format"
              type="date"
              format="YYYY-MM-DD"
              placeholder="Choose Date Range"
              width="100%"
              style="width: 100%"
              range
            >
            </date-picker>
          </div>
        </div>
        <div class="text-right mt-3">
          <a
            v-if="period_contract.length > 0"
            @click="downloadExcelContractByDate($event)"
            class="btn btn-success"
            ><i class="ti ti-file text-light"></i> Format .XLS</a
          >
        </div>
      </div>
    </div>
    <!-- ==================== -->

    <div class="popup popup-sm" v-if="isEnablePopup == 'detail'">
      <div class="content success">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <div class="row p-n">
          <div class="col-md-12">
            <div>
              <h5>
                Checklist Point <br /><small
                  >Detail photo, time and noted checklist point</small
                >
              </h5>
              <hr />
              <div class="photo-checklist">
                <img
                  :src="
                    $store.state.api_mobile_resource + model_detail_task.photo
                  "
                />
              </div>
              <hr />
              <div>
                <h6>Checklist Time :</h6>
                <p class="text-secondary">{{ model_detail_task.time }}</p>
              </div>
              <hr />
              <div>
                <h6>Noted :</h6>
                <p class="text-secondary">{{ model_detail_task.notes }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body" v-if="!isLoading">
        <div class="form-group">
          <div class="row p-2">
            <div class="col-md-8 p-3 pl-4">
              <h5>{{ model_contract.client_name }}</h5>
              <p>
                <span class="text-secondary">{{ model_contract.email }}</span
                ><br /><span class="">{{
                  model_contract.phone_number | formatPhone
                }}</span>
              </p>
              <p>
                {{ model_contract.office_address }} <br />
                {{ model_contract.nama_provinsi }},
                {{ model_contract.nama_kota }}
              </p>
            </div>
            <div class="col-md-4">
              <ul class="list-area">
                <li>
                  <span class="">Contract Number</span>
                  <span class="float-right"
                    ><b style="font-size: 16px"
                      ># {{ model_contract.contract_number }}</b
                    ></span
                  >
                </li>
                <li>
                  <span class="">Contract Period</span>
                  <span class="float-right"
                    ><b
                      >{{ model_contract.start_date | moment("DD/MM/YYYY") }} -
                      {{ model_contract.end_date | moment("DD/MM/YYYY") }}</b
                    ></span
                  >
                </li>
                <li v-if="model_contract.man_power !== undefined">
                  <span class="">MP Request</span><br /><br />
                  <span
                    class="text-muted"
                    v-for="(initems, index) in JSON.parse(
                      model_contract.man_power
                    )"
                    :key="index"
                  >
                    {{ initems.total }} ( {{ initems.job }} )
                    <span v-if="index % 2 == 0">,</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row form-group mt-5">
          <div class="col-md-12">
            <div class="float-left">
              <label class="text-primary mb-0"
                ><i class="ti ti-info-alt"></i> Employee Attendance</label
              ><br />
              <small class="text-secondary"
                >Report checklist point client area base
              </small>
            </div>

            <div class="float-right">
              <select
                class="form-control"
                v-model="statusAttendance"
                @change="checkStatusAttendance()"
              >
                <option value="0"> Show All</option>
                <option value="1"> Check IN / Out </option>
                
              </select>
              <!-- <a @click="modalExportContract()" class="btn btn-primary mr-3">
                <i class="ti ti-cloud-down"></i> Download Data
              </a> -->

              <date-picker
                v-model="filter_date"
                :default-value="filter_date"
                :disabled-date="disabledBeforeAfterFilterDate"
                :clearable="false"
                value-type="format"
                type="month"
                format="MM/YYYY"
                placeholder="Pilih Range Tanggal"
                width="100%"
                @change="FilterModelDate()"
                confirm
              >
              </date-picker>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
        <hr />
        <div class="row" v-if="model_date_range.length > 0">
          <div class="col-sm-3 pr-0">
            <div class="table-cover">
              <table class="table-hover">
                <thead>
                  <tr style="height: 66px">
                    <th class="no-filter text-center">Date</th>
                    <th class="no-filter text-center" colspan="2">
                      Section & Area Room
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(items, index) in model_date_range"
                    :key="index"
                    style="height: 33px"
                  >
                    <td class="text-left">
                      <span :class="'secdate ' + GetDataRange('date', items)">
                        {{ GetDataRange("date", items) }}
                      </span>
                    </td>
                    <td>
                      <span class="text-primary"
                        >Sec {{ GetDataRange("section", items) }}</span
                      >
                      - {{ GetDataRange("room", items) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="" colspan="2"><b>Total Attendance</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-sm-9 pl-0 pr-1">
            <div class="table-cover">
              <table
                class="table-hover"
                :style="'min-width:' + table_cover_width + 'px;'"
              >
                <thead>
                  <tr style="height: 33px">
                    <th
                      class="text-center no-filter"
                      v-for="(items, idx) in model_group_employee"
                      :key="idx"
                      style="position: relative"
                      colspan="4"
                    >
                      <a
                        href="javascript:;"
                        :title="
                          GetCompleteName(items.firstname, items.lastname)
                        "
                      >
                        <span class="text-secondary">
                          {{ items.nik }} -
                          {{
                            GetCompleteName(items.firstname, items.lastname)
                              | limit(15, " ...")
                          }}
                        </span>
                      </a>
                    </th>
                  </tr>
                  <tr style="height: 33px">
                    <th
                      class="text-center no-filter"
                      v-for="(items, idx) in total_employee * 4"
                      :key="idx"
                      style="position: relative"
                    >
                      <span>{{ InitialInOut(idx) }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style="height: 33px"
                    v-for="(items_date, index1) in model_date_range"
                    :key="index1"
                  >
                    <td
                      style="height: 40px"
                      class="text-center no-filter"
                      v-for="(items, idx) in model_checkinout"
                      :key="idx"
                    >
                      <template v-if="items.res == 'checkpoint'">
                        <span class="text-danger">
                          {{
                            GetDataTask(
                              "total",
                              GetEmployeeNik(items.index),
                              GetDataRange("date", items_date),
                              GetDataRange("id_section", items_date)
                            )
                          }}
                        </span>
                        <span class="pl-1 pr-1">x</span>
                        <span class="text-primary">Check Point</span>
                      </template>

                      <template v-if="items.res == 'shift'">
                        <span>
                          <!-- Shift - -->
                          {{
                            GetAttendanceInOut(
                              GetKeyEmployeeShift(items.index),
                              "shift",
                              GetDataRange("date", items_date)
                            )
                          }}
                        </span>
                      </template>

                      <template v-if="items.res == 'in'">
                        <span>
                          {{
                            GetAttendanceInOut(
                              GetKeyEmployeeShift(items.index),
                              "in",
                              GetDataRange("date", items_date)
                            )
                          }}
                        </span>
                      </template>

                      <template v-if="items.res == 'out'">
                        <span>
                          {{
                            GetAttendanceInOut(
                              GetKeyEmployeeShift(items.index),
                              "out",
                              GetDataRange("date", items_date)
                            )
                          }}
                        </span>
                      </template>
                      
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">Total days</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="rom mt-5" v-else>
          <div class="p-3">
            <i class="ti ti-info-alt text-danger"></i> Data Attendance is empty,
            Please manage contract shift work
          </div>
        </div>
      </div>
      <div class="card-body" v-else>
        <div class="row">
          <div class="col-md-6">
            <ListLoader
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-6">
            <ListLoader
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-12">
            <ContentLoader primaryColor="#f3f3f3" secondaryColor="#ecebeb">
              <rect x="0" y="0" rx="2" ry="2" width="400" height="400" />
            </ContentLoader>
          </div>
        </div>
        <!-- <h5>Please Wait ...</h5>
                <h6 class="text-secondary">System adjustment layout for a while !</h6> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";
import { ListLoader, ContentLoader } from "vue-content-loader";
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";

export default {
  components: {
    ListLoader,
    ContentLoader,
    DatePicker,
  },
  data() {
    return {
      isLoading: true,
      isEnablePopup: false,
      isEnablePopupDownload: false,
      table_cover_width: 0,
      statusAttendance: 1,
      model_contract: {},
      model_group_employee: [],
      model_group_employee_shift_work: [],
      model_date_range: [],
      model_date_task: [],
      model_detail_task: {},
      total_employee: 0,
      collection_month: [],
      filter_shift: "all",
      period_contract: [],
      model_checkinout: [],
      LableShift: [],
      // filter date
      filter_date: "",
      filter_year: "",
      filter_month: "",
      download: { fields: "", data: "", title: "" },
      pageNum: 1,
      totalPageInMonth: 0,
      daysInMonth: 0,
      count_date_range: 0,
    };
  },
  created() {
    this.QueryStartFromPeriod();
  },

  methods: {
    checkStatusAttendance(){
      this.DataQuery();
      // console.log(this.statusAttendance)
    },
    InitialInOut(index) {
      let modules = index % 4;
      if (modules == 0) {
        return "Checkpoint";
      }
      if (modules == 1) {
        return "Shift";
      }
      if (modules == 2) {
        return "IN";
      }
      if (modules == 3) {
        return "OUT";
      }
    },
    GetEmployeeNik(index) {
      let modelEmpl = this.model_group_employee[index];
      return modelEmpl.nik;
    },
    GetKeyEmployeeShift(index) {
      let modelEmpl = this.model_group_employee[index];
      let employeeNikName =
        modelEmpl.nik + "-" + modelEmpl.firstname + " " + modelEmpl.lastname;
      return employeeNikName;
    },
    disabledBeforeAfterPeriodContract(date) {
      // console.log(this.model_contract);
      const start_date = new Date(this.model_contract.start_date);
      start_date.setHours(0, 0, 0, 0);

      const end_date = new Date(this.model_contract.end_date);
      end_date.setHours(0, 0, 0, 0);

      return date < start_date || date > end_date;
    },
    disabledBeforeAfterFilterDate(date) {
      const start_date = new Date(this.model_contract.start_date);
      start_date.setHours(0, 0, 0, 0);

      const end_date = new Date(this.model_contract.end_date);
      end_date.setHours(0, 0, 0, 0);

      let dateNow = new Date(date.getFullYear(), date.getMonth());
      let dateStart = new Date(start_date.getFullYear(), start_date.getMonth());
      let dateEnd = new Date(end_date.getFullYear(), end_date.getMonth());

      return dateNow < dateStart || dateNow > dateEnd;
    },
    modalExportContract() {
      this.isEnablePopup = "download_by_date";
    },
    async downloadExcelContractByDate(event) {
      event.target.className = "btn btn-success disabled";
      this.$store.state.loading.msg =
        "Download Report Checklist Area " +
        this.period_contract[0] +
        " until " +
        this.period_contract[1];
      this.$store.state.loading.status = true;

      this.period_contract = [this.period_contract[0], this.period_contract[1]];

      let dataParams = {
        id_contract: localStorage.getItem("QUERY_ID"),
        start_date: this.period_contract[0],
        end_date: this.period_contract[1],
      };

      await axios
        .post("report/checklist-contract-download", dataParams, {
          responseType: "blob",
        })
        .then((res) => {
          // console.log(res);
          const link = document.createElement("a");

          link.href = window.URL.createObjectURL(new Blob([res.data]));

          link.setAttribute(
            "download",
            "Report Checklist Area " +
              this.model_contract.client_name +
              " Period " +
              this.period_contract[0] +
              " until " +
              this.period_contract[1] +
              ".xlsx"
          );

          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.$store.state.loading.status = false;
          event.target.className = "btn btn-success";
          this.isEnablePopup = false;
        });
    },
    DownloadData(nik, name) {
      this.$store.state.loading.msg = "Download Report Data...";
      this.$store.state.loading.status = true;

      axios
        .post("report/checklist-download", {
          id_contract: localStorage.getItem("QUERY_ID"),
          month: this.filter_month,
          year: this.filter_year,
          nik: nik,
        })
        .then((res) => {
          this.download.fields = {
            Date: "date",
            Section: "section",
            Checkpoint: "checkpoint",
          };
          this.download.title = "Report Checkpoint - " + name;
          this.download.name = name + ".xls";
          this.download.data = res.data;
          this.isEnablePopup = "download";
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    GetCompleteName(firstname, lastname) {
      return firstname + " " + lastname;
    },
    DetailReport(idx, data) {
      this.model_detail_task = data[idx];
      this.isEnablePopup = "detail";
    },
    checkDateRange(data) {
      let res = data.split("#");
      let data_date = res[0].split(".").join("-");
      console.log(data_date);
    },
    GetDataRange(initial, data) {
      let res = data.split("#");

      let data_date = res[0].split(".").join("-");
      let sp_date = data_date.split("-");

      // section & room
      var data_scr = res[1].split("-");
      let data_section = data_scr[1];
      let data_room = data_scr[2];

      if (initial == "date") {
        return data_date;
      }
      if (initial == "date_day") {
        return sp_date[0];
      }
      if (initial == "date_month") {
        return sp_date[1];
      }
      if (initial == "date_year") {
        return sp_date[2];
      }
      if (initial == "id_section") {
        return data_scr[0];
      }
      if (initial == "section") {
        return data_section;
      }
      if (initial == "room") {
        return data_room;
      }
    },

    GetDataTask(initial, nik, date, id_section) {
      // console.log(initial, nik, date, id_section);
      let data = [];
      let model = this.model_date_task;
      // console.log(model);

      var result = "";
      for (let key in model) {
        var dt =
          model[key].day + "-" + model[key].month + "-" + model[key].year;
        if (model[key].nik == nik && dt == date) {
          if (model[key].id_section_room == id_section) {
            if (model[key].checkpoint !== "") {
              var m = model[key].checkpoint;
              result = JSON.parse(model[key].checkpoint);
              if (initial == "total") {
                return result.length;
              } else {
                return result;
              }
            }
          }
        }
      }
      return 0;
    },

    GetAttendanceInOut(index, initial, date) {
      let model = this.model_group_employee_shift_work[index];
      let shift = [];
      for (let key in model) {
        var dt =
          model[key].day + "-" + model[key].month + "-" + model[key].year;

        if (dt == date && initial == "in") {
          return model[key].in;
        }

        if (dt == date && initial == "out") {
          return model[key].out;
        }

        if (dt == date && initial == "shift") {
          // shift.push(model[key].shift);
          //this.LableShift.push(model[key].shift)
          return model[key].shift;
        }
      }

      // console.log(this.LableShift);
    },

    FilterModelDate() {
      this.isLoading = true;

      let fdate = this.filter_date.split("/");
      this.filter_month = fdate[0];
      this.filter_year = fdate[1];
      this.DataQuery();
    },

    async QueryStartFromPeriod() {
      await axios
        .get("contract/period/" + localStorage.getItem("QUERY_ID"))
        .then((response) => {
          const d = new Date();
          let query = response.data;
          let default_date = query.default;
          let dt = default_date.split("/");

          this.collection_month = query.month_period;

          // this.filter_month = dt[0];
          // this.filter_year  = dt[1];
          // this.filter_date  = default_date;

          this.filter_month = ("0" + (d.getMonth() + 1)).slice(-2);
          this.filter_year = d.getFullYear().toString();

          this.filter_date = this.filter_month + "/" + this.filter_year;

          this.DataQuery();
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    nextPage(pageNum) {
      this.DataQuery(pageNum);
    },
    async DataQuery() {
      this.isLoading = true;
      let d = new Date();
      d.setMonth(this.filter_month);
      d.setFullYear(this.filter_year);

      await axios
        .post("report/summary-attendance-checklist-area", {
          id_contract: localStorage.getItem("QUERY_ID"),
          month: this.filter_month,
          year: this.filter_year,
          checkin: this.statusAttendance,
        })
        .then((response) => {
          let query = response.data;
          this.model_contract = query.contract;
          this.model_group_employee = query.employee;
          this.model_group_employee_shift_work = query.employee_shift;
          this.model_date_range = query.date_range;
          this.model_date_task = query.date_task;
          this.count_date_range = query.count_date_range;

          // reset
          this.total_employee = 0;

          // total employee
          for (let key in this.model_group_employee) {
            this.total_employee++;
          }

          this.model_checkinout = [];
          // in and out
          let m_employee = this.model_group_employee;

          for (let key in m_employee) {
            this.model_checkinout.push({
              index: key,
              res: "checkpoint",
            });
            this.model_checkinout.push({
              index: key,
              res: "shift",
            });
            this.model_checkinout.push({
              index: key,
              res: "in",
            });
            this.model_checkinout.push({
              index: key,
              res: "out",
            });
          }

          // adjustment width of table
          this.table_cover_width = this.total_employee * this.count_date_range;

          this.table_cover_width = this.table_cover_width * 10;

          this.totalPageInMonth = this.pageNum * this.count_date_range;
          this.daysInMonth = new Date(
            d.getFullYear(),
            d.getMonth(),
            0
          ).getDate();

          // default filter shift period
          var initial_class_date = [];
          let date_range = this.model_date_range;
          for (let key in date_range) {
            var dt = this.GetDataRange("date", date_range[key]);
            let dt_new = dt.split("-");

            if (!initial_class_date.includes(dt)) {
              initial_class_date.push(dt);
            }
          }

          setTimeout(() => {
            for (let key in initial_class_date) {
              $("." + initial_class_date[key] + ":eq(0)").show();
            }
          }, 200);
          // console.log(initial_class_date);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },

    async Submit() {
      let model = {
        id_contract: this.model_contract.id,
        shift: this.model_group_employee,
      };
      let data = {
        url: this.meta_url + "/assign",
        callback_url: "jobcon/shift",
        form_data: model,
      };
      let res = await this.$store.dispatch("crud/storeData", data);
      if (res) {
        this.total_employee = 0;
        this.DataQuery();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.secdate {
  display: none;
}
.shiftdate {
  display: none;
}
.tdp {
  padding-top: 12px;
  padding-bottom: 12px;
}
ul.list-area {
  float: right;
  width: 100%;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    float: left;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid whitesmoke;
  }
}

.popup-detail-report {
  h5 {
    text-align: left;
  }
}

.photo-checklist {
  text-align: center;
  background-color: #e8e8e8;
  img {
    height: 250px;
    width: auto;
    margin: 0 auto;
  }
}
</style>
