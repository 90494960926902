import axios from 'axios';
import store from '../../modules/index';

const propinsi = 
{
    namespaced: true,
    state: {
        data_model:[],
    },
    mutations: 
    {
        FETCH_DATA: (state, payload) => {
            state.data_model  = payload;
        }
    },
    actions: {
       
        async all({ commit }) 
        {
            store.state.loading.status = true;
            return await axios.post('location/propinsi/data',
            {
                order :{ key : 'id', value : 'asc' },
                query_type : 'all'
            })
            .then((response) => {
                commit("FETCH_DATA", response.data);
                return response.data;
            }).finally(() => {
                store.state.loading.status = false;
            });
        },
    }
}

export default propinsi