<template>    
    <div class="popup popup-lg">
        <div class="content">
            <small id="close-popup" @click="close()"><i class="ti ti-close"></i></small>
            <h5>Total Employees Added <br/><small>Please select employee to assign data.</small><hr/></h5>
            <div class="row p-0">
                <div class="col-md-12">
                    <div class="table-cover" style="height:350px; overflow-x:auto;">
                         <table class="table-hover" style="width:1300px; ">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th class="no-filter">Tools</th>
                                    <th class="text-center">Photo</th>
                                    <th class="text-center">Employee Status</th>
                                    <th>NIK</th>
                                    <th class="text-center">Complete Name</th>
                                    <th>Job Title</th>
                                    <th>Email</th>
                                    <th class="text-center">Phone Number</th>
                                    <th>Identify Card</th>
                                    <th>Address</th>
                                </tr>
                            </thead>
                            <tbody v-if="model_employee_temp.length > 0">
                                <tr  v-for="(items, idx) in model_employee_temp" :key="idx">
                                    <td>{{ idx + 1 }}</td>
                                    <td class="text-center">
                                        <!-- <input type="checkbox" @change="collectGroup(idx)" :checked="validateChecked(items.id)" :id="'checkbox-'+items.id" > -->
                                         <a
                                            href="javascript:;"
                                            class="btn"
                                            @click="applyRemoveTemp(items)"
                                            ><i class="ti ti-trash text-danger"></i
                                        ></a>
                                    </td>
                                    <td class="text-center">
                                        <img :src="$store.state.api_resource + items.photo" class="table-picture-profile" v-show="items.photo !== null">
                                        <div class="table-avatar"  v-show="items.photo == null"></div>
                                    </td>
                                    <td><span :class="items.activity_status | activityStatus">{{ items.activity_status }}</span></td>
                                    <td># {{ items.nik }}</td>
                                    <td>{{ items.firstname }}  {{ items.lastname }}</td>
                                    <td>{{ items.job_title }}</td>
                                    <td>{{ items.email }}</td>
                                    <td>{{ items.phone_number }}</td>
                                    <td>
                                        {{ items.identity_card }}<br/>
                                        {{ items.identity_number }}
                                    </td>
                                    <td width="250">{{ items.address }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="8" style="text-align:center">
                                        <i class="ti ti-info-alt text-danger"></i> Resource employee not found.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>                    
                    <hr/>
                    <a href="javascript:;" class="btn btn-default btn-execute float-right" @click="close()">Close</a>
                </div>  
            </div>
        </div> 
    </div>
</template>
<script>
    export default {
        
        props:['model_employee_temp'],

        data() 
        {
            return {                
            }
        },       
        computed: {
           
        },
        created()
        {
        },
        methods:
        {
            close(){
                this.$emit('exit');
            },
            applyRemoveTemp(item){
                // console.log(item);
                this.model_employee_temp.splice(this.model_employee_temp.indexOf(item), 1);
                // this.model_employee_temp.splice(idx, 1);
                // this.$emit('exit');
                // this.$emit('applyRemoveTemp', idx);
                this.updateModelNik(item)
            },
            updateModelNik(item){
                this.$emit('applyUpdateModelNik', item.nik);
            }


        }
    }
</script>
