import axios from "axios";
import store from "../../modules/index";
import router from "../../router";

const crud = {
  namespaced: true,
  state: {
    data_model: [],
    error: {},
  },
  mutations: {
    FETCH_DATA: (state, payload) => {
      state.data_model = payload;
    },
    RESPONSE_ERROR: (state, payload) => {
      state.error = payload;
    },
  },
  actions: {
    normalize({ commit }) {
      commit("FETCH_DATA", []);
      commit("RESPONSE_ERROR", {});
    },

    async getDataAll({ commit }, payload) {
      store.state.loading.msg = "Please Wait...";
      store.state.loading.status = true;
      await axios
        .post(payload.url, {})
        .then((response) => {
          commit("FETCH_DATA", response.data);
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },

    async findContract({ commit }, payload) {
      let meta_search = "";
      let page = payload && payload.offset !== undefined ? payload.offset : 1;

      // search query
      if (payload && payload.search !== undefined) {
        if (payload.search.key !== "" && payload.search.val !== "") {
          meta_search = {};
          meta_search.field = payload.search.key;
          meta_search.keyword = payload.search.value;
        }
      }

      store.state.loading.status = true;
      store.state.loading.msg = "Processing Query Data...";
      return await axios
        .post(payload.url + "?page=" + page, {
          search: meta_search,
        })
        .then((response) => {
          commit("FETCH_DATA", response.data);
          return response.data;
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },

    async find({ commit }, payload) {
      store.state.loading.status = true;
      return await axios
        .get(payload.url, {})
        .then((response) => {
          commit("FETCH_DATA", response.data);
          return response.data;
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },

    async getData({ commit }, payload) {
      var meta_search = "",
        meta_order = "",
        meta_pospoint = "",
        meta_filter = "";
      var page = payload && payload.offset !== undefined ? payload.offset : 1;

      // search query
      if (payload && payload.search !== undefined) {
        if (payload.search.key !== "" && payload.search.val !== "") {
          meta_search = {};
          meta_search.field = payload.search.key;
          meta_search.keyword = payload.search.value;
        }
      }

      // order query
      if (payload && payload.order !== undefined) {
        if (payload.order.key !== "" && payload.order.val !== "") {
          meta_order = {};
          meta_order.key = payload.order.key;
          meta_order.value = payload.order.value;
        }
      }

      // where query
      if (payload && payload.pospoint !== undefined) {
        if (payload.pospoint.key !== "" && payload.pospoint.val !== "") {
          meta_pospoint = {};
          meta_pospoint.key = payload.pospoint.key;
          meta_pospoint.value = payload.pospoint.value;
        }
      }

      // filter query
      if (payload && payload.filter !== undefined) {
        meta_filter = [];
        meta_filter = payload.filter;
      }

      // console.log(payload.period_start, payload.period_end);

      store.state.loading.status = true;
      store.state.loading.msg = "Processing Query Data...";
      return await axios
        .post(payload.url + "?page=" + page, {
          search: meta_search,
          order: meta_order,
          pospoint: meta_pospoint,
          filter: meta_filter,
          datarange: {
            start: payload.period_start,
            end: payload.period_end,
          },
        })
        .then((response) => {
          commit("FETCH_DATA", response.data);
          return response.data;
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },

    async storeData({ commit }, payload) {
      store.state.loading.status = true;
      store.state.loading.msg = "Processing Store Data...";
      return await axios
        .post(payload.url, payload.form_data)
        .then((response) => {
          let res = Object.assign({}, response);

          if (res.data.result == "ok") {
            store.state.alert.status = true;
            store.state.alert.msg = res.data.msg;
            if (payload.callback_url) {
              router.push("/" + payload.callback_url);
            }
          }

          return response.data;
        })
        .catch((error) => {
          let data = Object.assign({}, error);
          // console.log(data.response.statusText);
          store.state.alert.status = true;
          store.state.alert.type = "trash";
          store.state.alert.msg = data.response.statusText;
          if (data.response !== undefined && data.response.status == 422) {
            commit("RESPONSE_ERROR", JSON.parse(error.request.responseText));
          }
          return data;
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },

    async updateData({ commit }, payload) {
      store.state.loading.status = true;
      store.state.loading.msg = "Processing Update Data...";

      return await axios
        .post(payload.url, payload.form_data)
        .then((response) => {
          let res = Object.assign({}, response);

          if (res.data.result == "ok") {
            store.state.alert.status = true;
            store.state.alert.msg = res.data.msg;
            if (payload.callback_url) {
              router.push("/" + payload.callback_url);
            }
          } else {
            alert(res.data.msg);
          }
          return response.data;
        })
        .catch((error) => {
          let data = Object.assign({}, error);
          if (data.response !== undefined && data.response.status == 422) {
            commit("RESPONSE_ERROR", JSON.parse(error.request.responseText));
          }
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },

    async updateDataAssignEmployeeToShiftWork({ commit }, payload) {
      store.state.loading.status = true;
      store.state.loading.msg = "Processing Update Data...";

      return await axios
        .post(payload.url, payload.form_data)
        .then((response) => {
          let res = Object.assign({}, response);

          if (!res.data.status) {
            store.state.alert.status = true;
            store.state.alert.type = "trash";
            store.state.alert.msg = res.data.msg;
          } else {
            return res.data.status;
          }

        })
        .catch((error) => {
          let data = Object.assign({}, error);

          if (data.response !== undefined && data.response.status == 422) {
            commit("RESPONSE_ERROR", JSON.parse(error.request.responseText));
          }
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },

    async deleteData({ commit }, payload) {
      return await axios
        .get(payload.url)
        .then((response) => {
          let res = Object.assign({}, response);

          if (res.data.result == "ok") {
            // store.state.alert.type = "trash";
            store.state.alert.status = true;
            store.state.alert.msg = res.data.msg;
            if (payload.callback_url) {
              router.push("/" + payload.callback_url);
            }
          } else {
            alert(res.data.msg);
          }
          return response.data;
        })
        .catch((error) => {
          let data = Object.assign({}, error);
          if (data.response && data.response.status == 422) {
            commit("RESPONSE_ERROR", JSON.parse(error.request.responseText));
          }
          if (data.response && data.response.status == 500) {
            store.state.alert.status = true;
            store.state.alert.msg =
              "Cannot remove this data, This action has been locked for relationship data!";
          }
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },
  },
};

export default crud;
