import Vue from "vue";
import Vuex from "vuex";
import authenticate from "./Auth/auth";
import crud from "./Crud/index";
import mreg from "./Mreg/index";
import propinsi from "./Location/propinsi";
import kota from "./Location/kota";
import kecamatan from "./Location/kecamatan";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    app_session: "TBI787432JF456XHUYT3857FGVG",
    system_alert: { class: "", msg: "" },
    alert: {
      status: false,
      type: "success",
      msg: "",
      close_redirect: "",
      yes_redirect: "",
      no_redirect: "",
    },
    modelAssignEmployee: [],
    loading: { status: false, msg: "Loading...." },
    goto: { status: false, initial_id: "" },
    ho_status: localStorage.getItem("ho_status"),
    api_mobile_resource: "http://astation.tukangbersih.com/",
    api_resource: "http://mstation.tukangbersih.com/", // Production
    // api_resource: "http://stationtukangbersih.ecocare.co.id/api/", // Staging
    // api_resource: "http://stationtukangbersihdev.ecocare.co.id/api/", // Development
    // http://stationtukangbersihdev.ecocare.co.id/api/index.php/
    // api_resource: "http://localhost/mstation/public/", // Local

    //isPermission : true,
    app: {
      title: "",
      icon: "",
      mkey: "",
      breadcrumb: [],
      url: {},
      access: [],
    },
  },
  modules: {
    authenticate,
    mreg,
    crud,
    propinsi,
    kota,
    kecamatan,
  },
});
