import Vue from "vue";

Vue.filter("tableFilter", function(value, initial) {
  var output = value;
  if (initial !== "") {
    switch (initial) {
      case "format_amount":
        output = formatPrice(value);
        break;
    }
  }
  return output;
});

function formatPrice(value) {
  if (!value) return 0;
  let val = (value / 1).toFixed(0).replace(".", ",");
  let amount = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return amount;
}

Vue.filter("capitalizeFirst", function(value) {
  if (!value) return 0;
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter("cleanString", function(value) {
  if (!value) return 0;
  let str = value.replace(/[^a-zA-Z0-9]/g, " ");
  return str;
});
Vue.filter("length", function(value) {
  if (!value) return 0;
  return value.length;
});
Vue.filter("activityStatus", function(value) {
  if (!value) return 0;
  let cl = "";
  switch (value) {
    case "active":
      cl = "badge-success";
      break;
    case "ban":
      cl = "badge-danger";
      break;
    case "resign":
      cl = "badge-secondary";
      break;
  }
  return "badge " + cl;
});
Vue.filter("contractStatus", function(value) {
  if (!value) return 0;

  let cl = "";
  switch (value) {
    case "draft":
      cl = "badge-secondary";
      break;
    case "complete":
      cl = "badge-success";
      break;
    case "terminate":
      cl = "badge-danger";
      break;
    case "out_of_date":
      cl = "badge-danger";
      break;
    case "on_going":
      cl = "badge-success";
      break;
    case "finished":
        cl = "badge-primary";
        break;
    default:
      cl = "";
  }
  return "badge " + cl;
});
Vue.filter("formatClock", function(value) {
  value = value.toString();
  if (value < 1) return "00.00";
  else return value.length < 2 ? "0" + value + ".00" : value + ".00";
});
Vue.filter("formatPrice", function(value) {
  if (!value) return 0;
  let val = (value / 1).toFixed(0).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
});
Vue.filter("firstChar", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase();
});
Vue.filter("uppercase", function(value) {
  if (!value) return "";
  return value.toUpperCase();
});
Vue.filter("jsonToString", function(value) {
  if (!value) return "";

  var json = JSON.parse(value);
  var output = "";
  for (var i = 0; i < json.length; i++) {
    output += json[i] + ",";
  }
  var res = output.replace(/,*$/, "");
  return res;
});

Vue.filter("limit", function(text, char_length, suffix) {
  if (!text) return 0;
  if (text.length > char_length) {
    return text.substring(0, char_length) + suffix;
  } else {
    return text;
  }
});

Vue.filter("formatPhone", function(value) {
  if (!value) return 0;
  let val = value.toString().match(/\d{1,3}/g);
  var output = "";
  for (var i = 0; i < val.length; i++) {
    output += val[i] + " ";
  }
  return output;
});

Vue.filter("convertStringMonth", function(value) {
  if (!value) return "--";
  let cl = "";
  switch (value) {
    case 1:
      cl = "Jan";
      break;
    case 2:
      cl = "Feb";
      break;
    case 3:
      cl = "Mar";
      break;
    case 4:
      cl = "Apr";
      break;
    case 5:
      cl = "May";
      break;
    case 6:
      cl = "Jun";
      break;
    case 7:
      cl = "Jul";
      break;
    case 8:
      cl = "Aug";
      break;
    case 9:
      cl = "Sep";
      break;
    case 10:
      cl = "Oct";
      break;
    case 11:
      cl = "Nov";
      break;
    case 12:
      cl = "Dec";
      break;
  }
  return cl;
});
