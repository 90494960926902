<template>
  <div class="col-md-12 p-0">
    <!-- Download / export excel -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'download'">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <h5 class="text-left">
          {{ download.title }}<br />
          <span class="text-secondary mr-4"
            >Please choose available file report format to download.</span
          >
          <hr />
        </h5>
        <div class="text-right">
          <export-excel
            class="float-right mr-2"
            :data="download.data"
            :fields="download.fields"
            type="csv"
            :name="download.name"
            :title="download.title"
          >
            <a href="javascript:;" class="btn btn-light"
              ><i class="ti ti-file text-warning"></i> Format .CSV</a
            >
          </export-excel>
          <export-excel
            class="float-right mr-2"
            :data="download.data"
            :fields="download.fields"
            type="xls"
            :name="download.name"
            :title="download.title"
          >
            <a href="javascript:;" class="btn btn-light"
              ><i class="ti ti-file text-success"></i> Format .XLS</a
            >
          </export-excel>
        </div>
      </div>
    </div>
    <!-- ==================== -->

    <!-- Download / export excel -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'download_by_date'">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <div class="row">
          <div class="col-md-12 text-center">
            <h5 align="center">
              Please choose date range file report format to download.
            </h5>
            <img
              class="img-rounded text-center mt-3"
              :src="'img/uploadfile.png'"
              title="Upload Excel"
            />
          </div>
          <div class="col-md-12 mt-4">
            <date-picker
              v-model="period_contract"
              :disabled-date="disabledBeforeAfterPeriodContract"
              value-type="format"
              type="date"
              format="YYYY-MM-DD"
              placeholder="Choose Date Range"
              width="100%"
              style="width: 100%"
              range
            >
            </date-picker>
          </div>
        </div>
        <div class="text-right mt-3">
          <a
            v-if="period_contract.length > 0"
            @click="downloadExcelContractByDate($event)"
            class="btn btn-success"
            ><i class="ti ti-file text-light"></i> Format .XLS</a
          >
        </div>
      </div>
    </div>
    <!-- ==================== -->

    <div class="popup popup-lg" v-if="isEnablePopup == 'detail'">
      <div class="content success">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <div class="row p-n">
          <div class="col-md-12">
            <h5>
              Checklist Point <br /><small
                >Detail photo, time and noted checklist point</small
              >
            </h5>
            <hr />
            <div style="height: 450px; overflow-y: scroll">
              <div
                class="card"
                style="width: 31rem; margin: 5px; float: left"
                v-for="(item, index) in model_detail_task"
                :key="index"
              >
                <img
                  class="card-img-top"
                  :src="$store.state.api_mobile_resource + item.photo"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <ul
                    class="list-group list-group-flush"
                    style="text-align: center"
                  >
                    <li class="list-group-item">
                      <span>Checklist Time</span><br />
                      <span style="font-size: 16px; font-weight: bold">
                        {{ item.time }}</span
                      >
                    </li>
                    <li class="list-group-item" v-if="item.notes">
                      <q style="font-size: 14px">{{ item.notes }}</q>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body" v-if="!isLoading">
        <div v-if="isContractNotFound">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">Kontrak Periode Tidak ditemukan </div> 
              <div class="col-md-12">
                <date-picker
                          v-model="period"
                          value-type="format"
                          type="date"
                          format="YYYY-MM-DD"
                          placeholder="Pilih Range Tanggal"
                          width="100%"
                          @change="FilterModelDate()"
                          range
                          confirm
                        >
                          <template v-slot:header="{ emit }">
                            <div class="row text-left">
                              <div class="col-md-3">
                                <button
                                  class="mx-btn mx-btn-text"
                                  @click="selectLastDay(emit, 1)"
                                >
                                  1 hari sebelumnya
                                </button>
                              </div>
                              <div class="col-md-3">
                                <button
                                  class="mx-btn mx-btn-text"
                                  @click="selectLastDay(emit, 2)"
                                >
                                  2 hari sebelumnya
                                </button>
                              </div>
                              <div class="col-md-3">
                                <button
                                  class="mx-btn mx-btn-text"
                                  @click="selectLastDay(emit, 3)"
                                >
                                  3 hari sebelumnya
                                </button>
                              </div>
                              <div class="col-md-3">
                                <button
                                  class="mx-btn mx-btn-text"
                                  @click="selectLastDay(emit, 7)"
                                >
                                  7 hari sebelumnya
                                </button>
                              </div>
                            </div>
                          </template>
                          <template v-slot:footer="{ emit }">
                            <div class="row text-left mb-2">
                              <div class="col-md-3">
                                <button
                                  class="mx-btn mx-btn-text"
                                  @click="selectNextDay(emit, 1)"
                                >
                                  1 hari selanjutnya
                                </button>
                              </div>
                              <div class="col-md-3">
                                <button
                                  class="mx-btn mx-btn-text"
                                  @click="selectNextDay(emit, 2)"
                                >
                                  2 hari selanjutnya
                                </button>
                              </div>
                              <div class="col-md-3">
                                <button
                                  class="mx-btn mx-btn-text"
                                  @click="selectNextDay(emit, 3)"
                                >
                                  3 Hari selanjutnya
                                </button>
                              </div>
                              <div class="col-md-3">
                                <button
                                  class="mx-btn mx-btn-text"
                                  @click="selectNextDay(emit, 7)"
                                >
                                  7 Hari selanjutnya
                                </button>
                              </div>
                            </div>
                          </template>
                        </date-picker>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="form-group">
            <div class="row p-2">
              <div class="col-md-8 p-3 pl-4">
                <h5>{{ model_contract.client_name }}</h5>
                <p>
                  <span class="text-secondary">{{ model_contract.email }}</span
                  ><br /><span class="">{{
                    model_contract.phone_number | formatPhone
                  }}</span>
                </p>
                <p>
                  {{ model_contract.office_address }} <br />
                  {{ model_contract.nama_provinsi }},
                  {{ model_contract.nama_kota }}
                </p>
              </div>
              <div class="col-md-4">
                <ul class="list-area">
                  <li>
                    <span class="">Contract Number</span>
                    <span class="float-right"
                      ><b style="font-size: 16px"
                        ># {{ model_contract.contract_number }}</b
                      ></span
                    >
                  </li>
                  <li>
                    <span class="">Contract Period</span>
                    <span class="float-right"
                      ><b
                        >{{ model_contract.start_date | moment("DD/MM/YYYY") }} -
                        {{ model_contract.end_date | moment("DD/MM/YYYY") }}</b
                      ></span
                    >
                  </li>
                  <li v-if="model_contract.man_power !== undefined">
                    <span class="">MP Request</span><br /><br />
                    <span
                      class="text-muted"
                      v-for="(initems, index) in JSON.parse(
                        model_contract.man_power
                      )"
                      :key="index"
                    >
                      {{ initems.total }} ( {{ initems.job }} )
                      <span v-if="index % 2 == 0">,</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row form-group mt-5">
            <div class="col-md-12">
              <div class="float-left">
                <label class="text-primary mb-0"
                  ><i class="ti ti-info-alt"></i> Employee Checklist Area</label
                ><br />
                <small class="text-secondary"
                  >Report checklist point client area base
                </small>
              </div>

              <div class="float-right">
                <a @click="modalExportContract()" class="btn btn-primary mr-3">
                  <i class="ti ti-cloud-down"></i> Download Data
                </a>

                <!-- <date-picker
                  v-model="filter_date"
                  :default-value="filter_date"
                  :disabled-date="disabledBeforeAfterFilterDate"
                  :clearable="false"
                  value-type="format"
                  type="month"
                  format="MM/YYYY"
                  placeholder="Pilih Range Tanggal"
                  width="100%"
                  @change="FilterModelDate()"
                  confirm
                >
                </date-picker> -->

                <date-picker
                  v-model="period"
                  value-type="format"
                  type="date"
                  format="YYYY-MM-DD"
                  placeholder="Pilih Range Tanggal"
                  width="100%"
                  @change="FilterModelDate()"
                  range
                  confirm
                >
                  <template v-slot:header="{ emit }">
                    <div class="row text-left">
                      <div class="col-md-3">
                        <button
                          class="mx-btn mx-btn-text"
                          @click="selectLastDay(emit, 1)"
                        >
                          1 hari sebelumnya
                        </button>
                      </div>
                      <div class="col-md-3">
                        <button
                          class="mx-btn mx-btn-text"
                          @click="selectLastDay(emit, 2)"
                        >
                          2 hari sebelumnya
                        </button>
                      </div>
                      <div class="col-md-3">
                        <button
                          class="mx-btn mx-btn-text"
                          @click="selectLastDay(emit, 3)"
                        >
                          3 hari sebelumnya
                        </button>
                      </div>
                      <div class="col-md-3">
                        <button
                          class="mx-btn mx-btn-text"
                          @click="selectLastDay(emit, 7)"
                        >
                          7 hari sebelumnya
                        </button>
                      </div>
                    </div>
                  </template>
                  <template v-slot:footer="{ emit }">
                    <div class="row text-left mb-2">
                      <div class="col-md-3">
                        <button
                          class="mx-btn mx-btn-text"
                          @click="selectNextDay(emit, 1)"
                        >
                          1 hari selanjutnya
                        </button>
                      </div>
                      <div class="col-md-3">
                        <button
                          class="mx-btn mx-btn-text"
                          @click="selectNextDay(emit, 2)"
                        >
                          2 hari selanjutnya
                        </button>
                      </div>
                      <div class="col-md-3">
                        <button
                          class="mx-btn mx-btn-text"
                          @click="selectNextDay(emit, 3)"
                        >
                          3 Hari selanjutnya
                        </button>
                      </div>
                      <div class="col-md-3">
                        <button
                          class="mx-btn mx-btn-text"
                          @click="selectNextDay(emit, 7)"
                        >
                          7 Hari selanjutnya
                        </button>
                      </div>
                    </div>
                  </template>
                </date-picker>

                <!-- <select
                  class="form-control"
                  v-model="filter_date"
                  @change="FilterModelDate()"
                >
                  <option
                    v-for="(items, index) in collection_month"
                    :key="index"
                    :value="items"
                  >
                    {{ items }}
                  </option>
                </select> -->
              </div>
            </div>
            <div class="clearfix"></div>
          </div>

          <!-- style="height: 500px; overflow-y: scroll; margin-bottom: 5%" -->
          <div class="row">
            <div class="col-md-12 pr-0">
              <div tabindex="0" class="table-fixed">
                <!-- class="table-hover" -->
                <table :style="'min-width:' + table_cover_width + 'px;'">
                  <thead>
                    <tr>
                      <th class="no-filter">Date</th>
                      <th class="no-filter" style="width: 180px">
                        Section & Area Room
                      </th>
                      <th
                        v-for="(item, key_emp) in model_employee_checklist_area"
                        :key="key_emp"
                        class="no-filter"
                      >
                        {{ item["firstname"] }} {{ item["lastname"] }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item2, key_check_area2) in model_checklist_area"
                      :key="key_check_area2"
                    >
                      <td class="tdp">{{ item2.date_task }}</td>
                      <td class="tdp" style="width: 180px">
                        Section {{ item2.area_name }}
                      </td>
                      <td
                        v-for="(
                          item_emp2, key_emp2
                        ) in model_employee_checklist_area"
                        :key="key_emp2"
                        class="tdp"
                      >
                        <a
                          v-if="
                            GetSumCheckPointNik(
                              item_emp2['nik'],
                              key_check_area2
                            ) > 0
                          "
                          href="javascript:;"
                          @click="
                            getImage(
                              item2.id,
                              item2.id_contract,
                              item2.date_task,
                              item_emp2['nik']
                            )
                          "
                          class="text-secondary hover-checkpoint"
                        >
                          <span class="text-primary">
                            {{
                              GetSumCheckPointNik(
                                item_emp2["nik"],
                                key_check_area2
                              )
                            }}
                            X
                          </span>
                          Check Point
                        </a>
                        <span v-else class="text-secondary hover-checkpoint">
                          <span class="text-danger"> 0 X </span>
                          Check Point
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>  
      </div>    
        
      <div class="card-body" v-else>
        <div class="row">
          <div class="col-md-6">
            <ListLoader
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-6">
            <ListLoader
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-12">
            <ContentLoader primaryColor="#f3f3f3" secondaryColor="#ecebeb">
              <rect x="0" y="0" rx="2" ry="2" width="400" height="400" />
            </ContentLoader>
          </div>
        </div>
        <!-- <h5>Please Wait ...</h5>
                <h6 class="text-secondary">System adjustment layout for a while !</h6> -->
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";
import { ListLoader, ContentLoader } from "vue-content-loader";
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";
import { json } from "body-parser";

export default {
  components: {
    ListLoader,
    ContentLoader,
    DatePicker,
  },
  data() {
    return {
      period: [
        new Date().toISOString().split("T")[0],
        new Date(new Date().setDate(new Date().getDate() + 1))
          .toISOString()
          .split("T")[0],
      ],
      selectLastDay(emit, range) {
        const start = new Date();
        const end = new Date();
        start.setTime(start.getTime() - range * 24 * 3600 * 1000);
        const date = [start, end];
        emit(date);
      },
      selectNextDay(emit, range) {
        const start = new Date();
        const end = new Date();
        end.setTime(end.getTime() + range * 24 * 3600 * 1000);
        const date = [start, end];
        emit(date);
      },
      isLoading: true,
      isContractNotFound:true,
      isEnablePopup: false,
      isEnablePopupDownload: false,
      table_cover_width: 0,
      model_employee_checklist_area: [],
      model_checklist_area: [],
      model_checklist_area_v: [],
      model_contract: {},
      model_group_employee: [],
      model_date_range: [],
      model_date_task: [],
      model_detail_task: {},
      total_employee: 0,
      collection_month: [],
      filter_shift: "all",

      period_contract: [],
      // filter date
      filter_date: "",
      filter_year: "",
      filter_month: "",
      download: { fields: "", data: "", title: "" },
      pageNum: 1,
      totalPageInMonth: 0,
      daysInMonth: 0,
      count_date_range: 0,
    };
  },
  created() {
    this.QueryStartFromPeriod();
  },
  methods: {
    disabledBeforeAfterPeriodContract(date) {
      // console.log(this.model_contract);
      const start_date = new Date(this.model_contract.start_date);
      start_date.setHours(0, 0, 0, 0);

      const end_date = new Date(this.model_contract.end_date);
      end_date.setHours(0, 0, 0, 0);

      return date < start_date || date > end_date;
    },
    disabledBeforeAfterFilterDate(date) {
      const start_date = new Date(this.model_contract.start_date);
      start_date.setHours(0, 0, 0, 0);

      const end_date = new Date(this.model_contract.end_date);
      end_date.setHours(0, 0, 0, 0);

      let dateNow = new Date(date.getFullYear(), date.getMonth());
      let dateStart = new Date(start_date.getFullYear(), start_date.getMonth());
      let dateEnd = new Date(end_date.getFullYear(), end_date.getMonth());

      return dateNow < dateStart || dateNow > dateEnd;
    },
    modalExportContract() {
      this.isEnablePopup = "download_by_date";
    },
    async downloadExcelContractByDate(event) {
      event.target.className = "btn btn-success disabled";
      this.$store.state.loading.msg =
        "Download Report Checklist Area " +
        this.period_contract[0] +
        " until " +
        this.period_contract[1];
      this.$store.state.loading.status = true;

      this.period_contract = [this.period_contract[0], this.period_contract[1]];

      let dataParams = {
        id_contract: localStorage.getItem("QUERY_ID"),
        start_date: this.period_contract[0],
        end_date: this.period_contract[1],
      };

      await axios
        .post("report/checklist-contract-download", dataParams, {
          responseType: "blob",
        })
        .then((res) => {
          // console.log(res);
          const link = document.createElement("a");

          link.href = window.URL.createObjectURL(new Blob([res.data]));

          link.setAttribute(
            "download",
            "Report Checklist Area " +
              this.model_contract.client_name +
              " Period " +
              this.period_contract[0] +
              " until " +
              this.period_contract[1] +
              ".xlsx"
          );

          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.$store.state.loading.status = false;
          event.target.className = "btn btn-success";
          this.isEnablePopup = false;
        });
    },
    DownloadData(nik, name) {
      this.$store.state.loading.msg = "Download Report Data...";
      this.$store.state.loading.status = true;

      axios
        .post("report/checklist-download", {
          id_contract: localStorage.getItem("QUERY_ID"),
          month: this.filter_month,
          year: this.filter_year,
          nik: nik,
        })
        .then((res) => {
          this.download.fields = {
            Date: "date",
            Section: "section",
            Checkpoint: "checkpoint",
          };
          this.download.title = "Report Checkpoint - " + name;
          this.download.name = name + ".xls";
          this.download.data = res.data;
          this.isEnablePopup = "download";
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    GetCompleteName(firstname, lastname) {
      return firstname + " " + lastname;
    },
    DetailReport(idx, data) {
      this.model_detail_task = data[idx];
      this.isEnablePopup = "detail";
    },
    GetSumCheckPointNik(nik, idxCheckArea) {
      return this.model_checklist_area[idxCheckArea][nik];
    },
    FilterModelDate() {
      this.isLoading = true;

      let fdate = this.filter_date.split("/");
      this.filter_month = fdate[0];
      this.filter_year = fdate[1];

      this.DataChecklistArea();
    },
    async QueryStartFromPeriod() {
      
      await axios
        .get("contract/period/" + localStorage.getItem("QUERY_ID"))
        .then((response) => {
          
          const d = new Date();
          let query = response.data;
          let default_date = query.default;
          let dt = default_date.split("/");

          this.collection_month = query.month_period;

          this.filter_month = ("0" + (d.getMonth() + 1)).slice(-2);
          this.filter_year = d.getFullYear().toString();
          this.filter_date = this.filter_month + "/" + this.filter_year;
          // console.log(this.filter_date);
          // console.log(this.startOfWeek(d).toString());
          this.DataChecklistArea();
          // this.DataQuery();
        })
        .catch((error) => {
          // console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    async getImage(id_section_room, id_contract, date_task, nik) {
      await axios
        .post("contract/get-checkpoint", {
          id: id_contract,
          nik: nik,
          date_task: date_task,
          id_section_room: id_section_room,
        })
        .then((res) => {
          this.model_detail_task = JSON.parse(res.data.checkpoint);
          this.isEnablePopup = "detail";
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async DataChecklistArea(page = 1) {
      this.pageNum = page;
      this.isLoading = true;
      
      await axios
        .post("report/checklist-v2", {
          id_contract: localStorage.getItem("QUERY_ID"),
          start_date: this.period[0],
          end_date: this.period[1],
        })
        .then((response) => {

          console.log(response);
          this.isContractNotFound = false;
          this.isLoading = false;
          this.model_contract = response.data.contract;
          this.model_checklist_area = response.data.checklist_area;
          this.model_employee_checklist_area = response.data.employee;
          this.total_employee = response.data.total_emp;
          this.table_cover_width = this.total_employee * 150;
        })
        .catch((error) => {
          this.isLoading = false;
          this.isContractNotFound = true;
          console.log(this.isContractNotFound);
          console.log(error.statusText);
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.secdate {
  display: none;
}
.tdp {
  padding-top: 12px;
  padding-bottom: 12px;
}
ul.list-area {
  float: right;
  width: 100%;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    float: left;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid whitesmoke;
  }
}

.popup-detail-report {
  h5 {
    text-align: left;
  }
}

.photo-checklist {
  text-align: center;
  background-color: #e8e8e8;
  img {
    height: 250px;
    width: auto;
    margin: 0 auto;
  }
}

.table-fixed {
  overflow: auto;
  height: 500px;
}
.table-fixed thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

table th:nth-child(1) {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #ecf6ff;
  color: #373737;
  z-index: 2;
}

table td:nth-child(1) {
  position: sticky;
  left: 0;
  background-color: #ecf6ff;
  color: #373737;
  z-index: 1;
}

table th:nth-child(2) {
  position: sticky;
  top: 0;
  left: 80px;
  background-color: #ecf6ff;
  color: #373737;
  z-index: 2;
}

table td:nth-child(2) {
  position: sticky;
  left: 80px;
  background-color: #ecf6ff;
  color: #373737;
  z-index: 1;
}
// .table th:first-child,
// .table td:first-child {
//   position: sticky;
//   left: 0;
//   background-color: #ad6c80;
//   color: #373737;
// }
// .fix1 {
//   position: sticky;
// }
// .fix1:first-child {
//   left: 0;
//   width: 85px;
// }
</style>
