<template>
    <div class="col-md-12 p-0">
        <!-- Popup Detail Information -->
         <div class="popup popup-md" v-if="isEnablePopup">
            <div class="content">
                <small id="close-popup" @click="isEnablePopup = false"><i class="ti ti-close"></i></small>
                <h5>{{ model_detail.title }}<br/><small>{{ model_detail.created_at | moment("DD/MM/YYYY") }}</small></h5>

                <div class=" mt-5">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="info">
                                <h6>List of Contract</h6>
                                <hr/>
                                <div class="scrollbox">
                                    <ul>
                                        <li v-for="(items, idx) in model_detail.list_contract" :key="idx">Contract Number : {{ items }} <i class="ti ti-check float-right text-success"></i> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="info">
                                <h6>List of Employee</h6>
                                <hr/>
                                <div class="scrollbox">
                                    <ul>
                                        <li v-for="(items, idx) in model_detail.list_employee" :key="idx">Emplyee Nik : {{ items }} <i class="ti ti-check float-right text-success"></i> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ======================== -->
         <div class="card">
            <div class="card-body">
                <div class="table-header">
                    <h5 >Master Data <br/> <small>Total Records : <b>{{ data_model.total | formatPrice }}</b></small></h5>
                    <div class="group-search pull-right">
                        <a class="btn" @click="tableRefresh()" ><i class="ti ti-reload"></i></a>
                        <a class="btn" @click="isActiveSearch()" ><i :class="isEnableTools == false  ? 'ti ti-search' : 'ti ti-close'"></i></a>
                        <div class="select" v-if="isEnableTools" >
                            <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                                <option value="">-- Choose Field --</option>
                                <option value="title">Title</option>
                            </select>
                        </div>
                        <div class="group" v-if="isEnableTools">
                            <input type="date" v-model="meta_data.sval" v-if="meta_data.skey == 'created_at' || meta_data.skey == 'updated_at'"/>
                            <input type="text" v-model="meta_data.sval" placeholder="Enter Keyword ...." v-else/>
                            <a class="btn" @click="tableSearch()">
                                SEARCH DATA 
                            </a>
                        </div>  
                        <router-link :to="{ name: meta_url + '/store' }" class="btn btn-primary" v-if="$store.state.app.access.includes('insert')"><i class="ti ti-plus"></i> Create New</router-link>
                    </div>
                    
                </div>
                <div class="table-cover">
                    <table class="table-hover" >
                        <thead>
                            <tr>
                                <th>Tools</th>
                                <th :class="meta_data.okey == 'execute_by' ? 'active' : ''" v-on:click="tableOrdering('execute_by')">Execute By</th>
                                <th :class="meta_data.okey == 'title' ? 'active' : ''" v-on:click="tableOrdering('title')">Title</th>
                                <th :class="meta_data.okey == 'created_at' ? 'active' : ''" v-on:click="tableOrdering('created_at')">Created Date</th>
                            </tr>
                        </thead>
                        <tbody v-if="data_model.total > 0">
                             <tr  v-for="(items, idx) in data_model.data" :key="idx">
                                 <td width="110">
                                    <a href="javascript:;" @click="Information(data_model.data[idx])" ><i class="ti ti-info-alt text-primary"></i> Information</a>
                                </td>
                                <td width="150"> {{ items.execute_by }}</td>
                                <td> {{ items.title }}</td>
                                <td>{{ items.created_at | moment("DD/MM/YYYY") }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="pagination" >
                    <small class="info"><i class="ti ti-split-h"></i> Scroll horizontal to view data.</small>
                    <paginate
                    :page-count="parseInt(data_model.last_page)"
                    :click-handler="tableQuery"
                    :container-class="'pagination'"
                    :page-class="'page-item'" >
                    </paginate>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>

    import { mapState } from 'vuex';
    import axios from 'axios';

    export default {
        data() 
        {
            return {
                isEnablePopup : false,
                isEnableTools : false, 
                isEnableOrder : false,
                meta_url : 'cron',
                
                model_detail : [],
                model_province : [],
                model_kota : [],
                meta_data :{ okey: 'id', oval:'desc', wkey : '', wval : '', skey : '', sval : ''},
                meta_filter :{  id_propinsi : 'all', id_kota : 'all', }

            }
        },
        components: {
        },
        computed: {
            ...mapState('crud', {
                data_model: state => state.data_model
            })
        },
        created()
        {
            this.tableQuery();
        },
        methods:
        {
            isActiveSearch()
            {
                this.isEnableTools =! this.isEnableTools;
                if(this.isEnableTools) this.meta_data.sval = '';
            },
            Information(data)
            {
                this.model_detail = data;

                let desc = JSON.parse(data.desc);
                this.model_detail.list_contract = desc.coll_contract;
                this.model_detail.list_employee = desc.coll_nik;
                this.isEnablePopup = true;
            },
            tableSearch()
            {
                if(this.meta_data.skey == ''){
                    alert('🙏, Please choose target field');
                    return;
                }
                if(this.meta_data.sval == ''){
                    alert('🙏, Please enter the keyword');
                    return;
                }
                this.tableQuery();
            },
            tableOrdering(key)
            {
                this.isEnableOrder =! this.isEnableOrder;
                this.meta_data.okey = key;
                this.meta_data.oval = this.isEnableOrder ? 'ASC' : 'DESC';
                this.tableQuery(this.page);
            },
            tableQuery(pageNum)
            {
                this.page = pageNum;
                let data = { 
                    url: this.meta_url+'/contract',
                    offset : this.page,
                    search: { key : this.meta_data.skey, value : this.meta_data.sval },
                    order: { key : this.meta_data.okey, value : this.meta_data.oval },
                    where : { key : this.meta_data.wkey, value : this.meta_data.wval },
                };

                this.$store.state.loading.msg = 'Processing Fetching Data...';
                this.$store.state.loading.status = true;
                this.$store.dispatch('crud/getData', data );
            },  
            tableRefresh()
            {
                this.meta_data.skey = '';
                this.meta_data.sval = '';
                this.tableQuery();
            },

           
        }
    }
</script>

<style lang="scss" scoped>
.info{
   
    .scrollbox{
        height:600px;
        overflow-y:scroll;
        ul{
            margin:0;
            padding:0;
            li{
                padding:10px;
                float:left;
                width:100%;
                border-bottom:1px solid whitesmoke;
            }
        }
    }
    
}
</style>