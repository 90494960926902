<template>
    <div class="col-md-12 p-0">
       
          <!-- The Modal -->
        <div class="popup popup-sm" v-if="isEnablePopup">
            <div class="content">
                <small id="close-popup" @click="ClosePopup()"><i class="ti ti-close"></i></small>
                <h5>Master Job Position <br/> <small>Please insert complete data.</small></h5>
                <form  @submit="submit">
                    <div class="form-group">
                        <label class="col-sm-12 ">Job Title <small class="text-danger">*</small></label>
                        <div class="col-sm-12">
                            <input type="type" class="form-control" v-model="model_form.title"/>
                            <small class="text-danger" v-if="msg_error.title">{{ msg_error.title[0] }}</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-12 ">Job Description </label>
                        <div class="col-sm-12">
                            <textarea name="" class="form-control" v-model="model_form.description"></textarea>
                            <small class="text-danger" v-if="msg_error.description">{{ msg_error.description[0] }}</small>
                        </div>
                    </div>
                    <hr/>
                    <div class="form-group">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-execute btn-primary">Save Data</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
         <div class="card">
            <div class="card-body">
                <div class="table-header">
                    <h5 >Master Data <br/> <small>Total Records : <b>{{ data_model.total | formatPrice }}</b></small></h5>
                    <div class="group-search pull-right">
                        <a class="btn" @click="tableRefresh()" ><i class="ti ti-reload"></i></a>
                        <a class="btn" @click="isActiveSearch()" ><i :class="isEnableTools == false  ? 'ti ti-search' : 'ti ti-close'"></i></a>
                        <div class="select" v-if="isEnableTools" >
                            <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                                <option value="">-- Choose Field --</option>
                                <option value="title">Job Title</option>
                                <option value="description">Description</option>
                            </select>
                        </div>
                        <div class="group" v-if="isEnableTools">
                            <input type="date" v-model="meta_data.sval" v-if="meta_data.skey == 'created_at' || meta_data.skey == 'updated_at'"/>
                            <input type="text" v-model="meta_data.sval" placeholder="Enter Keyword ...." v-else/>
                            <a class="btn" @click="tableSearch()">
                                SEARCH DATA 
                            </a>
                        </div>  
                        <a href="javascript:;" @click="tableCreateData()" class="btn btn-primary" v-if="$store.state.app.access.includes('insert')"><i class="ti ti-plus"></i> Create New </a>
                    </div>
                   
                </div>
                <div class="table-cover">
                    <table class="table-hover" >
                        <thead>
                            <tr>
                                <th width="70" class="no-filter text-center" v-if="JSON.parse($store.state.app.access).length > 1">Action</th>
                                <th :class="meta_data.okey == 'title' ? 'active' : ''" v-on:click="tableOrdering('title')">Title Name</th>
                                <th :class="meta_data.okey == 'description' ? 'active' : ''" v-on:click="tableOrdering('description')">Description</th>
                                <th :class="meta_data.okey == 'created_at' ? 'active' : ''" v-on:click="tableOrdering('created_at')">Created Date</th>
                                <th :class="meta_data.okey == 'updated_at' ? 'active' : ''" v-on:click="tableOrdering('updated_at')">Updated Date</th>
                            </tr>
                        </thead>
                        <tbody v-if="data_model.total > 0">
                             <tr  v-for="(items, idx) in data_model.data" :key="idx">
                                <td v-if="JSON.parse($store.state.app.access).length > 1">
                                    <div class="tools">
                                        <a href="javascript:;">Tools</a>
                                        <ul>
                                            <li v-if="$store.state.app.access.includes('update')">
                                                <a href="javascript:;" @click="tableUpdate(idx)"><i class="text-success ti ti-pencil"></i> Edit</a>
                                            </li>
                                            <li v-if="$store.state.app.access.includes('delete')">
                                                <a href="javascript:;" @click="tableRemove(items.id)"><i class="text-danger ti ti-trash"></i> Remove</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td width="150">{{ items.title }}</td>
                                <td width="500">{{ items.description }}</td>
                                <td>{{ items.created_at | moment("DD/MM/YYYY") }}</td>
                                <td>{{ items.updated_at | moment("DD/MM/YYYY") }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="6"><div class="no-data" v-if="!$store.state.loading.status">Sorry, data is not available</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="pagination" >
                    <small class="info"><i class="ti ti-split-h"></i> Scroll horizontal to view data.</small>
                    <paginate
                    :page-count="parseInt(data_model.last_page)"
                    :click-handler="tableQuery"
                    :container-class="'pagination'"
                    :page-class="'page-item'" >
                    </paginate>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>

    import { mapState } from 'vuex';
    import axios from 'axios';
    import SelectBranch from '../../Branch/@SelectBranch';

    export default {
        data() 
        {
            return {
                isRoot : false,
                isEnableTools : false, 
                isEnableOrder : false,
                isEnablePopup : false,
                
                model_form : {},
                mode_form : '',
                meta_url : 'job_position',
                meta_data :{ okey: 'created_at', oval:'desc', wkey : '', wval : '', skey : '', sval : ''},
                meta_filter :{ id_branch : 'all'}
            }
        },
        components: {
            SelectBranch
        },
        computed: {
            ...mapState('crud', {
                data_model: state => state.data_model,
                msg_error: state => state.error,
            })
        },
        created()
        {
            // filter branch
            if(localStorage.getItem('userPrivilegesName') !== 'root'){
                this.meta_filter.id_branch = localStorage.getItem('userBranchId');
            }else{
                this.isRoot = true;
            }


            this.tableQuery();
        },
        methods:
        {
            
            isActiveSearch()
            {
                this.isEnableTools =! this.isEnableTools;
                if(this.isEnableTools) this.meta_data.sval = '';
            },
            ClosePopup()
            {
                this.tableQuery();
                this.isEnablePopup = false;
            },
            tableSearch()
            {
                if(this.meta_data.skey == ''){
                    alert('🙏, Please choose target field');
                    return;
                }
                if(this.meta_data.sval == ''){
                    alert('🙏, Please enter the keyword');
                    return;
                }
                this.tableQuery();
            },
            tableOrdering(key)
            {
                this.isEnableOrder =! this.isEnableOrder;
                this.meta_data.okey = key;
                this.meta_data.oval = this.isEnableOrder ? 'ASC' : 'DESC';
                this.tableQuery(this.page);
            },
            tableQuery(pageNum)
            {
                this.page = pageNum;
                let data = { 
                    url: this.meta_url+'/data',
                    offset : this.page,
                    search: { key : this.meta_data.skey, value : this.meta_data.sval },
                    order: { key : this.meta_data.okey, value : this.meta_data.oval },
                    where : { key : this.meta_data.wkey, value : this.meta_data.wval },
                };

                this.$store.state.loading.msg = 'Processing Fetching Data...';
                this.$store.state.loading.status = true;
                this.$store.dispatch('crud/getData', data );
            },  
            tableRefresh()
            {
                this.meta_data.skey = '';
                this.meta_data.sval = '';
                this.$store.state.loading.msg = 'Refreshing Data...';
                this.$store.state.loading.status = true;
                this.$store.dispatch('crud/getData', { url: this.meta_url+'/data'});
            },
            tableCreateData()
            {
                this.model_form = {};
                this.mode_form = 'store';
                this.isEnablePopup = true;
            },
            tableUpdate(idx)
            {
                this.model_form = {};
                this.mode_form = 'update';
                this.model_form = this.data_model.data[idx];
                this.isEnablePopup = true;
            },
            async tableRemove(id)
            {
                if( confirm( 'Are you sure want to remove this data ?' ) )
                { 
                    this.$store.state.loading.msg = 'Processing Remove Data...';
                    this.$store.state.loading.status = true;

                    let data = { url: this.meta_url+'/destroy/'+id };
                    let res = await this.$store.dispatch('crud/deleteData', data );
                    if(res && res.result == 'ok'){
                        this.$store.state.alert.status = true;
                        this.$store.state.alert.msg = res.msg;
                        this.tableQuery();
                    }
                }
            },
            
            async submit(e)
            {
                e.preventDefault();
               
                if( this.mode_form == 'store' )
                {
                    this.$store.state.loading.msg = 'Processing Submit Data...';
                    this.$store.state.loading.status = true;

                    // branch station
                    this.model_form.id_branch = localStorage.getItem('userBranchId');
                    
                    let data = { url: this.meta_url + '/store', form_data: this.model_form };
                    let res = await this.$store.dispatch('crud/storeData', data );
                    if(res && res.result == 'ok'){
                        this.isEnablePopup = false;
                        this.tableQuery();
                        this.$store.dispatch('crud/normalize');
                    }
                }

                if( this.mode_form == 'update' )
                {
                    this.$store.state.loading.msg = 'Processing Update Data...';
                    this.$store.state.loading.status = true;
                    
                    let data = { url: this.meta_url + '/update/'+this.model_form.id,  form_data: this.model_form };
                    let res = await this.$store.dispatch('crud/updateData', data );
                    if(res && res.result == 'ok'){
                        this.isEnablePopup = false;
                        this.tableQuery();
                        this.$store.dispatch('crud/normalize');
                    }
                }
            },
        }
    }
</script>