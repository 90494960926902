<template>
<a href="javascript:;" @click="init_fulllscreen()" class="right-link mr-4">
    <i class="ti ti-fullscreen"></i>
</a>
</template>

<script>
export default{

    methods: {
        init_fulllscreen()
        {
            this.isEnableFullscreen =! this.isEnableFullscreen;
            var elem = document.documentElement;
            if( this.isEnableFullscreen ){
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) { /* Firefox */
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE/Edge */
                    elem.msRequestFullscreen();
                }
            }else{
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE/Edge */
                    document.msExitFullscreen();
                }
            }
        },
    },
}
</script>
