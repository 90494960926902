<template>
  <div class="col-lg-12 col-md-12 col-sm-12 p-0">
    <PopupEmployeeMaster
      v-if="isEnablePopup"
      v-on:exit="ClosePopup"
      v-on:applyData="AssignData"
      :model_nik="model_nik"
    />
    <PopupEmployeeTemp
      v-if="isEnablePopupTemp"
      v-on:exit="ClosePopupTemp"
      v-on:applyUpdateModelNik="UpdateModelNik"
      :model_employee_temp="model_group_employee_temp"
    />
    <div class="card">
      <div class="card-body">
        <div class="form-group">
          <div class="row p-2">
            <div class="col-md-8 p-3 pl-4">
              <ContentLoader v-if="$store.state.loading.status">
                <rect x="0" y="0" rx="3" ry="0" width="200" height="7" />
                <rect x="0" y="15" rx="3" ry="0" width="160" height="7" />
                <rect x="0" y="30" rx="3" ry="0" width="125" height="5" />
              </ContentLoader>
              <div v-else>
                <p>
                  <span
                    :class="model_contract.status | contractStatus"
                    style="font-size: 12px"
                    >{{ model_contract.status | cleanString }}</span
                  >
                </p>
                <h5>{{ model_contract.client_name }}</h5>
                <p>
                  <span class="text-secondary">{{ model_contract.email }}</span
                  ><br /><span class="">{{
                    model_contract.phone_number | formatPhone
                  }}</span>
                </p>
                <p>
                  {{ model_contract.office_address }} <br />
                  {{ model_contract.nama_provinsi }},
                  {{ model_contract.nama_kota }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <ul class="list-area" v-if="$store.state.loading.status">
                <li>
                  <ContentLoader>
                    <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
                    <rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
                    <rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
                    <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
                    <rect x="20" y="80" rx="3" ry="3" width="200" height="10" />
                    <rect x="20" y="100" rx="3" ry="3" width="80" height="10" />
                  </ContentLoader>
                </li>
              </ul>
              <ul class="list-area" v-else>
                <li>
                  <span class="">Contract Number</span>
                  <span class="float-right"
                    ><b style="font-size: 16px"
                      ># {{ model_contract.contract_number }}</b
                    ></span
                  >
                </li>
                <li>
                  <span class="">Contract Period</span>
                  <span class="float-right"
                    ><b
                      >{{ model_contract.start_date | moment("DD/MM/YYYY") }} -
                      {{ model_contract.end_date | moment("DD/MM/YYYY") }}</b
                    ></span
                  >
                </li>
                <li v-if="model_contract.man_power !== undefined">
                  <span class="">MP Request</span><br /><br />
                  <span
                    class="text-muted"
                    v-for="(initems, index) in JSON.parse(
                      model_contract.man_power
                    )"
                    :key="index"
                  >
                    {{ initems.total }} ( {{ initems.job }} )
                    <span v-if="index % 2 == 0">,</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <br />
        <div class="form-group">
          <div class="col-sm-12" v-if="$store.state.loading.status"></div>
          <div class="col-sm-12" v-else>
            <div class="float-left">
              <label class="text-primary mb-0"
                ><i class="ti ti-info-alt"></i> Officer Client Base</label
              ><br />

              <small class="text-secondary" v-if="model_last_created !== ''"
                >Last Update :

                {{ model_last_created | moment("DD/MM/YYYY HH:mm") }}</small
              >
            </div>
            <div class="float-right" v-if="isUpdateTools">
              <a
                href="javascript:;"
                :class="
                  !isEditTable
                    ? 'float-right btn btn-light ml-2'
                    : 'float-right btn btn-light'
                "
                @click="isEditTable = !isEditTable"
                ><i
                  :class="
                    !isEditTable
                      ? 'ti ti-lock text-danger'
                      : 'ti ti-unlock text-primary'
                  "
                ></i
              ></a>
              <a
                href="javascript:;"
                class="btn btn-light float-right"
                @click="isEnablePopup = true"
                ><i class="ti ti-server text-primary"></i> Manage Employee</a
              >
            </div>
          </div>
          <div class="clearfix"></div>
          <hr />
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="group-search" style="margin-top: 20px; float: left">
              <a class="btn btn-default" @click="isEnablePopupTemp = true">
                <span class="badge badge-primary">{{
                  model_group_employee_temp.length
                }}</span>
                Employees to be add
              </a>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="table-header">
              <div class="group-search pull-right">
                <a class="btn" @click="tableRefresh()"
                  ><i class="ti ti-reload"></i
                ></a>
                <a class="btn" @click="isActiveSearch()"
                  ><i
                    :class="
                      isEnableTools == false ? 'ti ti-search' : 'ti ti-close'
                    "
                  ></i
                ></a>
                <div class="select" v-if="isEnableTools">
                  <select
                    v-model="meta_data.skey"
                    @change="meta_data.sval = ''"
                  >
                    <option value="">-- Choose Field --</option>
                    <option value="contract_employee.nik">Nik</option>
                    <option value="employee.firstname">Firstname</option>
                  </select>
                </div>
                <div class="group" v-if="isEnableTools">
                  <input
                    type="text"
                    v-model="meta_data.sval"
                    placeholder="Enter Keyword ...."
                  />
                  <a class="btn" @click="tableSearch()"> SEARCH DATA </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
            <div class="table-cover">
              <table class="table-hover" width="1400">
                <thead>
                  <tr>
                    <th>No</th>
                    <th class="no-filter" v-if="isUpdateTools">Action</th>
                    <th class="no-filter">NIK</th>
                    <th class="no-filter">Officer Name & Job Title</th>
                    <th class="no-filter text-center" colspan="4">
                      Basic Sallary & Benefits
                    </th>
                    <th class="no-filter">Employee Status</th>
                    <th class="no-filter">Phone Number</th>
                    <th class="no-filter">Complete Address</th>
                  </tr>
                </thead>
                <tbody v-if="model_group_employee_length > 0">
                  <tr
                    v-for="(items, idx) in model_group_employee.data"
                    :key="idx"
                  >
                    <td>{{ idx + model_group_employee.from }}</td>
                    <td v-if="isUpdateTools">
                      <a
                        href="javascript:;"
                        class="btn"
                        @click="RemoveEmployee(items.nik, items.new)"
                        ><i class="ti ti-trash text-danger"></i
                      ></a>
                    </td>
                    <td>{{ items.nik }}</td>
                    <td>
                      <img
                        :src="$store.state.api_resource + items.photo"
                        class="table-picture-profile"
                        v-show="items.photo !== null"
                      />
                      <div
                        class="table-avatar"
                        v-show="items.photo == null"
                      ></div>

                      {{ items.firstname }} {{ items.lastname }}<br />
                      <span class="text-primary">{{ items.job_title }}</span>
                    </td>
                    <td width="120">
                      <span class="text-muted">Gaji Pokok</span> <br />
                      <vue-numeric
                        separator="."
                        output-type="String"
                        currency="Rp"
                        v-model="sallary_gp[idx]"
                        class="form-control"
                        v-if="isEditTable"
                      ></vue-numeric>
                      <span v-else>Rp {{ sallary_gp[idx] | formatPrice }}</span>
                    </td>
                    <td width="130">
                      <span class="text-muted">Tunjangan Jabatan</span><br />
                      <vue-numeric
                        separator="."
                        output-type="String"
                        currency="Rp"
                        v-model="sallary_tj[idx]"
                        class="form-control"
                        v-if="isEditTable"
                      ></vue-numeric>
                      <span v-else>Rp {{ sallary_tj[idx] | formatPrice }}</span>
                    </td>
                    <td width="120">
                      <span class="text-muted">Insentif</span><br />
                      <vue-numeric
                        separator="."
                        output-type="String"
                        currency="Rp"
                        v-model="sallary_it[idx]"
                        class="form-control"
                        v-if="isEditTable"
                      ></vue-numeric>
                      <span v-else>Rp {{ sallary_it[idx] | formatPrice }}</span>
                    </td>
                    <td width="120">
                      <span class="text-muted">Uang Makan</span><br />
                      <vue-numeric
                        separator="."
                        output-type="String"
                        currency="Rp"
                        v-model="sallary_um[idx]"
                        class="form-control"
                        v-if="isEditTable"
                      ></vue-numeric>
                      <span v-else>Rp {{ sallary_um[idx] | formatPrice }}</span>
                    </td>
                    <td width="130">
                      <span :class="items.activity_status | activityStatus">{{
                        items.activity_status
                      }}</span>
                    </td>
                    <td>{{ items.phone_number }}</td>
                    <td width="250">{{ items.address }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="10">
                      <span class="text-danger"
                        >Please set some officer and get back to manage shift
                        worker !</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pagination" v-if="model_group_employee_length > 0">
              <small class="info"
                ><i class="ti ti-split-h"></i> Scroll horizontal to view
                data.</small
              >

              <paginate
                :page-count="parseInt(model_group_employee.last_page)"
                :click-handler="DataQuery"
                :container-class="'pagination'"
                :page-class="'page-item'"
              >
              </paginate>
            </div>
          </div>
        </div>
        <hr />
        <br />
        <div class="form-group">
          <div class="col-sm-12 text-right">
            <button
              class="btn btn-execute btn-primary"
              v-if="model_group_employee_temp.length > 0"
              @click="Submit()"
              :disabled="isDisable"
            >
              Update Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import $ from "jquery";
import PopupEmployeeMaster from "../Employment/@PopupEmployeeMaster";
import PopupEmployeeTemp from "../Employment/@PopupEmployeeTemp";
import { ContentLoader } from "vue-content-loader";
import { seriesType } from "highcharts";

export default {
  components: {
    PopupEmployeeMaster,
    PopupEmployeeTemp,
    ContentLoader,
  },
  data() {
    return {
      isDisable: false,
      isUpdateTools: false,
      isEditTable: false,
      isEnablePopup: false,
      isEnablePopupTemp: false,
      isEnableTools: false,
      model_contract: {},
      model_group_employee_length: 0,
      model_group_employee: [],
      model_group_employee_temp: [],
      model_nik: [],
      model_last_created: "",
      max_mp: 0,
      meta_data: {
        skey: "",
        sval: "",
      },
      // sallary
      sallary_gp: [],
      sallary_tj: [],
      sallary_it: [],
      sallary_um: [],
    };
  },

  created() {
    this.DataQuery();
  },
  methods: {
    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
      if (this.isEnableTools) this.meta_data.sval = "";
    },
    tableRefresh() {
      this.meta_data.skey = "";
      this.meta_data.sval = "";
      this.$store.state.loading.msg = "Refreshing Data...";
      this.$store.state.loading.status = true;
      this.DataQuery();
    },
    tableSearch() {
      if (this.meta_data.skey == "") {
        alert("🙏, Please choose target field");
        return;
      }
      if (this.meta_data.sval == "") {
        alert("🙏, Please enter the keyword");
        return;
      }
      this.DataQuery();
    },
    GetTotalManPower(data) {
      let res = JSON.parse(data);
      let total = 0;
      for (let key in res) {
        total = total + parseInt(res[key].total);
      }
      this.max_mp = total;
    },

    async DataQuery(pageNum = 1) {
      this.page = pageNum;
      // let search = { key: this.meta_data.skey, value: this.meta_data.sval };
      // console.log(search);
      // let params = "?page=" + this.page;
      // if (search.key != "") {
      //   params = "?page=" + this.page + "&" + search.key + "=" + search.value;
      // }

      let params = {
        url: "contract/one/" + localStorage.getItem("QUERY_ID"),
        offset: this.page,
        search: { key: this.meta_data.skey, value: this.meta_data.sval },
      };

      // let query = await this.$store.dispatch("crud/find", {
      //   url: "contract/one/" + localStorage.getItem("QUERY_ID") + params,
      // });

      let query = await this.$store.dispatch("crud/findContract", params);

      this.model_contract = query.data;
      this.model_group_employee_length = query.employee.data.length;
      this.model_group_employee = query.employee;
      //   console.log(this.model_group_employee);
      this.GetTotalManPower(this.model_contract.man_power);

      // enable tools
      let block_status = ["terminate", "out_of_date"];
      if (!block_status.includes(this.model_contract.status)) {
        this.isUpdateTools = true;
      }

      if (this.model_group_employee_length > 0) {
        this.model_last_created = this.model_group_employee.data[0].updated_at;
        // console.log(this.model_group_employee.data);
        for (let key in this.model_group_employee.data) {
          // console.log(this.model_group_employee.data[key]);
          // this.model_nik.push(this.model_group_employee.data[key].nik);
          this.sallary_gp.push(this.model_group_employee.data[key].gaji_pokok);
          this.sallary_tj.push(this.model_group_employee.data[key].tunjangan);
          this.sallary_it.push(this.model_group_employee.data[key].insentif);
          this.sallary_um.push(this.model_group_employee.data[key].uang_makan);
        }
      }
    },

    AssignData(data) {
      // Modify Rudy for added when EMployee
      // let model_nik = this.model_nik;
      //   console.log(model_nik)
      // let model_employee = this.model_group_employee;
      // for (let key in data) {

      //   if (!this.model_nik.includes(data[key].nik)) {
      //     data[key].new = true;
      //     this.model_group_employee.push(data[key]);
      //     this.model_nik.push(data[key].nik);
      //     this.sallary_gp.push(0);
      //     this.sallary_tj.push(0);
      //     this.sallary_it.push(0);
      //     this.sallary_um.push(0);
      //   }
      // }

      for (let key in data) {
        if (
          this.model_group_employee_temp.some(
            (temp) => temp.nik === data[key].nik
          )
        ) {
          this.$store.state.alert.type = "warning";
          this.$store.state.alert.msg =
            "Nik " +
            data[key].nik +
            " Pekerja atas nama " +
            data[key].firstname +
            data[key].lastname +
            " Sudah terdaftar";
          this.$store.state.alert.status = true;
          // alert("Nik " + data[key].nik + " Pekerja atas nama " + data[key].firstname + data[key].lastname + " Sudah terdaftar");
        } else {
          this.model_group_employee_temp.push(data[key]);
          this.model_nik.push(data[key].nik);
        }
      }
    },
    ClosePopup() {
      this.isEnablePopup = false;
    },
    ClosePopupTemp() {
      this.isEnablePopupTemp = false;
    },
    UpdateModelNik(nik) {
      this.model_nik.splice(this.model_nik.indexOf(nik), 1);
    },
    async RemoveEmployee(nik, initial_new) {
      if (confirm("Are you sure want to remove this data ?")) {
        // if (initial_new) {
        //   let model_group_employee = this.model_group_employee;
        //   let model_nik = this.model_nik;
        //   for (let key in model_group_employee) {
        //     if (model_group_employee[key].nik == nik) {
        //       model_group_employee.splice(key, 1);
        //     }
        //   }

        //   for (let key in model_nik) {
        //     if (model_nik[key] == nik) {
        //       model_nik.splice(key, 1);
        //     }
        //   }
        // } else {
        let id_contract = localStorage.getItem("QUERY_ID");
        let data = {
          url: "employee/destroy_assign/" + id_contract + "/" + nik,
        };
        let res = await this.$store.dispatch("crud/deleteData", data);

        if (res && res.result == "ok") {
          window.location.reload();
        } else {
          this.$store.state.alert.type = "warning";
          this.$store.state.alert.msg = res.msg;
          this.$store.state.alert.status = true;
        }
      }
    },

    async Submit() {
      this.isDisable = true;
      let data_model = {
        id_contract: localStorage.getItem("QUERY_ID"),
        employee: [],
      };

      // modify Rudy
      // let model_employee = this.model_group_employee;

      // for (let key in model_employee) {
      //   data_model.employee.push({
      //     id_contract: localStorage.getItem("QUERY_ID"),
      //     nik: model_employee[key].nik,
      //     gaji_pokok: this.sallary_gp[key],
      //     tunjangan: this.sallary_tj[key],
      //     insentif: this.sallary_it[key],
      //     uang_makan: this.sallary_um[key],
      //   });
      // }

      let modelEmpTemp = this.model_group_employee_temp;

      for (let key in modelEmpTemp) {
        data_model.employee.push({
          id_contract: localStorage.getItem("QUERY_ID"),
          nik: modelEmpTemp[key].nik,
          gaji_pokok: modelEmpTemp[key].gaji_pokok || 0,
          tunjangan: modelEmpTemp[key].tunjangan || 0,
          insentif: modelEmpTemp[key].insentif || 0,
          uang_makan: modelEmpTemp[key].uang_makan || 0,
        });
      }

      this.$store.state.loading.msg = "Updating Data...";
      this.$store.state.loading.status = true;

      // let data = {
      //   url: "employee/assign",
      //   callback_url: "jobcon/contract-data",
      //   form_data: data_model,
      // };
      // console.log(JSON.stringify(data_model));

      await axios
        .post("employee/assign-v2", data_model)
        .then((res) => {
          const response = res.data;

          this.$store.state.alert.status = true;
          this.$store.state.alert.msg = response.msg;
          this.$store.state.loading.status = false;
          this.isDisable = false;
          this.DataQuery(this.page);
          this.model_group_employee_temp = [];
        })
        .catch((error) => {
          this.$store.state.alert.status = true;
          this.$store.state.alert.type = "trash";
          this.$store.state.alert.msg = error.response.data.msg;
          this.$store.state.loading.status = false;
          this.isDisable = false;
          this.model_group_employee_temp = [];
        });
      // let res = await this.$store.dispatch("crud/storeData", data);

      // if (res) {
      //   console.log(res);
      //   this.isDisable = false;
      //   this.$store.state.loading.status = false;
      // }
    },
  },
};
</script>

<style lang='scss' scoped>
.table-date {
  height: 500px;
  overflow-y: scroll;
}
.tdp {
  padding: 9px;
  span {
    width: 100%;
    float: left;
  }
}
table thead tr > th {
  border: 1px solid #fff;
}

.table-picture-profile {
  float: left;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  position: relative;
  z-index: 1;
}

.table-avatar {
  float: left;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  z-index: 2;
  border: 1px solid #c3c3c3;
}
.table-avatar:after {
  position: relative;
  top: -6px;
  left: -1px;
}

div.tools {
  ul {
    max-height: 400px;
    overflow-y: scroll;
  }
  small.tools-plus {
    color: green;
    position: relative;
    left: 3px;
    top: -4px;
  }
}
div.list-officer {
  padding: 0;
  margin: 0;
  float: left;
  list-style: none;
  padding-right: 7px;
  position: relative;
  left: 20px;
  .box {
    float: left;
    span {
      margin-left: 3px;
      font-size: 12px;
    }
    span:before {
      content: "#";
      color: red;
      padding-right: 5px;
    }

    .table-avatar {
      float: left;
      width: 30px;
      height: 30px;
      background-color: #d0d0d0;
      position: relative;
      margin-right: 5px;
      margin-top: -3px;
      margin-bottom: -5px;
    }
    .table-group-avatar {
      position: absolute !important;
      left: 80px;
      z-index: 2;
    }
    .table-avatar::after {
      font-size: 12px;
      position: relative;
      top: -7px;
      left: -4px;
      color: #fff;
    }
    .table-picture-profile {
      width: 30px;
      height: 30px;
      position: relative;
      margin-right: 5px;
      margin-top: -3px;
      margin-bottom: -5px;
    }
    .table-group-picture {
      position: absolute !important;
      left: 80px;
      z-index: 2;
    }
    .count-all {
      position: absolute;
      z-index: 4;
      left: 100px;
      top: -4px;
      color: #ff6060;
    }

    .plus-minimize {
      position: absolute;
      z-index: 3;
      left: 107px;
      top: -6px;
      font-size: 9px;
    }
  }
}

ul.list-area {
  float: right;
  width: 100%;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    float: left;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid whitesmoke;
  }
}
</style>
