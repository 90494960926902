<template>
  <div class="col-md-12 p-0">
    <div class="card">
      <div class="card-body">
        <div class="table-info table-update">
          <form @submit="submit">
            <table>
              <tr v-if="this.errorMsg.length > 0">
                <td colspan="3">
                  <div class="alert alert-danger">
                    <ul class="ml-3">
                      <li v-for="(err, idx) in this.errorMsg" :key="idx">
                        {{ err }}
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="head-title">Personal Info</td>
              </tr>
              <tr>
                <td class="title">
                  Activity Status <small class="text-danger">*</small>
                </td>
                <td>
                  <select v-model="model.activity_status" class="form-control">
                    <option value="" disabled>-- Choose One --</option>
                    <option value="active">Active</option>
                    <option value="resign">Resign</option>
                  </select>
                  <small class="text-danger" v-if="msg_error.id_job_position"
                    ><br />Job Position field is required.</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Profile Image Employee <small class="text-danger">*</small>
                </td>
                <td>
                  <div style="width: 220px">
                    <UploadSingle
                      :default_image="$store.state.api_resource + data_image"
                      v-on:processFile="processFile"
                      :label="'Image Type / Series'"
                      :msg_error="msg_error"
                      v-if="isEnableMode"
                    />
                  </div>
                </td>
              </tr>
              <tr v-if="model.join_contract_status == 'no'">
                <td class="title">
                  Branch Station <small class="text-danger">*</small>
                </td>
                <td>
                  <SelectBranch
                    v-on:processBranch="processBranch"
                    :default_id="model.id_branch"
                    v-if="isEnableMode"
                  />
                  <small class="text-danger" v-if="msg_error.id_branch"
                    ><br />{{ msg_error.id_branch[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Job Position <small class="text-danger">*</small>
                </td>
                <td>
                  <select v-model="model.id_job_position" class="form-control">
                    <option value="" disabled>-- Choose One --</option>
                    <option
                      v-for="(items, idx) in model_job_position"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.title }}
                    </option>
                  </select>
                  <small class="text-danger" v-if="msg_error.id_job_position"
                    ><br />Job Position field is required.</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Firstname <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    v-model="model.firstname"
                    placeholder="....."
                    autofocus
                  />
                  <small class="text-danger" v-if="msg_error.firstname"
                    ><br />{{ msg_error.firstname[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Lastname <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    v-model="model.lastname"
                    placeholder="....."
                  />
                  <small class="text-danger" v-if="msg_error.lastname"
                    ><br />{{ msg_error.lastname[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Gender <small class="text-danger">*</small>
                </td>
                <td>
                  <select v-model="model.gender">
                    <option value="" disabled>-- Choose One --</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <small class="text-danger" v-if="msg_error.gender"
                    ><br />{{ msg_error.gender[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Marital Status <small class="text-danger">*</small>
                </td>
                <td>
                  <select v-model="model.marital_status">
                    <option value="" disabled>-- Choose One --</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                  </select>
                  <small class="text-danger" v-if="msg_error.marital_status"
                    ><br />{{ msg_error.marital_status[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Phone Number <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    v-model="model.phone_number"
                    placeholder="....."
                    @keypress="onlyNumber"
                    @keyup="removeLeadingZero"
                  />
                  <small class="text-danger" v-if="msg_error.phone_number"
                    ><br />{{ msg_error.phone_number[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Email <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    v-model="model.email"
                    placeholder="....."
                  />
                  <small class="text-danger" v-if="msg_error.email"
                    ><br />{{ msg_error.email[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Propinsi <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="provinces_name"
                    readonly
                    style="width: 220px"
                  />
                  <small class="text-danger" v-if="msg_error.id_propinsi">{{
                    msg_error.id_propinsi[0]
                  }}</small>
                </td>
              </tr>
              <tr>
                <td class="title">
                  Kota/Kabupaten <small class="text-danger">*</small>
                </td>
                <td>
                  <select
                    class="form-control"
                    v-model="model.id_kota"
                    @change="fetchKecamatan()"
                    :disabled="model_kota.length > 0 ? false : true"
                  >
                    <option value="" disabled>-- Choose One --</option>
                    <option
                      v-for="(items, idx) in model_kota"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.name }}
                    </option>
                  </select>
                  <small class="text-danger" v-if="msg_error.id_kota">{{
                    msg_error.id_kota[0]
                  }}</small>
                </td>
              </tr>
              <tr>
                <td class="title">
                  Kecamatan <small class="text-danger">*</small>
                </td>
                <td>
                  <select
                    class="form-control"
                    v-model="model.id_kecamatan"
                    :disabled="model_kecamatan.length > 0 ? false : true"
                  >
                    <option value="" disabled>-- Choose One --</option>
                    <option
                      v-for="(items, idx) in model_kecamatan"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.nama_kecamatan }}
                    </option>
                  </select>
                  <small class="text-danger" v-if="msg_error.id_kecamatan">{{
                    msg_error.id_kecamatan[0]
                  }}</small>
                </td>
              </tr>
              <tr>
                <td class="title">
                  Address <small class="text-danger">*</small>
                </td>
                <td>
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="model.address"
                    Placeholder="...."
                  ></textarea>
                  <small class="text-danger" v-if="msg_error.address"
                    ><br />{{ msg_error.address[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Individual Skill / Keterampilan
                  <small class="text-danger">*</small>
                </td>
                <td>
                  <textarea
                    class="form-control"
                    rows="3"
                    v-model="model.individual_skill"
                    Placeholder="...."
                  ></textarea>
                  <small class="text-danger" v-if="msg_error.individual_skill"
                    ><br />{{ msg_error.individual_skill[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td colspan="2" class="head-title">Identify Info</td>
              </tr>
              <tr>
                <td class="title">
                  Identity Card <small class="text-danger">*</small>
                </td>
                <td>
                  <select v-model="model.identity_card">
                    <option value="" disabled>-- Choose One --</option>
                    <option value="Ktp">KTP</option>
                    <option value="Sim">SIM</option>
                    <option value="Paspor">PASPOR</option>
                  </select>
                  <small class="text-danger" v-if="msg_error.identity_card"
                    ><br />{{ msg_error.identity_card[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Identify Card Number <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="....."
                    v-model="model.identity_number"
                  />
                  <small class="text-danger" v-if="msg_error.identity_number"
                    ><br />{{ msg_error.identity_number[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Nationality <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="....."
                    v-model="model.nationality"
                  />
                  <small class="text-danger" v-if="msg_error.nationality"
                    ><br />{{ msg_error.nationality[0] }}</small
                  >
                </td>
              </tr>

              <tr>
                <td class="title">
                  Birthday <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="date"
                    placeholder="....."
                    v-model="model.birth_date"
                  />
                  <small class="text-danger" v-if="msg_error.birth_date"
                    ><br />{{ msg_error.birth_date[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Birthplace <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="....."
                    v-model="model.birth_place"
                  />
                  <small class="text-danger" v-if="msg_error.birth_place"
                    ><br />{{ msg_error.birth_place[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td colspan="2" class="head-title">Bank Information Account</td>
              </tr>
              <tr>
                <td class="title">
                  Bank Name <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="....."
                    v-model="model.bank_name"
                  />
                  <small class="text-danger" v-if="msg_error.bank_name"
                    ><br />{{ msg_error.bank_name[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Account Name <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="....."
                    v-model="model.bank_account_name"
                  />
                  <small class="text-danger" v-if="msg_error.bank_account_name"
                    ><br />{{ msg_error.bank_account_name[0] }}</small
                  >
                </td>
              </tr>
              <tr>
                <td class="title">
                  Account Number <small class="text-danger">*</small>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="....."
                    v-model="model.bank_account_number"
                  />
                  <small
                    class="text-danger"
                    v-if="msg_error.bank_account_number"
                    ><br />{{ msg_error.bank_account_number[0] }}</small
                  >
                </td>
              </tr>
            </table>
            <hr />
            <div class="form-group mt-5 mb-5">
              <div class="col-md-12 text-center">
                <span class="text-secondary float-left"
                  >Mandatory (<span class="text-danger">*</span>) must be filled
                  before saving data</span
                >
                <a
                  href="javascript:;"
                  class="btn btn-secondary mr-3"
                  @click="$router.go(-1)"
                  >CANCEL</a
                >
                <button type="submit" class="btn btn-primary btn-full-screen">
                  Submit Data
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import router from "../../router";
import UploadSingle from "../../services/UploadSingle";
import SelectBranch from "../Branch/@SelectBranch";

export default {
  components: {
    UploadSingle,
    SelectBranch,
  },
  data() {
    return {
      isEnableMode: false,
      data_image: "",
      model: {},
      model_job_position: [],
      provinces_name: "",
      model_kota: [],
      model_kecamatan: [],
      errorMsg: [],
    };
  },

  computed: {
    ...mapState("crud", {
      msg_error: (state) => state.error,
    }),
  },
  created() {
    if (localStorage.getItem("userPrivilegesName") == "root") {
      this.isEnableSelectBranch = true;
    } else {
      this.model.id_branch = localStorage.getItem("userBranchId");
      this.branch_name = localStorage.getItem("userBranchName");
    }

    this.model.id_propinsi = localStorage.getItem("id_provinces");
    this.provinces_name = localStorage.getItem("provinces_name");

    this.fetchKota();
    setTimeout(() => {
      this.QueryData();
      this.QueryJobPosition();
    }, 500);
  },

  methods: {
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    removeLeadingZero() {
      if (parseInt(this.model.phone_number, 10)) {
        let phone_number = parseInt(this.model.phone_number, 10);
        this.model.phone_number = phone_number;
      }
    },
    async processBranch(id_branch) {
      // console.log("id_branch", id_branch);
      this.$store.state.loading.msg = "Fetching Data Province by Branch...";
      this.model.id_branch = id_branch;

      let idProvince = await axios
        .get("branch/find/" + id_branch)
        .then((response) => {
          return response.data ? response.data.id_provinces : null;
        })
        .catch((err) => {
          console.log(err);
        });

      if (idProvince) {
        await axios
          .post("location/propinsi/data", {
            where: { key: "id", value: idProvince },
          })
          .then((response) => {
            this.provinces_name =
              response.data.length > 0
                ? response.data[0].nama_provinsi
                : localStorage.getItem("provinces_name");

            if (response.data.length > 0) {
              this.model.id_propinsi = response.data[0].id;
              this.fetchKota(idProvince);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.$store.state.loading.status = false;
          });
      }
    },
    async fetchKota(idProvince = null) {
      let cities = [];
      if (!idProvince) {
        cities = JSON.parse(localStorage.getItem("data_regencies"));
      } else {
        // console.log(idProvince);

        await axios
          .post("location/kota/data", {
            where: { key: "provinsi_id", value: idProvince },
          })
          .then((res) => {
            // console.log(JSON.parse(localStorage.getItem("data_regencies")));
            // console.log("kota ");
            // console.log(res.data);
            if (res.data.length > 0) {
              this.model.id_kota = "";
              this.model.id_kecamatan = "";
              let newCities = [];
              this.model_kecamatan = [];
              for (let i = 0; i < res.data.length; i++) {
                newCities.push({
                  id: res.data[i].id,
                  name: res.data[i].nama_kota,
                });
              }
              cities = newCities;
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.$store.state.loading.status = false;
          });
      }
      this.model_kota = cities;
    },
    async fetchKecamatan() {
      this.$store.state.loading.msg = "Fetching Data Kecamatan...";
      console.log(this.model.id_kota);
      // reset
      this.model_kecamatan = [];
      this.model_kelurahan = [];

      return await axios
        .post("location/kecamatan/data", {
          where: { key: "kota_id", value: this.model.id_kota },
        })
        .then((response) => {
          this.model_kecamatan = response.data;
          return response.data;
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },

    processFile(data) {
      this.data_image = data;
    },

    async QueryJobPosition() {
      await axios
        .get("job_position/all")
        .then((res) => {
          this.model_job_position = res.data;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
          this.isEnableEquipment = true;
        });
    },
    async QueryData() {
      let res = await this.$store.dispatch("crud/find", {
        url: "employee/find/" + localStorage.getItem("QUERY_ID"),
      });
      if (res) {
        // console.log(res);
        this.model = res;

        this.data_image = this.model.photo;
        //this.model.birth_date = this.$moment(this.model.date, 'YYYY-MM-DD').format('MM/DD/YYYY');
        // default model location

        this.fetchKecamatan().then(() => {
          this.isEnableMode = true;
        });
      }
    },
    async submit(e) {
      e.preventDefault();
      this.model.photo = this.data_image;
      this.$store.state.loading.msg = "Saving Data...";
      this.$store.state.loading.status = true;
      this.$store.state.alert.type = "success";
      let data = {
        url: "employee/update/" + localStorage.getItem("QUERY_ID"),
        callback_url: "employee/data",
        form_data: this.model,
      };
      // console.log(this.model);
      let res = await this.$store.dispatch("crud/storeData", data);

      if (res.response.status == 422) {
        for (let key in res.response.data) {
          this.errorMsg.push(res.response.data[key][0]);
        }
      }
    },
  },
};
</script>

<style lang="scss">
table {
  tr {
    td.title {
      width: 30%;
      small {
        width: auto;
        float: right;
      }
    }
    td {
      select {
        width: 30% !important;
        border: 1px solid red;
      }
      textarea {
        border: 1px solid whitesmoke !important;
        font-style: normal !important;
      }
      small {
        margin-top: 0 !important;
      }
    }
  }
}
</style>
