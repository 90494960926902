<template>
  <div>
    <div class="dropbox mb-2">
      <img :src="data_image" v-if="data_image !== ''" />
      <div class="tools">
        <p>
          Drop your files here to begin <br />or<br />
          click to browse
        </p>
        <input
          type="file"
          class="form-control input-file"
          accept="image/*"
          @change="processFile($event)"
        />
      </div>
    </div>
    <small class="info">
      <i class="ti ti-info-alt text-primary"></i> Click the box to upload new
      image.
    </small>
  </div>
</template>


<script>
export default {
  props: ["min_width", "min_height", "default_image"],
  data() {
    return {
      max_image_size: 2097152, // 2 MB;
      data_image: "",
      isEnableInfo: false,
      info_image: [],
    };
  },
  mounted() {
    console.log("def", this.default_image);

    if (this.default_image !== "") {
      this.isEnableInfo = true;
      this.data_image = this.default_image;
      this.info_image.name = this.default_image;
    }
  },
  methods: {
    processFile(event) {
      this.file = event.target.files[0];
      if (event.target.files && event.target.files[0]) {
        let fileSize = event.target.files[0].size;
        if (parseInt(fileSize) > this.max_image_size) {
          alert("File image cannot more than 2 MB");
        } else {
          let reader = new FileReader();
          reader.onload = (event) => {
            let img = new Image();
            img.onload = () => {
              if (img.width < this.min_width && img.height < this.min_height) {
                event.preventDefault();
                alert(
                  "Minimal ukuran dimensi image adalah : " +
                    this.min_width +
                    "x" +
                    this.min_height
                );
                return false;
              } else {
                this.isEnableInfo = true;
                this.info_image = this.file;
                this.data_image = event.target.result;
                this.$emit("processFile", this.data_image);
              }
            };
            img.src = event.target.result;
          };
          reader.readAsDataURL(event.target.files[0]);
        }
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: whitesmoke;
  color: dimgray;
  padding: 20px 10px;
  height: 180px;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: pointer;

  .input-file {
    top: 0;
    opacity: 0;
    width: 100% !important;
    height: 180px;
    position: absolute;
    z-index: 999;
    border: 1px solid red;
    cursor: pointer;
  }
  img {
    position: relative;
    z-index: 1;
    height: 100%;
  }
  p {
    font-size: 1em;
    text-align: center;
    padding: 50px 0;
  }
}
.dropbox:hover {
  background: whitesmoke;
}
.dropbox-info {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  margin-top: 15px;
  table {
    width: 100%;
  }
  table tr td {
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>