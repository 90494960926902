<style scoped>
.table-wrapper {
  width: 100%;
  max-height: 400px;
  overflow: auto;
  display: inline-block;
}
</style>
<template>
  <div class="popup popup-lg">
    <div class="content">
      <small id="close-popup" @click="close()"
        ><i class="ti ti-close"></i
      ></small>
      <h5>
        History Send Mail
        <hr />
      </h5>
      <div class="row p-0">
        <div class="col-md-12">
          <div class="table-header">
            <h5>
              List Data <br />
              <small
                >Total Records : <b>{{ list_history_total }}</b></small
              >
            </h5>
          </div>
          <div class="table-wrapper">
            <table class="table-hover">
              <thead>
                <tr>
                  <th class="no-filter text-center">No</th>
                  <th class="no-filter">Client Name</th>
                  <th class="no-filter">Email</th>
                  <th class="no-filter">Manual Attch</th>
                  <th class="no-filter">Generate Period Date</th>
                  <th class="no-filter">Created Date</th>
                </tr>
              </thead>
              <tbody v-if="list_history_total > 0">
                <tr v-for="(items, idx) in list_history.data" :key="idx">
                  <td>{{ idx + 1 }}</td>
                  <td>
                    <b>{{ items.name_client }}</b>
                  </td>
                  <td>{{ items.email_client }}</td>
                  <td>{{ items.manual_attch }}</td>
                  <td>
                    {{ items.startdate_period }} - {{ items.enddate_period }}
                  </td>
                  <td>
                    {{ items.created_At }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="5">
                    <div class="no-data" v-if="!$store.state.loading.status">
                      Sorry data is not available
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import { mapState } from "vuex";

export default {
  props: ["id_customreport"],
  data() {
    return {
      list_history: [],
      list_history_total: 0,

      meta_url: "report",
    };
  },

  computed: {
    ...mapState("crud", {
      data_model: (state) => state.data_model,
      msg_error: (state) => state.error,
    }),
  },
  created() {
    this.tableQuery();
  },

  methods: {
    close() {
      this.$emit("exit");
    },
    applyData(initial, data) {
      if (initial == "single") {
        this.$emit("applyData", [data]);
      }
      if (initial == "multi") {
        this.$emit("applyData", data);
      }

      this.$emit("exit");
    },

    async tableQuery() {
      console.log(this.id_customreport);
      this.$store.state.loading.msg = "Processing Fetching Data...";
      this.$store.state.loading.status = true;
      await axios
        .post(this.meta_url + "/history-send", {
          id: this.id_customreport,
        })
        .then((res) => {
          this.list_history = res.data;
          console.log(this.list_history);
          this.list_history_total = this.list_history.data.length;
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.state.loading.status = false;
          }, 750);
        });
    },
  },
};
</script>
