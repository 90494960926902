<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <h5>Report Attendance by Period</h5>
      </div>
    </div>

    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="contract-select">Select Contract</label>
              <select
                id="contract-select"
                class="form-control"
                v-model="id_contract"
              >
                <option value="" disabled>Select a contract</option>
                <option
                  v-for="contract in contracts"
                  :key="contract.id"
                  :value="contract.id"
                >
                  {{ contract.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Add date period start and end -->
            <div class="form-group">
              <label for="date-start">Date Period</label>
              <date-picker
                v-model="period_contract"
                value-type="format"
                type="date"
                format="YYYY-MM-DD"
                placeholder="Choose Date Range"
                width="100%"
                style="width: 100%"
                range
              >
              </date-picker>
            </div>
          </div>
          <div class="col-md-2 text-left" style="margin-top: 2.4%">
            <div class="form-group">
              <label for="date-end"></label>
              <a
                class="btn btn-success"
                @click="downloadExcelContractPeriode($event)"
              >
                <i class="ti ti-file text-light"></i> Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../../router.js";
import PopupDetailContract from "../../JobContract/@PopupDetailContract";
import { mapState } from "vuex";
import MixServerDate from "../../../mixin/mix_serverdate.js";
import SelectBranch from "../../Branch/@SelectBranch";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";

export default {
  mixins: [MixServerDate],
  components: {
    PopupDetailContract,
    SelectBranch,
    DatePicker,
  },
  data() {
    return {
      isRoot: false,
      isEnableTools: false,
      isEnableOrder: false,
      id_contract: null,
      period_contract: [],
      contracts: [
        // Dummy data, replace with actual data
        { id: 1, name: "Contract A" },
        { id: 2, name: "Contract B" },
        { id: 3, name: "Contract C" },
      ],

      meta_url: "contract",
    };
  },
  computed: {
    ...mapState("crud", {
      data_model: (state) => state.data_model,
      msg_error: (state) => state.error,
    }),
  },
  created() {},
  methods: {
    async downloadExcelContractPeriode(event) {
      event.target.className = "btn btn-success disabled";
      this.$store.state.loading.msg = `Download Report Data Period ${this.period_contract[0]} until ${this.period_contract[1]}`;
      this.$store.state.loading.status = true;

      this.period_contract = [this.period_contract[0], this.period_contract[1]];

      let dataParams = {
        id_contract: this.id_contract,
        start_date: this.period_contract[0],
        end_date: this.period_contract[1],
      };
      console.log(dataParams);
    },
  },
};
</script>
