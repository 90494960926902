<template>
    <div class="col-md-12 p-0">
        <div class="card">
            <div class="card-body">
                <form class="form-horizontal mt-lg" @submit="submit">
                    <div class="form-group">
                        <label for="focusedinput" class="col-sm-3 ">Privileges Group Level Name</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" id="focusedinput" placeholder="example : Administrator" v-model="model.name">
                            <small class="text-danger" v-if="msg_error.name">{{ msg_error.name[0] }}</small>
                        </div>
                    </div>
                    
                    <hr/>
                    <div class="form-group">
                        <div class="col-sm-11" >
                            <button type="submit" class="float-right btn btn-execute btn-full-screen">Submit</button>
                        </div>
                    </div>
                </form>
            </div> 
        </div> 
    </div> 
</template>

<script>
    import { mapState } from 'vuex';
    export default 
    {   
        data() {
            return {
                meta_url : 'privileges',
                model: {},
            };
        },
        computed: {
            ...mapState('crud', {
                msg_error: state => state.error,
            }),
        },
        
        methods: 
        {
            submit(e) 
            {
                e.preventDefault();
                this.$store.state.loading.msg = 'Processing submit data...';
                this.$store.state.loading.status = true;
                
                let data = { url: this.meta_url + '/store', callback_url:this.meta_url + '/data', form_data: this.model };
                this.$store.dispatch('crud/storeData', data );
            }
        }
    }
</script>
