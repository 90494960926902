<template>
    <div class="print-layout">
        <div class="row">
            <div class="col-md-12">
                <ul>
                    <li v-for="(items, idx) in model_section" :key="idx">
                        <VueQRCodeComponent 
                        :text="items.id_contract+'-'+items.id"
                        :size="300"
                        ></VueQRCodeComponent>
                        <p>S{{ items.section }}F{{ items.area_floor }}-{{items.area_type}}-{{ items.area_name }}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import VueQRCodeComponent from 'vue-qrcode-component';
    export default 
    {
         components:{
            VueQRCodeComponent
        },
        data() {
            return{
                model_section : []
            }
        },
        created() {
            this.DataQuery();
        },
        methods:{

            async DataQuery()
            {
                let query = await this.$store.dispatch('crud/find', { url: 'contract/find/'+localStorage.getItem('QUERY_ID') } );
                
                // re-assign section 
                let model_section = query.section;
                console.log(model_section);
                for(let key in model_section)
                {
                    for(let inkey in model_section[key]){
                        this.model_section.push(model_section[key][inkey]);
                    }
                }
                setTimeout(() => {
                    window.print();
                }, 500); 
            },
        }
    }
</script>

<style lang="scss" scoped>
.print-layout
{
    margin:0;
    padding:0px;
    width:100%;
    height:100%;
    left:0;
    top:0;
    position:absolute;
    z-index: 9999;
    background-color:#fff;
    ul{
        padding:0;
        margin:0;
        width:100%;
        float:left;
        li{
            width:33.3%;
            list-style:none;
            float:left;
            padding:10px;
            margin:0px;
            border:1px dashed #000;
            p{margin-bottom:0;}
        }
    }
}
    
</style>