<template>
  <div class="popup popup-lg">
    <div class="content">
      <small id="close-popup" @click="close()"
        ><i class="ti ti-close"></i
      ></small>
      <h5>
        Data Section Task <br /><small
          >Please select section task to continue process.</small
        >
        <hr />
      </h5>
      <div class="row">
        <div class="col-md-12">
          <div class="table-header">
            <h5>
              Data Section Task <br />
              <small
                >Total Records : <b>{{ model.length | formatPrice }}</b></small
              >
            </h5>
            <div class="group-search pull-right" style="margin: 25px 10px 0 0">
              <div style="width: 100px; float: left">
                <select
                  class="form-group"
                  v-model="filter_area"
                  @change="tableSearch()"
                  style="
                    padding: 5px;
                    margin-top: -7px;
                    border: 1px solid #007bff;
                    border-radius: 4px;
                  "
                >
                  <option value="all">-- All Area --</option>

                  <option v-for="(area, idx) in list_area" :key="idx">
                    {{ area }}
                  </option>
                </select>
              </div>
              <a class="btn btn-primary" @click="applyData()">
                Apply Selected Images
                <div class="badge badge-secondary font-weight-normal">
                  {{ model_group.length }}
                </div>
              </a>
              <a class="btn" @click="tableRefresh()"
                ><i class="ti ti-reload"></i
              ></a>
              <a class="btn" @click="isActiveSearch()"
                ><i
                  :class="
                    isEnableTools == false ? 'ti ti-search' : 'ti ti-close'
                  "
                ></i
              ></a>

              <div class="group" v-if="isEnableTools">
                <input
                  type="text"
                  v-model="filter_type"
                  placeholder="Enter Keyword ...."
                />
                <a class="btn" @click="tableSearch()"> SEARCH DATA </a>
              </div>
              <div class="group-filter mb-3"></div>
            </div>
            <div>
              <!-- :disabled-date="disabledBeforeAfterFilterDatePeriodImg" range -->
              <date-picker
                v-model="filter_date_period_img"
                :default-value="filter_date_period_img"
                :clearable="false"
                value-type="format"
                type="date"
                format="DD/MM/YYYY"
                placeholder="Pilih Range Tanggal"
                width="100%"
                :disabled-date="disabledBeforeAfterFilterDatePeriodImg"
                @change="FilterModelDateImg()"
                confirm
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <!-- <div class="table-cover"> -->
        <table>
          <thead>
            <tr>
              <th class="no-filter"></th>
              <th class="no-filter">Image</th>
              <th class="no-filter">Section Area</th>
              <th class="no-filter">Notes</th>
              <th class="no-filter">Type</th>
            </tr>
          </thead>
          <tbody v-if="model.length > 0">
            <tr v-for="(item, idx) in model" :key="idx">
              <td>
                <input
                  type="checkbox"
                  @change="collectGroup(idx)"
                  :checked="validateChecked(item.id)"
                  :id="'checkbox-' + idx"
                />
              </td>
              <td style="vertical-align: top">
                <img :src="item.photo" width="150" />
              </td>
              <td style="vertical-align: top">
                Section {{ item.section }} - {{ item.area_type }} -
                {{ item.area }}
              </td>
              <td style="vertical-align: top">
                {{ item.notes }} - (Pukul {{ item.time }})
              </td>
              <td style="vertical-align: top">{{ item.type }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="19">
                <i class="ti ti-info-alt text-danger"></i> Images in Section
                Task not found.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pick-image-container:hover {
  cursor: pointer;
  transition: 0.5s ease;
  background-color: #008cba !important;
  color: white !important;
  text-decoration: none;
}
#pick-image-container-active {
  background-color: #008cba !important;
  color: white !important;
  text-decoration: none;
}
</style>
<script>
import $ from "jquery";
import DatePicker from "vue2-datepicker";
export default {
  props: [
    "section_task",
    "image_type",
    "list_check_images_before_after",
    "filter_period_start_date",
    "filter_period_end_date",
  ],
  components: {
    DatePicker,
  },
  data() {
    return {
      isEnableTools: false,
      model: [],
      model_group: [],
      length_model_group: 0,
      model_collection: [],
      temp_model: [],
      activeBefore: false,
      filter_type: null,
      filter_area: "all",
      list_area: [],
      filter_date_period_img: null,
      new_filter_period_start_date: null,
      new_filter_period_end_date: null,
      new_filter_date_period_img: null,
    };
  },
  computed: {},
  created() {
    this.mappindDataImages();
    this.mappingDataArea();
    this.filter_date_period_img = this.filter_period_start_date;

    let newStartDate = this.filter_period_start_date.split("/");
    let newEndDate = this.filter_period_end_date.split("/");

    this.new_filter_period_start_date =
      newStartDate[2] + "-" + newStartDate[1] + "-" + newStartDate[0];
    this.new_filter_period_end_date =
      newEndDate[2] + "-" + newEndDate[1] + "-" + newEndDate[0];

    this.FilterModelDateImg();
  },

  methods: {
    mappindDataImages() {
      if (this.list_check_images_before_after.length > 0) {
        if (this.image_type === "before" || this.image_type === "after") {
          for (let key in this.section_task) {
            // console.log("kesini")
            // console.log(this.section_task[key]);
            let iDidentity =
              this.section_task[key].id + "-" + this.section_task[key].time;
            let checkImageExist = this.validateImageSelected(iDidentity);
            if (!checkImageExist) {
              this.temp_model.push(this.section_task[key]);
            }
          }
          this.model = this.temp_model;
        } else {
          this.model = this.section_task;
        }
      } else {
        this.model = this.section_task;
      }
      // console.log(this.model);
    },
    mappingDataArea() {
      for (let key in this.section_task) {
        this.list_area.push(this.section_task[key].area);
      }
      this.list_area = [...new Set(this.list_area)];
    },
    disabledBeforeAfterFilterDatePeriodImg(date) {
      const start_date = new Date(this.new_filter_period_start_date);
      start_date.setHours(0, 0, 0, 0);

      const end_date = new Date(this.new_filter_period_end_date);
      end_date.setHours(0, 0, 0, 0);

      let dateNow = new Date(date.getFullYear(), date.getMonth());

      let dateStart = new Date(start_date.getFullYear(), start_date.getMonth());
      let dateEnd = new Date(end_date.getFullYear(), end_date.getMonth());

      return dateNow < dateStart || dateNow > dateEnd;
    },
    FilterModelDateImg() {
      let split_filter_date_period_img = this.filter_date_period_img.split("/");
      this.new_filter_date_period_img =
        split_filter_date_period_img[2] +
        "-" +
        split_filter_date_period_img[1] +
        "-" +
        split_filter_date_period_img[0];
      this.filter_type = this.image_type;
      this.tableSearch();
    },
    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
    },
    tableSearch() {
      if (this.filter_type == "" || this.filter_type == null) {
        alert("🙏, Mohon pilih type gambar");
        return;
      }

      this.mappindDataImages();

      // console.log(this.new_filter_date_period_img);

      let newModel = this.model.filter((item) => {
        // console.log(item);
        if (item.type) {
          if (
            item.type == this.filter_type &&
            this.filter_area == "all" &&
            this.new_filter_date_period_img == item.date_work
          ) {
            return item;
          } else if (
            item.type == this.filter_type &&
            this.filter_area == item.area &&
            this.new_filter_date_period_img == item.date_work
          ) {
            return item;
          }
        }
      });

      this.model = newModel;
      // console.log(this.model);
    },
    tableRefresh() {
      this.filter_type = "";
      this.filter_area = "all";
      this.FilterModelDateImg();
    },
    validateImageSelected(iDidentity) {
      const getExistImage = this.list_check_images_before_after.filter(
        (item) => item.id + "-" + item.time === iDidentity
      );

      if (getExistImage.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    close() {
      this.$emit("exit");
    },
    applyData() {
      if (this.model_group.length < 1) {
        alert("Please choose some image !");
        return false;
      }
      this.$emit("exit");
      this.$emit("applyData", this.model_group);
    },
    validateChecked(id) {
      var model = this.model_collection;
      return model.includes(id) ? true : false;
    },
    collectGroup(idx) {
      let model = this.model[idx];

      if ($("#checkbox-" + idx).is(":checked")) {
        // this.model_collection.push(model.id);

        if (this.model_group.length < 1) {
          this.model_group = [];
        }

        this.model_group.unshift(model);

        // validate selected images
        // if (this.image_type === "before" || this.image_type === "after") {
        //   if (this.model_group.length > 2) {
        //     alert("Images selectex max 2");
        //     $("#checkbox-" + model.id).prop("checked", false);
        //     this.disCollecGroup(idx);
        //   }
        // } else {
        //   if (this.model_group.length > 4) {
        //     alert("Images selectex max 4");
        //     $("#checkbox-" + model.id).prop("checked", false);
        //     this.disCollecGroup(idx);
        //   }
        // }
      } else {
        this.disCollecGroup(idx);
      }
    },
    disCollecGroup(idx) {
      let model = this.model[idx];
      // var model_collection = this.model_collection;
      // var index_collection = model_collection.indexOf(model.id);
      // this.model_collection.splice(index_collection, 1);

      var model_group = this.model_group;
      var no = 0;
      for (let key in model_group) {
        if (model_group[key].id == model.id) {
          this.model_group.splice(no, 1);
        }
        no++;
      }
    },
  },
};
</script>
