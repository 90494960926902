<template>
  <div class="col-md-12 p-0">
    <!-- Download / export excel -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'download'">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <h5 class="text-left">
          {{ download.title }}<br />
          <span class="text-secondary mr-4"
            >Please choose available file report format to download.</span
          >
          <hr />
        </h5>
        <div class="text-right">
          <export-excel
            class="float-right mr-2"
            :data="download.data"
            :fields="download.fields"
            type="csv"
            :name="download.name"
            :title="download.title"
          >
            <a href="javascript:;" class="btn btn-light"
              ><i class="ti ti-file text-warning"></i> Format .CSV</a
            >
          </export-excel>
          <export-excel
            class="float-right mr-2"
            :data="download.data"
            :fields="download.fields"
            type="xls"
            :name="download.name"
            :title="download.title"
          >
            <a href="javascript:;" class="btn btn-light"
              ><i class="ti ti-file text-success"></i> Format .XLS</a
            >
          </export-excel>
        </div>
      </div>
    </div>
    <!-- ==================== -->

    <!-- Download / export excel -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'download_by_date'">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <div class="row">
          <div class="col-md-12 text-center">
            <h5 align="center">
              Please choose date range file report format to download.
            </h5>
            <img
              class="img-rounded text-center mt-3"
              :src="'img/uploadfile.png'"
              title="Upload Excel"
            />
          </div>
          <div class="col-md-12 mt-4">
            <date-picker
              v-model="period_contract"
              :disabled-date="disabledBeforeAfterPeriodContract"
              value-type="format"
              type="date"
              format="YYYY-MM-DD"
              placeholder="Choose Date Range"
              width="100%"
              style="width: 100%"
              range
            >
            </date-picker>
          </div>
        </div>
        <div class="text-right mt-3">
          <a
            v-if="period_contract.length > 0"
            @click="downloadExcelContractByDate($event)"
            class="btn btn-success"
            ><i class="ti ti-file text-light"></i> Format .XLS</a
          >
        </div>
      </div>
    </div>
    <!-- ==================== -->

    <div class="card">
      <div class="card-body" v-if="!isLoading">
        <div class="form-group">
          <div class="row p-2">
            <div class="col-md-8 p-3 pl-4">
              <ListLoader
                v-if="$store.state.loading.status"
                :speed="1"
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
              ></ListLoader>
              <div v-else>
                <h5>{{ model_contract.client_name }}</h5>
                <p>
                  <span class="text-secondary">{{ model_contract.email }}</span
                  ><br /><span class="">{{
                    model_contract.phone_number | formatPhone
                  }}</span>
                </p>
                <p>
                  {{ model_contract.office_address }} <br />
                  {{ model_contract.nama_provinsi }},
                  {{ model_contract.nama_kota }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <ul class="list-area" v-if="$store.state.loading.status">
                <li>
                  <ListLoader
                    :speed="1"
                    primaryColor="#f3f3f3"
                    secondaryColor="#ecebeb"
                  ></ListLoader>
                </li>
              </ul>
              <ul class="list-area" v-else>
                <li>
                  <span class="">Contract Number</span>
                  <span class="float-right"
                    ><b style="font-size: 16px"
                      ># {{ model_contract.contract_number }}</b
                    ></span
                  >
                </li>
                <li>
                  <span class="">Contract Period</span>
                  <span class="float-right"
                    ><b
                      >{{ model_contract.start_date | moment("DD/MM/YYYY") }} -
                      {{ model_contract.end_date | moment("DD/MM/YYYY") }}</b
                    ></span
                  >
                </li>
                <li v-if="model_contract.man_power !== undefined">
                  <span class="">MP Request</span><br /><br />
                  <span
                    class="text-muted"
                    v-for="(initems, index) in JSON.parse(
                      model_contract.man_power
                    )"
                    :key="index"
                  >
                    {{ initems.total }} ( {{ initems.job }} )
                    <span v-if="index % 2 == 0">,</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="form-group mt-5">
          <div class="col-sm-12">
            <div class="float-left">
              <label class="text-primary mb-0"
                ><i class="ti ti-info-alt"></i> Employee Attendance</label
              ><br />
              <small class="text-secondary"
                >P( Shift Pagi ), S (Shift Siang), M (Shift Malam)</small
              >
            </div>
            <div class="float-right">
              <a @click="modalExportContract()" class="btn btn-primary mr-3">
                <i class="ti ti-cloud-down"></i> Download Data
              </a>

              <!-- modify rudy -->
              <!--<select class="form-control" v-model="filter_date"  @change="FilterModelDate()" style="width:200px;">
                                <option v-for="(items, index) in collection_period" :key="index" :value="items" >{{ items }}</option>
                            </select> -->
              <date-picker
                v-model="filter_date"
                :default-value="filter_date"
                :disabled-date="disabledBeforeAfterFilterDate"
                :clearable="false"
                value-type="format"
                type="month"
                format="MM/YYYY"
                placeholder="Pilih Range Tanggal"
                width="100%"
                @change="FilterModelDate()"
                confirm
              >
              </date-picker>
            </div>
          </div>
          <div class="clearfix"></div>
          <hr />
        </div>
        <div class="row mt-5" v-if="model_date_range.length > 0">
          <div class="col-sm-2 pr-0">
            <div class="table-cover">
              <table class="table-hover xo">
                <thead>
                  <!-- rowspan='2' height="65" -->
                  <tr rowspan="2">
                    <th class="text-center no-filter" style="height: 66px">
                      <small
                        ><b class="text-danger">Officer Attendance</b></small
                      >
                    </th>
                  </tr>
                  <!-- <tr >
                                        <th class="text-center no-filter" style="height:33px">&nbsp;</th>
                                    </tr> -->
                  <tr>
                    <th class="text-center no-filter" style="height: 33px">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(items, index) in model_date_range"
                    :key="index"
                    :class="'trdate ' + items.month + '-' + items.year"
                  >
                    <!-- disable class tdp -->
                    <td class="tdp" style="height: 70px">
                      <span
                        :class="items.name_of_day == 'Sun' ? 'text-danger' : ''"
                        >{{ items.name_of_day }}</span
                      ><br />
                      <small class="text-secondary">{{
                        items.day + "/" + items.month + "/" + items.year
                      }}</small>
                    </td>
                  </tr>
                  <tr rowspan="2">
                    <td class=""><b>Total Attendance</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-sm-10 pl-0 pr-1">
            <div class="table-cover">
              <!-- :style="'min-width:'+table_cover_width+'px;'" disable sementara -->
              <table
                class="table-hover xo"
                :style="'min-width:' + table_cover_width + 'px;'"
              >
                <thead>
                  <tr style="height: 33px">
                    <th
                      class="text-center no-filter"
                      v-for="(items, idx) in model_group_employee"
                      :key="idx"
                      style="position: relative"
                      colspan="2"
                    >
                      <a
                        href="javascript:;"
                        class="mr-2"
                        @click="
                          DownloadData(
                            GetDataSplitEmployee('nik', idx),
                            GetDataSplitEmployee('name', idx)
                          )
                        "
                        ><i class="ti-cloud-down text-primary"></i> Download</a
                      >
                    </th>
                  </tr>
                  <tr style="height: 33px">
                    <th
                      class="text-center no-filter"
                      v-for="(items, idx) in model_group_employee"
                      :key="idx"
                      style="position: relative"
                      colspan="2"
                    >
                      <a
                        href="javascript:;"
                        :title="GetDataSplitEmployee('name', idx)"
                      >
                        <span class="text-secondary">
                          {{
                            GetDataSplitEmployee("name", idx)
                              | limit(15, " ...")
                          }}
                        </span>
                      </a>
                    </th>
                  </tr>
                  <tr style="height: 33px">
                    <th
                      class="text-center no-filter"
                      v-for="(items, idx) in total_employee * 2"
                      :key="idx"
                      style="position: relative"
                    >
                      <span
                        :class="
                          InitialInOut(idx) == 'IN'
                            ? 'text-success'
                            : 'text-danger'
                        "
                        >{{ InitialInOut(idx) }}</span
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(items_date, idx) in model_date_range"
                    :key="idx"
                    :class="
                      'trdate ' + items_date.month + '-' + items_date.year
                    "
                  >
                    <!-- disable class tdp -->
                    <td
                      class="tdp"
                      v-for="(items, idx) in model_inout"
                      :key="idx"
                      style="height: 70px"
                    >
                      <span
                        >Shift -
                        {{
                          GetAttendanceInOut(
                            items.index,
                            "shift",
                            items_date.day +
                              "-" +
                              items_date.month +
                              "-" +
                              items_date.year
                          )
                        }}</span
                      ><br />
                      <span
                        :class="
                          items.res == 'in' ? 'text-success' : 'text-danger'
                        "
                        >{{
                          GetAttendanceInOut(
                            items.index,
                            items.res,
                            items_date.day +
                              "-" +
                              items_date.month +
                              "-" +
                              items_date.year
                          )
                        }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="text-center"
                      v-for="(items, idx) in model_inout"
                      :key="idx"
                      v-if="items.res == 'in'"
                      colspan="2"
                    >
                      <span class="badge badge-success"
                        >{{ GetTotalAttendanceIn(items.index) }} Days</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="rom mt-5" v-else>
          <div class="p-3">
            <i class="ti ti-info-alt text-danger"></i> Data Attendance is empty,
            Please manage contract shift work
          </div>
        </div>
      </div>
      <div class="card-body" v-else>
        <div class="row">
          <div class="col-md-6">
            <ListLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-6">
            <ListLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-12">
            <ContentLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="2" ry="2" width="400" height="250" />
            </ContentLoader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";

import { ContentLoader, ListLoader, CodeLoader } from "vue-content-loader";
import axios from "axios";

export default {
  components: {
    ContentLoader,
    ListLoader,
    CodeLoader,
    DatePicker,
  },
  data() {
    return {
      isLoading: true,
      isEnablePopup: false,
      table_cover_width: 0,
      model_contract: {},
      model_group_employee: [],
      model_date_range: [],
      model_inout: [],
      total_employee: 0,
      collection_period: [],
      filter_shift: "all",
      period_contract: [],
      // filter date
      filter_date: "",
      filter_year: "",
      filter_month: "",
      download: { fields: "", data: "", title: "", name: "" },
      download_by_date: { fields: "", data: "", title: "", name: "" },
    };
  },
  mounted() {
    this.QueryStartFromPeriod();
  },
  methods: {
    disabledBeforeAfterPeriodContract(date) {
      // console.log(this.model_contract);
      const start_date = new Date(this.model_contract.start_date);
      start_date.setHours(0, 0, 0, 0);

      const end_date = new Date(this.model_contract.end_date);
      end_date.setHours(0, 0, 0, 0);

      return date < start_date || date > end_date;
    },
    disabledBeforeAfterFilterDate(date) {
      const start_date = new Date(this.model_contract.start_date);
      start_date.setHours(0, 0, 0, 0);

      const end_date = new Date(this.model_contract.end_date);
      end_date.setHours(0, 0, 0, 0);

      let dateNow = new Date(date.getFullYear(), date.getMonth());
      let dateStart = new Date(start_date.getFullYear(), start_date.getMonth());
      let dateEnd = new Date(end_date.getFullYear(), end_date.getMonth());

      return dateNow < dateStart || dateNow > dateEnd;
    },
    modalExportContract() {
      this.download_by_date.title = "Report Attendace By Date Range";
      this.isEnablePopup = "download_by_date";
    },
    async downloadExcelContractByDate(event) {
      event.target.className = "btn btn-success disabled";
      this.$store.state.loading.msg =
        "Download Report Data Period " +
        this.period_contract[0] +
        " until " +
        this.period_contract[1];
      this.$store.state.loading.status = true;

      this.period_contract = [this.period_contract[0], this.period_contract[1]];

      let dataParams = {
        id_contract: localStorage.getItem("QUERY_ID"),
        start_date: this.period_contract[0],
        end_date: this.period_contract[1],
      };

      await axios
        .post("report/attendance-contract-download", dataParams, {
          responseType: "blob",
        })
        .then((res) => {
          // console.log(res);
          const link = document.createElement("a");

          link.href = window.URL.createObjectURL(new Blob([res.data]));

          link.setAttribute(
            "download",
            "Report Attendace " +
              this.model_contract.client_name +
              " Period " +
              this.period_contract[0] +
              " until " +
              this.period_contract[1] +
              ".xlsx"
          );

          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.$store.state.loading.status = false;
          event.target.className = "btn btn-success";
          this.isEnablePopup = false;
        });
    },
    DownloadData(nik, name) {
      this.$store.state.loading.msg = "Download Report Data...";
      this.$store.state.loading.status = true;
      axios
        .post("report/attendance-download", {
          id_contract: localStorage.getItem("QUERY_ID"),
          month: this.filter_month,
          year: this.filter_year,
          nik: nik,
        })
        .then((res) => {
          this.download.fields = { Date: "date", In: "in", Out: "out" };
          this.download.title = "Report Attendace - " + name;
          this.download.name = name + ".xls";
          this.download.data = res.data;
          this.isEnablePopup = "download";
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },

    InitialInOut(index) {
      return index % 2 == 0 ? "IN" : "OUT";
    },

    GetDataSplitEmployee(initial, data) {
      let data_split = data.split("-");

      if (initial == "nik") {
        return data_split[0];
      }

      if (initial == "name") {
        return data_split[1];
      }
    },

    GetAttendanceInOut(index, initial, date) {
      let model = this.model_group_employee[index];
      for (let key in model) {
        var dt =
          model[key].day + "-" + model[key].month + "-" + model[key].year;
        if (dt == date && initial == "in") {
          return model[key].in;
        }

        if (dt == date && initial == "out") {
          return model[key].out;
        }

        if (dt == date && initial == "shift") {
          return model[key].shift;
        }
      }
    },

    GetTotalAttendanceIn(index) {
      var total = 0;
      let model = this.model_group_employee[index];
      for (let key in model) {
        if (model[key].in !== "00:00:00") {
          total++;
        }
      }
      return total;
    },

    FilterModelDate() {
      let fdate = this.filter_date.split("/");
      this.filter_month = fdate[0];
      this.filter_year = fdate[1];
      this.DataQuery();
    },

    async QueryStartFromPeriod() {
      await axios
        .get("contract/period/" + localStorage.getItem("QUERY_ID"))
        .then((response) => {
          const d = new Date();
          let query = response.data;
          let default_date = query.default;
          // var dt = default_date.split('/');

          this.collection_period = query.month_period;
          // this.filter_month = dt[0];
          // this.filter_year  = dt[1];
          this.filter_month = ("0" + (d.getMonth() + 1)).slice(-2);
          this.filter_year = d.getFullYear().toString();

          this.filter_date = this.filter_month + "/" + this.filter_year;

          // this.filter_date  = default_date;

          this.DataQuery();
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },

    async DataQuery() {
      this.isLoading = true;

      this.$store.state.loading.msg = "Query Data...";
      this.$store.state.loading.status = true;
      await axios
        .post("report/attendance", {
          id_contract: localStorage.getItem("QUERY_ID"),
          month: this.filter_month,
          year: this.filter_year,
        })
        .then((response) => {
          let query = response.data;
          this.model_group_employee = query.employee;
          this.model_contract = query.contract;
          this.model_date_range = query.date_range;

          // reset
          this.total_employee = 0;
          this.model_inout = [];

          // total employee
          for (let key in this.model_group_employee) {
            this.total_employee++;
          }

          // in and out
          let m_employee = this.model_group_employee;
          for (let key in m_employee) {
            this.model_inout.push({
              index: key,
              res: "in",
            });
            this.model_inout.push({
              index: key,
              res: "out",
            });
          }

          // table width
          // console.log("table with cover => ", parseInt(this.model_date_range.length), parseInt(this.model_date_range.length) * 70)
          this.table_cover_width = parseInt(this.model_date_range.length) * 70;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },

    async Submit() {
      let model = {
        id_contract: this.model_contract.id,
        shift: this.model_group_employee,
      };
      let data = {
        url: this.meta_url + "/assign",
        callback_url: "jobcon/shift",
        form_data: model,
      };
      let res = await this.$store.dispatch("crud/storeData", data);
      if (res) {
        this.total_employee = 0;
        this.DataQuery();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.tdp {
  padding: 12px;
}
.date-month {
  position: absolute;
  top: -5px;
  color: gray;
}
.select-filter {
  width: 100%;
  height: 20px;
  border: none;
  background-color: transparent;
  color: #dc3545;
}

ul.list-area {
  float: right;
  width: 100%;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    float: left;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid whitesmoke;
  }
}
table thead tr > th {
  padding: 0px;
}
</style>
