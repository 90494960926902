<template>
  <div class="col-md-12 p-0">
    <!-- The Modal -->
    <div class="popup popup-md" v-if="isEnablePopup">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = !isEnablePopup"
          ><i class="ti ti-close"></i
        ></small>
        <h5>
          Configuration Privileges Access<br />
          <small>Module application installment</small>
        </h5>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <div class="form-group border-bottom">
              <div class="float-left">
                <label for="">Package Module</label><br />
                <small class="text-secondary"
                  >Please choose package module.</small
                >
              </div>
              <div class="float-right">
                <select
                  class="form-control"
                  v-model="selected_index_module"
                  @change="
                    module_collection = model_module[selected_index_module]
                  "
                >
                  <option disabled value="">Please select one</option>
                  <option
                    v-for="(items, idx) in model_module"
                    :value="idx"
                    v-bind:key="idx"
                  >
                    Package :: {{ items.parent.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <div class="scrollbar" id="style-1">
                <div class="list-module" v-if="!module_collection.child">
                  <ul>
                    <li
                      class="part"
                      v-for="(items, idx) in module_collection"
                      :key="idx"
                    >
                      <h5>
                        <i class="ti ti-package text-info"></i> {{ items.name }}
                      </h5>
                      <div
                        v-for="(key, index) in items.action"
                        :class="key !== '' ? 'fitur-install' : ''"
                        v-bind:key="index"
                      >
                        <input
                          type="checkbox"
                          :id="items.key + '-' + index"
                          :checked="validateChecked(items.key, index)"
                          @change="
                            collectModule(
                              items.key + '-' + index,
                              items.key,
                              index
                            )
                          "
                          :disabled="isEnableCheckbox"
                        />
                        {{ CleanString(index) }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="list-module" v-else>
                  <ul>
                    <li
                      class="part"
                      v-for="(items, idx) in module_collection.child"
                      :key="idx"
                    >
                      <h5>
                        <i class="ti ti-package text-info"></i> {{ items.name }}
                      </h5>
                      <div
                        v-for="(row, index) in items.action"
                        :class="row !== '' ? 'fitur-install' : ''"
                        v-bind:key="index"
                      >
                        <input
                          type="checkbox"
                          :id="GenerateId(items.key + '-' + index)"
                          :checked="validateChecked(items.key, index)"
                          @change="
                            collectModule(
                              GenerateId(items.key + '-' + index),
                              items.key,
                              index
                            )
                          "
                          :disabled="isEnableCheckbox"
                        />
                        {{ CleanString(index) }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-if="module_collection.length < 1">
                  <div class="alert alert-secondary" role="alert" height="100">
                    Please choose package module!
                  </div>
                </div>
              </div>
              <div>
                <small class="float-right"
                  ><i class="ti ti-arrows-vertical"></i> Scroll down to see
                  others.</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-header">
          <h5>
            Master Data <br />
            <small
              >Total Records :
              <b>{{ data_model.total | formatPrice }}</b></small
            >
          </h5>
          <div class="group-search pull-right">
            <a class="btn" @click="tableRefresh()"
              ><i class="ti ti-reload"></i
            ></a>
            <a class="btn" @click="isActiveSearch()"
              ><i
                :class="isEnableTools == false ? 'ti ti-search' : 'ti ti-close'"
              ></i
            ></a>
            <a href="#/privileges/store" class="btn btn-primary"
              ><i data-v-df722e22="" class="ti ti-plus"></i> Create New</a
            >
            <div class="select" v-if="isEnableTools">
              <select v-model="meta_data.skey" @change="sval = ''">
                <option value="">-- Pilih Kolom --</option>
                <option value="name">Privileges Group Level</option>
                <option value="created_at">Created Date</option>
                <option value="updated_at">Updated Date</option>
              </select>
            </div>
            <div class="group" v-if="isEnableTools">
              <input
                type="date"
                v-model="meta_data.sval"
                v-if="
                  meta_data.skey == 'created_at' ||
                  meta_data.skey == 'updated_at'
                "
              />
              <input
                type="text"
                v-model="meta_data.sval"
                placeholder="Enter Keyword ...."
                v-else
              />
              <a class="btn" @click="tableSearch()"> SEARCH DATA </a>
            </div>
            <!--<router-link :to="{ name: meta_url + '/store' }" class="btn btn-primary" v-if="$store.state.app.access.includes('insert')"><i class="ti ti-plus"></i> Create New</router-link>-->
          </div>
        </div>
        <div class="table-cover">
          <table class="table-hover" style="width: 1000px">
            <thead>
              <tr>
                <th v-if="JSON.parse($store.state.app.access).length > 1">
                  Action
                </th>
                <th
                  v-for="(items, idx) in meta_field"
                  v-bind:key="idx"
                  :class="meta_data.okey == items.key ? 'active' : ''"
                  v-on:click="tableOrdering(items.key)"
                >
                  {{ items.value }}
                </th>
              </tr>
            </thead>
            <tbody v-if="data_model.total > 0">
              <tr v-for="(row, index) in data_model.data" :key="index">
                <td v-if="JSON.parse($store.state.app.access).length > 1">
                  <div class="tools">
                    <a href="javascript:;">Tools</a>
                    <ul>
                      <li v-if="$store.state.app.access.includes('access')">
                        <a href="javascript:;" @click="ConfigureAccess(index)"
                          ><i class="text-primary ti ti-unlock"></i> Setting
                          Access</a
                        >
                      </li>
                      <li v-if="$store.state.app.access.includes('update')">
                        <a href="javascript:;" @click="tableUpdate(row.id)"
                          ><i class="text-success ti ti-pencil"></i> Edit</a
                        >
                      </li>
                      <!--<li v-if="$store.state.app.access.includes('delete')  && row.status == 'free'">
                                                <a href="javascript:;" @click="tableRemove(row.id)" ><i class="text-danger ti ti-trash"></i> Remove</a>
                                            </li>-->
                    </ul>
                  </div>
                </td>
                <td v-for="(items, idx) in meta_field" v-bind:key="idx">
                  <span>{{ row[items.key] }}</span>
                </td>
              </tr>
            </tbody>
            <tbody v-if="data_model !== undefined && data_model.total < 1">
              <tr>
                <td :colspan="meta_field.length" class="text-danger">
                  No Record Data!
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          <small class="info"
            ><i class="ti ti-split-h"></i> Scroll horizontal to view
            data.</small
          >
          <paginate
            :page-count="parseInt(data_model.last_page)"
            :click-handler="tableQuery"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import router from "../../router";
import axios from "axios";
import $ from "jquery";

export default {
  data() {
    return {
      isEnablePopup: false,
      isEnableTools: false,
      isEnableOrder: false,
      isEnableCheckbox: false,

      modules: [],
      module_collection: [],
      model_privileges: [],
      model_single: { privileges: "", id_privileges: "" },
      selected_index_module: "",

      meta_url: "privileges",
      meta_data: { okey: "id", oval: "desc", skey: "", sval: "" },
      meta_field: [
        { key: "name", value: "Privileges Group Level" },
        { key: "created_at", value: "Created Date" },
        { key: "updated_at", value: "Updated Date" },
      ],
    };
  },

  computed: {
    ...mapState("crud", {
      data_model: (state) => state.data_model,
    }),
    ...mapState("mreg", {
      model_module: (state) => state.modules,
    }),
  },
  created() {
    this.tableQuery();
  },

  methods: {
    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
      if (this.isEnableTools) {
        this.meta_data.sval = "";
      }
    },
    CleanString(str) {
      return str.replace("_", " ");
    },
    GenerateId(string) {
      let s = string.split(".");
      if (s[1] !== undefined) {
        return s[1];
      } else {
        return string;
      }
    },
    ConfigureAccess(idx) {
      this.modules = [];
      this.selected_index_module = "";
      this.module_collection = [];

      let data_model = this.data_model.data[idx];
      this.selected_index = idx;
      this.model_single.pospoint = data_model.office_name;
      this.model_single.privileges = data_model.name;
      this.model_single.id_privileges = data_model.id;
      if (this.SettingAccess()) {
        this.isEnablePopup = true;
      }
    },

    async SettingAccess() {
      return await axios
        .get("module/privileges/" + this.model_single.id_privileges, {})
        .then((res) => {
          this.model_privileges = res.data;
          let model_privileges = this.model_privileges;
          for (let key in model_privileges) {
            var userAction = JSON.parse(model_privileges[key].action);
            for (let row in userAction) {
              this.modules.push({
                mkey: model_privileges[key].mkey,
                action: userAction[row],
              });
            }
          }

          return true;
        })
        .finally(() => {});
    },

    validateChecked(mkey, action) {
      let model = this.modules;
      for (let key in model) {
        if (model[key].mkey == mkey && model[key].action == action) {
          if (!this.isEnableCheckbox) {
            return true;
          }
        }
      }
      return false;
    },

    collectModule(initial_id, mkey, action) {
      var value = "no";
      if ($("#" + initial_id).is(":checked")) {
        value = "yes";
      }

      this.isEnableCheckbox = true;
      axios
        .post("module/update", {
          id_privileges: this.model_single.id_privileges,
          action: action,
          mkey: mkey,
          val: value,
        })
        .then((res) => {
          let data = res.data;
          if (data.result == "ok") {
            if (value == "no") {
              let model = this.modules;
              var idx = 0;
              for (let key in model) {
                if (model[key].mkey == mkey && model[key].action == action) {
                  this.modules.splice(idx, 1);
                }
                idx++;
              }
            } else {
              this.modules = [];
              this.SettingAccess();
            }
          }
        })
        .finally(() => {
          this.isEnableCheckbox = false;
        });
    },

    tableSearch() {
      if (this.meta_data.skey == "") {
        alert("🙏, Please choose target field");
        return;
      }
      if (this.meta_data.sval == "") {
        alert("🙏, Please enter the keyword");
        return;
      }
      this.tableQuery();
    },
    tableOrdering(key) {
      this.isEnableOrder = !this.isEnableOrder;
      this.meta_data.okey = key;
      this.meta_data.oval = this.isEnableOrder ? "ASC" : "DESC";
      this.tableQuery(this.page);
    },
    tableQuery(pageNum) {
      this.page = pageNum;
      let data = {
        url: this.meta_url + "/data",
        offset: this.page,
        search: { key: this.meta_data.skey, value: this.meta_data.sval },
        order: { key: this.meta_data.okey, value: this.meta_data.oval },
        pospoint: { key: this.meta_data.pkey, value: this.meta_data.pwal },
      };

      this.$store.state.loading.msg = "Processing Fetching Data...";
      this.$store.state.loading.status = true;
      this.$store.dispatch("crud/getData", data);
    },
    tableRefresh() {
      this.meta_data.skey = "";
      this.meta_data.sval = "";
      this.tableQuery();
    },
    tableUpdate(id) {
      localStorage.setItem("QUERY_ID", id);
      router.push({ name: this.meta_url + "/update" });
    },
    async tableRemove(id) {
      if (confirm("Are you sure want to remove this data ?")) {
        let data = { url: this.meta_url + "/destroy/" + id };
        let res = await this.$store.dispatch("crud/deleteData", data);
        if (res && res.result == "ok") {
          this.tableQuery();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollbar {
  height: 230px;
}
.list-module {
  width: 100%;
  margin-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
  float: left;
  ul {
    padding: 0;
    margin: 0;
    li {
      float: left;
      list-style: none;
      margin-top: 15px;
      text-transform: capitalize;
      div.fitur-install {
        float: left;
        margin-right: 10px;
        padding: 5px;
        border: 1px solid #dedede;
        background-color: #f5f5f5;
        border-radius: 3px;
        margin-bottom: 10px;
      }
    }

    li.part {
      width: 100%;
      border-bottom: 1px solid #1683ea1f;
      padding-bottom: 12px;
      h5 {
        text-align: left;
        font-size: 13px;
        margin-bottom: 10px;
        i {
          font-size: 16px;
          margin-right: 5px;
        }
      }
    }
    li.part:last-child {
      border-bottom: none;
    }
  }
}
</style>
