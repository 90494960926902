<template>
  <div class="col-md-12 p-0">
    <!-- popup shift wark -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'shiftwork'">
      <div class="content">
        <h5>
          Shift Worker <br /><small class="text-secondary"
            >Edit shift name and hour and then click button "Add New" to
            generate new shift work</small
          >
        </h5>
        <hr />

        <div class="row p-0 mt-5">
          <div class="col-md-5 p-0">
            <div class="form-group">
              <label>Shift Name</label><br />
              <select class="form-control" v-model="sw.name">
                <option value="">-- Choose One --</option>
                <option value="pagi">Pagi</option>
                <option value="siang">Siang</option>
                <option value="malam">Malam</option>
                <option value="middle">Middle</option>
              </select>
              <!-- <input
                type="text"
                class="form-control"
                v-model="sw.name"
                placeholder="..."
              /> -->
            </div>
          </div>
          <div class="col-md-4 p-0">
            <div class="form-group">
              <label>Start Shift Hour </label><br />
              <input
                type="time"
                class="form-control"
                v-model="sw.hour"
                placeholder="..."
              />
            </div>
          </div>
          <div class="col-md-3 p-0">
            <div class="form-group">
              <br />
              <a
                href="javascript:;"
                class="btn btn-secondary"
                @click="AddShiftWork()"
                >Add New</a
              >
            </div>
          </div>
        </div>
        <hr />
        <div class="row p-0">
          <div class="col-md-11">
            <span class="text-danger" v-if="this.sw.msg_error !== ''">{{
              this.sw.msg_error
            }}</span
            ><br />
            <div
              class="list-data"
              v-for="(items, idx) in this.sw.data"
              :key="idx"
            >
              - {{ items.name }} ( {{ items.hour }} )
              <i
                v-if="model.status == 'draft'"
                class="ti ti-trash text-danger"
                @click="RemoveShiftWorker(idx)"
              ></i>
            </div>
          </div>
        </div>
        <hr />
        <div class="row p-0 mb-5">
          <div class="col-md-12">
            <a
              href="javascript:;"
              class="btn btn-primary float-right"
              @click="isEnablePopup = false"
              >Finish & Close</a
            >
          </div>
        </div>
      </div>
    </div>

    <!-- popup man power -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'manpower'">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <h5>
          Generate Man Power <br /><small class="text-secondary"
            >Click button "Add New" to generate new man power</small
          >
        </h5>
        <hr />
        <div class="row p-0">
          <div class="col-md-2 p-0">
            <div class="form-group">
              <label>Total</label><br />
              <input
                type="number"
                min="1"
                class="form-control"
                placeholder="0"
                v-model="mp.total"
              />
            </div>
          </div>
          <div class="col-md-7 p-0">
            <div class="form-group">
              <label>Job Position</label><br />
              <select class="form-control" v-model="mp.job">
                <option value="">-- Choose One --</option>
                <option v-for="(items, idx) in model_jobpos" :key="idx">
                  {{ items.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 p-0">
            <div class="form-group">
              <br />
              <a
                href="javascript:;"
                class="btn btn-secondary"
                @click="AddMoreManPower()"
                >Add New</a
              >
            </div>
          </div>
        </div>
        <div class="row p-0 mb-5">
          <div class="col-md-11">
            <span class="text-danger" v-if="this.mp.msg_error !== ''">
              {{ this.mp.msg_error }} </span
            ><br />
            <h5></h5>
            <div
              class="list-data"
              v-for="(items, idx) in this.mp.data"
              :key="idx"
            >
              - {{ items.total }} ( {{ items.job }} )
              <i
                class="ti ti-trash text-danger"
                @click="RemoveManPower(idx)"
              ></i>
            </div>
          </div>
        </div>

        <hr />
        <div class="row p-0 mb-5">
          <div class="col-md-12">
            <a
              href="javascript:;"
              class="btn btn-primary float-right"
              @click="isEnablePopup = false"
              >Finish & Close</a
            >
            <!-- <a
              href="javascript:;"
              class="btn btn-primary float-right"
              @click="UpdateManPower()"
              >Update Man Power</a
            > -->
          </div>
        </div>
      </div>
    </div>

    <!-- popup section section_insert room  -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'section_insert'">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <h5>
          Section & Room<br /><small>Section Room Creator</small>
          <hr />
        </h5>

        <div class="row p-0">
          <div class="col-md-12">
            <span class="text-success float-left" id="add-more-notify"
              >1 slot unit is already created.</span
            >
            <a
              href="javascript:;"
              class="float-right btn btn-execute mb-5"
              @click="AddMoreSlotRoom('section_insert')"
            >
              <i class="ti ti-plus text-success"></i> Add More Room</a
            >
            <br />
          </div>
        </div>

        <div
          class="row p-0 mb-2"
          v-for="(items, idx) in edit_data_section"
          :key="idx"
        >
          <div class="col-md-12">
            <div class="row" v-for="(items, idx) in items.data" :key="idx">
              <div class="col-md-2">
                <span>Floor</span>
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  placeholder="1"
                  v-model="items.area_floor"
                />
              </div>
              <div class="col-md-4">
                <span>Area Type </span>
                <select class="form-control" v-model="items.area_type">
                  <option value="indoor">InDoor</option>
                  <option value="outdoor">Outdoor</option>
                </select>
              </div>
              <div class="col-md-6">
                <span>Area Name</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="...."
                  v-model="items.area_name"
                />
              </div>
              <div class="col-md-12 mt-4">
                <span class="text-secondary">Area Job Description</span>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="........"
                  v-model="items.area_task"
                ></textarea>
                <hr />
              </div>
            </div>
          </div>
        </div>

        <div class="row p-0">
          <div class="col-md-12 mt-4 text-right">
            <a
              href="javascript:;"
              class="btn btn-primary"
              @click="UpdateBulkSectionRoom()"
              >Continue Add Section</a
            >
          </div>
        </div>
      </div>
    </div>

    <!-- popup section section_edit room  -->
    <div class="popup popup-md" v-if="isEnablePopup == 'section_edit'">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <h5>
          Section #{{ this.no_section_edit }} & Room<br /><small
            >Section Room Creator</small
          >
          <hr />
        </h5>

        <div class="row p-0">
          <div class="col-md-12">
            <span class="text-success float-left" id="add-more-notify"
              >1 slot unit is already created.</span
            >
            <a
              href="javascript:;"
              class="float-right btn btn-execute mb-5"
              @click="AddMoreSlotRoom('section_edit')"
            >
              <i class="ti ti-plus text-success"></i> Add More Room</a
            >
            <br />
          </div>
        </div>

        <div
          class="row p-0 mb-2"
          v-for="(items, idx) in edit_data_section"
          :key="idx"
          v-if="items.section == no_section_edit"
        >
          <div class="col-md-12">
            <div
              class="row"
              v-for="(items, idxChild) in items.data"
              :key="idxChild"
            >
              <div class="col-md-12">
                <a
                  v-if="model.status == 'draft'"
                  href="javascript:;"
                  class="float-right"
                  @click="DeleteSectionItem(idx, idxChild, items)"
                  ><i class="ti ti-trash text-danger"></i> Remove</a
                >
              </div>
              <div class="col-md-2">
                <span>Floor</span>
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  placeholder="1"
                  v-model="items.area_floor"
                />
              </div>
              <div class="col-md-5">
                <span>Area Type </span>
                <select class="form-control" v-model="items.area_type">
                  <option value="indoor">InDoor</option>
                  <option value="outdoor">Outdoor</option>
                </select>
              </div>
              <div class="col-md-5">
                <span>Area Name</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="...."
                  v-model="items.area_name"
                />
              </div>
              <div class="col-md-12 mt-4">
                <span class="text-secondary">Area Job Description</span>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="........"
                  v-model="items.area_task"
                ></textarea>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div class="row p-0">
          <div class="col-md-12 mt-4 text-right">
            <a
              href="javascript:;"
              class="btn btn-primary"
              @click="UpdateBulkSectionRoom()"
              >Update Section</a
            >
          </div>
        </div>
      </div>
    </div>

    <!-- popup section section_perlist room  -->
    <!-- <div class="popup popup-sm" v-if="isEnablePopup == 'section_perlist'">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <h5>
          Section & Room
          <hr />
        </h5>

        <div class="row">
          <div class="col-md-2">
            <span>Floor</span>
            <input
              type="number"
              min="1"
              class="form-control"
              placeholder="1"
              v-model="item_edit_section_per_list.area_floor"
            />
          </div>
          <div class="col-md-4">
            <span>Area Type </span>
            <select
              class="form-control"
              v-model="item_edit_section_per_list.area_type"
            >
              <option value="indoor">InDoor</option>
              <option value="outdoor">Outdoor</option>
            </select>
          </div>
          <div class="col-md-6">
            <span>Area Name</span>
            <input
              type="text"
              class="form-control"
              placeholder="...."
              v-model="item_edit_section_per_list.area_name"
            />
          </div>
          <div class="col-md-12 mt-4">
            <span class="text-secondary">Area Job Description</span>
            <textarea
              class="form-control"
              rows="3"
              placeholder="........"
              v-model="item_edit_section_per_list.area_task"
            ></textarea>

            <hr />
          </div>
          <div class="col-md-12 mt-4 text-center">
            <a
              href="javascript:;"
              class="btn btn-primary"
              @click="UpdateSectionRoomPerList()"
              >Update Section</a
            >
          </div>
        </div>
      </div>
    </div> -->

    <!-- popup client  -->
    <PopupClientMaster
      v-on:exit="closeClient"
      v-on:applyData="applyClient"
      :id_branch="model.id_branch"
      v-if="isEnablePopup == 'client'"
    />

    <div v-if="$store.state.loading.status">
      <div class="col-md-6">
        <ListLoader
          :speed="1"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        ></ListLoader>
      </div>
      <div class="col-md-6">
        <ListLoader
          :speed="1"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        ></ListLoader>
      </div>
      <div class="col-md-12">
        <ContentLoader
          :speed="1"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="0" rx="2" ry="2" width="400" height="400" />
        </ContentLoader>
      </div>
    </div>

    <div v-else>
      <form class="form-horizontal mb-5" @submit="submit">
        <div class="card">
          <div class="card-body">
            <!-- header contract -->
            <div class="form-group">
              <div class="col-sm-12">
                <label class="text-primary mb-0"
                  ><i class="ti ti-info-alt"></i> Detail Information
                  Contract</label
                >
                <small class="text-secondary"
                  >Please fill completely detail data.</small
                >
                <hr />
              </div>
            </div>

            <div class="form-group" v-if="isEnableSelectBranch">
              <label class="col-sm-3"
                >Branch Station <small class="text-danger">*</small></label
              >
              <div class="col-sm-9">
                <SelectBranch
                  v-if="isAutoloadSelectBranch"
                  :default_id="model.id_branch"
                  v-on:processBranch="processBranch"
                  :title="'disable'"
                />
                <small class="text-danger" v-if="msg_error.id_branch">{{
                  msg_error.id_branch[0]
                }}</small>
              </div>
            </div>
            <div class="form-group" v-else>
              <label class="col-sm-3"
                >Branch Station <small class="text-danger">*</small></label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  v-model="branch_name"
                  readonly
                />
                <small class="text-danger" v-if="msg_error.id_branch">{{
                  msg_error.id_branch[0]
                }}</small>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3"
                >Number of Contract <small class="text-danger">*</small></label
              >
              <div class="col-sm-9">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  placeholder="...."
                  v-model="model.contract_number"
                />
                <small class="text-danger" v-if="msg_error.contract_number">{{
                  msg_error.contract_number[0]
                }}</small>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3"
                >Contract Value (Amount)
                <small class="text-danger">*</small></label
              >
              <div class="col-sm-9">
                <vue-numeric
                  separator="."
                  output-type="String"
                  currency="Rp"
                  v-model="model.contract_value"
                  class="form-control"
                ></vue-numeric>
                <small class="text-danger" v-if="msg_error.contract_value">{{
                  msg_error.contract_value[0]
                }}</small>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3"
                >Contract Date <small class="text-danger">*</small></label
              >
              <div class="col-sm-9">
                <input
                  type="date"
                  class="form-control"
                  placeholder="...."
                  v-model="sdate"
                  disabled
                />
                <small class="text-danger" v-if="msg_error.contract_date">{{
                  msg_error.contract_date[0]
                }}</small>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3"
                >Contract Period <small class="text-danger">*</small></label
              >
              <div class="col-sm-4">
                <span>Start Date</span>
                <input
                  type="date"
                  min="1"
                  class="form-control"
                  placeholder="0"
                  v-model="model.start_date"
                  id="startDate"
                  disabled
                />
                <small class="text-danger" v-if="msg_error.start_date">{{
                  msg_error.start_date[0]
                }}</small>
              </div>
              <div class="col-sm-4 float-right">
                <span>End Date</span>
                <input
                  type="date"
                  min="1"
                  class="form-control"
                  placeholder="0"
                  v-model="model.end_date"
                  id="endDate"
                  disabled
                />
                <small class="text-danger" v-if="msg_error.end_date">{{
                  msg_error.end_date[0]
                }}</small>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3">Contract Description</label>
              <div class="col-sm-9">
                <small class="text-secondary"
                  >Fill in the description fields as needed</small
                >
                <textarea
                  class="form-control"
                  rows="3"
                  v-model="model.description"
                ></textarea>
                <small class="text-danger" v-if="msg_error.description">{{
                  msg_error.description[0]
                }}</small>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <label class="text-primary mb-0"
                  ><i class="ti ti-info-alt"></i> Detail Information
                  Client</label
                >
                <small class="text-secondary"
                  >Please fill completely detail data.</small
                >
                <hr />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3"
                >Client Base <small class="text-danger">*</small></label
              >
              <!-- @change="switchClientBase()" -->
              <div class="col-sm-9">
                <select v-model="isBaseClient" class="form-control" disabled>
                  <option value="new">New Client</option>
                  <option value="old">Take From Resource Client</option>
                </select>
              </div>
            </div>
            <!-- <div class="form-group" v-if="isBaseClient == 'old'">
                    <label class="col-sm-3"></label>
                    <div class="col-sm-9">
                      <a
                        href="javascript:;"
                        class="btn btn-execute"
                        @click="isEnablePopup = 'client'"
                        ><i class="ti ti-server text-primary"></i>&nbsp; Browse Client
                        ...</a
                      >
                    </div>
                  </div> -->
            <div class="form-group">
              <label class="col-sm-3"
                >Client Name <small class="text-danger">*</small></label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  placeholder="...."
                  v-model="model.name"
                  :readonly="isBaseClient == 'old' ? true : false"
                />
                <small class="text-danger" v-if="msg_error.name">{{
                  msg_error.name[0]
                }}</small>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3"
                >Email Address <small class="text-danger">*</small></label
              >
              <div class="col-sm-9">
                <input
                  type="email"
                  class="form-control"
                  placeholder="...."
                  v-model="model.email"
                  :readonly="isBaseClient == 'old' ? true : false"
                />
                <small class="text-danger" v-if="msg_error.email">{{
                  msg_error.email[0]
                }}</small>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-3"
                >Phone Number <small class="text-danger">*</small></label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  placeholder="...."
                  v-model="model.phone_number"
                  :readonly="isBaseClient == 'old' ? true : false"
                  @keyup="removeLeadingZero"
                  @keypress="onlyNumber"
                />
                <small class="text-danger" v-if="msg_error.phone_number">{{
                  msg_error.phone_number[0]
                }}</small>
              </div>
            </div>

            <div class="form-group">
              <label class="col-sm-3"
                >Client Address <small class="text-danger">*</small></label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  v-model="provinces_name"
                  readonly
                />
                <small class="text-danger" v-if="msg_error.id_propinsi">{{
                  msg_error.id_propinsi[0]
                }}</small>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3"></label>
              <div class="col-sm-9">
                <select
                  v-model="model.id_kota"
                  class="form-control"
                  :disabled="model_kota.length > 0 ? false : true"
                >
                  <!-- :readonly="isBaseClient == 'old' ? true : false" disable -->
                  <option disabled value="">-- Pilih Kabupaten --</option>
                  <option
                    v-for="(items, idx) in model_kota"
                    :value="items.id"
                    :key="idx"
                  >
                    {{ items.name ? items.name : items.nama_kota }}
                  </option>
                </select>
                <small class="text-danger" v-if="msg_error.id_kota">{{
                  msg_error.id_kota[0]
                }}</small>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3"></label>
              <div class="col-sm-9">
                <Gmap
                  name="example"
                  :default_model="markerCoordinates"
                  ref="Gmap"
                  v-if="isAutoloadMaps"
                ></Gmap>
                <!-- :disable="true" disable -->
                <small class="text-danger" v-if="msg_error.office_address">{{
                  msg_error.office_address[0]
                }}</small>
                <small class="text-danger" v-if="msg_error.lat">{{
                  msg_error.lat[0]
                }}</small>
                <small class="text-danger" v-if="msg_error.lng">{{
                  msg_error.lng[0]
                }}</small>
              </div>
            </div>
            <div class="col-md-12 text-right mb-5">
              <a
                href="javascript:;"
                class="btn btn-secondary mr-3"
                @click="$router.go(-1)"
                >CANCEL</a
              >
              <button type="submit" class="btn btn-primary btn-full-screen">
                Update Contract
              </button>
            </div>
          </div>
        </div>
      </form>
      <!-- details contract -->

      <div class="card">
        <div class="card-body">
          <form class="form-horizontal">
            <div class="form-group">
              <div class="col-sm-12">
                <label class="text-primary mb-0"
                  ><i class="ti ti-info-alt"></i> Client Station Area
                  Base</label
                >
                <small class="text-secondary"
                  >Describe contract shift work for employee.</small
                >

                <hr />
              </div>

              <div class="form-group">
                <div class="mt-2">
                  <div class="col-sm-3">
                    Request Man Power:
                    <span class="badge badge-primary">{{
                      this.mp.data.length
                    }}</span
                    ><br />
                    <small class="text-secondary"
                      >Setting Total Man Power and Job Position on Contract
                    </small>
                  </div>
                  <div class="col-sm-9">
                    <a
                      href="javascript:;"
                      class="btn btn-light mb-1"
                      @click="OpenDialog('manpower')"
                    >
                      <i class="ti ti-plus text-success"></i> Manage Man
                      Power...
                    </a>
                    <br />
                    <div class="mt-3" v-if="this.mp.data.length > 0">
                      <div
                        class="list-section"
                        v-for="(items, idx) in this.mp.data"
                        :key="idx"
                      >
                        - {{ items.total }} ( {{ items.job }} )
                      </div>
                    </div>
                    <small class="text-danger" v-if="msg_error.man_power">{{
                      msg_error.man_power[0]
                    }}</small>
                  </div>
                </div>

                <div class="mt-5">
                  <div class="col-sm-3">
                    Total Shift Work :
                    <span class="badge badge-primary">{{
                      this.sw.data.length
                    }}</span
                    ><br />
                    <small class="text-secondary"
                      >Add more area to listing your unit.
                    </small>
                  </div>

                  <div class="col-sm-9">
                    <a
                      href="javascript:;"
                      class="btn btn-light mb-1"
                      @click="OpenDialog('shiftwork')"
                      ><i class="ti ti-plus text-success"></i> Manage Shift Work
                      ...</a
                    >
                    <br />

                    <div class="row mt-3" v-if="this.sw.data.length > 0">
                      <div class="col-md-12">
                        <div
                          class="list-section"
                          v-for="(items, idx) in this.sw.data"
                          :key="idx"
                        >
                          - {{ items.name }} ( {{ items.hour }} )
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-5">
                  <div class="col-sm-3">
                    Total Area :
                    <span class="badge badge-primary">{{
                      data_section.length
                    }}</span
                    ><br />
                    <small class="text-secondary"
                      >Add more area to listing your unit.
                    </small>
                  </div>
                  <div class="col-sm-5">
                    <a
                      href="javascript:;"
                      class="btn btn-light mb-1"
                      @click="AddMoreSection()"
                      ><i class="ti ti-plus text-success"></i> Manage New
                      Section ...</a
                    >
                  </div>

                  <div
                    class="row mt-3"
                    v-for="(items_parent, index_parent) in data_section"
                    :key="index_parent"
                  >
                    <div class="col-md-3"></div>
                    <div class="col-md-9">
                      <h5>
                        SECTION #{{ items_parent.section }}

                        <a
                          href="javascript:;"
                          class="float-right ml-3"
                          @click="EditSectionBulk(items_parent)"
                          ><i class="ti ti-pencil text-primary"></i
                        ></a>
                        &nbsp;<span class="text-light">|</span> &nbsp;
                        <a
                          v-if="model.status == 'draft'"
                          href="javascript:;"
                          class="float-right"
                          @click="DeleteSection(index_parent, items_parent)"
                          ><i class="ti ti-trash text-danger"></i
                        ></a>
                      </h5>

                      <div
                        v-for="(items, idx) in items_parent.data"
                        :key="idx"
                        :class="'form-group mt-2 initial-section'"
                      >
                        <div class="list-section">
                          {{ idx + 1 }} - Floor({{ items.area_floor }}),
                          {{ items.area_type }} - {{ items.area_name }}, Job
                          Task ({{
                            items.area_taks == null ? "..." : items.area_taks
                          }})
                          <!-- <a
                                href="javascript:;"
                                class="float-right ml-3"
                                @click="EditSectionPerList(items)"
                                ><i class="ti ti-pencil text-primary"></i
                              ></a>
                              &nbsp;<span class="text-light">|</span> &nbsp;
                              -->
                          <!-- <a
                            v-if="model.status == 'draft'"
                            href="javascript:;"
                            class="float-right ml-3"
                            @click="DeleteSectionPerList(items)"
                            ><i class="ti ti-trash text-danger"></i
                          ></a> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import $ from "jquery";
import Gmap from "../../../services/Gmap";
import PopupClientMaster from "../../ClientBase/Master/@PopupClientMaster";
import SelectBranch from "../../Branch/@SelectBranch";
import { ContentLoader, ListLoader } from "vue-content-loader";

export default {
  components: {
    Gmap,
    PopupClientMaster,
    SelectBranch,
    ContentLoader,
    ListLoader,
  },
  data() {
    return {
      meta_url: "contract",
      isAutoloadMaps: true,
      isAutoloadSelectBranch: false,
      isBaseClient: "old",
      isEnablePopup: false,
      isEnableSelectBranch: false,
      sdate: new Date(Date.now() + -6 * 24 * 3600 * 1000)
        .toISOString()
        .slice(0, 10),
      model: { name: "", id_kota: "" },
      model_kota: [],
      markerCoordinates: [],

      popup_mode: "insert",
      data_store: [],
      no_section: 0,
      no_section_edit: 0,
      active_edit_section: 1,
      data_section: [],
      edit_data_section: [],
      data_slot: [],
      data_shift: [],
      mp: { total: 1, job: "", msg_error: "", data: [] },
      sw: { name: "", hour: "", msg_error: "", data: [] },
      model_jobpos: [],
      meta_filter: { id_branch: "" },
      provinces_name: "",
      branch_name: "",
      item_edit_section_per_list: [],
    };
  },
  computed: {
    ...mapState("crud", {
      msg_error: (state) => state.error,
    }),
  },
  created() {
    if (localStorage.getItem("userPrivilegesName") == "root") {
      this.isEnableSelectBranch = true;
    } else {
      this.model.id_branch = localStorage.getItem("userBranchId");
      this.branch_name = localStorage.getItem("userBranchName");
    }

    this.model.id_propinsi = localStorage.getItem("id_provinces");
    this.provinces_name = localStorage.getItem("provinces_name");
  },
  mounted() {
    this.fetchKota();
    this.QueryJobPosition();
    this.DataQuery();
  },
  methods: {
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    removeLeadingZero() {
      if (parseInt(this.model.phone_number, 10)) {
        let phone_number = parseInt(this.model.phone_number, 10);
        this.model.phone_number = phone_number;
      }
    },
    OpenDialog(initial) {
      window.scrollTo(500, 0);
      this.isEnablePopup = initial;
    },
    processBranch(data) {
      this.model.id_branch = data;
    },
    closeClient() {
      this.isEnablePopup = false;
    },
    applyClient(data) {
      this.isAutoloadMaps = false;
      this.model.id_client = data.id;
      this.model.name = data.name;
      this.model.email = data.email;
      this.model.phone_number = data.phone_number;
      this.model.id_propinsi = data.id_propinsi;
      this.model.id_kota = data.id_kota;

      this.markerCoordinates = {
        latitude: parseFloat(data.lat),
        longitude: parseFloat(data.lng),
        address: data.office_address,
      };
      setTimeout(() => {
        this.isAutoloadMaps = true;
      }, 200);
    },
    AddShiftWork() {
      let ValueShiftName = this.sw.name;
      let ValueShiftHour = this.sw.hour;
      let checkExistShift = false;
      // validation exist shift
      for (let i = 0; i < this.sw.data.length; i++) {
        let shiftName = this.sw.data[i].name;
        let shiftHour = this.sw.data[i].hour;

        if (shiftName == ValueShiftName && shiftHour == ValueShiftHour) {
          checkExistShift = true;
          break;
        }
      }
      if (this.sw.name !== "" && this.sw.hour !== "") {
        if (checkExistShift) {
          this.sw.msg_error = "Shift name Existing !";
        } else {
          this.sw.msg_error = "";
          let model = this.sw.data;

          model.push({ name: this.sw.name, hour: this.sw.hour });
          this.sw.name = "";
          this.sw.hour = "";
          this.updateShiftWork();
        }
      } else {
        this.sw.msg_error = "Please insert shift name and hour !";
      }
    },
    RemoveShiftWorker(idx) {
      this.sw.data.splice(idx, 1);
      this.updateShiftWork();
    },
    async updateShiftWork() {
      this.$store.state.loading.status = true;
      this.$store.state.loading.msg = "Loading Process ..";
      await axios
        .post(
          this.meta_url +
            "/edit/" +
            localStorage.getItem("QUERY_ID") +
            "/update-shiftwork",
          {
            shift_work: this.sw.data,
          }
        )
        .then((res) => {
          let response = res.data;
          this.$store.state.loading.msg = response.msg;
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.state.loading.status = false;
            this.DataQuery();
          }, 1000);
        });
    },
    async UpdateBulkSectionRoom() {
      let dataSection = this.edit_data_section;
      let validate_area = false;
      if (dataSection.length > 0) {
        for (let key in dataSection[0]["data"]) {
          if (dataSection[0]["data"][key].area_name == "") {
            validate_area = true;
          }
        }

        if (!validate_area) {
          this.$store.state.loading.status = true;
          await axios
            .post(
              this.meta_url +
                "/edit/" +
                localStorage.getItem("QUERY_ID") +
                "/upsert-section",
              dataSection[0]
            )
            .then((res) => {
              this.edit_data_section = [];
              let response = res.data;
              if (response.result == "ok") {
                this.isEnablePopup = false;
                this.$store.state.loading.msg = response.msg;
              } else {
                this.$store.state.loading.msg = response.msg;
              }
              this.DataQuery();
            })
            .finally(() => {
              this.$store.state.loading.status = false;
              this.isEnablePopup = false;
            });
        } else {
          alert("Please entry completely all room name");
        }
      }
    },
    // async UpdateSectionRoomPerList() {

    //   this.$store.state.loading.status = true;
    //   await axios
    //     .post(
    //       this.meta_url +
    //         "/update-section-single/" +
    //         localStorage.getItem("QUERY_ID"),
    //       this.item_edit_section_per_list
    //     )
    //     .then((res) => {
    //       this.data_section = [];
    //       let response = res.data;
    //       if (response.result == "ok") {
    //         this.isEnablePopup = false;
    //         this.$store.state.loading.msg = response.msg;
    //       } else {
    //         this.$store.state.loading.msg = response.msg;
    //       }
    //       this.DataQuery();
    //     })
    //     .finally(() => {
    //       this.$store.state.loading.status = false;
    //     });
    // },
    async AddMoreManPower() {
      console.log(this.mp.data);
      let model = this.mp.data;
      this.mp.msg_error = "";
      if (this.mp.job !== "") {
        for (let key in model) {
          if (model[key].job == this.mp.job) {
            this.mp.msg_error = "Job position is already exists !";
            return;
          }
        }
        model.push({ total: this.mp.total, job: this.mp.job });
        this.updateManPower();
      } else {
        this.mp.msg_error = "Please choose job position !";
      }
      console.log(model);
    },
    RemoveManPower(idx) {
      this.mp.data.splice(idx, 1);
      this.updateManPower();
    },

    async updateManPower() {
      this.$store.state.loading.status = true;
      this.$store.state.loading.msg = "Loading Process ..";
      await axios
        .post(
          this.meta_url +
            "/edit/" +
            localStorage.getItem("QUERY_ID") +
            "/update-manpower",
          {
            man_power: this.mp.data,
          }
        )
        .then((res) => {
          let response = res.data;
          console.log(response);
          if (response.result == "ok") {
            // this.isEnablePopup = false;
            this.$store.state.loading.msg = response.msg;
          } else {
            this.$store.state.loading.msg = response.msg;
          }
        })
        .finally(() => {
          this.$store.state.loading.status = false;
          this.DataQuery();
        });
    },
    AddMoreSection() {
      this.edit_data_section = [];
      this.no_section = parseInt(this.no_section) + 1;
      this.edit_data_section.push({
        section: this.no_section,
        data: [
          {
            id: "",
            section: this.no_section,
            area_type: "indoor",
            area_task: "",
            area_name: "",
            area_floor: 1,
          },
        ],
      });

      window.scrollTo(500, 0);
      this.isEnablePopup = "section_insert";
    },
    EditSectionBulk(items) {
      this.edit_data_section = [];
      this.no_section_edit = items.section;
      let dataSection = items.data;

      this.edit_data_section.push({
        section: this.no_section_edit,
        data: [],
      });
      for (let idx in this.edit_data_section) {
        for (let idxChild in dataSection) {
          this.edit_data_section[idx].data.push({
            id: dataSection[idxChild].id,
            section: dataSection[idxChild].section,
            area_type: dataSection[idxChild].area_type,
            area_task: dataSection[idxChild].area_task,
            area_name: dataSection[idxChild].area_name,
            area_floor: dataSection[idxChild].area_floor,
          });
        }
      }

      window.scrollTo(500, 0);
      this.isEnablePopup = "section_edit";
    },
    // EditSectionPerList(item) {
    //   this.item_edit_section_per_list = item;
    //   this.isEnablePopup = "section_perlist";
    // },
    async DeleteSectionItem(idx, idxChild, item) {
      // console.log(this.data_section);
      // console.log(idx);
      // console.log(idxChild);
      // console.log(this.data_section[idx].data);
      if (
        confirm(
          "Continue Remove Section #" + item.section + " Area " + item.area_name
        )
      ) {
        this.$store.state.loading.status = true;
        await axios
          .post(this.meta_url + "/edit/delete-section-child/", {
            id_contract: localStorage.getItem("QUERY_ID"),
            data: item,
          })
          .then((res) => {
            let response = res.data;
            if (response.result == "ok") {
              this.$store.state.loading.msg = response.msg;
              this.edit_data_section[idx].data.splice(idxChild, 1);
            } else {
              this.$store.state.loading.msg = response.msg;
            }
            this.DataQuery();
          })
          .finally(() => {});
      }
    },
    async DeleteSectionPerList(item) {
      if (
        confirm(
          "Continue Remove Section #" + item.section + " Area " + item.area_name
        )
      ) {
        this.data_section.splice(no, 1);
        this.$store.state.loading.status = true;
        await axios
          .post(this.meta_url + "/delete-section-child/", { data: item })
          .then((res) => {
            this.data_section = [];
            let response = res.data;
            if (response.result == "ok") {
              this.isEnablePopup = false;
              this.$store.state.loading.msg = response.msg;
            } else {
              this.$store.state.loading.msg = response.msg;
            }
            this.DataQuery();
          })
          .finally(() => {
            this.$store.state.loading.status = false;
          });
      }
    },
    async DeleteSection(no, items = null) {
      if (confirm("Continue remove section #" + (no + 1))) {
        let tempIdSection = [];

        let noSection = items.section;
        for (let key in items.data) {
          tempIdSection.push(items.data[key].id);
        }

        this.$store.state.loading.status = true;
        await axios
          .post(this.meta_url + "/edit/delete-section-parent/", {
            id_contract: localStorage.getItem("QUERY_ID"),
            section: noSection,
            idsection: tempIdSection,
          })
          .then((res) => {
            this.data_section = [];
            let response = res.data;
            if (response.result == "ok") {
              this.data_section.splice(no, 1);
              this.isEnablePopup = false;
              this.$store.state.loading.msg = response.msg;
            } else {
              this.$store.state.loading.msg = response.msg;
            }
            this.DataQuery();
          })
          .finally(() => {
            this.$store.state.loading.status = false;
          });
      }
    },
    AddMoreSlotRoom(cmd = null) {
      let edit_data_section = this.edit_data_section;

      if (cmd == "section_insert") {
        for (let idx in edit_data_section) {
          edit_data_section[idx].data.push({
            id: "",
            section: edit_data_section[idx].section,
            area_type: "indoor",
            area_task: "",
            area_name: "",
            area_floor: 1,
          });
          console.log(edit_data_section);
        }
      } else {
        for (let idx in edit_data_section) {
          let IdxW = parseInt(idx) + 1;
          if (IdxW === edit_data_section.length) {
            edit_data_section[idx].data.push({
              id: "",
              section: edit_data_section[idx].section,
              area_type: "indoor",
              area_task: "",
              area_name: "",
              area_floor: 1,
            });
          }
        }

        console.log(edit_data_section);
      }

      // console.log(edit_data_section);
      $("#add-more-notify").text("1 slot unit is already created.");
      $("#add-more-notify").show();
      setTimeout(() => {
        $("#add-more-notify").fadeOut();
      }, 1000);
    },

    removeSlotRoom(idx) {
      this.data_slot.splice(idx, 1);
    },

    QueryJobPosition() {
      axios
        .post("job_position/data/all")
        .then((res) => {
          this.model_jobpos = res.data;
        })
        .finally(() => {});
    },
    async DataQuery() {
      this.sw.data = [];
      this.mp.data = [];
      this.data_section = [];
      this.isAutoloadMaps = false;
      this.$store.state.loading.status = true;
      await axios
        .get(this.meta_url + "/find/" + localStorage.getItem("QUERY_ID"))
        .then((res) => {
          let query = res.data;
          // let as = new Date(Date.now() + -6 * 24 * 3600 * 1000)
          //   .toISOString()
          //   .slice(0, 10);
          let dataMapping = query.data;
          let sectionMapping = query.section;

          this.processBranch(dataMapping.id_branch);
          this.model.contract_number = dataMapping.contract_number;
          this.model.contract_value = dataMapping.contract_value;
          this.model.status = dataMapping.status;
          this.sdate = this.$moment(dataMapping.contract_date).format(
            "YYYY-MM-DD"
          );

          this.model.description = dataMapping.description;

          this.model.id_client = dataMapping.id_client;
          this.model.name = dataMapping.client_name;
          this.model.email = dataMapping.email;
          this.model.phone_number = dataMapping.phone_number;
          this.model.id_propinsi = dataMapping.id_propinsi;
          this.model.id_kota = dataMapping.id_kota;

          this.model.start_date = this.$moment(dataMapping.start_date).format(
            "YYYY-MM-DD"
          );
          this.model.end_date = this.$moment(dataMapping.end_date).format(
            "YYYY-MM-DD"
          );

          this.markerCoordinates = {
            latitude: parseFloat(dataMapping.lat),
            longitude: parseFloat(dataMapping.lng),
            address: dataMapping.office_address,
          };

          setTimeout(() => {
            this.isAutoloadMaps = true;
            this.isAutoloadSelectBranch = true;
            // this.model.id_branch = dataMapping.id_branch;
          }, 200);

          // mapping ShiftWork Existing Contract Project
          let shifWorkMapping = JSON.parse(dataMapping.contract_shift);
          if (shifWorkMapping != undefined) {
            if (shifWorkMapping.length > 0) {
              for (let key in shifWorkMapping) {
                this.sw.data.push({
                  name: shifWorkMapping[key].name,
                  hour: shifWorkMapping[key].hour,
                });
              }
            }
          }

          // mapping ManPower Existing Contract Project
          let manPower = JSON.parse(dataMapping.man_power);
          // console.log(manPower.length)
          if (manPower != undefined) {
            if (manPower.length > 0) {
              for (let key in manPower) {
                this.mp.data.push({
                  total: manPower[key].total,
                  job: manPower[key].job,
                });
              }
            }
          }

          // mapping Section Existing Contract Project
          let counterSection = 0;

          for (let key in sectionMapping) {
            counterSection = key;
            let lengthSection = sectionMapping[key].length;
            if (lengthSection > 0) {
              let temp_data = [];
              for (let i = 0; i < lengthSection; i++) {
                if (sectionMapping[key][i].section == key) {
                  temp_data.push(sectionMapping[key][i]);
                }
              }

              this.data_section.push({
                section: key,
                data: temp_data,
              });
            }
          }

          this.no_section = counterSection;
          // console.log(this.no_section);
          // console.log(this.data_section);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    async fetchKota() {
      this.model_kota = JSON.parse(localStorage.getItem("data_regencies"));
    },
    switchClientBase() {
      if (this.isBaseClient == "new") {
        this.isAutoloadMaps = false;

        // reset default value model from old
        this.model.id_client = "";
        this.model.name = "";
        this.model.email = "";
        this.model.phone_number = "";

        this.model_kota = [];
        this.model.id_kota = "";
        this.model.id_propinsi = "";
        this.model.lat = "";
        this.model.lng = "";
        this.model.office_address = "";
        this.markerCoordinates = [];

        setTimeout(() => {
          this.isAutoloadMaps = true;
        }, 200);
      }
    },
    async submit(e) {
      e.preventDefault();

      this.model.isBaseClient = this.isBaseClient;
      // this.model.man_power = this.mp.data;
      // this.model.shift = this.sw.data;

      // rewrite data DOM  parsing to model
      this.model.office_address =
        document.getElementById("input_address").value;
      this.model.lat = document.getElementById("input_lat").value;
      this.model.lng = document.getElementById("input_lon").value;
      // this.model.data_slot = this.data_section;
      this.model.contract_date = this.$moment(this.sdate).format("DD-MM-YYYY");

      // start date and end date
      var sdate = document.getElementById("startDate").value;
      var edate = document.getElementById("endDate").value;
      this.model.start_date =
        sdate == "" ? "" : this.$moment(sdate).format("DD-MM-YYYY");
      this.model.end_date =
        edate == "" ? "" : this.$moment(edate).format("DD-MM-YYYY");

      if (this.model.id_branch == "all") {
        this.model.id_branch = localStorage.getItem("userBranchId");
      }

      // console.log(this.model);

      let data = {
        url: this.meta_url + "/update/" + localStorage.getItem("QUERY_ID"),
        callback_url: "jobcon/contract-data",
        form_data: this.model,
      };
      // alert("process develop");
      // console.log(this.model);
      let res = await this.$store.dispatch("crud/updateData", data);
      console.log(res);
    },
  },
};
</script>

<style lang="scss" scoped>
ul.list-area {
  float: right;
  width: 100%;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    float: left;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid whitesmoke;
  }
}
#add-more-notify {
  display: none;
}
.area-section {
  background-color: whitesmoke;
}
.list-data {
  position: relative;
  float: left;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  .ti {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}

.list-section {
  width: 100%;
  float: left;
  // border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  padding: 10px;
}
</style>