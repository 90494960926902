<style scoped>
</style>
<template>
  <div class="popup popup-lg">
    <div class="content">
      <small id="close-popup" @click="close()"
        ><i class="ti ti-close"></i
      ></small>
      <h5>
        Send Attachment to Customer
        <hr />
      </h5>
      <div class="row p-0">
        <form class="form-horizontal" @submit="applySubmit" style="width: 100%">
          <div class="form-group">
            <div class="col-sm-3">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="form.manualattch"
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Manual Attachment
                </label>
              </div>
            </div>
            <div class="col-sm-9"></div>
          </div>
          <div class="area-manual-attch" v-if="form.manualattch">
            <div class="form-group">
              <div class="col-sm-3">Email</div>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  v-model="form.email"
                  placeholder="..."
                />
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-3">Upload File</div>
              <div class="col-sm-9">
                <input
                  type="file"
                  @change="viewFile"
                  class="form-control"
                  accept="application/pdf"
                />
                <br />
                <object v-if="blob" :data="blob" width="100%" height="700" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-md-12 text-right">
              <button type="submit" class="btn btn-primary btn-full-screen">
                SEND
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data_customer"],
  data() {
    return {
      form: {
        id: null,
        email: null,
        file: null,
        manualattch: false,
      },
      blob: null,
    };
  },

  computed: {},
  created() {
    this.mappingDataCustomer();
  },

  methods: {
    mappingDataCustomer() {
      this.form.id = this.data_customer.id;
      this.form.email = this.data_customer.client_email;
    },
    close() {
      this.$emit("exit");
    },
    viewFile(event) {
      const file = event.target.files[0];
      this.form.file = file;
      this.blob = URL.createObjectURL(file);
    },
    applySubmit(e) {
      e.preventDefault();
      //   this.$emit("exit");
      this.$emit("applyData", this.form);
      
    },
  },
};
</script>
