<template>
    <div class="col-md-12 p-0">

        <!-- popup -->
        <div class="popup popup-sm" v-if="isEnablePopup">
            <div class="content">
            <a href="javascript:;" @click="isEnablePopup = false" class="btn float-right" type="button" v-if="model_regencies.length < 1" >
              <span>X</span>
            </a>
                <h5>Master Data Regencies <br/><small>Please choose one data.</small><hr/></h5>
                <div class="row p-0">
                    <div class="col-md-12">
                        <div class="scroll">
                            <ul class="area-list" v-if="model_regencies.length > 0">
                                <li v-for="(items,idx) in model_regencies" :key="idx">
                                    <div class="form-check form-check-primary">
                                        <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input"  :checked="validateChecked(items.id)" :id="'checkbox-'+items.id" @change="ChooseLocation($event, items.id, items.nama_kota)"> {{ items.tipe }}.{{ items.nama_kota }} <i class="input-helper"></i>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                            <div v-else>
                                <span class="text-danger">To continues selected regencies please choose province !</span>
                            </div>
                        </div>
                    </div>  
                </div> 
                <div class="row mt-3" v-if="model_regencies.length > 0">
                    <hr/>
                    <div class="col-md-12">
                        <a href="javascript:;" class="btn btn-primary float-right" @click="isEnablePopup = false">Close & Continue</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- ===== -->

        <div class="card">
            <div class="card-body">
                  <form class="form-horizontal" @submit="submit">
                    <div class="form-group">
                        <div class="col-sm-12">
                            <label class="text-primary mb-0"><i class="ti ti-info-alt"></i> Enter New Branch Station Address</label>
                            <small class="text-secondary">Please check the branch station before you create new.</small>
                            <hr/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-3 ">Branch Station Name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control"  placeholder="...." v-model="model.name">
                            <small class="text-danger" v-if="msg_error.name">{{ msg_error.name[0] }}</small>
                        </div>
                    </div>
                        <div class="form-group">
                        <label class="col-sm-3 ">Description</label>
                        <div class="col-sm-9">
                            <textarea type="text" class="form-control" rows="4"  placeholder="...." v-model="model.description"></textarea>
                            <small class="text-danger" v-if="msg_error.description">{{ msg_error.description[0] }}</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-12">
                            <label class="text-primary mb-0"><i class="ti ti-info-alt"></i> Coverage Area Access</label>
                            <small class="text-secondary">This feature will limit data information by area description.</small>
                            <hr/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-3 ">Provinces <small class="text-danger">*</small></label>
                        <div class="col-sm-9">
                            <select v-model="model.id_provinces"  @change="fetchKota()"  class="form-control">
                                <option disabled value="">-- Choose Province --</option>
                                <option v-for="(items, idx) in model_province" :value="items.id" :key="idx">{{ items.nama_provinsi }}</option>
                            </select>
                            <small class="text-danger" v-if="msg_error.id_provinces">{{ msg_error.id_provinces[0] }}</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-3 ">Regencies</label>
                        <div class="col-sm-9">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="applying_area" id="optionsRadios2" value="all" v-model="applying_regencies" >  All Area <i class="input-helper"></i>
                                    <br/><small class="text-secondary">Regencies area location will allow  all permission data area</small> 
                                </label>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <div class="form-check mt-2">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="applying_area" id="optionsRadios2" value="selected-area" v-model="applying_regencies" > Manual Selected Area <i class="input-helper"></i>
                                    <br/>
                                    <a href="javascript:;" class="btn btn-execute mt-4" v-if="applying_regencies == 'selected-area'" @click="isEnablePopup = true"><i class="mdi mdi-database-plus"></i> Browse Area ...</a><br v-if="applying_regencies == 'selected-area'"/>
                                    <small class="text-secondary">Regencies area will allow to selected area</small> 
                                    <small class="text-danger" v-if="validate_duplicate !== ''"><br/>Some of area is duplicated :  {{ validate_duplicate }}</small>

                                </label>
                            </div>
                            <small class="text-danger" v-if="msg_error.id_regencies">{{ msg_error.id_regencies[0] }}</small>

                            <div class="p-2" v-if="group_regencies.length > 0">
                                <hr/>   
                                <ul class="area-list">
                                    <li v-for="(items, idx) in group_regencies" :key="idx">{{ items.name }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="form-group">
                        <div class="col-md-12 text-center">
                            <a href="javascript:;" class="btn btn-secondary mr-3" @click="$router.go(-1)">CANCEL</a>
                            <button type="submit" class="btn btn-primary btn-full-screen">Submit Data</button>
                        </div>
                    </div>
                </form>
            </div> 
        </div> 
    </div> 
</template>

<script>

    import { mapState } from 'vuex';
    import axios from 'axios';
    import $ from 'jquery';


    export default 
    {   
        components: {
        },
        data() {
            return {
                meta_url : 'branch',
                model: {id_provinces : '',},
                
                model_province : [],
                model_regencies : [],
                applying_regencies : 'all',
                group_regencies : [],
                validate_duplicate: '',
                isEnablePopup : false,
            };
        },
        computed: {
        
            ...mapState('crud', {
                msg_error: state => state.error,
            }),
        },
        
        mounted() {
            this.fetchProvince();
        },
        
        methods: 
        {
            
            ChooseLocation(e, id, name)
            {
                if( e.target.checked )
                {
                    this.group_regencies.push({id : id, name : name})
                }else{
                    for(let key in this.group_regencies)
                    {
                        if( this.group_regencies[key].id == id ){
                            this.group_regencies.splice(key, 1);
                        }
                    }
                }
            },
          
            validateChecked(id)
            {
                let model = this.group_regencies;
                for(let key in model)
                {
                    if(model[key].id == id)
                    {
                        return true;
                    }
                }
                return false;
            },
            
            async fetchProvince() 
            {
                await axios.get('location/propinsi/data')
                .then((res) => {
                    
                    this.model_province = res.data;
                }).finally(() => {});
            },
            async fetchKota()
            {
                this.group_regencies = [];
                this.model_regencies = [];
                await axios.post("location/kota/data", { where : { key : 'provinsi_id', value : this.model.id_provinces } })
                .then((response) => {
                    this.model_regencies = response.data;
                })
                .catch((error => {
                    console.log(error.statusText)
                })).finally(() => {
                    this.$store.state.loading.status = false;
                });
            },
            async submit(e) 
            {
                e.preventDefault();

                if( this.applying_regencies !== 'all' && this.group_regencies.length < 1){
                    alert('Please choose data regencies');
                    return;
                }

                this.$store.state.loading.msg = 'Processing submit data...';
                this.$store.state.loading.status = true;

                this.model.applying_regencies = this.applying_regencies;
                this.model.group_regencies = this.applying_regencies == 'all' ? 'all' : this.group_regencies;
                let data = { url: this.meta_url + '/store', callback_url:this.meta_url + '/data', form_data: this.model };
                let res = await this.$store.dispatch('crud/storeData', data );
                
                if(res && res.result == 'false'){
                    this.validate_duplicate = res.data_rejected;
                }
            }
        }
    }
</script>
<style scoped lang="scss">

ul.area-list{
    margin:0;
    padding:0;
    float:left;
    li{
      width:100%;
      float:left;
      list-style: none !important;
      border-bottom:1px solid #ececec;
      position:relative;
      padding:10px;
      div.form-check{
        float:left;
      }
      
      i.mdi-chevron-right{
        position:absolute;
        cursor: pointer !important;
        z-index: 2;
        right:10px;
        top:2px;
        font-size:20px;
        &:hover{
          color:gray;
        }
      }
    }
}
</style>



