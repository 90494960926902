<template>
  <div class="screen-app" id="initial-module" v-if="!isEnableSetup">
    <div class="msg">
      <i class="ti ti-package"></i>
      <h5>Preparation Activation Module</h5>
      <small>{{ task_status }}</small>
      <div class="form-group" v-if="isEnableProcess">
        <button
          class="btn btn-info-alt"
          v-if="isEnableReload"
          @click="setup_module()"
        >
          Try Again
        </button>
        <div class="progress-bar" v-else>
          <span v-bind:style="{ width: task_progress + '%' }"></span>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-primary mt-5" @click="closeApp()">
          Close Application
        </button>
      </div>
    </div>
    <div class="copyright">
      Copyrights &copy; 2019
      <span class="blue"> PT.Tukangbersih Indonesia</span> All Rights Reserved.
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "../../router";

import $ from "jquery";

export default {
  data() {
    return {
      isEnableProcess: true,
      isEnableSetup: localStorage.setItem("APP-START", true),
      isEnableReload: false,
      task_progress: 10,
      task_status: "Loading Configuration Client ...",
    };
  },
  mounted() {
    setTimeout(() => {
      this.setup_config();
    }, 2000);
  },
  methods: {
    closeApp() {
      this.$store.dispatch("authenticate/logout");
    },
    async setup_config() {
      this.isEnableReload = false;
      this.task_status = "Setup Server Configuration ... ";

      await axios
        .get("/setup/config")
        .then((result) => {
          localStorage.setItem("server-date", JSON.stringify(result.data.date));
          localStorage.setItem(
            "firebase-apikey",
            JSON.stringify(result.data.plugins.firebase_apikey)
          );
          this.task_progress = 30;
          this.setup_module();
        })
        .catch((error) => {
          if (!error.status) {
            // network error
            this.task_status = "Error Connection Network !";
            this.isEnableReload = true;
          } else {
            let data = Object.assign({}, error);
            if (data.response !== undefined && data.response.status !== 200) {
              this.task_status = "Opps, Something Wrong with server !";
              this.isEnableReload = true;
            }
          }
        });
    },

    async setup_module() {
      this.isEnableReload = false;
      this.task_status = "Installment package module ... ";

      await axios
        .post("/setup/module", {
          id_privileges: localStorage.getItem("userIdPrivileges"),
        })
        .then((result) => {
          let access = result.data;
          if (access.length > 0) {
            localStorage.setItem("user-access", JSON.stringify(result.data));
            this.task_progress = 70;
            this.setup_notification();
          } else {
            this.isEnableProcess = false;
            this.task_progress = 100;
            this.task_status =
              "Permission Denied !!, You dont have any access to application modules. Please contact administrator! ";
          }
        })
        .catch((error) => {
          if (!error.status) {
            // network error
            this.task_status = "Error Connection Network !";
            this.isEnableReload = true;
          } else {
            let data = Object.assign({}, error);
            if (data.response !== undefined && data.response.status !== 200) {
              this.task_status = "Opps, Something Wrong with server !";
              this.isEnableReload = true;
            }
          }
        });
    },

    async setup_notification() {
      this.task_status = "Configure sound notification ... ";
      await axios
        .post("/setup/notification", {
          userid: localStorage.getItem("userid"),
        })
        .then((result) => {
          localStorage.setItem("NOTIFICATION_SOUND", result.data);

          // done - ./
          this.task_status = "Preparation application start ....";
          this.task_progress = 80;

          this.setup_profile();
        })
        .catch((error) => {
          if (!error.status) {
            // network error
            this.task_status = "Error Connection Network !";
            this.isEnableReload = true;
          } else {
            let data = Object.assign({}, error);
            if (data.response !== undefined && data.response.status !== 200) {
              this.task_status = "Opps, Something Wrong with server !";
              this.isEnableReload = true;
            }
          }
        });
    },

    async setup_profile() {
      this.isEnableReload = false;
      this.task_status = "Preparation application start ....";

      await axios
        .post("/setup/profile", {
          userid: localStorage.getItem("userid"),
        })
        .then((result) => {
          localStorage.setItem("userName", result.data.name);
          localStorage.setItem("userPhoto", result.data.photo);
          localStorage.setItem("userEmail", result.data.email);

          let privileges_name = result.data.privileges_name;
          localStorage.setItem(
            "userPrivilegesName",
            privileges_name.toLowerCase()
          );
          localStorage.setItem("userPospointName", result.data.office_name);
          localStorage.setItem(
            "userContract",
            result.data.id_contract == "0" ? "all" : result.data.id_contract
          );
          // done - ./
          this.task_progress = 90;

          // emiter
          this.$emit("start");
        })
        .catch((error) => {
          if (!error.status) {
            // network error
            this.task_status = "Error Connection Network !";
            this.isEnableReload = true;
          } else {
            let data = Object.assign({}, error);
            if (data.response !== undefined && data.response.status !== 200) {
              this.task_status = "Opps, Something Wrong with server !";
              this.isEnableReload = true;
            }
          }
        });
    },
  },
};
</script>
<style lang="scss">
.screen-app {
  position: fixed;
  z-index: 99999;
  left: 0px;
  top: 0px;
  margin: 10px;
  padding: 20px;
  padding-top: 30vh;
  text-align: center;
  background-color: #fff;
  width: 100%;
  height: 100%;
  margin: auto;

  i {
    font-size: 80px;
    color: #1683ea;
  }
  h5 {
    margin-top: 30px;
  }
  small {
    color: gray;
    font-size: 12px;
  }
  .form-group {
    margin-top: 30px;
  }
  .progress-bar {
    height: 4px;
    width: 200px;
    margin: 0 auto;
    background-color: gray;
    span {
      background-color: #376afb;
      width: 20%;
      height: 4px;
    }
  }
}

.copyright {
  margin: 0 auto;
  font-size: 11px;
  color: #b7b7b7;
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, -50%);
  .blue {
    font-size: inherit;
    color: #0084fd;
  }
}
</style>
