<template>
        <div class="screen-app">
            <div class="msg">
                <i :class="meta_icon"></i>
                <h5>{{ meta_title }}</h5>
                <small>{{ meta_msg }}</small>
                 <div class="form-group">
                    <button class="btn btn-info-alt">
                        Syncronize API Supplier
                    </button>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    props: ['meta_title', 'meta_msg', 'meta_icon'],
    components: {
       
    }
}
</script>