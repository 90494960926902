<template>
    <div class="col-md-12 p-0">
        
        <div class="card">
            <div class="card-body">
                
                <form class="form-horizontal mt-lg" @submit="submit">
                    <div class="form-group">
                        <div class="col-sm-12 p-0 mb-3">
                             <div class="col-md-3" v-if="isEnableYear">
                                <label> <i class="ti ti-calendar text-primary"></i> Choose Year</label>
                                <select class="form-control " v-model="active_year" @change="queryData()" >
                                    <option :value="items" v-for="(items, idx) in year" :key="idx">{{ items }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-7">
                            <div>
                                <small class="text-secondary"><b class="text-primary">Kalendar Indonesia ( {{ active_year }} )</b>
                                
                                <hr/> 
                                 Ketentuan hari libur  berlaku pada hari yang ditandai warna merah.</small><br/><br/>
                                <small><i class="ti-control-record text-primary"></i> Hari Kerja/Biasa  <i class="ti-control-record text-danger"></i> Hari Libur</small>
                            </div>
                            
                            <div class="group-calendar">
                                <ul class="date-weekly">
                                    <li>
                                        <span>Senin</span>
                                    </li>
                                    <li>
                                        <span>Selasa</span>
                                    </li>
                                     <li>
                                        <span>Rabu</span>
                                    </li>
                                     <li>
                                        <span>Kamis</span>
                                    </li>
                                    <li>
                                        <span >Jumat</span>
                                    </li>
                                    <li >
                                        <span class="weekdays">Sabtu</span>
                                    </li>
                                    <li>
                                        <span class="weekdays">Minggu</span>
                                    </li>
                                </ul>
                            </div>
                          
                        </div>
                         <div class="col-sm-4">
                             <span class="text-secondary">DATE & DESCRIPTION</span>
                             <hr/>

                            <small class="text-secondary"><b>Choose Calendar</b></small>
                            <input type="date"   class="form-control mt-2"   id="calendar-date" >
                            <small class="text-danger" v-if="msg_error.date">{{ msg_error.date[0] }}</small>

                            <hr/>
                            <small class="text-secondary"><b>Description  Weekdays Date</b><br/> Nama yang di berlakukan untuk hari libur</small>
                            <input type="text" class="form-control"  placeholder="....."  v-model="model.title">
                            <small class="text-danger" v-if="msg_error.title">{{ msg_error.title[0] }}</small>
                            <hr/>
                            <button type="submit" class="btn btn-execute btn-fullscreen">SAVE CONFIGURE WEEKDAYS</button>

                        </div>
                    </div>
                    <hr/>
                    <div class="row p-3 mb-5">
                        <div class="col-md-4">
                            <p><i class="ti-calendar text-success"></i> January</p>
                            <ul  class="list-weekedays">
                                <li v-for="(items, idx) in data_model[0]" :key="idx"><i class="ti-control-record text-danger"></i>  <span class="date">{{ items.date | moment("DD") }}</span> <span class="title">{{ items.title }}</span> <i class="ti ti-trash remove-date" @click="removeData(items.id)"></i></li>
                            </ul>
                        </div>
                        <div class="col-md-4">
                            <p><i class="ti-calendar text-success"></i> Febuary</p>
                            <ul  class="list-weekedays">
                                <li v-for="(items, idx) in data_model[1]" :key="idx"><i class="ti-control-record text-danger"></i>  <span class="date">{{ items.date | moment("DD") }}</span> <span class="title">{{ items.title }}</span> <i class="ti ti-trash remove-date" @click="removeData(items.id)"></i></li>
                            </ul>
                        </div>
                         <div class="col-md-4">
                            <p><i class="ti-calendar text-success"></i> March</p>
                            <ul  class="list-weekedays">
                                <li v-for="(items, idx) in data_model[2]" :key="idx"><i class="ti-control-record text-danger"></i>  <span class="date">{{ items.date | moment("DD") }}</span> <span class="title">{{ items.title }}</span> <i class="ti ti-trash remove-date" @click="removeData(items.id)"></i></li>
                           </ul>
                        </div>
                         <div class="col-md-4 mt-5">
                            <p><i class="ti-calendar text-success"></i> April</p>
                            <ul  class="list-weekedays">
                                <li v-for="(items, idx) in data_model[3]" :key="idx"><i class="ti-control-record text-danger"></i>  <span class="date">{{ items.date | moment("DD") }}</span> <span class="title">{{ items.title }}</span> <i class="ti ti-trash remove-date" @click="removeData(items.id)"></i></li>
                            </ul>
                        </div>
                        <div class="col-md-4 mt-5">
                            <p><i class="ti-calendar text-success"></i> May</p>
                            <ul  class="list-weekedays">
                                <li v-for="(items, idx) in data_model[4]" :key="idx"><i class="ti-control-record text-danger"></i>  <span class="date">{{ items.date | moment("DD") }}</span> <span class="title">{{ items.title }}</span> <i class="ti ti-trash remove-date" @click="removeData(items.id)"></i></li>
                            </ul>
                        </div>
                        <div class="col-md-4 mt-5">
                            <p><i class="ti-calendar text-success"></i> June</p>
                            <ul  class="list-weekedays">
                                <li v-for="(items, idx) in data_model[5]" :key="idx"><i class="ti-control-record text-danger"></i>  <span class="date">{{ items.date | moment("DD") }}</span> <span class="title">{{ items.title }}</span> <i class="ti ti-trash remove-date" @click="removeData(items.id)"></i></li>
                            </ul>
                        </div>

                        <div class="col-md-4 mt-5">
                            <p><i class="ti-calendar text-success"></i> July</p>
                            <ul  class="list-weekedays">
                                <li v-for="(items, idx) in data_model[6]" :key="idx"><i class="ti-control-record text-danger"></i>  <span class="date">{{ items.date | moment("DD") }}</span> <span class="title">{{ items.title }}</span> <i class="ti ti-trash remove-date" @click="removeData(items.id)"></i></li>
                            </ul>
                        </div>
                        <div class="col-md-4 mt-5">
                            <p><i class="ti-calendar text-success"></i> August</p>
                            <ul  class="list-weekedays">
                                <li v-for="(items, idx) in data_model[7]" :key="idx"><i class="ti-control-record text-danger"></i>  <span class="date">{{ items.date | moment("DD") }}</span> <span class="title">{{ items.title }}</span> <i class="ti ti-trash remove-date" @click="removeData(items.id)"></i></li>
                            </ul>
                        </div>
                        <div class="col-md-4 mt-5">
                            <p><i class="ti-calendar text-success"></i> September</p>
                            <ul  class="list-weekedays">
                                <li v-for="(items, idx) in data_model[8]" :key="idx"><i class="ti-control-record text-danger"></i>  <span class="date">{{ items.date | moment("DD") }}</span> <span class="title">{{ items.title }}</span> <i class="ti ti-trash remove-date" @click="removeData(items.id)"></i></li>
                           </ul>
                        </div>

                        <div class="col-md-4 mt-5">
                            <p><i class="ti-calendar text-success"></i> October</p>
                            <ul  class="list-weekedays">
                                <li v-for="(items, idx) in data_model[9]" :key="idx"><i class="ti-control-record text-danger"></i>  <span class="date">{{ items.date | moment("DD") }}</span> <span class="title">{{ items.title }}</span> <i class="ti ti-trash remove-date" @click="removeData(items.id)"></i></li>
                           </ul>
                        </div>

                        <div class="col-md-4 mt-5">
                            <p><i class="ti-calendar text-success"></i> November</p>
                            <ul  class="list-weekedays">
                                <li v-for="(items, idx) in data_model[10]" :key="idx"><i class="ti-control-record text-danger"></i>  <span class="date">{{ items.date | moment("DD") }}</span> <span class="title">{{ items.title }}</span> <i class="ti ti-trash remove-date" @click="removeData(items.id)"></i></li>
                            </ul>
                        </div>

                        <div class="col-md-4 mt-5">
                            <p><i class="ti-calendar text-success"></i> December</p>
                            <ul  class="list-weekedays">
                                <li v-for="(items, idx) in data_model[11]" :key="idx"><i class="ti-control-record text-danger"></i>  <span class="date">{{ items.date | moment("DD") }}</span> <span class="title">{{ items.title }}</span> <i class="ti ti-trash remove-date" @click="removeData(items.id)"></i></li>
                            </ul>
                        </div>
                    </div>
                    
                </form>
            </div> 
        </div> 
    </div> 
</template>

<script>
    import { mapState } from 'vuex';
    import axios from 'axios';
    import router from '../../router';
    import MixServerDate from '../../mixin/mix_serverdate.js';

    import $ from 'jquery';

    export default 
    {   
        mixins: [MixServerDate],
        data() {
            return {
                isEnableYear : false,
                active_year : '',
                year:[],

                model: {},
              
            };
        },
        computed: {
            ...mapState('crud', {
                data_model: state => state.data_model,
                msg_error: state => state.error,
            }),
        },
        created() 
        {
            this.InitServerDate();
            this.generateYear();
            this.queryData();
        },
        methods: 
        {   
            generateYear()
            {
                let no = 0;
                for( var i = 2019; i <= this.active_year; i++ ){
                    this.year.push(i);
                    if(no == i){
                        this.year.push(i+1);
                    }
                    no++;
                    
                }
                this.isEnableYear = true;
            },
            
            InitServerDate()
            {
                this.year = [];
                this.active_year = this.MX_ServerDate('active_year');
            },

            async removeData(id)
            {
                if( confirm( 'Are you sure want to remove this data ?' ) )
                { 
                    let data = { url: 'calendar/destroy/'+id};
                    let res = await this.$store.dispatch('crud/deleteData', data );
                    if(res){
                        this.queryData();
                    }
                }
            },

            async queryData()
            {
                let data = { 
                    url: 'calendar/data/'+this.active_year,
                };
                this.$store.state.loading.msg = 'Processing Fetching Data...';
                this.$store.state.loading.status = true;
                await this.$store.dispatch('crud/getDataAll', data );
            },
            
            async submit(e) 
            {
                e.preventDefault();

                // convert calendar date
                var calendar_date = document.getElementById('calendar-date').value;
                if( calendar_date == '' ){
                    this.model.date = '';
                }else{
                    this.model.date  = this.$moment(calendar_date).format("YYYY-MM-DD");
                }
                
                this.$store.state.loading.msg = 'Processing Save Calendar...';
                this.$store.state.loading.status = true;
                
                let data = { url: 'calendar/setup', form_data: this.model };
                let res = await this.$store.dispatch('crud/storeData', data );
                if(res && res.result == 'ok')
                {
                    alert(res.msg);
                    this.model = {};
                    this.queryData();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
a.btn{
    position: relative;
    top:-17px;
}

.group-calendar{
    float:left;
    width:100%;
    margin-bottom:30px;
    .date-weekly{
        padding:0;
        margin:0;
        margin-top:10px;
        li{
            list-style: none;
            text-align: center;
            float:left;
            padding-top:13px;
            padding-bottom:14px;
            width:calc(100%/7);
            border-bottom:2px solid #8ac6ff;
            position: relative;
            span{
                color:gray;
            }
            span:after{
                content:'|';
                position: absolute;
                left: 50%;
                top: 35px;
                font-size: 10px;
                color: #cecece;
            }
            span:before{
                content:".";
                position:absolute;
                left:47%;
                top:26px;
                font-size:26px;
                color:#1d92ff;
            }
            span.weekdays:before{
                color:red;
            }
            
        }
    }
}



.list-weekedays{
    margin:0;
    float:left;
    width:100%;
    border-top:1px solid whitesmoke;
    padding-top:10px;
    li{
        float:none;
        padding-top:3px;
        padding-bottom:3px;
        margin:0px;
        color:gray;
        position:relative;
        list-style:none;
        span.date{
            color:#000;
            font-size:12px;
        }
        span.date:after{
            content:'-';
            font-size:14px;
            padding-left:3px;
            padding-right:3px;
        }
        i.remove-date{
            font-size:12px;
            position:absolute;
            right:-5px;
            top:8px;
            cursor:pointer;

        }
        i.remove-date:hover{
            color:red;
        }
        
    }
}
</style>
