<template>
  <div class="popup popup-lg">
    <div class="content">
      <small id="close-popup" @click="close()"
        ><i class="ti ti-close"></i
      ></small>
      <h5>
        Data Section Task <br /><small
          >Please select section task to continue process.</small
        >
        <hr />
      </h5>

      <div class="row mt-4" style="height: 450px; overflow-x: auto">
        <!-- <div class="table-cover"> -->
        <table>
          <thead>
            <tr>
              <th class="no-filter">Choose</th>
              <th class="no-filter">Image</th>
              <th class="no-filter">Notes</th>
              <th class="no-filter">Type</th>
            </tr>
          </thead>
          <tbody v-if="list_section_task_checkpoint.length > 0">
            <tr v-for="(item, key) in list_section_task_checkpoint" :key="key">
              <td>
                <a class="btn btn-success" @click="pickImg(item)">Pick</a>
              </td>
              <td style="vertical-align: top">
                <img :src="item.photo" width="150" />
              </td>
              <td style="vertical-align: top">
                {{ item.notes }} - (Pukul {{ item.time }})
              </td>
              <td style="vertical-align: top">{{ item.type }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="19">
                <i class="ti ti-info-alt text-danger"></i> Images in Section
                Task not found.
              </td>
            </tr>
          </tbody>
        </table>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
  
  <style scoped>
.pick-image-container:hover {
  cursor: pointer;
  transition: 0.5s ease;
  background-color: #008cba !important;
  color: white !important;
  text-decoration: none;
}
#pick-image-container-active {
  background-color: #008cba !important;
  color: white !important;
  text-decoration: none;
}
</style>
  <script>
export default {
  props: ["list_section_task_checkpoint"],
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {
    window.scrollTo(50, 0);
    // console.log(this.list_section_task_checkpoint);
  },
  methods: {
    close() {
      this.$emit("exit");
    },
    pickImg(item) {
      this.$emit("submitPickImage", item);
    },
  },
};
</script>
  