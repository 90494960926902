<template>
  <div class="popup popup-xl">
    <div class="content">
      <small id="close-popup" @click="close()"
        ><i class="ti ti-close"></i
      ></small>
      <h5>
        Data Section Task <br /><small
          >Please select section task to continue process.</small
        >
        <hr />
      </h5>
      <div class="row">
        <div class="col-md-12">
          <div class="table-header">
            <h5>
              Data Section Task <br />
              <small
                >Total Records :
                <b>{{
                  (model_img_before.length + model_img_after.length)
                    | formatPrice
                }}</b></small
              >
            </h5>
            <div class="group-search pull-right" style="margin: 25px 10px 0 0">
              <div style="width: 100px; float: left">
                <select
                  class="form-group"
                  v-model="filter_area"
                  @change="tableSearch()"
                  style="
                    padding: 5px;
                    margin-top: -7px;
                    border: 1px solid #007bff;
                    border-radius: 4px;
                  "
                >
                  <option value="all">-- All Area --</option>

                  <option v-for="(area, idx) in list_area" :key="idx">
                    {{ area }}
                  </option>
                </select>
              </div>
              <a class="btn btn-primary" @click="applyDataImg()">
                Apply Selected Images
                <div
                  v-if="gallery_image_type == 'BeforeAfter'"
                  class="badge badge-secondary font-weight-normal"
                >
                  {{
                    model_img_before_selected.length +
                    model_img_after_selected.length
                  }}
                </div>
                <div
                  v-if="gallery_image_type == 'Other'"
                  class="badge badge-secondary font-weight-normal"
                >
                  {{ model_img_other_selected.length }}
                </div>
              </a>
              <a class="btn" @click="tableRefresh()"
                ><i class="ti ti-reload"></i
              ></a>
              <div class="group-filter mb-3"></div>
            </div>
            <div>
              <!-- :disabled-date="disabledBeforeAfterFilterDatePeriodImg" range -->
              <date-picker
                v-model="filter_date_period_img"
                :default-value="filter_date_period_img"
                :clearable="false"
                value-type="format"
                type="date"
                format="DD/MM/YYYY"
                placeholder="Pilih Range Tanggal"
                width="100%"
                :disabled-date="disabledBeforeAfterFilterDatePeriodImg"
                @change="FilterModelDateImg()"
                confirm
              ></date-picker>
            </div>
          </div>
          <span style="font-weight: 600"
            >*Mohon Pilih 1 Gambar untuk tipe <i>Before</i> dan 1 tipe
            <i>After</i></span
          >
        </div>
      </div>

      <div v-if="gallery_image_type == 'BeforeAfter'" class="row mt-4">
        <div class="col-lg-6 mb-4">
          <span style="font-size: initial">Image Before</span>
        </div>
        <div class="col-lg-6 mb-4">
          <span style="font-size: initial">Image After</span>
        </div>
        <div class="col-lg-6" style="border-right: 1px solid #eee">
          <div v-if="model_img_before.length > 0">
            <div class="row" style="margin: -10px">
              <div
                style="padding: 2%; border: none"
                class="col-md-6 card"
                v-for="(item, idx) in model_img_before"
                :key="idx"
              >
                <div style="position: absolute; color: white; padding: 11px">
                  <label class="container-checkbox"
                    >Pick
                    <input
                      type="checkbox"
                      @change="selectBefore(idx)"
                      :id="'checkbox-before' + idx"
                      :checked="model_img_before_selected.includes(idx)"
                      :disabled="isCheckboxDisabled(idx, 'before')"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>

                <img
                  :src="item.photo"
                  class="card-img-top"
                  :alt="item.notes"
                  loading="lazy"
                />
                
                <div
                  class="card-body"
                  style="
                    padding: 3%;
                    border-left: 1px solid #eee;
                    border-right: 1px solid #eee;
                  "
                >
                  <h5
                    class="card-title"
                    style="
                      position: absolute;
                      bottom: 100px;
                      color: #ffffff;
                      padding: 12px;
                      left: 10px;
                      right: 11px;
                      background-color: rgb(34 32 32 / 30%);
                    "
                    v-if="item.notes"
                  >
                    {{ item.notes }}
                  </h5>
                  <p class="card-text">
                    Section {{ item.section }} - {{ item.area_type }} -
                    {{ item.area }}
                  </p>
                </div>
                <div
                  class="card-footer"
                  style="
                    padding: 3%;
                    border-bottom: 1px solid #eee;
                    border-left: 1px solid #eee;
                    border-right: 1px solid #eee;
                  "
                >
                  <small class="text-muted"
                    >Date work {{ item.date_work }} - (Pukul
                    {{ item.time }})</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div v-if="model_img_after.length > 0">
            <div class="row" style="margin: -10px">
              <div
                style="padding: 2%; border: none"
                class="col-md-6 card"
                v-for="(item, idx) in model_img_after"
                :key="idx"
              >
                <div style="position: absolute; color: white; padding: 11px">
                  <label class="container-checkbox"
                    >Pick
                    <input
                      type="checkbox"
                      @change="selectAfter(idx)"
                      :id="'checkbox-after' + idx"
                      :checked="model_img_after_selected.includes(idx)"
                      :disabled="isCheckboxDisabled(idx, 'after')"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>

                <img
                  :src="item.photo"
                  class="card-img-top"
                  :alt="item.notes"
                  loading="lazy"
                />
                <div
                  class="card-body"
                  style="
                    padding: 3%;
                    border-left: 1px solid #eee;
                    border-right: 1px solid #eee;
                  "
                >
                  <h5
                    class="card-title"
                    style="
                      position: absolute;
                      bottom: 100px;
                      color: #ffffff;
                      padding: 12px;
                      left: 10px;
                      right: 11px;
                      background-color: rgb(34 32 32 / 30%);
                    "
                    v-if="item.notes"
                  >
                    {{ item.notes }}
                  </h5>
                  <p class="card-text" style="padding: 2%">
                    Section {{ item.section }} - {{ item.area_type }} -
                    {{ item.area }}
                  </p>
                </div>
                <div
                  class="card-footer"
                  style="
                    padding: 3%;
                    border-bottom: 1px solid #eee;
                    border-left: 1px solid #eee;
                    border-right: 1px solid #eee;
                  "
                >
                  <small class="text-muted"
                    >Date work {{ item.date_work }} - (Pukul
                    {{ item.time }})</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="gallery_image_type == 'Other'" class="row mt-4">
        <div class="col-lg-12 mb-4">
          <span style="font-size: initial">Image Others</span>
        </div>
        <div class="col-lg-12">
          <div v-if="model_img_other.length > 0">
            <div class="row">
              <div
                style="border: none"
                class="col-md-4 mb-4 card"
                v-for="(item, idx) in model_img_other"
                :key="idx"
              >
                <div style="position: absolute; color: white; padding: 11px">
                  <label class="container-checkbox"
                    >Pick
                    <input
                      type="checkbox"
                      :id="'checkbox-other' + idx"
                      @change="selectOther(idx)"
                      :checked="model_img_after_selected.includes(idx)"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>

                <img
                  :src="item.photo"
                  class="card-img-top"
                  :alt="item.notes"
                  loading="lazy"
                />
                <div
                  class="card-body"
                  style="
                    padding: 3%;
                    border-left: 1px solid #eee;
                    border-right: 1px solid #eee;
                  "
                >
                  <h5
                    class="card-title"
                    style="
                      position: absolute;
                      bottom: 113px;
                      color: #ffffff;
                      padding: 12px;
                      left: 15px;
                      right: 15px;
                      background-color: rgb(34 32 32 / 30%);
                    "
                    v-if="item.notes"
                  >
                    {{ item.notes }}
                  </h5>
                  <p class="card-text" style="padding: 2%">
                    Section {{ item.section }} - {{ item.area_type }} -
                    {{ item.area }}
                  </p>
                </div>
                <div
                  class="card-footer"
                  style="
                    padding: 3%;
                    border-bottom: 1px solid #eee;
                    border-left: 1px solid #eee;
                    border-right: 1px solid #eee;
                  "
                >
                  <small class="text-muted"
                    >Date work {{ item.date_work }} - (Pukul
                    {{ item.time }})</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pick-image-container:hover {
  cursor: pointer;
  transition: 0.5s ease;
  background-color: #008cba !important;
  color: white !important;
  text-decoration: none;
}
#pick-image-container-active {
  background-color: #008cba !important;
  color: white !important;
  text-decoration: none;
}

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
<script>
import $ from "jquery";
import DatePicker from "vue2-datepicker";
export default {
  props: [
    "section_task",
    "filter_period_start_date",
    "filter_period_end_date",
    "gallery_image_type",
  ],
  components: {
    DatePicker,
  },
  data() {
    return {
      isEnableTools: false,
      model_img_before: [],
      model_img_after: [],
      model_img_other: [],
      model_img_before_selected: [],
      model_img_after_selected: [],
      model_img_other_selected: [],
      max_img_selected: 1,
      model: [],
      model_group: [],
      length_model_group: 0,
      model_collection: [],
      temp_model: [],
      activeBefore: false,
      filter_type: null,
      filter_area: "all",
      list_area: [],
      filter_date_period_img: null,
      new_filter_period_start_date: null,
      new_filter_period_end_date: null,
      new_filter_date_period_img: null,
    };
  },
  computed: {},
  created() {
    this.newMappindDataImages();
    // this.mappindDataImages();
    this.mappingDataArea();
    // console.log(this.filter_period_start_date);
    this.filter_date_period_img = this.filter_period_start_date;

    let newStartDate = this.filter_period_start_date.split("/");
    let newEndDate = this.filter_period_end_date.split("/");

    this.new_filter_period_start_date =
      newStartDate[2] + "-" + newStartDate[1] + "-" + newStartDate[0];
    this.new_filter_period_end_date =
      newEndDate[2] + "-" + newEndDate[1] + "-" + newEndDate[0];

    this.FilterModelDateImg();
  },

  methods: {
    newMappindDataImages() {
      if (this.gallery_image_type === "BeforeAfter") {
        for (let key in this.section_task) {
          if (this.section_task[key].type === "before") {
            this.model_img_before.push(this.section_task[key]);
          } else if (this.section_task[key].type === "after") {
            this.model_img_after.push(this.section_task[key]);
          }
        }
      } else {
        for (let key in this.section_task) {
          this.model_img_other.push(this.section_task[key]);
        }
      }
    },
    mappindDataImages() {
      if (this.list_check_images_before_after.length > 0) {
        if (this.image_type === "before" || this.image_type === "after") {
          for (let key in this.section_task) {
            // console.log("kesini")
            // console.log(this.section_task[key]);
            let iDidentity =
              this.section_task[key].id + "-" + this.section_task[key].time;
            let checkImageExist = this.validateImageSelected(iDidentity);
            if (!checkImageExist) {
              this.temp_model.push(this.section_task[key]);
            }
          }
          this.model = this.temp_model;
        } else {
          this.model = this.section_task;
        }
      } else {
        this.model = this.section_task;
      }
      // console.log(this.model);
    },
    mappingDataArea() {
      for (let key in this.section_task) {
        this.list_area.push(this.section_task[key].area);
      }
      this.list_area = [...new Set(this.list_area)];
    },
    disabledBeforeAfterFilterDatePeriodImg(date) {
      const start_date = new Date(this.new_filter_period_start_date);
      start_date.setHours(0, 0, 0, 0);

      const end_date = new Date(this.new_filter_period_end_date);
      end_date.setHours(0, 0, 0, 0);

      let dateNow = new Date(date.getFullYear(), date.getMonth());

      let dateStart = new Date(start_date.getFullYear(), start_date.getMonth());
      let dateEnd = new Date(end_date.getFullYear(), end_date.getMonth());

      return dateNow < dateStart || dateNow > dateEnd;
    },
    FilterModelDateImg() {
      let split_filter_date_period_img = this.filter_date_period_img.split("/");
      this.new_filter_date_period_img =
        split_filter_date_period_img[2] +
        "-" +
        split_filter_date_period_img[1] +
        "-" +
        split_filter_date_period_img[0];

      this.tableSearch();
    },
    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
    },
    tableSearch() {
      this.newMappindDataImages();

      if (this.gallery_image_type === "BeforeAfter") {
        let newModelImgBefore = this.model_img_before.filter((item) => {
          if (
            this.filter_area == "all" &&
            this.new_filter_date_period_img == item.date_work
          ) {
            return item;
          } else if (
            this.filter_area == item.area &&
            this.new_filter_date_period_img == item.date_work
          ) {
            return item;
          }
        });

        let newModelImgAfter = this.model_img_after.filter((item) => {
          if (
            this.filter_area == "all" &&
            this.new_filter_date_period_img == item.date_work
          ) {
            return item;
          } else if (
            this.filter_area == item.area &&
            this.new_filter_date_period_img == item.date_work
          ) {
            return item;
          }
        });

        this.model_img_before = newModelImgBefore;
        this.model_img_after = newModelImgAfter;
      } else {
        let newModelOther = this.model_img_other.filter((item) => {
          if (
            this.filter_area == "all" &&
            this.new_filter_date_period_img == item.date_work
          ) {
            return item;
          } else if (
            this.filter_area == item.area &&
            this.new_filter_date_period_img == item.date_work
          ) {
            return item;
          }
        });

        this.model_img_other = newModelOther;
      }
    },
    tableRefresh() {
      this.filter_type = "";
      this.filter_area = "all";
      this.FilterModelDateImg();
    },
    validateImageSelected(iDidentity) {
      const getExistImage = this.list_check_images_before_after.filter(
        (item) => item.id + "-" + item.time === iDidentity
      );

      if (getExistImage.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    close() {
      this.$emit("exit");
    },
    applyDataImg() {
      if (this.gallery_image_type === "BeforeAfter") {
        if (
          this.model_img_before_selected.length < 1 ||
          this.model_img_after_selected.length < 1
        ) {
          alert("Please choose some image !");
          return false;
        }

        this.$emit("exit");
        this.$emit(
          "applyData",
          this.model_img_before[this.model_img_before_selected],
          this.model_img_after[this.model_img_after_selected],
          null
        );

        this.model_img_before_selected = [];
        this.model_img_after_selected = [];
      } else {
        if (this.model_img_other_selected.length < 1) {
          alert("Please choose some image !");
          return false;
        }

        let dataImgOther = [];
        for (let key in this.model_img_other_selected) {
          let imgOther =
            this.model_img_other[this.model_img_other_selected[key]];
          dataImgOther.push(imgOther);
        }

        if (dataImgOther.length > 0) {
          this.$emit("exit");
          this.$emit("applyData", null, null, dataImgOther);

          this.model_img_other_selected = [];
        } else {
          alert("Please choose some image !");
          return false;
        }
      }
    },
    validateChecked(id) {
      var model = this.model_collection;
      return model.includes(id) ? true : false;
    },
    collectGroup(idx) {
      let model = this.model[idx];

      if ($("#checkbox-" + idx).is(":checked")) {
        // this.model_collection.push(model.id);

        if (this.model_group.length < 1) {
          this.model_group = [];
        }

        this.model_group.unshift(model);

        // validate selected images
        // if (this.image_type === "before" || this.image_type === "after") {
        //   if (this.model_group.length > 2) {
        //     alert("Images selectex max 2");
        //     $("#checkbox-" + model.id).prop("checked", false);
        //     this.disCollecGroup(idx);
        //   }
        // } else {
        //   if (this.model_group.length > 4) {
        //     alert("Images selectex max 4");
        //     $("#checkbox-" + model.id).prop("checked", false);
        //     this.disCollecGroup(idx);
        //   }
        // }
      } else {
        this.disCollecGroup(idx);
      }
    },
    disCollecGroup(idx) {
      let model = this.model[idx];
      // var model_collection = this.model_collection;
      // var index_collection = model_collection.indexOf(model.id);
      // this.model_collection.splice(index_collection, 1);

      var model_group = this.model_group;
      var no = 0;
      for (let key in model_group) {
        if (model_group[key].id == model.id) {
          this.model_group.splice(no, 1);
        }
        no++;
      }
    },
    selectBefore(idx) {
      // Jika checkbox telah dipilih sebelumnya, hapus dari array
      const index = this.model_img_before_selected.indexOf(idx);
      if (index !== -1) {
        this.model_img_before_selected.splice(index, 1);
      } else {
        // Checkbox belum dipilih sebelumnya, tambahkan ke array
        this.model_img_before_selected.push(idx);
      }
    },
    selectAfter(idx) {
      // Jika checkbox telah dipilih sebelumnya, hapus dari array
      const index = this.model_img_after_selected.indexOf(idx);
      if (index !== -1) {
        this.model_img_after_selected.splice(index, 1);
      } else {
        // Checkbox belum dipilih sebelumnya, tambahkan ke array
        this.model_img_after_selected.push(idx);
      }
    },
    selectOther(idx) {
      // this.model_img_other_selected.push(idx);
      const index = this.model_img_other_selected.indexOf(idx);
      if (index !== -1) {
        this.model_img_other_selected.splice(index, 1);
      } else {
        // Checkbox belum dipilih sebelumnya, tambahkan ke array
        this.model_img_other_selected.push(idx);
      }
    },
    isCheckboxDisabled(idx, type) {
      // Checkbox dinonaktifkan jika indeks checkbox sudah dipilih sebelumnya
      if (type == "before") {
        return (
          this.model_img_before_selected.length >= 1 &&
          !this.model_img_before_selected.includes(idx)
        );
      } else {
        return (
          this.model_img_after_selected.length >= 1 &&
          !this.model_img_after_selected.includes(idx)
        );
      }
    },
  },
};
</script>
