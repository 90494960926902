<template>
  <div class="dashboard">
    <div>
      <div
        v-if="
          isRoot ||
          isControlReport ||
          isOperationManager ||
          isBranchManager ||
          isSupervisor ||
          isAdminExecutive
        "
      >
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="notif">
              <span
                ><i class="ti ti-info-alt text-info"></i> Status Contract
                Station at the {{ meta_filter.name_branch }} Branch
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="box-draft">
              <h5><i class="ti ti-notepad"></i> Draft</h5>
              <h5>{{ status_contract.draft }}</h5>
              <div class="layer"></div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="box-on_going">
              <h5><i class="ti ti-control-shuffle"></i> On Going</h5>
              <h5>{{ status_contract.on_going }}</h5>
              <div class="layer"></div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="box-terminate">
              <h5><i class="ti ti-close"></i> Terminate</h5>
              <h5>{{ status_contract.terminate }}</h5>
              <div class="layer"></div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="box-finished">
              <h5><i class="ti ti-check-box"></i> Finished</h5>
              <h5>{{ status_contract.finished }}</h5>
              <div class="layer"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4 mb-4">
            <div class="table-cover" style="height: 650px">
              <table class="table-hover">
                <thead>
                  <tr>
                    <th
                      class="no-filter text-center"
                      style="cursor: default; width: 300px"
                    >
                      Client Name
                    </th>
                    <th class="no-filter text-center" style="cursor: default">
                      Contract Number
                    </th>
                    <th
                      class="no-filter text-center"
                      style="cursor: default; width: 165px"
                    >
                      Periode Contract
                    </th>
                    <th class="no-filter text-center" style="cursor: default">
                      CreatedAt Report
                    </th>
                    <th
                      class="no-filter text-center"
                      style="cursor: default; width: 97px"
                    >
                      Status Report
                    </th>
                    <th class="no-filter text-center" style="cursor: default">
                      Created By
                    </th>
                    <th class="no-filter text-center" style="cursor: default">
                      Approve By
                    </th>
                  </tr>
                </thead>
                <tbody v-if="model_contract_generate_report.length > 0">
                  <tr
                    v-for="(item, idx) in model_contract_generate_report"
                    :key="idx"
                  >
                    <template
                      v-if="
                        idx === 0 ||
                        item.client_name !==
                          model_contract_generate_report[idx - 1].client_name
                      "
                    >
                      <td
                        :rowspan="rowCount(item.client_name, idx)"
                        style="vertical-align: top"
                      >
                        {{ item.client_name }}
                      </td>
                    </template>
                    <template
                      v-if="
                        idx === 0 ||
                        item.contract_number !==
                          model_contract_generate_report[idx - 1]
                            .contract_number
                      "
                    >
                      <td
                        :rowspan="
                          rowCountContractNumber(item.contract_number, idx)
                        "
                        style="vertical-align: top"
                      >
                        {{ item.contract_number }}
                      </td>
                    </template>
                    <template
                      v-if="
                        idx === 0 ||
                        item.start_date !==
                          model_contract_generate_report[idx - 1].start_date ||
                        item.end_date !==
                          model_contract_generate_report[idx - 1].end_date
                      "
                    >
                      <td
                        :rowspan="
                          rowCountDate(item.start_date, item.end_date, idx)
                        "
                        style="vertical-align: top"
                      >
                        {{ item.start_date }} - {{ item.end_date }}
                      </td>
                    </template>
                    <template v-if="item.startdate_period">
                      <td>{{ item.startdate_period }}</td>
                      <td>
                        <span
                          v-if="item.report_status == 'draft'"
                          class="badge badge-warning"
                        >
                          {{ item.report_status }}
                        </span>
                        <span
                          v-if="item.report_status == 'publish'"
                          class="badge badge-success"
                        >
                          {{ item.report_status }}
                        </span>

                        <a
                          title="Detail Report Generate"
                          href="javascript:;"
                          @click="getDetailReport(item.custom_report_id)"
                          class="btn-info btn-sm ml-2 mr-2"
                          v-if="item.report_status == 'publish'"
                        >
                          <i class="ti ti-printer text-light"></i>
                        </a>

                        <span
                          v-if="item.report_status == 'revisi'"
                          class="badge badge-danger"
                        >
                          {{ item.report_status }}
                        </span>
                      </td>
                      <td>{{ item.created_by }}</td>
                      <td>{{ item.approve_by }}</td>
                    </template>
                    <template v-else>
                      <td
                        colspan="4"
                        class="text-center"
                        style="background-color: #ffc107"
                      >
                        Belum Membuat Generate Report
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div id="welcome" v-else>
        <h5>Hi, Welcome</h5>
        <div id="img-welcome"></div>
        <h6 class="blue mt-4">TBI Dashboard Station</h6>
        <p>Management project of contract, client base and employee.</p>
      </div>
      <!-- v-if="!isRoot && $store.state.app.access !== undefined" -->
      <!-- <div v-if="!isRoot && $store.state.app.access !== undefined">
        <div class="row">
          <div class="col-md-12 mb-3">
            <div class="row">
              <div class="col-md-3">
                <label>Client Station</label>
                <select
                  class="form-control"
                  v-model="selected_index_client"
                  @change="processStation()"
                >
                  <option value="">-- Choose Contract Station --</option>
                  <option
                    v-for="(items, idx) in model_contract"
                    :key="idx"
                    :value="idx"
                  >
                    {{ items.contract_number }} - {{ items.client_name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="box-info">
              <h5><i class="ti ti-location-pin"></i> Client Station</h5>
              <h6 v-if="default_contract.client_name">
                {{ default_contract.client_name }} <br /><small>{{
                  default_contract.office_address
                }}</small>
              </h6>
              <h6 v-else>Client data does not exist yet</h6>
              <div class="layer"></div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="box-info">
              <h5><i class="ti ti-user"></i> Housekeeping</h5>
              <span class="title float-left mt-2">Today will be attended</span>
              <span class="num"
                >{{ total_employee }} <small class="">HK</small></span
              >
              <div class="layer"></div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="box-info">
              <h5><i class="ti ti-calendar"></i> Shift Work - Today</h5>
              <div class="text-center" v-if="model_shift.length > 0">
                <span
                  class="float-right mr-3 title"
                  v-for="(items, idx) in model_shift"
                  :key="idx"
                  >{{ items }} <br /><span
                    :class="
                      items == 'Absent'
                        ? 'badge badge-danger'
                        : 'badge badge-warning'
                    "
                    >{{ GetTotalShift(items) }}</span
                  ></span
                >
              </div>
              <div class="text-center" v-else>
                <span class="float-left title mt-2"
                  >Data shift work does not exist yet</span
                >
              </div>
              <div class="layer"></div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="box-date">
              <span class="dt-day">{{ date.day }}</span>
              <span class="dt-year">{{ date.year }}</span>
              <span class="dt-month">{{
                date.month | convertStringMonth
              }}</span>
              <span class="dt-time" id="init-clock">00:00</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4 mb-4" v-if="default_contract.client_name">
            <div class="notif">
              <span
                ><i class="ti ti-info-alt text-danger"></i> This contract period
                will end on
                {{ default_contract.end_date | moment("DD/MM/YYYY") }}</span
              >
            </div>
          </div>
          <div class="col-md-5 mt-4">
            <div class="title-box">
              <h5>Client Section Area</h5>
            </div>
            <div class="box-list">
              <ul v-if="default_contract.client_name">
                <li v-for="(items, idx) in section" :key="idx">
                  <span class="text-secondary"># Section {{ idx }}</span
                  ><br />
                  <div class="row mt-2" v-for="(row, idx) in items" :key="idx">
                    <label class="col-sm-5"
                      ><i class="ti ti-control-record text-success"></i>
                      {{ row.area_name }}</label
                    >
                    <label class="col-sm-7 text-right">
                      Floor <b>{{ row.area_floor }} ( {{ row.area_type }} )</b>
                    </label>
                  </div>
                </li>
              </ul>
              <div class="empty-box" v-else>
                <div class="icon-room"></div>
                <h6>The data section is still empty,</h6>
                <h6>please input client station first.</h6>
              </div>
            </div>
          </div>
          <div class="col-md-7 mt-4">
            <div class="title-box">
              <h5>Employee Attendance</h5>
            </div>
            <div class="box-list">
              <ul v-if="model_employee.length > 0">
                <li v-for="(items, idx) in model_employee" :key="idx">
                  <div class="img">
                    <img
                      :src="$store.state.api_resource + items.photo"
                      class="table-picture-profile"
                      v-show="items.photo !== null"
                    />
                    <div
                      class="table-avatar"
                      v-show="items.photo == null"
                    ></div>
                  </div>
                  <div class="desc">
                    <span>{{ items.name }}</span
                    ><br />
                    <span class="text-secondary">{{ items.nik }}</span>
                  </div>
                  <div class="desc float-right">
                    <span class="text-danger">Out</span><br />
                    <span class="text-secondary">{{ items.out }}</span>
                  </div>
                  <div class="desc float-right mr-3">
                    <span class="text-success">In</span><br />
                    <span class="text-secondary">{{ items.in }}</span>
                  </div>
                  <div class="desc float-right mr-5">
                    <span>Shift</span><br />
                    <span class="text-secondary">{{ items.shift }}</span>
                  </div>
                </li>
              </ul>
              <div class="empty-box" v-else>
                <div class="icon-data"></div>
                <h6>Opps.., There is currently no attendance record.</h6>
                <h6>maybe some housekeeping are not present yet.</h6>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 
      <div id="welcome" v-else>
        <h5>Hi, Welcome </h5>
        <div id="img-welcome"></div>
        <h6 class="blue mt-4">TBI Dashboard Station</h6>
        <p>Management project of contract, client base and employee.</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "../../router";
import MixServerDate from "../../mixin/mix_serverdate.js";
import $ from "jquery";
import SelectBranch from "../Branch/@SelectBranch";

export default {
  mixins: [MixServerDate],
  components: {
    SelectBranch,
  },
  data() {
    return {
      isRoot: false,
      isControlReport: false,
      isOperationManager: false,
      isBranchManager: false,
      isSupervisor: false,
      isAdminExecutive: false,

      model_contract: [],
      selected_index_client: "",
      default_contract: {},
      status_contract: {
        draft: 0,
        on_going: 0,
        terminate: 0,
        finished: 0,
      },
      model_contract_generate_report: [],
      section: [],
      total_employee: 0,
      model_employee: [],

      model_shift: [],
      model_all_shift: [],

      date: { year: "", month: "", day: "" },
      meta_filter: { id_branch: null, name_branch: "" },
      meta_data: {
        okey: "created_at",
        oval: "desc",
        wkey: "",
        wval: "",
        skey: "",
        sval: "",
      },
    };
  },
  created() {
    this.InitServerDate();
    this.InitClock();
    //  this.$store.state.app = {start :'disable'};

    // filter branch
    this.isRoot = localStorage.getItem("userIdPrivileges") == 10 ? true : false;
    this.isControlReport =
      localStorage.getItem("userIdPrivileges") == 18 ? true : false;
    this.isOperationManager =
      localStorage.getItem("userIdPrivileges") == 11 ? true : false;
    this.isBranchManager =
      localStorage.getItem("userIdPrivileges") == 20 ? true : false;
    this.isSupervisor =
      localStorage.getItem("userIdPrivileges") == 12 ? true : false;
    this.isAdminExecutive =
      localStorage.getItem("userIdPrivileges") == 19 ? true : false;

    this.meta_filter.id_branch = localStorage.getItem("userBranchId");
    this.meta_filter.name_branch = localStorage.getItem("userBranchName");
    // this.InitialContractClient();
    this.QueryGetStatusContract();
    this.QueryCheckContractGenerateReport();
  },

  methods: {
    GetSpecialPrivileges() {
      let privileges = [11, 15];
      if (privileges.includes(this.model.id_privileges)) {
        return true;
      } else {
        return false;
      }
    },
    InitClock() {
      var today = new Date();
      var h = today.getHours();
      var m = today.getMinutes();
      var s = today.getSeconds();
      m = this.checkTime(m);
      s = this.checkTime(s);
      $("#init-clock").html(h + ":" + m + ":" + s);
      var t = setTimeout(this.InitClock, 500);
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      } // add zero in front of numbers < 10
      return i;
    },

    processStation() {
      this.default_contract = "";

      if (this.selected_index_client !== "") {
        this.default_contract = this.model_contract[this.selected_index_client];
        this.QueryContract();
        this.QueryAttendance();
      }
    },
    InitialContractClient(mode) {
      // reset
      this.total_employee = 0;
      this.model_shift = [];
      this.model_all_shift = [];

      let coll_contract = localStorage.getItem("userContract");
      axios
        .post("contract/all", {
          where: {
            key: "contract.id_branch",
            value: this.meta_filter.id_branch,
          },
        })
        .then((res) => {
          this.model_contract = res.data;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    InitServerDate() {
      this.year = [];
      this.date.year = this.MX_ServerDate("active_year");
      this.date.month = parseInt(this.MX_ServerDate("active_month"));
      this.date.day = this.MX_ServerDate("active_day");
    },
    GetTotalShift(shift_name) {
      let total = 0;
      for (let key in this.model_all_shift) {
        if (this.model_all_shift[key] == shift_name) {
          total++;
        }
      }

      return total;
    },
    GetDataSplitEmployee(initial, data) {
      let data_split = data.split("-");
      if (initial == "nik") {
        return data_split[0];
      }

      if (initial == "name") {
        return data_split[1];
      }
    },
    async QueryContract() {
      this.$store.state.loading.msg = "Query Detail Contract";
      this.$store.state.loading.status = false;
      await axios
        .get("contract/find/" + this.default_contract.id)
        .then((res) => {
          let query = res.data;
          this.section = query.section;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    async QueryAttendance() {
      this.$store.state.loading.msg = "Query Data...";
      this.$store.state.loading.status = true;
      await axios
        .post("report/attendance", {
          id_contract: this.selected_index_client,
          month: this.date.month,
          year: this.date.year,
        })
        .then((response) => {
          let query = response.data;

          let model_employee = query.employee;
          let final_model_employee = [];

          for (let key in model_employee) {
            for (let items in model_employee[key]) {
              let shift_name = model_employee[key][items].shift;

              if (
                parseInt(model_employee[key][items].day) == this.date.day &&
                parseInt(model_employee[key][items].month) == this.date.month &&
                parseInt(model_employee[key][items].year) == this.date.year
              ) {
                this.total_employee++;

                this.model_all_shift.push(shift_name);
                if (!this.model_shift.includes(shift_name)) {
                  this.model_shift.push(shift_name);
                }

                if (model_employee[key][items].in !== "00:00:00") {
                  let idx_name = key.split("-");
                  final_model_employee.push({
                    name: idx_name[1],
                    nik: model_employee[key][items].nik,
                    photo: model_employee[key][items].photo,
                    in: model_employee[key][items].in,
                    out: model_employee[key][items].out,
                    shift: model_employee[key][items].shift,
                  });
                }
              }
            }
          }

          this.model_employee = final_model_employee;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    async QueryGetStatusContract() {
      this.$store.state.loading.msg = "Query Status Contract";
      this.$store.state.loading.status = false;
      await axios
        .post("dashboard/count-status-contract", {
          branch_id: this.meta_filter.id_branch,
        })
        .then((res) => {
          let data = res.data;
          this.status_contract.draft = parseInt(data.draft_count);
          this.status_contract.on_going = parseInt(data.on_going_count);
          this.status_contract.terminate = parseInt(data.terminate_count);
          this.status_contract.finished = parseInt(data.finished_count);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    async QueryCheckContractGenerateReport() {
      this.$store.state.loading.msg = "Query Contract Generate Report";
      this.$store.state.loading.status = false;
      await axios
        .post("dashboard/check-contract-generate-report", {
          branch_id: this.meta_filter.id_branch,
        })
        .then((res) => {
          let data = res.data;
          this.model_contract_generate_report = data;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    getDetailReport(itemId) {
      localStorage.setItem("QUERY_ID", itemId);
      router.push({ name: "report/generate-report/detail" });
    },
    rowCount(clientName, currentIndex) {
      let count = 1;
      for (
        let i = currentIndex + 1;
        i < this.model_contract_generate_report.length;
        i++
      ) {
        if (clientName === this.model_contract_generate_report[i].client_name) {
          count++;
        } else {
          break;
        }
      }
      return count;
    },
    rowCountDate(startDate, endDate, currentIndex) {
      let count = 1;
      for (
        let i = currentIndex + 1;
        i < this.model_contract_generate_report.length;
        i++
      ) {
        if (
          startDate === this.model_contract_generate_report[i].start_date &&
          endDate === this.model_contract_generate_report[i].end_date
        ) {
          count++;
        } else {
          break;
        }
      }
      return count;
    },
    rowCountContractNumber(contractNumber, currentIndex) {
      let count = 1;
      for (
        let i = currentIndex + 1;
        i < this.model_contract_generate_report.length;
        i++
      ) {
        if (
          contractNumber ===
          this.model_contract_generate_report[i].contract_number
        ) {
          count++;
        } else {
          break;
        }
      }
      return count;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  float: left;
  width: 100%;
  padding-bottom: 200px;
  .title-box {
    float: left;
    width: 100%;
    h5 {
      font-size: 14px;
    }
    span {
      padding-top: 5px;
      font-size: 12px;
    }
  }
  .box-info {
    padding: 15px;
    width: 100%;
    height: 100px;
    overflow: hidden;
    float: left;
    background-color: #1b8bff;
    color: #fff;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    position: relative;
    .title {
      font-size: 12px;
    }
    div.layer {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: url("../../assets/img/bg-account.png") no-repeat;
      background-size: cover;
      z-index: 99;
    }
    h5 {
      i.ti {
        color: #b2dbff;
        font-size: 15px;
      }
    }
    span.total {
      position: relative;
      top: 5px;
    }
    span.num {
      float: right;
      font-size: 20px;
      small {
        font-size: 13px;
      }
    }
  }

  .box-draft {
    padding: 15px;
    width: 100%;
    height: 100px;
    overflow: hidden;
    float: left;
    background-color: #6c757d;
    color: #ffffff;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    position: relative;
    .title {
      font-size: 12px;
    }
    div.layer {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: url("../../assets/img/bg-account.png") no-repeat;
      background-size: cover;
      z-index: 99;
    }
    h5 {
      i.ti {
        color: #ffffff;
        font-size: 15px;
      }
    }
    span.total {
      position: relative;
      top: 5px;
    }
    span.num {
      float: right;
      font-size: 20px;
      small {
        font-size: 13px;
      }
    }
  }

  .box-on_going {
    padding: 15px;
    width: 100%;
    height: 100px;
    overflow: hidden;
    float: left;
    background-color: #28a745;
    color: #ffffff;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    position: relative;
    .title {
      font-size: 12px;
    }
    div.layer {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: url("../../assets/img/bg-account.png") no-repeat;
      background-size: cover;
      z-index: 99;
    }
    h5 {
      i.ti {
        color: #ffffff;
        font-size: 15px;
      }
    }
    span.total {
      position: relative;
      top: 5px;
    }
    span.num {
      float: right;
      font-size: 20px;
      small {
        font-size: 13px;
      }
    }
  }

  .box-terminate {
    padding: 15px;
    width: 100%;
    height: 100px;
    overflow: hidden;
    float: left;
    background-color: #dc3545;
    color: #ffffff;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    position: relative;
    .title {
      font-size: 12px;
    }
    div.layer {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: url("../../assets/img/bg-account.png") no-repeat;
      background-size: cover;
      z-index: 99;
    }
    h5 {
      i.ti {
        color: #ffffff;
        font-size: 15px;
      }
    }
    span.total {
      position: relative;
      top: 5px;
    }
    span.num {
      float: right;
      font-size: 20px;
      small {
        font-size: 13px;
      }
    }
  }

  .box-finished {
    padding: 15px;
    width: 100%;
    height: 100px;
    overflow: hidden;
    float: left;
    background-color: #007bff;
    color: #ffffff;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    position: relative;
    .title {
      font-size: 12px;
    }
    div.layer {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: url("../../assets/img/bg-account.png") no-repeat;
      background-size: cover;
      z-index: 99;
    }
    h5 {
      i.ti {
        color: #ffffff;
        font-size: 15px;
      }
    }
    span.total {
      position: relative;
      top: 5px;
    }
    span.num {
      float: right;
      font-size: 20px;
      small {
        font-size: 13px;
      }
    }
  }

  .box-date {
    position: relative;
    padding: 12px;
    .dt-day {
      position: relative;
      top: -7px;
      font-size: 45px;
      font-weight: bold;
      color: #3251a9;
    }
    .dt-month {
      position: relative;
      top: -8px;
      left: 5px;
    }
    .dt-year {
      color: gray;
      position: absolute;
      top: 20px;
      margin-left: 3px;
    }
    .dt-time {
      position: relative;
      top: -15px;
      left: 10px;
      float: left;
      width: 100%;
    }
  }

  .notif {
    padding: 10px;
    background-color: #e8e8e8;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
  }

  .box-list {
    padding: 20px 10px 20px 10px;
    background-color: #fff;
    float: left;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    width: 100%;
    height: 350px;
    overflow-y: scroll;
    div.empty-box {
      text-align: center;
      .icon-data {
        width: 300px;
        height: 300px;
        background: url("../../assets/img/empty.jpg") no-repeat;
        background-size: contain;
        margin: 0 auto;
      }
      .icon-room {
        width: 300px;
        height: 300px;
        background: url("../../assets/img/client.png") no-repeat;
        background-size: contain;
        margin: 0 auto;
      }
      h6 {
        font-size: 14px;
        color: gray;
        position: relative;
        top: -80px;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        padding: 10px;
        margin: 0;
        float: left;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;

        div.img {
          float: left;
          width: 40px;
          height: 40px;
          border-radius: 40px;
          -moz-border-radius: 40px;
          -webkit-border-radius: 40px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        div.desc {
          margin-left: 5px;
          float: left;
          padding: 2px;
        }
      }
    }
  }
}

#welcome {
  position: relative;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 80px;
  width: 350px;
  height: auto;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  text-align: center;

  h5 {
    margin: 5px;
    font-size: 20px;
    margin-bottom: 25px;
    text-align: center;
  }
  h6 {
    font-size: 16px;
  }
  p {
    color: gray;
  }
  #img-welcome {
    width: 222px;
    height: 155px;
    background: url("../../assets/img/analytics.png") no-repeat;
    background-size: contain;
    margin: 0 auto;
  }
}
</style>
