<template>
  <div>
    <nav class="navbar navbar-expand-md fixed-top bg-tb">
      <!-- popup multi regencies -->
      <div class="popup popup-sm popup-top" v-if="isEnablePopup">
        <div class="content">
          <a
            href="javascript:;"
            @click="isEnablePopup = false"
            class="btn float-right"
            type="button"
          >
            <span>X</span>
          </a>
          <h5>
            Branch Station <br /><small
              >Switch branch station to preview data.</small
            >
            <hr />
          </h5>
          <div v-if="model_branch.length > 1">
            <div class="row p-0">
              <div class="col-md-12">
                <p>
                  Branch options can change the data transfer between one branch
                  and another
                  <br />by selecting one of the following, all contract data,
                  employee, client and others can be displayed.
                </p>
                <div class="form-group">
                  <select class="form-control" v-model="active_index_branch">
                    <option value="none" disabled>-- Choose One --</option>
                    <option
                      v-for="(items, idx) in model_branch"
                      :key="idx"
                      :value="idx"
                    >
                      {{ items.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <hr />
              <div class="col-md-12">
                <a
                  href="javascript:;"
                  class="btn btn-primary float-right"
                  @click="SwitchBranch()"
                  >Swtich Change</a
                >
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row p-0">
              <div class="col-md-12">
                <p class="text-danger">
                  <i class="ti ti-alert text-secondary"></i> You don't have any
                  access to another branch station data.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ===== -->
      <router-link :to="{ name: '/' }" class="navbar-brand"
        ><img src="../../assets/img/logo.ecleaning.svg"
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="top-navbar">
        <a
          href="javascript:;"
          class="btn float-right btn-danger"
          @click="isEnablePopup = true"
          v-if="model.user_branch_name !== '' && model.privileges_id != 14"
          ><i class="ti-location-pin"></i>
          {{
            model.user_branch_name == "all"
              ? "All Branch"
              : model.user_branch_name
          }}
        </a>
        <span
          class="float-right delimiter mr-3 ml-3"
          v-if="model.user_branch_name !== ''"
          >|</span
        >
        <span class="float-right"
          ><i class="ti-bookmark text-success"></i>
          {{ model.privileges_name | capitalizeFirst }}</span
        >
        <span class="float-right delimiter mr-3 ml-3">|</span>
        <span class="float-right"
          ><i class="ti-user text-warning"></i> {{ model.user_name }}</span
        >
      </div>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <!-- <ul class="navbar-nav mr-5">
          <li class="nav-item">
            <span></span><br />
            <small class="text-primary"></small>
          </li>
        </ul> -->
        <ul class="navbar-nav mr-auto">
          <li
            class="nav-item mr-1"
            v-for="(items, idx) in data_model"
            :key="idx"
            v-if="
              items.parent.access !== 'block' &&
              items.parent.position == 'top-menu'
            "
          >
            <router-link
              :to="{ name: items.parent.action.view }"
              class="nav-link"
              v-if="!items.child"
            >
              <i :class="items.parent.icon"></i> {{ items.parent.name }}
              <br /><small class="caption">{{ items.parent.caption }}</small>
            </router-link>
            <a class="nav-link" href="javascript:;" v-if="items.child"
              ><i :class="items.parent.icon"></i>
              {{ items.parent.name }}
              <br /><small class="caption">{{ items.parent.caption }}</small>
              <div class="dropdown" v-if="items.child">
                <ul>
                  <li
                    v-for="(row, index) in items.child"
                    :key="index"
                    v-if="row.access !== 'block'"
                  >
                    <router-link :to="{ name: row.action.view }"
                      ><i :class="row.icon"></i> {{ row.name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </a>
          </li>
        </ul>
        <ul class="navbar-nav float-right">
          <li class="nav-item delimiter-right mr-3">
            <!--<a href="javascript:;"><i class="ti ti-bell notification active-notif" ></i></a>-->
            <Notification />
          </li>
          <li class="nav-item mr-5">
            <a class="nav-link" href="javascript:;">
              <img
                src="../../assets/img/user-avatar.png"
                class="photo-avatar"
              />
              <div class="dropdown" style="right: 55px">
                <ul>
                  <li>
                    <router-link :to="{ name: 'profile/data' }"
                      ><i class="ti ti-user"></i> Profile
                    </router-link>
                  </li>
                  <li>
                    <a href="javascript:;" @click="logout"
                      ><i class="ti ti-power-off"></i> Logout</a
                    >
                  </li>
                </ul>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";

import Fullscreen from "../../components/parts/Fullscreen";
import Refresh from "../../components/parts/Refresh";
import Notification from "../../components/parts/Notification";
import axios from "axios";
export default {
  components: {
    Fullscreen,
    Refresh,
    Notification,
  },
  data() {
    return {
      isEnablePopup: false,
      isEnableFullscreen: false,
      active_index_branch: "",
      model_branch: [],
      model: { privileges_name: "", user_name: "", user_branch_name: "" },
    };
  },
  computed: {
    ...mapState("mreg", {
      data_model: (state) => state.modules,
    }),
  },
  created() {
    this.$store.dispatch("mreg/setModules");
    this.model.privileges_name = localStorage.getItem("userPrivilegesName");
    this.model.privileges_id = localStorage.getItem("userIdPrivileges");
    this.model.user_name = localStorage.getItem("userName");

    // branch
    if (localStorage.getItem("userBranchName")) {
      this.model.user_branch_name = localStorage.getItem("userBranchName");
      this.active_branch = localStorage.getItem("userBranchId");
    }
    if (localStorage.getItem("userBranchData")) {
      this.model_branch = JSON.parse(localStorage.getItem("userBranchData"));
      for (let key in this.model_branch) {
        if (this.model_branch[key].id == localStorage.getItem("userBranchId")) {
          this.active_index_branch = key;
        }
      }
    }
  },

  methods: {
    async SwitchBranch() {
      let model = this.model_branch[this.active_index_branch];
      // regencies

      let data_branch = await this.$store.dispatch("crud/find", {
        url: "branch/find/" + model.id,
      });
      if (data_branch) {
        if (data_branch.applying_regencies == "selected-area") {
          localStorage.setItem("data_regencies", data_branch.group_regencies);
        } else {
          let data_regencies = await axios.post("location/kota/data", {
            where: { key: "provinsi_id", value: model.id_provinces },
          });
          if (data_regencies) {
            localStorage.setItem(
              "data_regencies",
              data_regencies.group_regencies
            );
          }
        }

        //alert(model.id+'=='+model.name);
        localStorage.setItem("userBranchId", model.id);
        localStorage.setItem("userBranchName", model.name);

        let history_back = "";
        switch (this.$route.name) {
          case "report/attendance-detail":
            history_back = "/report/attendance-data";
            break;
          case "report/checklist-area-detail":
            history_back = "/report/checklist-area-data";
            break;
          case "jobcon/contract-assign":
            history_back = "/jobcon/contract-data";
            break;
          case "jobcon/task":
            history_back = "/jobcon/contract-data";
            break;
          case "jobcon/shift":
            history_back = "/jobcon/contract-data";
            break;

          case "client/update":
            history_back = "/client/data";
            break;
          case "employee/update":
            history_back = "/employee/data";
            break;
        }

        this.isEnablePopup = false;
        if (history_back !== "") {
          this.$router.push({ path: history_back });
        }

        setTimeout(() => {
          window.location.reload();
        }, 200);
      }
    },

    logout() {
      this.$store.dispatch("authenticate/logout");
    },
  },
};
</script>

<style lang="scss">
.caption {
  font-size: 10px;
  color: gray;
}
.navbar {
  top: 39px !important;
}
.top-navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  padding: 5px;
  padding-left: 5%;
  padding-right: 9%;
  background-color: #0aaebf;
  height: 42px;

  i {
    position: relative;
    top: 1px;
  }
  span {
    color: #fff;
    position: relative;
    top: 5px;
  }
  span.delimiter {
    color: #425ead;
  }
  .btn {
    color: #fff;
    i {
      color: #fff;
    }
  }
}
.navbar {
  top: 30px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.popup-top {
  margin-top: 50px !important;
}
</style>
