<template>
  <div>
    <label class="text-secondary" v-if="title && title !== 'disable'"
      >Branch Station</label
    >
    <select class="form-control" v-model="id_branch" @change="ChooseBranch()">
      <option value="all">-- All Branch Station --</option>
      <option v-for="(items, idx) in model" :key="idx" :value="items.id">
        {{ items.name }}
      </option>
    </select>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["title", "default_id"],
  data() {
    return {
      model: [],
      id_branch: "all",
    };
  },
  created() {
    this.QueryBranch();
    if (this.default_id) {
      console.log("kesini kali ya");
      this.id_branch = this.default_id;
    } else {
      const roleUserPrivilegesName = ["root", "hrd nasional"];
      if (
        !roleUserPrivilegesName.includes(
          localStorage.getItem("userPrivilegesName")
        )
      ) {
        this.id_branch = localStorage.getItem("userBranchId");
      }

      //   if (localStorage.getItem("userIdPrivileges") != 10) {
      //     this.id_branch = localStorage.getItem("userBranchId");
      //   }
    }
  },
  methods: {
    ChooseBranch() {
      this.$emit("processBranch", this.id_branch);
    },
    async QueryBranch() {
      await axios
        .post("branch/data/all", {
          filter: [{ key: "branch_station.id", val: this.id_branch }],
        })
        .then((res) => {
          this.model = res.data;
        })
        .finally(() => {});
    },
  },
};
</script>