<template>
  <div class="popup popup-xl">
    <div class="content">
      <small id="close-popup" @click="close()"
        ><i class="ti ti-close"></i
      ></small>
      <h5>
        Data Section Task <br /><small
          >Please select section task to continue process.</small
        >
        <hr />
      </h5>

      <div
        v-if="list_section_task_checkpoint.length > 0"
        style="height: 550px; overflow-x: auto"
      >
        <div class="row" style="margin: -10px">
          <div
            style="border: none:"
            class="col-md-4 card"
            v-for="(item, key) in list_section_task_checkpoint"
            :key="key"
          >
            <img
              :src="item.photo"
              class="card-img-top"
              :alt="item.notes"
              loading="lazy"
            />

            <div
              style="position: absolute; color: white; right: 15px; top: 11px"
            >
              <a class="btn btn-success" @click="pickImg(item)">Pick</a>
            </div>

            <div
              style="
                position: absolute;
                top: 30px;
                background-color: rgba(34, 32, 32, 0.3);
                padding: 6px;
                color: rgb(255, 255, 255);
              "
            >
              {{ item.type }}
            </div>

            <div
              class="card-body"
              v-if="item.notes"
              style="
                padding: 3%;
                border-left: 1px solid #eee;
                border-right: 1px solid #eee;
                border-bottom: 1px solid #eee;
              "
            >
              <p class="card-text" style="padding: 2%">
                {{ item.notes }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row mt-4" style="height: 450px; overflow-x: auto">
        <table>
          <thead>
            <tr>
              <th class="no-filter">Choose</th>
              <th class="no-filter">Image</th>
              <th class="no-filter">Notes</th>
              <th class="no-filter">Type</th>
            </tr>
          </thead>
          <tbody v-if="list_section_task_checkpoint.length > 0">
            <tr v-for="(item, key) in list_section_task_checkpoint" :key="key">
              <td>
                <a class="btn btn-success" @click="pickImg(item)">Pick</a>
              </td>
              <td style="vertical-align: top">
                <img :src="item.photo" width="150" />
              </td>
              <td style="vertical-align: top">
                {{ item.notes }} - (Pukul {{ item.time }})
              </td>
              <td style="vertical-align: top">{{ item.type }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="19">
                <i class="ti ti-info-alt text-danger"></i> Images in Section
                Task not found.
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
  </div>
</template>
  
  <style scoped>
.pick-image-container:hover {
  cursor: pointer;
  transition: 0.5s ease;
  background-color: #008cba !important;
  color: white !important;
  text-decoration: none;
}
#pick-image-container-active {
  background-color: #008cba !important;
  color: white !important;
  text-decoration: none;
}
</style>
  <script>
export default {
  props: ["list_section_task_checkpoint"],
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {
    window.scrollTo(50, 0);
  },
  methods: {
    close() {
      this.$emit("exit");
    },
    pickImg(item) {
      this.$emit("submitPickImage", item);
    },
  },
};
</script>
  