import store from "../../modules/index";
const mreg = {
  namespaced: true,
  state: {
    access: localStorage.getItem("user-access"),
    modules: [
      // Dashborad
      {
        parent: {
          key: "dashboard",
          icon: "ti ti-home text-primary",
          name: "DASHBOARD",
          action: { view: "/" },
          position: "top-menu",
          caption: "Information Summary",
        },
      },
      // Settings
      {
        parent: {
          key: "settings",
          icon: "ti-settings text-primary",
          name: "CONFIG",
          position: "top-menu",
          caption: "Message, Notification",
        },
        child: [
          {
            key: "settings.notification",
            icon: "ti-bell text-primary",
            name: "Sound Notification",
            action: { view: "notification/sound" },
          },
          {
            key: "settings.message",
            icon: "ti-email text-primary",
            name: "Information Message",
            action: { view: "notification/message" },
          },
          {
            key: "settings.cron",
            icon: "ti-panel text-primary",
            name: "Engine Cron",
            action: { view: "notification/cron" },
          },
        ],
      },
      // Manage User
      {
        parent: {
          key: "manage_user",
          icon: "ti ti-user text-primary",
          name: "MANAGE USER",
          position: "top-menu",
          caption: "Manage User, Privileges Role",
        },
        child: [
          {
            key: "manage_user.application_user",
            icon: "ti-user text-primary",
            name: "Application User",
            action: {
              view: "user/data",
              insert: "user/insert",
              update: "user/update",
              delete: "user/delete",
            },
          },
          {
            key: "manage_user.privileges_group_level",
            icon: "ti-lock text-primary",
            name: "Privileges Group Level",
            action: {
              view: "privileges/data",
              insert: "privileges/insert",
              update: "privileges/update",
              delete: "privileges/delete",
              access: "default",
            },
          },
        ],
      },
      // HRD - Employement
      {
        parent: {
          key: "hrd",
          icon: "ti ti-clipboard text-primary",
          name: "HUMAN & RESOURCE",
          position: "top-menu",
          caption: "Employee, Job Position",
        },
        child: [
          {
            key: "hrd.job-position",
            icon: "ti-medall-alt text-primary",
            name: "Job Position",
            action: {
              view: "job-position/data",
              insert: "default",
              update: "default",
              delete: "default",
            },
          },
          {
            key: "hrd.employee",
            icon: "ti-user text-primary",
            name: "Employee",
            action: {
              view: "employee/data",
              insert: "employee/store",
              update: "employee/update",
              delete: "default",
              performance: "default",
              information: "default",
              resign: "default",
            },
          },
          {
            key: "hrd.branch",
            icon: "ti ti-map-alt text-primary",
            name: "Branch Station",
            action: {
              view: "branch/data",
              insert: "branch/store",
              update: "branch/store",
              delete: "default",
            },
          },
        ],
      },
      // Job Contract
      {
        parent: {
          key: "jobcon",
          icon: "ti ti-view-grid text-primary",
          name: "CONTRACT & CLIENT",
          position: "top-menu",
          caption: "Master Contract & Client",
        },
        child: [
          {
            key: "jobcon.contract",
            icon: "ti ti-server text-primary",
            name: "Master Contract",
            action: {
              view: "jobcon/contract-data",
              assign: "jobcon/contract-assign",
              shift_work: "jobcon/shift",
              job_task: "jobcon/task",
              terminate: "default",
              insert: "jobcon/contract-store",
              delete: "delete",
              print_qrcode: "jobcon/print-qrcode",
            },
          },
          {
            key: "jobcon.client",
            icon: "ti ti-server text-primary",
            name: "Client Base",
            action: {
              view: "client/data",
              insert: "client/store",
              update: "client/store",
              delete: "default",
            },
          },
        ],
      },
      // Report
      {
        parent: {
          key: "report",
          icon: "ti ti-bar-chart-alt text-primary",
          name: "REPORT",
          position: "top-menu",
          caption: "Attendance, Checklist Area",
        },
        child: [
          {
            key: "report.attendance",
            icon: "ti ti-calendar text-primary",
            name: "Attendance",
            action: {
              view: "report/attendance-data",
              detail: "report/attendance-detail",
            },
          },
          {
            key: "report.checklist_area",
            icon: "ti ti-check-box text-primary",
            name: "Checklist Area",
            action: {
              view: "report/checklist-area-data",
              detail: "report/checklist-area-detail",
            },
          },
          {
            key: "report.summary",
            icon: "ti ti-notepad text-primary",
            name: "Summary",
            action: {
              view: "report/summary-data",
              detail: "report/summary-data",
            },
          },
          {
            key: "report.generate_report",
            icon: "ti ti-pencil-alt text-primary",
            name: "Generate Report",
            action: {
              view: "report/generate-report",
              insert: "report/generate-report/create",
              detail: "report/generate-report/detail",
              update: "report/generate-report/detail",
            },
          },
        ],
      },
    ],
  },
  mutations: {
    FETCH_MODULES: (state) => {
      let modules = state.modules;
      let access = JSON.parse(state.access);

      let module_access = [];
      for (let act in access) {
        module_access.push(access[act].mkey);
      }

      for (let items in modules) {
        // for processing modules that have child
        if (modules[items].parent && modules[items].child) {
          for (let key in modules[items]) {
            // check parent
            var key_parent = modules[items].parent.key;
            if (!module_access.includes(key_parent)) {
              state.modules[items].parent.access = "block";
            }

            for (let key in modules[items].child) {
              var key_child = modules[items].child[key].key;
              var sp = key_child.split(".");
              var parent_key = sp[0];

              // check parent again for rewrite
              if (module_access.includes(key_child)) {
                state.modules[items].parent.access = "open";
              }

              // check child
              if (!module_access.includes(key_child)) {
                state.modules[items].child[key].access = "block";
              }
            }
          }

          for (let key in modules[items].child) {
            var key_child = modules[items].child[key].key;
            if (!module_access.includes(key_child)) {
              state.modules[items].child[key].access = "block";
            }
          }
        }

        // for process modules tha doesn't have any child
        if (modules[items].parent && !modules[items].child) {
          for (let key in modules[items].parent) {
            var key_parent = modules[items].parent.key;
            if (!module_access.includes(key_parent)) {
              state.modules[items].parent.access = "block";
            }
          }
        }
      }
    },

    ACTION: (state, payload) => {
      let modules = state.modules;
      let access = JSON.parse(state.access);

      // for access
      for (let act in access) {
        if (access[act].mkey == payload.mkey) {
          store.state.app.access = access[act].action;
        }
      }

      for (let items in modules) {
        for (let key in modules[items].parent) {
          // check - multi in parent

          if (modules[items].parent.key == payload.mkey) {
            store.state.app.url = modules[items].parent.action;
          }

          // check - multi in child && modules[items].child.key == payload.mkey
          if (modules[items].child) {
            let module_child = modules[items].child;
            for (let key in module_child) {
              if (modules[items].child[key].key == payload.mkey) {
                store.state.app.url = modules[items].child[key].action;
              }
            }
          }
        }
      }
    },
  },
  actions: {
    setModules({ commit }) {
      commit("FETCH_MODULES");
    },

    actionModules({ commit }, payload) {
      commit("ACTION", payload);
    },
  },
};

export default mreg;
