<template>
  <div class="col-md-12 p-0">
    <PopupMasterBranch
      v-on:exit="PopupClose"
      v-if="isEnablePopup == 'branch'"
      :id_privileges="model.id_privileges"
      v-on:applyData="applyBranch"
    />
    <PopupMasterContract
      v-on:exit="PopupClose"
      v-if="isEnablePopup == 'contract'"
      :id_privileges="model.id_privileges"
      :id_branch="model.id_branch"
      v-on:applyData="applyClient"
    />
    <!-- popup multi regencies -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'regencies'">
      <div class="content">
        <a
          href="javascript:;"
          @click="isEnablePopup = false"
          class="btn float-right"
          type="button"
          v-if="model_regencies.length < 1"
        >
          <span>X</span>
        </a>
        <h5>
          Master Data Regencies <br /><small>Please choose one data.</small>
          <hr />
        </h5>
        <div class="row p-0">
          <div class="col-md-12">
            <div class="scroll">
              <ul class="area-list" v-if="model_regencies.length > 0">
                <li v-for="(items, idx) in model_regencies" :key="idx">
                  <div class="form-check form-check-primary">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :checked="validateChecked(items.id)"
                        :id="'checkbox-' + items.id"
                        @change="
                          ChooseLocation($event, items.id, items.nama_kota)
                        "
                      />
                      {{ items.tipe }}.{{ items.nama_kota }}
                      <i class="input-helper"></i>
                    </label>
                  </div>
                </li>
              </ul>
              <div v-else>
                <span class="text-danger"
                  >To continues selected regencies please choose province
                  !</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3" v-if="model_regencies.length > 0">
          <hr />
          <div class="col-md-12">
            <a
              href="javascript:;"
              class="btn btn-primary float-right"
              @click="isEnablePopup = false"
              >Close & Continue</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- ===== -->

    <div class="card">
      <div class="card-body">
        <form class="form-horizontal mt-lg" @submit="submit">
          <div class="form-group">
            <label class="col-sm-3"
              >Privileges Group <small class="text-danger">*</small></label
            >
            <div class="col-sm-8">
              <select
                class="form-control"
                v-model="model.id_privileges"
                @change="ChoosePrivileges()"
              >
                <option value="">-- Choose One --</option>
                <option
                  v-for="(items, index) in model_privileges"
                  v-bind:key="index"
                  :value="items.id"
                >
                  {{ items.name }}
                </option>
              </select>
              <small class="text-danger" v-if="msg_error.id_privileges">{{
                msg_error.id_privileges[0]
              }}</small>
            </div>
          </div>

          <div class="form-group" v-if="model.id_privileges == 14">
            <label class="col-sm-3">Client</label>
            <div class="col-sm-8">
              <multiselect
                v-model="value_client"
                placeholder="Select One Client"
                label="name"
                track-by="code"
                :options="customers"
                @select="addTagCustomer"
              ></multiselect>
              <!-- <pre class="language-json"><code>{{ value_client  }}</code></pre> -->
            </div>
          </div>

          <div
            class="form-group"
            v-if="model.id_privileges == 14 && this.contracts.length > 0"
          >
            <label class="col-sm-3"> Contract </label>
            <div class="col-sm-8">
              <multiselect
                v-model="value_contract"
                tag-placeholder="Add this as new Contract"
                placeholder="Search or add a Contract"
                label="name"
                track-by="code"
                :options="contracts"
                :multiple="true"
                :taggable="true"
              ></multiselect>
              <!-- <pre
                class="language-json"
              ><code>{{ value_contract  }}</code></pre> -->
            </div>
          </div>

          <div
            class="form-group"
            v-if="
              model.id_privileges == 11 ||
              model.id_privileges == 12 ||
              model.id_privileges == 13 ||
              model.id_privileges == 15 ||
              model.id_privileges == 19 || 
              model.id_privileges == 20
            "
          >
            <label class="col-sm-3"
              >Branch Station <small class="text-danger">*</small></label
            >
            <div class="col-sm-8" v-if="isRoot">
              <a
                href="javascript:;"
                class="btn btn-execute"
                @click="isEnablePopup = 'branch'"
                ><i class="ti ti-server text-primary"></i>&nbsp; Browse Branch
                ...</a
              >
              <div class="list-station">
                <ul>
                  <li v-for="(items, idx) in model_branch" :key="idx">
                    <span class="">{{ items.name }}</span
                    ><br />
                    <span class="text-muted">{{ items.description }}</span>
                  </li>
                </ul>
              </div>
              <small class="text-danger mt-2" v-if="msg_error.id_branch"
                ><br />{{ msg_error.id_branch[0] }}</small
              >
            </div>
            <div class="col-sm-8" v-else>
              <input
                type="text"
                class="form-control"
                placeholder="...."
                v-model="branch_name"
                readonly
              />
              <small class="text-danger mt-2" v-if="msg_error.id_branch"
                ><br />{{ msg_error.id_branch[0] }}</small
              >
            </div>
          </div>
          <div class="form-group">
            <div
              class="mt-3"
              v-if="model.id_privileges == 12 || model.id_privileges == 13"
            >
              <label class="col-sm-3"
                >Client Station <small class="text-danger">*</small></label
              >
              <div class="col-sm-8">
                <a
                  href="javascript:;"
                  class="btn btn-execute"
                  @click="isEnablePopup = 'contract'"
                  ><i class="ti ti-server text-primary"></i>&nbsp; Browse
                  Contract ...</a
                >
                <div class="list-station">
                  <ul>
                    <li v-for="(items, idx) in model_contract" :key="idx">
                      <span class="">{{ items.client_name }}</span
                      ><br />
                      <span class="text-muted">{{ items.office_address }}</span>
                    </li>
                  </ul>
                </div>
                <small class="text-danger mt-2" v-if="msg_error.id_contract"
                  ><br />{{ msg_error.id_contract[0] }}</small
                >
              </div>
            </div>
          </div>
          <div
            class="form-group"
            v-if="
              first_privileges.includes(model.id_privileges) &&
              model.id_privileges !== 10
            "
          >
            <!-- Sales  -->
            <div v-if="model.id_privileges == 17">
              <label class="col-sm-3"
                >Province <small class="text-danger">*</small></label
              >
              <div class="col-sm-4">
                <select
                  v-model="model.id_provinces"
                  @change="fetchKota()"
                  class="form-control"
                >
                  <option disabled value="">-- Choose Province --</option>
                  <option
                    v-for="(items, idx) in model_province"
                    :value="items.id"
                    :key="idx"
                  >
                    {{ items.nama_provinsi }}
                  </option>
                </select>
                <small class="text-danger" v-if="msg_error.id_provinces">{{
                  msg_error.id_provinces[0]
                }}</small>
              </div>
            </div>

            <!-- Sales Manger -->
            <div v-if="model.id_privileges == 16">
              <label class="col-sm-3"
                >Province <small class="text-danger">*</small></label
              >
              <div class="col-sm-8">
                <select
                  v-model="model.id_provinces"
                  @change="fetchKota()"
                  class="form-control"
                >
                  <option disabled value="">-- Choose Province --</option>
                  <option
                    v-for="(items, idx) in model_province"
                    :value="items.id"
                    :key="idx"
                  >
                    {{ items.nama_provinsi }}
                  </option>
                </select>
                <small class="text-danger" v-if="msg_error.id_provinces">{{
                  msg_error.id_provinces[0]
                }}</small>
              </div>
              <div class="col-sm-3 mt-4">Regencies</div>
              <div class="col-sm-8">
                <a
                  href="javascript:;"
                  class="btn btn-execute mt-4"
                  @click="isEnablePopup = 'regencies'"
                  ><i class="mdi mdi-database-plus"></i> Browse Regencies ...</a
                >
                <br /><small class="text-danger" v-if="msg_error.id_regencies"
                  ><br />{{ msg_error.id_regencies[0] }}</small
                >
                <div class="p-2" v-if="group_regencies.length > 0">
                  <ul class="area-list">
                    <li v-for="(items, idx) in group_regencies" :key="idx">
                      {{ items.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="form-group">
            <label class="col-sm-3"> Nickname</label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                placeholder="...."
                v-model="model.name"
              />
              <small class="text-danger" v-if="msg_error.name">{{
                msg_error.name[0]
              }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3">Email Address</label>
            <small class="p-4"
              >Email address email will be use for authenticate "Username" when
              the user login.</small
            >
            <div class="col-sm-8">
              <input
                type="email"
                class="form-control"
                placeholder="...."
                v-model="model.email"
              />
              <small class="text-danger" v-if="msg_error.email">{{
                msg_error.email[0]
              }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3"> Default Password</label>
            <div class="col-sm-8">
              <password
                v-model="model.password"
                :toggle="true"
                @score="showScore"
                @feedback="showFeedback"
                defaultClass="form-control"
                name="password"
              />
              <small class="text-danger" v-if="msg_error.password">{{
                msg_error.password[0]
              }}</small>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3"> Confirm Default Password</label>
            <div class="col-sm-8">
              <password
                v-model="model.password_confirmation"
                :toggle="true"
                @score="showScore"
                @feedback="showFeedback"
                defaultClass="form-control"
                name="password"
                placeholder="Confirmation Password"
              />
              <small
                class="text-danger"
                v-if="msg_error.password_confirmation"
                >{{ msg_error.password_confirmation[0] }}</small
              >
            </div>
          </div>
          <hr />
          <div class="form-group">
            <div class="col-md-12 text-center">
              <a
                href="javascript:;"
                class="btn btn-secondary mr-3"
                @click="$router.go(-1)"
                >CANCEL</a
              >
              <button type="submit" class="btn btn-primary btn-full-screen">
                Submit Data
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Password from "vue-password-strength-meter";
import PopupMasterContract from "../JobContract/@PopupContract";
import PopupMasterBranch from "../Branch/@PopuMasterBranch";
import Multiselect from "vue-multiselect";
export default {
  components: { Password, PopupMasterContract, PopupMasterBranch, Multiselect },
  data() {
    return {
      isRoot: false,
      meta_url: "user",

      isEnablePopup: false,
      isEnableSelectStation: false,
      first_privileges: [10, 11, 16, 17],
      second_privileges: [12, 13, 14, 15],

      model_province: [],
      model_regencies: [],
      group_regencies: [],

      model: {
        id_branch: "",
        id_privileges: "",
        id_provinces: "",
        id_regencies: "",
      },
      model_privileges: [],
      model_branch: [],
      model_contract: [],
      branch_name: "",
      selectedCountries: [],
      customers: [],
      contracts: [],
      isLoading: false,
      value_client: [],
      value_contract: [],
    };
  },
  computed: {
    ...mapState("crud", {
      msg_error: (state) => state.error,
    }),
  },
  created() {
    if (localStorage.getItem("userPrivilegesName") == "root") {
      this.isRoot = true;
    } else {
      this.model.id_branch = parseInt(localStorage.getItem("userBranchId"));
      this.branch_name = localStorage.getItem("userBranchName");
    }
  },
  mounted() {
    this.QueryPrivileges();
    this.fetchProvince();
    this.getCustomer();
  },
  methods: {
    applyClient(data) {
      this.model_contract = data;
    },
    applyBranch(data) {
      this.model_branch = data;
      if (data.length == 1) {
        this.model.id_branch = data[0].id;
      }
    },
    PopupClose() {
      this.isEnablePopup = false;
    },
    ChoosePrivileges() {
      console.log(this.model.id_privileges);
      if (this.model.id_privileges == 17) {
        this.model.id_regencies = 0;
      }
      this.model_branch = [];
      this.model_contract = [];
    },

    ChooseLocation(e, id, name) {
      if (e.target.checked) {
        this.group_regencies.push({ id: id, name: name });
      } else {
        for (let key in this.group_regencies) {
          if (this.group_regencies[key].id == id) {
            this.group_regencies.splice(key, 1);
          }
        }
      }
    },

    validateChecked(id) {
      let model = this.group_regencies;
      for (let key in model) {
        if (model[key].id == id) {
          return true;
        }
      }
      return false;
    },
    async fetchProvince() {
      await axios
        .get("location/propinsi/data")
        .then((res) => {
          this.model_province = res.data;
        })
        .finally(() => {});
    },
    async fetchKota() {
      this.group_regencies = [];
      this.model_regencies = [];
      await axios
        .post("location/kota/data", {
          where: { key: "provinsi_id", value: this.model.id_provinces },
        })
        .then((response) => {
          this.model_regencies = response.data;
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },

    showFeedback({ suggestions, warning }) {
      console.log("🙏", suggestions);
    },
    showScore(score) {
      console.log("💯", score);
    },

    async QueryPrivileges() {
      await axios
        .get("privileges/all", {})
        .then((res) => {
          var model = res.data;
          this.model_privileges = model;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    async submit(e) {
      e.preventDefault();

      // processing id regencies
      if (this.model.id_privileges == 16 && this.group_regencies.length > 0) {
        let data_reg = [];
        for (let key in this.group_regencies) {
          data_reg.push(this.group_regencies[key].id);
        }
        this.model.id_regencies = data_reg;
      }

      // Operation Manager - Branch
      if (this.model.id_privileges == 11) {
        let mbranch = [];
        if (this.model_branch.length > 0) {
          for (let key in this.model_branch) {
            mbranch.push(this.model_branch[key].id);
          }
        }
        this.model.id_branch = mbranch.length > 0 ? mbranch : [];
      } else {
        this.model.id_branch = this.model.id_branch;
      }

      // processing collection client
      let mstation = [];
      if (this.model_contract.length > 0) {
        for (let key in this.model_contract) {
          mstation.push(this.model_contract[key].id);
        }
      }
      this.model.id_contract = mstation.length > 0 ? mstation : "";

      if (this.model.id_privileges == 14) {
        this.model.id_contract =
          this.value_contract.length > 0 ? this.value_contract : "";

        this.model.id_client = this.value_client.code
          ? this.value_client.code
          : "";
      }

      let data = {
        url: this.meta_url + "/store",
        callback_url: this.meta_url + "/data",
        form_data: this.model,
      };

      // console.log(data);
      await this.$store.dispatch("crud/storeData", data);
    },

    async getCustomer() {
      await axios.get("client/data-all", {}).then((res) => {
        let temp_customers = res.data;

        temp_customers.forEach((res) => {
          this.customers.push({
            name: res.name,
            code: res.id,
          });
        });
      });
    },
    async addTagCustomer(value) {
      await this.getContractByIdCustomer(value.code);
    },
    async getContractByIdCustomer(idCustomer) {
      await axios
        .post("client/contract/", {
          id_client: idCustomer,
        })
        .then((res) => {
          let temp_contract = res.data;
          temp_contract.forEach((res) => {
            this.contracts.push({
              name: res.contract_number,
              code: res.id,
            });
          });
        });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.list-station {
  width: 100%;
  float: left;
  margin-top: 15px;
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      float: left;
      width: 100%;
      border-top: 1px solid whitesmoke;
      padding: 10px 0px 17px 0px;
    }
  }
}

ul.area-list {
  margin: 0;
  padding: 0;
  float: left;
  overflow-y: scroll;
  li {
    width: 100%;
    float: left;
    list-style: none !important;
    border-bottom: 1px solid #ececec;
    position: relative;
    padding: 10px;
    div.form-check {
      float: left;
    }

    i.mdi-chevron-right {
      position: absolute;
      cursor: pointer !important;
      z-index: 2;
      right: 10px;
      top: 2px;
      font-size: 20px;
      &:hover {
        color: gray;
      }
    }
  }
}
</style>