<template>
    <div class="group-search pull-right">
        <a class="btn" @click="reload">
            <i class="ti ti-reload"></i>
        </a>
        <a class="btn" @click="isActiveTools()">
            <i :class="isEnableTools == false  ? 'ti ti-search' : 'ti ti-close'"></i>
        </a>
        <div class="select" v-if="isEnableTools">
            <select v-model="skey">
                <option value="">-- Pilih Kolom --</option>
                <option v-for="(items, idx) in meta_field" v-bind:key="idx" :value="items.key_alias ? items.key_alias : items.key" v-if="items.key !== ''">{{ items.value }}</option>
            </select>
        </div>
        <div class="group" v-if="isEnableTools">
            <input type="text" v-model="sval" placeholder="Masukan Keyword ...." autocomplete/>
            <a class="btn" @click="quickSearch">
                CARI DATA 
            </a>
        </div>  
        <router-link :to="{ name: meta_url + '-store' }" class="btn" v-if="$store.state.app.access.insert"><i class="ti ti-plus"></i> Tambah Data</router-link>
    </div>
</template>

<script>
 
export default {
    props: [ 'meta_url', 'meta_field'],
    data(){
        return {
            isEnableTools : false, 
            skey : '',
            sval : '',
        }
    },
    methods:
    {
        isActiveTools(){
            this.isEnableTools =! this.isEnableTools;
            if(this.isEnableTools) this.sval = '';
        },
        reload(){
            this.data = { url:this.meta_url+'-data', field : '', keyword : '' };
            this.$store.dispatch('crud/getData', this.data );
        },
        quickSearch()
        {
            if(this.skey == ''){
                alert('Silahkan pilih target kolom pencarian terlebih dahulu.');
                return;
            }
            if(this.sval == ''){
                alert('Silahkan isikan keyword terlebih dahulu.');
                return;
            }
            this.data = { url:this.meta_url+'-data', field : this.skey, keyword : this.sval };
            this.$store.dispatch('crud/getData', this.data );
        }
    }
}
</script>


<style lang="scss">
     div.group-search{
        position:relative;
        float: right;
        position: relative;
        margin-left: 5px;
        a.btn{
            top:-10px;
            margin-left:5px;
            box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
        }
        a:hover{
            box-shadow: none;
        }
        .btn{
            position:relative;
            top:-15px;
            float:right;
        }
        div.select{
            position:relative;
            top:-10px;
            margin-left:10px;
            width:185px;
            height:31px;
            border:none;
            float:right;
            background-color:#fff;
            border:1px solid #03a9f4;
            border-radius:0px;
            -moz-border-radius:0px;
            -webkit-border-radius:0px;
            select{
                width:100%;
                height:29px;
                border:none;
                position:relative;
                border-radius:0px;
                -moz-border-radius:0px;
                -webkit-border-radius:0px;
                background-color:transparent;
                outline: none;
            }
        }
        div.group{
            position:absolute;
            top:30px;
            z-index: 9;
            right:3px;
            padding:10px;
            border:none;
            float:right;
            background-color:#fff;
            border:1px solid #e4e4e4;
            border-radius:3px;
            -moz-border-radius:3px;
            -webkit-border-radius:3px;
            margin-bottom:20px;
            input{
                float:left;
                width:100%;
                outline:none;
                padding:10px;
                border:none;
                border-bottom:1px solid #d8d8d8;
                font-size:12px;
                margin-bottom:30px;
            }
            .btn{
                float:left;
                width:100%;
                right:4px;
            }
        }
    }
</style>

