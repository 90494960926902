<template>
    <div class="col-md-12 p-0">
        <div class="card">
            <div class="card-body">
                <form class="form-horizontal mt-lg" @submit="submit">
                    <div class="row">
                        <div class="col-md-3" v-if="!$store.state.loading.status">
                            <img :src="user_image" class="avatar-circle-image" v-if="model.photo">
                            <div class="col-md-12 text-center" v-else>
                                <div class="avatar-circle-alphabet" >
                                    <span class="initials">{{ model.name | firstChar }}</span>
                                </div>
                            </div>

                            <div class="col-md-12 mt-3 text-center">
                                <div class="form-group">
                                    <input type="file" class="input-file" accept="image/*"  @change="processFile($event)">
                                </div>
                            </div>
                            <hr/>
                        </div>
                        <div class="col-md-9">
                            <div class="form-group">
                                <label class="col-sm-3 ">Privilges Group</label>
                                <div class="col-sm-8">
                                    {{ model.privileges_name }}
                                </div>
                            </div>
                            <hr/>
                            <div class="form-group">
                                <label class="col-sm-3 "> Nickname</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control"  placeholder="...." v-model="model.name">
                                    <small class="text-danger" v-if="msg_error.name">{{ msg_error.name[0] }}</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3">Email Address</label>
                                <small class="p-4">Email address email will be use for authenticate "Username" when the user login.</small>
                                <div class="col-sm-8">
                                    <input type="email" class="form-control"  placeholder="...." v-model="model.email">
                                    <small class="text-danger" v-if="msg_error.email">{{ msg_error.email[0] }}</small>
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <label class="col-sm-3 "> Default Password</label>
                                <div class="col-sm-8">
                                    <password
                                        v-model="model.password"
                                        :toggle="true"
                                        defaultClass="form-control"
                                        name="password"
                                    />
                                    <small class="text-danger" v-if="msg_error.password">{{ msg_error.password[0] }}</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 "> Confirm Default Password</label>
                                <div class="col-sm-8">
                                    <password
                                        v-model="model.password_confirmation"
                                        :toggle="true"
                                        defaultClass="form-control"
                                        name="password"
                                        placeholder="Confirmation Password"
                                    />
                                    <small class="text-danger" v-if="msg_error.password_confirmation">{{ msg_error.password_confirmation[0] }}</small>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-11 p-0" >
                            <button type="submit" class="float-right btn btn-primary btn-execute">Submit</button>
                        </div>
                    </div>
                </form>
            </div> 
        </div> 
    </div> 
</template>

<script>
    import { mapState } from 'vuex';
    import Password from 'vue-password-strength-meter';

    export default 
    {   
        components: { Password },

        data() {
            return {
                password:'',
                meta_url : 'user',
            };
        },
        computed: {
            ...mapState('crud', {
                model: state => state.data_model,
                msg_error: state => state.error,
            }),
        },
        created()
        {
            this.$store.state.app = {
                title :'Profile',
                icon  : 'ti ti-user',
                breadcrumb : [
                    {name:'Data'}, 
                ],
            };

            let query = this.$store.dispatch('crud/find', {url:this.meta_url+'/find/'+localStorage.getItem('userid')});
        },
        mounted() 
        {
            setTimeout(() => {
                if( this.model.photo ){
                    this.user_image = this.model.photo;
                }
            }, 500);
        },
        methods: 
        {
            processFile(event)
            {
                var min_width  = 100;
                var min_height  = 100;

                this.file = event.target.files[0];
                if (event.target.files && event.target.files[0])
                {
                    let fileSize = event.target.files[0].size;
                    if( parseInt(fileSize) > this.max_image_size ){
                        alert('File image cannot more than 2 MB');
                    }else
                    {
                        let reader = new FileReader();
                        reader.onload = (event) =>
                        {
                            let img = new Image;
                            img.onload = () =>{

                                if( img.width < min_width && img.height < min_height )
                                {
                                    event.preventDefault();
                                    alert('Minimal ukuran dimensi image adalah : ' + min_width + 'x' + min_height);
                                    return false;
                                }else{
                                    this.user_image = event.target.result;
                                    // parsing for model 
                                    this.user_image = event.target.result;
                                }
                            }
                            img.src = event.target.result;
                        }
                        reader.readAsDataURL(event.target.files[0]);
                    }
                }
            },
            submit(e) 
            {
                e.preventDefault();
                let data = { url: this.meta_url + '/update/'+localStorage.getItem('userid'), callback_url:'profile/data/', form_data: this.model };
                this.$store.dispatch('crud/storeData', data );
            }
        }
    }
</script>

<style lang="scss" scoped>
.avatar-circle-image, .avatar-circle-image{
    width:120px;
    height:120px;
    border-radius:120px;
    -moz-border-radius:120px;
    -webkit-border-radius:120px;
}
</style>
