import axios from "axios";
import store from "../../modules/index";

const auth = {
  namespaced: true,
  state: {
    error: {},
  },
  mutations: {
    FETCH_DATA: (state, payload) => {
      if (payload.result == "false") {
        state.error = payload.msg;
      }
    },
  },
  actions: {
    logout() {
      localStorage.clear();
      window.location.href = "/";
    },

    async privileges({ commit }, payload) {
      store.state.loading.status = true;

      return await axios
        .get("auth/privileges")
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if (!error.status) {
            // network error
            store.state.loading.msg = "error_network";
          }
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },

    async login({ commit }, payload) {
      let app_id = "1555309664580";
      let api_secret = "4d672ce3-e422-4d8a-86ff-fabb1808a689";
      localStorage.setItem("userIdPrivileges", payload.id_privileges);

      store.state.loading.status = true;
      return await axios
        .post("auth/login", {
          id_privileges: payload.id_privileges,
          email: payload.email,
          password: payload.password,
          app_id: app_id,
          api_secret: api_secret,
        })
        .then((response) => {
          let res = response.data;
          commit("FETCH_DATA", res);
          
          if (res.result !== "false" && res.client_access == undefined) {
            return {
              form: res.next_step,
              data_branch: res.data_branch,
              auth: {
                userid: res.userid,
                token: res.token,
                app_session: store.state.app_session,
              },
            };
          }


          if (res.result !== "false" && res.client_access.length > 0) {
            return {
              form: res.next_step,
              data_branch: res.data_branch,
              client_access: res.client_access,
              auth: {
                userid: res.userid,
                token: res.token,
                app_session: store.state.app_session,
              },
            };
          }

        })
        .catch((error) => {
          if (!error.status) {
            // network error
            store.state.loading.msg = "error_network";
          }
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },

    async postmail({ commit }, payload) {
      store.state.loading.status = true;
      return await axios
        .post("postmail/pincode", {
          email: payload.email,
          name: payload.name,
          pincode: payload.pincode,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if (!error.status) {
            // network error
            store.state.loading.msg = "error_network";
          }
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },

    async forgot({ commit }, payload) {
      store.state.loading.status = true;
      return await axios
        .post("forgot", {
          email: payload.email,
        })
        .then((response) => {
          let model = response.data;
          if (model.result !== "true") {
            commit("FETCH_DATA", model);
          } else {
            return model;
          }
        })
        .catch((error) => {
          if (!error.status) {
            // network error
            store.state.loading.msg = "error_network";
          }
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },

    async check({ commit }, payload) {
      store.state.loading.status = true;

      return await axios
        .post("check", {
          email: payload.email,
          code: payload.code1 + payload.code2 + payload.code3 + payload.code4,
        })
        .then((response) => {
          let model = response.data;
          if (model.result !== "true") {
            commit("FETCH_DATA", model);
          } else {
            return model;
          }
        })
        .catch((error) => {
          if (!error.status) {
            // network error
            store.state.loading.msg = "error_network";
          }
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },

    async reset({ commit }, payload) {
      store.state.loading.status = true;

      return await axios
        .post("reset", {
          email: payload.email,
          pincode:
            payload.code1 + payload.code2 + payload.code3 + payload.code4,
          password: payload.password,
          password_confirmation: payload.password_confirmation,
        })
        .then((response) => {
          let model = response.data;
          if (model.result !== "true") {
            commit("FETCH_DATA", model);
          } else {
            return model;
          }
        })
        .catch((error) => {
          if (!error.status) {
            // network error
            store.state.loading.msg = "error_network";
          }
        })
        .finally(() => {
          store.state.loading.status = false;
        });
    },
  },
};

export default auth;
