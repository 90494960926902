<template>
    <div class="col-md-12 p-0">
         <div class="card">
            <div class="card-body">
                <div class="table-header">
                    <h5 >Master Data <br/> <small>Total Records : <b>{{ data_model.total | formatPrice }}</b></small></h5>
                    <div class="group-search pull-right">
                        <a class="btn" @click="tableRefresh()" ><i class="ti ti-reload"></i></a>
                        <a class="btn" @click="isActiveSearch()" ><i :class="isEnableTools == false  ? 'ti ti-search' : 'ti ti-close'"></i></a>
                        <div class="select" v-if="isEnableTools" >
                            <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                                <option value="">-- Choose Field --</option>
                                <option value="name">Branch Name</option>
                                <option value="description">Description</option>
                            </select>
                        </div>
                        <div class="group" v-if="isEnableTools">
                            <input type="date" v-model="meta_data.sval" v-if="meta_data.skey == 'created_at' || meta_data.skey == 'updated_at'"/>
                            <input type="text" v-model="meta_data.sval" placeholder="Enter Keyword ...." v-else/>
                            <a class="btn" @click="tableSearch()">
                                SEARCH DATA 
                            </a>
                        </div>  
                        <router-link :to="{ name: meta_url + '/store' }" class="btn btn-primary" v-if="$store.state.app.access.includes('insert')"><i class="ti ti-plus"></i> Create New</router-link>
                    </div>
                    <div class="group-filter">
                         <div class="form-group mr-2">
                                <label class="text-secondary">Propinsi</label>
                                <select  class="form-control" v-model="meta_filter.id_provinces"  @change="fetchKota()" style="max-width:200px;">
                                    <option value="all">-- All Propinsi --</option>
                                    <option v-for="(items, idx) in model_province"  :key="idx" :value="items.id">{{ items.nama_provinsi }}</option>
                                </select>
                            </div>
                            <!--<div class="form-group" style="width:200px;">
                                <label class="text-secondary">Kota</label>
                                <select  class="form-control" v-model="meta_filter.id_regencies"  @change="tableQuery()">
                                    <option value="all">-- All Kota --</option>
                                    <option v-for="(items, idx) in model_kota"  :key="idx" :value="items.id">{{ items.nama_kota }}</option>
                                </select>
                            </div>-->
                        </div>
                    </div>
                </div>
                <div class="table-cover">
                    <table class="table-hover" style="width:1800px;">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th width="70" class="no-filter text-center"  v-if="JSON.parse($store.state.app.access).length > 1">Action</th>
                                <th :class="meta_data.okey == 'name' ? 'active' : ''" v-on:click="tableOrdering('name')">Branch Station Name</th>
                                <th :class="meta_data.okey == 'id_provinces' ? 'active' : ''" v-on:click="tableOrdering('id_provinces')">Propinsi</th>
                                <th :class="meta_data.okey == 'group_regencies' ? 'active' : ''" v-on:click="tableOrdering('group_regencies')">Kota/Kabupaten</th>
                                <th :class="meta_data.okey == 'description' ? 'active' : ''" v-on:click="tableOrdering('description')">Branch Description</th>
                                <th :class="meta_data.okey == 'created_at' ? 'active' : ''" v-on:click="tableOrdering('created_at')">Created Date</th>
                                <th :class="meta_data.okey == 'updated_at' ? 'active' : ''" v-on:click="tableOrdering('updated_at')">Updated Date</th>
                            </tr>
                        </thead>
                        <tbody v-if="data_model.total > 0">
                             <tr  v-for="(items, idx) in data_model.data" :key="idx">
                                <td>{{ idx + data_model.from }}</td>
                                 <td  v-if="JSON.parse($store.state.app.access).length > 1"> 
                                    <div class="tools">
                                        <a href="javascript:;">Tools</a>
                                        <ul>
                                            <li  v-if="$store.state.app.access.includes('update')">
                                                <a href="javascript:;" @click="tableUpdate(items.id)" ><i class="text-success ti ti-pencil"></i> Edit</a>
                                            </li>
                                            <li  v-if="$store.state.app.access.includes('delete')">
                                                <a href="javascript:;" @click="tableRemove(items.id)"><i class="text-danger ti ti-trash"></i> Remove</a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td width="250"> {{ items.name }}</td>
                                <td width="150"> {{ items.nama_provinsi }}</td>
                                <td width="550"> 
                                    <div v-if="items.applying_regencies == 'selected-area'">
                                        <span class="btn btn-light m-1" v-for="(items_area, idx) in DataDecode(items.group_regencies)" :key="idx" >{{ items_area.name }} </span>
                                    </div>
                                    <div v-else>
                                        <span class="btn btn-light m-1">All Area Regencies</span>
                                    </div>
                                </td>
                                <td width="350"> {{ items.description }}</td>
                                <td>{{ items.created_at | moment("DD/MM/YYYY") }}</td>
                                <td>{{ items.updated_at | moment("DD/MM/YYYY") }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="pagination" >
                    <small class="info"><i class="ti ti-split-h"></i> Scroll horizontal to view data.</small>
                    <paginate
                    :page-count="parseInt(data_model.last_page)"
                    :click-handler="tableQuery"
                    :container-class="'pagination'"
                    :page-class="'page-item'" >
                    </paginate>
                </div>
        </div>
    </div>
</template>
<script>

    import { mapState } from 'vuex';
    import axios from 'axios';

    export default {
        data() 
        {
            return { 
                isEnableTools : false, 
                isEnableOrder : false,
                meta_url : 'branch',
                
                model_province : [],
                model_kota : [],
                meta_data :{ okey: 'id', oval:'desc', wkey : '', wval : '', skey : '', sval : ''},
                meta_filter :{  id_provinces : 'all', id_regencies : 'all', }

            }
        },
       
        computed: {
            ...mapState('crud', {
                data_model: state => state.data_model,
                msg_error: state => state.error,
            })
        },
        created()
        {
            this.tableQuery();
            this.fetchProvinces();
        },
        methods:
        {
            
            isActiveSearch()
            {
                this.isEnableTools =! this.isEnableTools;
                if(this.isEnableTools) this.meta_data.sval = '';
            },
            DataDecode(data)
            {
                if(data)
                return JSON.parse(data);
            },
            async fetchProvinces()
            {
                await axios.get("location/propinsi/data")
                .then((response) => {
                    this.model_province = response.data;
                    this.model_kota = [];
                })
                .catch((error => {
                    console.log(error.statusText)
                })).finally(() => {
                    this.$store.state.loading.status = false;
                });
            },
            async fetchKota()
            {
                this.meta_filter.id_regencies      = 'all'; 
                this.meta_filter.id_kecamatan = 'all'; 
                await axios.post("location/kota/data", { 
                    where : { key : 'provinsi_id', value : this.meta_filter.id_provinces } ,
                    order : { key : 'id', value : 'asc' },
                })
                .then((response) => {
                    this.model_kota = response.data;
                    this.tableQuery();
                })
                .catch((error => {
                })).finally(() => {
                    this.$store.state.loading.status = false;
                });
            },
            tableUpdate(id)
            {
                localStorage.setItem('QUERY_ID', id);
                this.$router.push({name : this.meta_url + '/update'});
            },
            tableSearch()
            {
                if(this.meta_data.skey == ''){
                    alert('🙏, Please choose target field');
                    return;
                }
                if(this.meta_data.sval == ''){
                    alert('🙏, Please enter the keyword');
                    return;
                }
                this.tableQuery();
            },
            tableOrdering(key)
            {
                this.isEnableOrder =! this.isEnableOrder;
                this.meta_data.okey = key;
                this.meta_data.oval = this.isEnableOrder ? 'ASC' : 'DESC';
                this.tableQuery(this.page);
            },
            tableQuery(pageNum)
            {
                this.page = pageNum;
                let data = { 
                    url: this.meta_url+'/data',
                    offset : this.page,
                    search: { key : this.meta_data.skey, value : this.meta_data.sval },
                    order: { key : this.meta_data.okey, value : this.meta_data.oval },
                    where : { key : this.meta_data.wkey, value : this.meta_data.wval },
                    filter : [
                        {key : 'id_provinces', val:this.meta_filter.id_provinces},
                        {key : 'id_regencies', val:this.meta_filter.id_regencies},
                    ]
                };

                this.$store.state.loading.msg = 'Processing Fetching Data...';
                this.$store.state.loading.status = true;
                this.$store.dispatch('crud/getData', data );
            },  
            tableRefresh()
            {
                this.meta_data.skey = '';
                this.meta_data.sval = '';
                this.tableQuery();
            },

            async tableRemove(id)
            {
                if( confirm( 'Are you sure want to remove this data ?' ) )
                { 
                    let data = { url: this.meta_url+'/destroy/'+id };
                    let res = await this.$store.dispatch('crud/deleteData', data );
                    if(res)
                    {
                        if( res.result == 'ok' ){
                            this.tableQuery();
                        }
                        if( res.result == 'false' ){
                            alert(res.msg);
                        }
                    }
                }
            }
        }
    }
</script>