<template>
  <div class="col-md-12 p-0">
    <div class="card">
      <div class="card-body" v-if="!isLoading">
        <div class="form-group">
          <div class="row p-2">
            <div class="col-md-8 p-3 pl-4">
              <p>
                <span
                  :class="model_contract.status | contractStatus"
                  style="font-size: 12px"
                  >{{ model_contract.status | cleanString }}</span
                >
              </p>
              <h5>{{ model_contract.client_name }}</h5>
              <p>
                <span class="text-secondary">{{ model_contract.email }}</span
                ><br /><span class="">{{
                  model_contract.phone_number | formatPhone
                }}</span>
              </p>
              <p>
                {{ model_contract.office_address }} <br />
                {{ model_contract.nama_provinsi }},
                {{ model_contract.nama_kota }}
              </p>
            </div>
            <div class="col-md-4">
              <ul class="list-area">
                <li>
                  <span class="">Contract Number</span>
                  <span class="float-right"
                    ><b style="font-size: 16px"
                      ># {{ model_contract.contract_number }}</b
                    ></span
                  >
                </li>
                <li>
                  <span class="">Contract Period</span>
                  <span class="float-right"
                    ><b
                      >{{ model_contract.start_date | moment("DD/MM/YYYY") }} -
                      {{ model_contract.end_date | moment("DD/MM/YYYY") }}</b
                    ></span
                  >
                </li>
                <li v-if="model_contract.man_power !== undefined">
                  <span class="">MP Request</span><br /><br />
                  <span
                    class="text-muted"
                    v-for="(initems, index) in JSON.parse(
                      model_contract.man_power
                    )"
                    :key="index"
                  >
                    {{ initems.total }} ( {{ initems.job }} )
                    <span v-if="index % 2 == 0">,</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-12">
            <div class="float-left">
              <label class="text-primary mb-0"
                ><i class="ti ti-info-alt"></i> Schedule Shift Work</label
              ><br />
              <small class="text-secondary"
                >Last Update :
                {{ shift_last_updated | moment("DD/MM/YYYY HH:mm") }}</small
              >
            </div>
            <div class="float-right">
              <!--<select class="form-control" v-model="filter_shift" @change="FilterShiftPeriod()" style="width:200px;">
                                <option value="all">All Month</option>
                                <option v-for="(items, index) in collection_month" :key="index" :value="items" >{{ items }}</option>
                            </select>-->
              <!-- disable modify rudy -->
              <!-- <select class="form-control" v-model="filter_date"  @change="FilterModelDate()" style="width:200px;">
                                <option v-for="(items, index) in collection_month" :key="index" :value="items" >{{ items }}</option>
                            </select> -->
              <date-picker
                v-model="filter_date"
                :default-value="filter_date"
                :disabled-date="disabledBeforeAfterFilterDate"
                :clearable="false"
                @change="FilterModelDate()"
                value-type="format"
                type="month"
                format="MM/YYYY"
                placeholder="Pilih Range Tanggal"
                width="100%"
                confirm
              >
              </date-picker>
            </div>
          </div>
          <div class="clearfix"></div>
          <hr />
        </div>
        <div class="row" v-if="modelContractEmployees.length > 0">
          <div class="col-sm-12 mb-5">
            <div class="table-cover">
              <table>
                <thead>
                  <!-- <tr>
                    <th class="no-filter text-center text-danger" colspan="3">
                      Officer Client Base
                    </th>
                    <th
                      class="no-filter text-center"
                      v-for="(items, idx) in model_shift_hour"
                      :key="idx"
                      rowspan="2"
                    >
                      <span class="" style="text-transform: capitalize"
                        >{{ items.name }} - {{ items.hour }}</span
                      >
                    </th>
                  </tr> -->
                  <tr style="height: 45px">
                    <th class="no-filter text-center" width="50">#</th>
                    <!-- <th class="no-filter" width="80">Action</th>                                  -->
                    <th class="no-filter" width="100">Nik</th>
                    <th class="no-filter" colspan="2">Nama Petugas</th>
                    <!-- <th :colspan="model_shift_hour.length" class="text-center">
                      Total
                    </th>
                    <th
                      class="no-filter text-center"
                      v-for="(items, idx) in model_shift_hour"
                      :key="idx"
                    >
                      <span class="text-primary">{{ items.hour }}</span>
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, idxEmpl) in modelContractEmployees">
                    <tr
                      :key="idxEmpl"
                      :class="{ openedhighlight: opened.includes(item.nik) }"
                    >
                      <td class="tdp text-center">
                        <a
                          class="text-primary text-center"
                          @click="selectRowEmployee(item.nik)"
                          style="cursor: pointer"
                        >
                          <i class="ti ti-zoom-in" style="font-size: 18px"></i>
                        </a>
                      </td>
                      <td class="tdp">{{ item.nik }}</td>
                      <td class="tdp">
                        {{ item.firstname }} {{ item.lastname }}
                      </td>
                      <!-- <td
                        class="no-filter text-center"
                        v-for="(itemShift, idx) in model_shift_hour"
                        :key="idx"
                      >
                        <span
                          class="text-default"
                          v-if="itemShift.name.toLowerCase() == 'pagi'"
                        >
                          {{ item.count_Pagi }}
                        </span>
                        <span
                          class="text-default"
                          v-if="itemShift.name.toLowerCase() == 'siang'"
                        >
                          {{ item.count_Siang }}
                        </span>
                        <span
                          class="text-default"
                          v-if="itemShift.name.toLowerCase() == 'malam'"
                        >
                          {{ item.count_Malam }}
                        </span>
                        <span
                          class="text-default"
                          v-if="itemShift.name.toLowerCase() == 'midle'"
                        >
                          {{ item.count_Midle }}
                        </span>
                        <span
                          class="text-default"
                          v-if="itemShift.name.toLowerCase() == 'absent'"
                        >
                          {{ item.count_Absent }}
                        </span>
                      </td> -->
                      <!-- <td class="tdp text-center">{{ item.count_Pagi }}</td>
                      <td class="tdp text-center">{{ item.count_Siang }}</td>
                      <td class="tdp text-center">{{ item.count_Malam }}</td>
                      <td class="tdp text-center">{{ item.count_Midle }}</td>
                      <td class="tdp text-center">{{ item.count_Absent }}</td> -->
                    </tr>
                    <tr :key="item.nik" v-if="opened.includes(item.nik)">
                      <td colspan="9">
                        <!-- For Calender -->
                        <div
                          class="row"
                          style="height: 300px; width: 100%; overflow-y: scroll"
                        >
                          <div class="col-md-12" v-if="shiftwork_employee">
                            <div
                              class="col-md-2"
                              v-for="(item, idx) in shiftwork_employee"
                              :key="idx"
                              style="float: left; padding: 1%"
                            >
                              <div class="row">
                                <div class="col-md-5">
                                  <span
                                    style="font-size: 14px; font-weight: 600"
                                    :class="
                                      item.name_of_day == 'Sun'
                                        ? 'text-danger'
                                        : ''
                                    "
                                  >
                                    {{ item.name_of_day }}
                                  </span>
                                </div>
                                <div class="col-md-7">
                                  <div class="tools-click">
                                    <a
                                      href="javascript:;"
                                      :class="GetShiftClass(item.shift)"
                                      @click="ToolsOpen($event)"
                                    >
                                      {{ item.shift }} {{ item.hour }}
                                    </a>
                                    <ul v-if="item.in == '00:00:00'">
                                      <li
                                        v-for="(
                                          item_shift, idx_shift
                                        ) in model_shift_hour"
                                        :key="idx_shift"
                                      >
                                        <a
                                          href="javascript:;"
                                          @click="
                                            AssignEmployeeToShiftWork(
                                              idxEmpl,
                                              item.id,
                                              idx,
                                              item_shift.name,
                                              item_shift.hour,
                                              item.nik
                                            )
                                          "
                                        >
                                          <span
                                            :class="
                                              item_shift.name == 'Absent'
                                                ? 'text-danger'
                                                : 'text-success'
                                            "
                                          >
                                            {{ item_shift.name }}
                                          </span>
                                          ( {{ item_shift.hour }} )
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:;"
                                          @click="
                                            CancelEmployeeToShiftWork(
                                              idxEmpl,
                                              item.id,
                                              idx,
                                              'O',
                                              item.nik
                                            )
                                          "
                                        >
                                          <span class="text-warning">
                                            OFF
                                          </span>
                                        </a>
                                      </li>
                                    </ul>
                                    <ul v-else>
                                      <li>Absen pada pukul {{ item.in }}</li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <small class="text-secondary">{{
                                    item.day +
                                    "/" +
                                    item.month +
                                    "/" +
                                    item.year
                                  }}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12" v-else>
                            <h2>Loading data ..</h2>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="pagination">
              <small class="info"
                ><i class="ti ti-split-h"></i> Scroll horizontal to view
                data.</small
              >

              <paginate
                v-model="page"
                :page-count="parseInt(modelContractEmployee_lastpage)"
                :page-range="4"
                :margin-pages="1"
                :active-class="'active'"
                :click-handler="getEmployeeContractShiftWork"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'"
              >
              </paginate>
            </div>
            <!-- <FullCalendar :options="calendarOptions" /> -->
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-12">
            <span class="text-danger"
              >Please set some officer and get back to manage shift worker
              !</span
            >
          </div>
        </div>
      </div>

      <div class="card-body" v-else>
        <div class="row">
          <div class="col-md-6">
            <ListLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-6">
            <ListLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-12">
            <ContentLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="2" ry="2" width="400" height="400" />
            </ContentLoader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ContentLoader, ListLoader, CodeLoader } from "vue-content-loader";
import $ from "jquery";
import axios from "axios";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";
import DatePicker from "vue2-datepicker";
// import '@fullcalendar/core/vdom' // solves problem with Vite
// import FullCalendar from '@fullcalendar/vue'
// import dayGridPlugin from '@fullcalendar/daygrid'
// import interactionPlugin from '@fullcalendar/interaction'

export default {
  components: {
    ContentLoader,
    ListLoader,
    CodeLoader,
    DatePicker,
    // FullCalendar,
  },

  data() {
    return {
      // calendarOptions: {
      //     plugins: [ dayGridPlugin, interactionPlugin ],
      //     initialView: 'dayGridMonth',
      //     selectable: true,
      //     dateClick: function(arg) {
      //         alert('date click! ' + arg.dateStr)
      //     },
      //     select: function(info) {
      //     alert('selected ' + info.startStr + ' to ' + info.endStr);
      //     },
      //     events: [
      //         { title: 'event 1', date: '2019-04-01' },
      //         { title: 'event 2', date: '2019-04-02' }
      //     ]
      // },

      isUpdateTools: false,
      isLoading: true,
      meta_url: "shiftwork",
      model_contract: {},
      numberSequence: 1,
      page: 1,
      modelContractEmployee_lastpage: 0,
      modelContractEmployee_frompage: 0,
      opened: [],
      openedDetailMonth: false,
      modelContractEmployees: [],
      model_shift_hour: [],
      shift_last_updated: "",
      total_employee: 0,

      filter_shift: "all",

      // filter date
      collection_month: [],
      filter_date: "",
      filter_year: "",
      filter_month: "",
      dateNowProject: null,
      shiftwork_employee: [],
      shiftwork_datenow: null,
      loading_shiftwork_employee: null,
    };
  },
  mounted() {
    this.QueryStartFromPeriod();
  },
  methods: {
    selectRowEmployee(nik) {
      // this.opened = [];
      // console.log(this.opened);
      // console.log(this.opened.includes(value));

      // if (!this.opened.includes(value)) {
      //   this.loading_shiftwork_employee = true;
      //   this.getShiftWorkPerMonthByNik(value);
      // } else {
      //   const index = this.opened.indexOf(value);
      //   this.opened.splice(index, 1);
      // }

      // console.log(this.opened.length);

      const index = this.opened.indexOf(nik);
      // console.log(index);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        if (this.opened.length > 0) {
          this.opened = [];
          this.opened.push(nik);
        } else {
          this.opened.push(nik);
        }
      }

      if (this.opened.length == 1) {
        this.getShiftWorkPerMonthByNik(nik);
      }

      // console.log(this.opened);
    },
    disabledBeforeAfterFilterDate(date) {
      const start_date = new Date(this.model_contract.start_date);
      start_date.setHours(0, 0, 0, 0);

      const end_date = new Date(this.model_contract.end_date);
      end_date.setHours(0, 0, 0, 0);

      let dateNow = new Date(date.getFullYear(), date.getMonth());
      let dateStart = new Date(start_date.getFullYear(), start_date.getMonth());
      let dateEnd = new Date(end_date.getFullYear(), end_date.getMonth());

      return dateNow < dateStart || dateNow > dateEnd;
    },
    ToolsOpen(e) {
      let _self = e.target;
      $(".tools-click").find("ul").css("display", "none");
      $(_self).parent().find("ul").css("display", "block");
    },
    async QueryStartFromPeriod() {
      await axios
        .get("contract/period/" + localStorage.getItem("QUERY_ID"))
        .then((response) => {
          const d = new Date();
          let query = response.data;
          let default_date = query.default;

          var dt = default_date.split("/");

          this.collection_month = query.month_period;
          // disable modify rudy
          // this.filter_month = dt[0];
          // this.filter_year  = dt[1];
          this.filter_month = ("0" + (d.getMonth() + 1)).slice(-2);
          this.filter_year = d.getFullYear().toString();

          this.filter_date = this.filter_month + "/" + this.filter_year;

          // this.filter_date  = default_date;

          this.DataQuery();
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    FilterModelDate() {
      let fdate = this.filter_date.split("/");
      this.filter_month = fdate[0];
      this.filter_year = fdate[1];
      this.DataQuery();
    },
    async getShiftWorkPerMonthByNik(nik) {
      this.shiftwork_employee = [];
      await axios
        .post("shiftwork/date-range", {
          id_contract: localStorage.getItem("QUERY_ID"),
          month: this.filter_month,
          year: this.filter_year,
          nik: nik,
        })
        .then((response) => {
          let res = response.data;
          setTimeout(() => {
            this.shiftwork_employee = res.date_range_shiftwork;
          }, 1000);
        })
        .catch((error) => {
          // this.loading_shiftwork_employee = false;
          console.log(error.statusText);
        });
    },
    async getEmployeeContractShiftWork(pageNum = 1) {
      // console.log(this.filter_month, this.filter_year)
      this.isLoading = true;
      this.page = pageNum;
      await axios
        .post("shiftwork/employee?page=" + this.page, {
          id_contract: localStorage.getItem("QUERY_ID"),
          month: this.filter_month,
          year: this.filter_year,
        })
        .then((response) => {
          let query = response.data;
          this.modelContractEmployees = query.data;
          this.modelContractEmployee_lastpage = query.last_page;
          this.modelContractEmployee_frompage = query.from;

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async DataQuery() {
      this.isLoading = true;
      this.opened = [];
      await axios
        .post("shiftwork/contract-v2", {
          id_contract: localStorage.getItem("QUERY_ID"),
          month: this.filter_month,
          year: this.filter_year,
        })
        .then((response) => {
          let query = response.data;

          this.dateNowProject = query.date;
          this.model_contract = query.contract;
          this.shift_last_updated = query.shift_last_updated.updated_at;
          this.model_shift_hour = query.shift_hour;

          // enable tools
          let block_status = ["terminate", "out_of_date"];
          if (!block_status.includes(this.model_contract.status)) {
            this.isUpdateTools = true;
          }

          // Add Default Shift
          this.model_shift_hour.push({ hour: "--:--", name: "Absent" });

          this.getEmployeeContractShiftWork();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },

    GetShiftClass(shift) {
      if (shift == "O") {
        return "text-danger";
      } else {
        return "text-primary";
      }
    },
    async CancelEmployeeToShiftWork(idxEmpl, id, index, nameShift, nik) {
      // console.log("CancelEmployeeToShiftWork");
      // console.log(idxEmpl, id, index, nameShift);
      if (this.shiftwork_employee[index].in == "00:00:00") {
        let employeeShiftWork = {
          id: id,
          shift: nameShift,
          hour: null,
          id_contract: localStorage.getItem("QUERY_ID"),
        };

        let data = {
          url: this.meta_url + "/assign-v2",
          callback_url: "jobcon/shift-v2",
          form_data: employeeShiftWork,
        };

        let res = await this.$store.dispatch(
          "crud/updateDataAssignEmployeeToShiftWork",
          data
        );

        if (res) {
          this.getEmployeeContractShiftWork(this.page);
          this.getShiftWorkPerMonthByNik(nik);
        }

        $(".tools-click").find("ul").css("display", "none");
      } else {
        alert("Failed Update Shift, Karyawn Telah hadir pada hari itu");
      }
    },
    async AssignEmployeeToShiftWork(
      idxEmpl,
      id,
      index,
      nameShift,
      timeShift,
      nik
    ) {
      if (this.shiftwork_employee[index].in == "00:00:00") {
        this.shiftwork_employee[index].shift = nameShift;

        let employeeShiftWork = {
          id: id,
          shift: nameShift,
          hour: timeShift,
          id_contract: localStorage.getItem("QUERY_ID"),
        };

        let data = {
          url: this.meta_url + "/assign-v2",
          callback_url: "jobcon/shift-v2",
          form_data: employeeShiftWork,
        };

        let res = await this.$store.dispatch(
          "crud/updateDataAssignEmployeeToShiftWork",
          data
        );

        if (res) {
          this.getEmployeeContractShiftWork(this.page);
          this.getShiftWorkPerMonthByNik(nik);
        }

        $(".tools-click").find("ul").css("display", "none");
      } else {
        alert("Failed Update Shift, Karyawn Telah hadir pada hari itu");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-picture {
  float: left;
  margin-right: 10px;
  padding: 2px;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  background-color: whitesmoke;
  position: absolute;
  z-index: 1;
  top: 4px;
}
.employee-name {
  position: relative;
  top: 0px;
  left: 40px;
}
.tdp {
  padding: 12px;
}
.date-month {
  position: absolute;
  top: 3px;
  color: gray;
}
.date-year {
  position: relative;
  top: 6px;
}

ul.list-area {
  float: right;
  width: 100%;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    float: left;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid whitesmoke;
  }
}

.openedhighlight {
  background-color: rgb(245, 245, 245);
}
</style>
