<template>
  <div class="col-md-12 p-0 mb-4">
    <!-- popup client  -->
    <PopupContract
      v-on:exit="closeModal"
      v-on:applyData="applyContract"
      v-if="isEnablePopup == 'contract'"
    />

    <PopupSection
      v-if="isEnablePopup == 'section'"
      v-on:exit="closeModal"
      v-on:applyData="AssignSection"
      :id_contract="idContract"
    />

    <PopupSectionTaskGallery
      v-if="isEnablePopup == 'section_task'"
      v-on:exit="closeModal"
      v-on:applyData="AssignSectionTaskImage"
      :section_task="SectionTask"
      :image_type="typeImage"
      :list_check_images_before_after="listCheckImagesBeforeAfter"
      :filter_period_start_date="filter_start_date_period"
      :filter_period_end_date="filter_end_date_period"
    />

    <PopupSectionTaskGalleryNew
      v-if="isEnablePopup == 'open_gallery_new'"
      v-on:exit="closeModal"
      v-on:applyData="AssignSectionTaskImageNew"
      :gallery_image_type="galleryImageType"
      :section_task="SectionTask"
      :filter_period_start_date="filter_start_date_period"
      :filter_period_end_date="filter_end_date_period"
    />

    <PopupGallerySelected
      v-if="isEnablePopup == 'images_selected'"
      v-on:exit="closeModal"
      v-on:applyData="AssignImage"
      :list_images_selected="listImagesSelectedGenerate"
    />

    <form class="form-horizontal">
      <div class="card">
        <div class="card-body">
          <div class="form-group">
            <div class="col-sm-6">
              <label class="text-primary mb-0"
                ><i class="ti ti-info-alt"></i> Create Generate Custom
                Report</label
              >
              <small class="text-secondary">Please fill completely data.</small>
            </div>
            <div class="col-sm-6" style="float: right; position: relative">
              <a
                class="btn btn-warning ml-2"
                style="position: absolute; right: 75px"
                v-if="this.SectionChecked.length > 0"
                @click="generateReport()"
              >
                <span v-if="$store.state.loading.status">
                  <i class="ti ti-reload"></i> loading ..</span
                >
                <span v-else
                  ><i class="ti ti-save-alt"></i> Generate Report</span
                >
              </a>
              <a
                style="position: absolute; right: 0"
                class="btn btn-danger ml-4"
                v-if="this.SectionChecked.length > 0"
                @click="clearAlldata()"
              >
                <span><i class="ti ti-save-alt"></i> Clear</span>
              </a>
            </div>
          </div>
          <hr />
          <!-- <div class="form-group">
            <label class="col-sm-3"
              >Branch Station <small class="text-danger">*</small></label
            >
            <div class="col-sm-9">
              <SelectBranch
                v-on:processBranch="processBranch"
                :title="'disable'"
              />
              
            </div>
          </div> -->

          <div class="form-group">
            <div class="mt-2">
              <div class="col-sm-3">Contract Project</div>
              <div class="col-sm-9">
                <a
                  href="javascript:;"
                  class="btn btn-light"
                  @click="isEnablePopup = 'contract'"
                  ><i class="ti ti-server"></i>&nbsp;
                  <span v-if="!this.contract">Choose Contract ...</span>
                  <span v-else>
                    {{ contract.contract_number }} -
                    <b>{{ contract.client_name }}</b>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="form-group" v-if="this.contract">
            <div class="mt-2">
              <div class="col-sm-3">Date Period</div>
              <div class="col-sm-9">
                <date-picker
                  v-model="filter_date"
                  :default-value="filter_date"
                  :disabled-date="disabledBeforeAfterFilterDate"
                  :clearable="false"
                  value-type="format"
                  type="date"
                  format="DD/MM/YYYY"
                  placeholder="Pilih Range Tanggal"
                  width="100%"
                  @change="FilterModelDate()"
                  confirm
                  range
                ></date-picker>
              </div>
            </div>
          </div>
          <div class="form-group" v-if="this.filter_start_date_period">
            <div class="mt-2">
              <div class="col-sm-3">Section Contract Project</div>
              <div class="col-sm-9">
                <a
                  href="javascript:;"
                  class="btn btn-light"
                  @click="isEnablePopup = 'section'"
                >
                  <i class="ti ti-server"></i>
                  &nbsp;
                  <span>Choose Section ...</span>
                </a>
                <div
                  v-if="SectionChecked.length > 0"
                  style="
                    background-color: rgb(248, 249, 250);
                    color: rgb(33, 37, 41);
                    border-color: rgb(248, 249, 250);
                    height: 350px;
                    overflow-y: scroll;
                    padding: 2% 0 1% 2%;
                    margin-top: 2%;
                  "
                >
                  <ol>
                    <li
                      v-for="(item, index) in SectionChecked"
                      :key="index"
                      style="text-align: left"
                    >
                      <b> {{ item.area_type }} - {{ item.area_name }} </b>
                    </li>
                  </ol>
                  <!-- <span v-for="(item, index) in SectionChecked" :key="index">
                      <b>{{ item.area_name }}</b>
                      
                      <span v-if="index == SectionChecked.length - 1"></span>
                      <span v-else>&nbsp; , </span>
                    </span> -->
                </div>
              </div>
            </div>
          </div>
          <div
            class="row justify-content-md-center m-0 p-0"
            v-if="this.SectionChecked.length > 0"
          >
            <div class="col-md-8">
              <div class="bd-callout bd-callout-warning">
                <h5 id="conveying-meaning-to-assistive-technologies">
                  <!-- Images Before / After -->
                </h5>
              </div>
              <div v-if="listSelectedImagesBeforeAfter.length > 0">
                <div
                  class="row"
                  v-for="(item, idx) in listSelectedImagesBeforeAfter"
                  :key="idx"
                >
                  <div class="col-md-12 mt-3" v-if="idx > 0"></div>

                  <div class="col-md-2" style="align-self: self-end">
                    No. {{ idx + 1 }}
                  </div>

                  <div class="col-md-10" style="text-align: end">
                    <a
                      href="javascript:;"
                      class="btn btn-sm btn-primary"
                      @click="updateImageBeforeAfter(idx)"
                    >
                      Update Image Before After
                    </a>
                  </div>

                  <div class="col-md-12 mb-1"></div>

                  <div style="border: none" class="col-md-6 card">
                    <img
                      :src="item.img_before_photo"
                      class="card-img-top"
                      :alt="item.img_before_notes"
                    />
                    <div
                      style="
                        position: absolute;
                        top: 14px;
                        background-color: rgba(34, 32, 32, 0.3);
                        padding: 6px;
                        color: #fff;
                      "
                    >
                      Before
                    </div>
                    <div
                      class="card-body"
                      style="
                        padding: 3%;
                        border-left: 1px solid #eee;
                        border-right: 1px solid #eee;
                      "
                    >
                      <h5
                        class="card-title"
                        style="
                          position: absolute;
                          bottom: 124px;
                          color: #ffffff;
                          padding: 12px;
                          left: 14px;
                          right: 14px;
                          background-color: rgb(34 32 32 / 30%);
                        "
                        v-if="item.img_before_notes"
                      >
                        {{ item.img_before_notes }}
                      </h5>
                      <p class="card-text">
                        Section {{ item.img_before_section }} -
                        {{ item.img_before_area_type }} -
                        {{ item.img_before_area }}
                      </p>
                    </div>
                    <div
                      class="card-footer"
                      style="
                        padding: 3%;
                        border-bottom: 1px solid #eee;
                        border-left: 1px solid #eee;
                        border-right: 1px solid #eee;
                      "
                    >
                      <small class="text-muted"
                        >Date work {{ item.img_before_date_work }} - (Pukul
                        {{ item.img_before_time }})</small
                      >
                    </div>
                  </div>

                  <div style="border: none" class="col-md-6 card">
                    <img
                      :src="item.img_after_photo"
                      class="card-img-top"
                      :alt="item.img_after_notes"
                    />
                    <div
                      style="
                        position: absolute;
                        top: 14px;
                        background-color: rgba(34, 32, 32, 0.3);
                        padding: 6px;
                        color: #fff;
                      "
                    >
                      After
                    </div>
                    <div
                      class="card-body"
                      style="
                        padding: 3%;
                        border-left: 1px solid #eee;
                        border-right: 1px solid #eee;
                      "
                    >
                      <h5
                        class="card-title"
                        style="
                          position: absolute;
                          bottom: 124px;
                          color: #ffffff;
                          padding: 12px;
                          left: 14px;
                          right: 14px;
                          background-color: rgb(34 32 32 / 30%);
                        "
                        v-if="item.img_after_notes"
                      >
                        {{ item.img_after_notes }}
                      </h5>
                      <p class="card-text">
                        Section {{ item.img_after_section }} -
                        {{ item.img_after_area_type }} -
                        {{ item.img_after_area }}
                      </p>
                    </div>
                    <div
                      class="card-footer"
                      style="
                        padding: 3%;
                        border-bottom: 1px solid #eee;
                        border-left: 1px solid #eee;
                        border-right: 1px solid #eee;
                      "
                    >
                      <small class="text-muted"
                        >Date work {{ item.img_after_date_work }} - (Pukul
                        {{ item.img_after_time }})</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="bd-callout bd-callout-warning">
                <h5 id="conveying-meaning-to-assistive-technologies">
                  <!-- Images Other -->
                </h5>

                <div
                  class="row"
                  v-for="(item, idx) in listSelectedImagesOther"
                  :key="idx"
                >
                  <div class="col-md-12 mt-3" v-if="idx > 0"></div>

                  <div class="col-md-4" style="align-self: self-end">
                    No. {{ idx + 1 }}
                  </div>

                  <div class="col-md-8" style="text-align: end">
                    <a
                      href="javascript:;"
                      class="btn btn-sm btn-danger"
                      @click="deleteImageOther(idx)"
                    >
                      Delete Image Other
                    </a>
                  </div>

                  <div class="col-md-12 mb-1"></div>

                  <div style="border: none" class="col-md-12 card">
                    <img
                      :src="item.img_other_photo"
                      class="card-img-top"
                      :alt="item.img_other_notes"
                    />
                    <div
                      style="
                        position: absolute;
                        top: 14px;
                        background-color: rgba(34, 32, 32, 0.3);
                        padding: 6px;
                        color: #fff;
                      "
                    >
                      Other
                    </div>
                    <div
                      class="card-body"
                      style="
                        padding: 3%;
                        border-left: 1px solid #eee;
                        border-right: 1px solid #eee;
                      "
                    >
                      <h5
                        class="card-title"
                        style="
                          position: absolute;
                          bottom: 124px;
                          color: #ffffff;
                          padding: 12px;
                          left: 14px;
                          right: 14px;
                          background-color: rgb(34 32 32 / 30%);
                        "
                        v-if="item.img_other_notes"
                      >
                        {{ item.img_other_notes }}
                      </h5>
                      <p class="card-text">
                        Section {{ item.img_other_section }} -
                        {{ item.img_other_area_type }} -
                        {{ item.img_other_area }}
                      </p>
                    </div>
                    <div
                      class="card-footer"
                      style="
                        padding: 3%;
                        border-bottom: 1px solid #eee;
                        border-left: 1px solid #eee;
                        border-right: 1px solid #eee;
                      "
                    >
                      <small class="text-muted"
                        >Date work {{ item.img_other_date_work }} - (Pukul
                        {{ item.img_other_time }})</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-8 mt-4">
              <a
                style="width: 100%"
                href="javascript:;"
                @click="openNewGallery('BeforeAfter')"
                class="btn btn-warning"
                ><i class="ti ti-upload"></i>&nbsp; Add Image Before & After...
              </a>
            </div>
            <div class="col-md-4 mt-4">
              <a
                style="width: 100%"
                href="javascript:;"
                @click="openNewGallery('Other')"
                class="btn btn-warning"
                ><i class="ti ti-upload"></i>&nbsp; Choose Image Other...
              </a>
            </div>
            <br />
            <!-- old style -->
            <!-- <div class="col-sm-4 mt-4">
              <a
                href="javascript:;"
                @click="openGallery('before')"
                class="btn btn-light"
                ><i class="ti ti-upload"></i>&nbsp; Choose Image Before ...
              </a>
              <div class="col-md-12 mt-3 p-0">
                <img
                  v-for="(item, idx) in listImagesBefore"
                  :key="idx"
                  :src="item.photo"
                  class="mr-1 p-1"
                  :alt="item.notes"
                  width="80"
                  height="80"
                />
              </div>
            </div>
            <div class="col-sm-4 mt-4">
              <a
                href="javascript:;"
                @click="openGallery('after')"
                class="btn btn-light"
                ><i class="ti ti-upload"></i>&nbsp; Choose Image After ...
              </a>
              <div class="col-md-12 mt-3 p-0">
                <img
                  v-for="(item, idx) in listImagesAfter"
                  :key="idx"
                  :src="item.photo"
                  class="mr-1 p-1"
                  :alt="item.notes"
                  width="80"
                  height="80"
                />
              </div>
            </div>
            <div class="col-sm-4 mt-4">
              <a
                href="javascript:;"
                @click="openGallery('other')"
                class="btn btn-light"
                ><i class="ti ti-upload"></i>&nbsp; Choose Image Other ...
              </a>
              <div class="col-md-12 mt-3 p-0">
                <img
                  v-for="(item, idx) in listImagesOther"
                  :key="idx"
                  :src="item.photo"
                  class="mr-1 p-1"
                  :alt="item.notes"
                  width="80"
                  height="80"
                />
              </div>
            </div> -->
            <!-- end old style -->
          </div>
        </div>
      </div>
      <!-- v-if="isGenerateReport" -->
      <div class="card mt-4" v-if="isGenerateReport">
        <div class="row p-4" v-if="$store.state.loading.status">
          <div class="col-md-6">
            <ListLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-6">
            <ListLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-12">
            <ContentLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="2" ry="2" width="400" height="250" />
            </ContentLoader>
          </div>
        </div>
        <div v-else class="view-generate" id="printView">
          <!-- <GenerateReport
            :generate_report="resultGenerateReport"
            v-on:applyData="ApplyGenerateReportSave"
          ></GenerateReport> -->
          <GenerateReportV2
            :generate_report="resultGenerateReport"
            v-on:applyData="ApplyGenerateReportSave"
          ></GenerateReportV2>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import $ from "jquery";
import Gmap from "../../../services/Gmap";
import { ContentLoader, ListLoader, CodeLoader } from "vue-content-loader";
import router from "../../../router";
// import PopupClientMaster from "../../ClientBase/Master/@PopupClientMaster";
import PopupContract from "./@PopupContract.vue";
import PopupSection from "./@PopupSection.vue";
import PopupSectionTaskGallery from "./@PopupSectionTaskGallery.vue";
import PopupSectionTaskGalleryNew from "./@PopupSectionTaskGalleryNew.vue";
import PopupGallerySelected from "./@PopupGallerySelected.vue";
import GenerateReport from "./@GenerateReport";
import GenerateReportV2 from "./@GenerateReportNew";
import SelectBranch from "../../Branch/@SelectBranch";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";

export default {
  components: {
    Gmap,
    PopupContract,
    PopupSection,
    PopupSectionTaskGallery,
    PopupSectionTaskGalleryNew,
    PopupGallerySelected,
    GenerateReport,
    GenerateReportV2,
    DatePicker,
    SelectBranch,
    ContentLoader,
    ListLoader,
    CodeLoader,
  },
  data() {
    return {
      isEnablePopup: false,
      idContract: null,
      filter_date: null,
      filter_start_date_period: null,
      filter_end_date_period: null,
      filter_year: null,
      filter_month: null,
      filter_year_start: null,
      filter_year_end: null,
      filter_month_start: null,
      filter_month_end: null,
      contract: null,
      typeImage: null,
      isGenerateReport: false,
      galleryImageType: null,
      ContractShiftMonthly: [],
      SectionChecked: [],
      SectionTask: [],
      idSectionChecked: [],
      listCheckImagesBeforeAfter: [],
      listSelectedImagesBeforeAfter: [],
      listSelectedImagesOther: [],
      SelectedUpdateImagesBeforeAfter: null,
      SelectedUpdateImagesOther: null,
      listImagesBefore: [],
      listImagesAfter: [],
      listImagesOther: [],
      resultGenerateReport: {},
      listImagesSelectedGenerate: [],
      ImagesSelectedNotes: {
        photo: null,
        notes: null,
      },
      meta_url: "report/generate",
      notes_default: null,
    };
  },
  computed: {
    ...mapState("crud", {
      msg_error: (state) => state.error,
    }),
  },
  created() {},
  mounted() {},
  methods: {
    processBranch(data) {
      // console.log(data);
    },
    closeModal() {
      this.isEnablePopup = false;
    },
    clearAlldata() {
      this.SectionChecked = [];
      this.idSectionChecked = [];
      this.listCheckImagesBeforeAfter = [];
      this.listSelectedImagesBeforeAfter = [];
      this.listSelectedImagesOther = [];
      this.SelectedUpdateImagesBeforeAfter = null;
      this.SelectedUpdateImagesOther = null;
      this.listImagesBefore = [];
      this.listImagesAfter = [];
      this.listImagesOther = [];
      this.isGenerateReport = false;
      this.filter_date = null;
      this.contract = null;
      this.filter_start_date_period = null;
      this.filter_end_date_period = null;
      this.resultGenerateReport = {};
    },
    clearGenerateReport() {
      this.SectionChecked = [];
      this.idSectionChecked = [];
      this.listCheckImagesBeforeAfter = [];
      this.listSelectedImagesBeforeAfter = [];
      this.listSelectedImagesOther = [];
      this.SelectedUpdateImagesBeforeAfter = null;
      this.SelectedUpdateImagesOther = null;
      this.listImagesBefore = [];
      this.listImagesAfter = [];
      this.listImagesOther = [];
      this.isGenerateReport = false;
      this.filter_start_date_period = null;
      this.filter_end_date_period = null;
      this.resultGenerateReport = {};
    },
    applyContract(data) {
      this.contract = data[0];
      this.idContract = data[0].id;

      let getStartDateContract = data[0].start_date.split("-");
      let startYearContract = getStartDateContract[0];
      let startMonthContract = getStartDateContract[1];
      this.filter_date = startMonthContract + "/" + startYearContract;
      this.clearGenerateReport();
    },
    AssignSection(data) {
      this.SectionChecked = data;
      this.idSectionChecked = [];
      this.SectionTask = [];
      this.listImagesBefore = [];
      this.listImagesAfter = [];
      this.listImagesOther = [];
      this.resultGenerateReport = {};
      for (let key in data) {
        this.idSectionChecked.push(data[key].id);
      }

      // console.log(this.idSectionChecked);

      this.getDataSectionTask();
    },
    AssignSectionTaskImage(data) {
      if (this.typeImage == "before") {
        for (let key in data) {
          this.listImagesBefore.push(data[key]);
        }
      }

      if (this.typeImage == "after") {
        for (let key in data) {
          this.listImagesAfter.push(data[key]);
        }
      }

      if (this.typeImage == "other") {
        for (let key in data) {
          this.listImagesOther.push(data[key]);
        }
      }

      this.listCheckImagesBeforeAfter = this.listImagesBefore.concat(
        this.listImagesAfter
      );
    },
    AssignSectionTaskImageNew(imgBefore, imgAfter, imgOther = null) {
      // console.log(imgBefore, imgAfter);
      if (imgOther == null) {
        if (this.SelectedUpdateImagesBeforeAfter == null) {
          let dataImageBeforeAfter = {
            img_before_id: imgBefore.id,
            img_before_idqr: imgBefore.idqr,
            img_before_area: imgBefore.area,
            img_before_area_type: imgBefore.area_type,
            img_before_notes: imgBefore.notes,
            img_before_photo: imgBefore.photo,
            img_before_date_work: imgBefore.date_work,
            img_before_time: imgBefore.time,
            img_after_id: imgAfter.id,
            img_after_idqr: imgAfter.idqr,
            img_after_area: imgAfter.area,
            img_after_area_type: imgAfter.area_type,
            img_after_notes: imgAfter.notes,
            img_after_photo: imgAfter.photo,
            img_after_date_work: imgAfter.date_work,
            img_after_time: imgAfter.time,
          };

          this.listSelectedImagesBeforeAfter.push(dataImageBeforeAfter);
        } else {
          this.listSelectedImagesBeforeAfter[
            this.SelectedUpdateImagesBeforeAfter
          ] = {
            img_before_id: imgBefore.id,
            img_before_idqr: imgBefore.idqr,
            img_before_area: imgBefore.area,
            img_before_area_type: imgBefore.area_type,
            img_before_notes: imgBefore.notes,
            img_before_photo: imgBefore.photo,
            img_before_date_work: imgBefore.date_work,
            img_before_time: imgBefore.time,
            img_after_id: imgAfter.id,
            img_after_idqr: imgAfter.idqr,
            img_after_area: imgAfter.area,
            img_after_area_type: imgAfter.area_type,
            img_after_notes: imgAfter.notes,
            img_after_photo: imgAfter.photo,
            img_after_date_work: imgAfter.date_work,
            img_after_time: imgAfter.time,
          };
          this.SelectedUpdateImagesBeforeAfter = null;
        }
      } else {
        if (this.SelectedUpdateImagesOther == null) {
          for (let key in imgOther) {
            let dataImageOther = {
              img_other_id: imgOther[key].id,
              img_other_idqr: imgOther[key].idqr,
              img_other_area: imgOther[key].area,
              img_other_area_type: imgOther[key].area_type,
              img_other_notes: imgOther[key].notes,
              img_other_photo: imgOther[key].photo,
              img_other_date_work: imgOther[key].date_work,
              img_other_time: imgOther[key].time,
            };
            this.listSelectedImagesOther.push(dataImageOther);
          }
        } else {
          alert("Process Update Image Other");
          console.log(imgOther);
        }

        // console.log(this.listSelectedImagesOther);
      }

      window.scrollTo(0, document.body.scrollHeight + 100);
    },
    updateImageBeforeAfter(idx) {
      this.SelectedUpdateImagesBeforeAfter = idx;
      this.openNewGallery("BeforeAfter");
      this.galleryImageType = "BeforeAfter";
    },
    deleteImageOther(idx) {
      for (let i = 0; i < this.listSelectedImagesOther.length; i++) {
        if (i === idx) {
          this.listSelectedImagesOther.splice(i, 1);
        }
      }
    },
    AssignImage(data) {
      // console.log(data);
      this.ImagesSelectedNotes.photo = data.photo;
      this.ImagesSelectedNotes.notes =
        data.area +
        " Section " +
        data.section +
        " Floor " +
        data.floor +
        " " +
        data.type +
        " Notes:" +
        data.notes;

      // console.log(this.ImagesSelectedNotes);
    },
    disabledBeforeAfterFilterDate(date) {
      const start_date = new Date(this.contract.start_date);
      start_date.setHours(0, 0, 0, 0);
      const end_date = new Date(this.contract.end_date);
      end_date.setHours(0, 0, 0, 0);

      let dateNow = new Date(date.getFullYear(), date.getMonth());
      let dateStart = new Date(start_date.getFullYear(), start_date.getMonth());
      let dateEnd = new Date(end_date.getFullYear(), end_date.getMonth());

      return dateNow < dateStart || dateNow > dateEnd;
    },
    FilterModelDate() {
      let periodEndSplit = this.filter_date[1].split("/");

      let newEndPeriodLastDay = this.getLastDay(
        periodEndSplit[2],
        periodEndSplit[1]
      );

      const result_end_period =
        newEndPeriodLastDay + "/" + periodEndSplit[1] + "/" + periodEndSplit[2];

      this.filter_start_date_period = this.filter_date[0];
      // this.filter_end_date_period = result_end_period;
      this.filter_end_date_period = this.filter_date[1];
      this.idSectionChecked = [];
      this.SectionChecked = [];
      this.listImagesBefore = [];
      this.listImagesAfter = [];
      this.listImagesOther = [];
    },
    getLastDay(y, m) {
      return new Date(y, m, 0).getDate();
    },
    async getDataSectionTask() {
      this.isLoading = true;
      this.$store.state.loading.msg = "Query Data Section Room ...";
      this.$store.state.loading.status = true;
      // let param = {
      //   id: this.idContract,
      //   date_period: {
      //     start: this.filter_start_date_period,
      //     end: this.filter_end_date_period,
      //   },
      //   id_section_room: this.idSectionChecked,
      // };
      // console.log(param);
      await axios
        .post("contract/get-section-task", {
          id: this.idContract,
          date_period: {
            start: this.filter_start_date_period,
            end: this.filter_end_date_period,
          },
          id_section_room: this.idSectionChecked,
        })
        .then((res) => {
          // console.log(res);
          let data = res.data;
          for (let key in data) {
            let checkpoint = JSON.parse(data[key].checkpoint);
            for (let keyCheckPoint in checkpoint) {
              this.SectionTask.push({
                id: data[key].id,
                section: data[key].section,
                floor: data[key].area_floor,
                area_type: data[key].area_type,
                area: data[key].area_name,
                task: data[key].area_task,
                date_work: data[key].date_work,
                time: checkpoint[keyCheckPoint].time,
                idqr: checkpoint[keyCheckPoint].idqr,
                photo:
                  "https://astation.tukangbersih.com/" +
                  checkpoint[keyCheckPoint].photo,
                notes: checkpoint[keyCheckPoint].notes,
                type: checkpoint[keyCheckPoint].type,
              });
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    openNewGallery(type) {
      this.isEnablePopup = "open_gallery_new";
      this.galleryImageType = type;
      window.scrollTo(0, 0);
    },
    openGallery(type) {
      this.isEnablePopup = "section_task";
      this.typeImage = type;
    },
    openGalleryImagesSelected() {
      this.listImagesSelectedGenerate =
        this.resultGenerateReport.images_before.concat(
          this.resultGenerateReport.images_after
        );

      this.isEnablePopup = "images_selected";
    },
    async ApplyGenerateReportSave(param) {
      this.isGenerateReport = true;
      this.$store.state.loading.msg = "Save " + param.status + " Report ..";
      this.$store.state.loading.status = true;

      let listOfBeforeImages = [];
      let listOfAfterImages = [];
      let listOfOtherImages = [];

      for (let key in this.listSelectedImagesBeforeAfter) {
        listOfBeforeImages.push({
          time: this.listSelectedImagesBeforeAfter[key].img_before_time,
          idqr: this.listSelectedImagesBeforeAfter[key].img_before_idqr,
          photo: this.listSelectedImagesBeforeAfter[key].img_before_photo,
          notes: this.listSelectedImagesBeforeAfter[key].img_before_notes,
          type_img: "before",
        });

        listOfAfterImages.push({
          time: this.listSelectedImagesBeforeAfter[key].img_after_time,
          idqr: this.listSelectedImagesBeforeAfter[key].img_after_idqr,
          photo: this.listSelectedImagesBeforeAfter[key].img_after_photo,
          notes: this.listSelectedImagesBeforeAfter[key].img_after_notes,
          type_img: "after",
        });
      }

      for (let key in this.listSelectedImagesOther) {
        listOfOtherImages.push({
          time: this.listSelectedImagesOther[key].img_other_time,
          idqr: this.listSelectedImagesOther[key].img_other_idqr,
          photo: this.listSelectedImagesOther[key].img_other_photo,
          notes: this.listSelectedImagesOther[key].img_other_notes,
          type_img: "other",
        });
      }

      let params = {
        id: param.id ? param.id : null,
        id_contract: this.idContract,
        id_section_room: this.idSectionChecked,
        create_by: localStorage.getItem("userid"),
        status: param.status,
        date_period: {
          start: this.filter_start_date_period,
          end: this.filter_end_date_period,
        },
        cleaning_images: {
          images_before: listOfBeforeImages,
          images_after: listOfAfterImages,
          images_other: listOfOtherImages,
        },
        executive_summary: param.notes,
        regards: param.regards,
      };

      // console.log(params);

      await axios
        .post(this.meta_url + "/save", params)
        .then((res) => {
          let response = res.data;
          if (response.result == "ok") {
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "success";
            this.$store.state.alert.msg = response.msg;
            localStorage.setItem("QUERY_ID", response.id);
            router.push({ name: "report/generate-report/detail" });
          } else {
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "trash";
            this.$store.state.alert.msg = response.msg;
          }
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async generateReport() {
      this.isGenerateReport = true;
      this.$store.state.loading.msg = "Generate Data...";
      this.$store.state.loading.status = true;

      await axios
        .post("report/generate-report-quantitative", {
          id_contract: this.idContract,
          id_section_room: this.idSectionChecked,
          date_period: {
            start: this.filter_start_date_period,
            end: this.filter_end_date_period,
          },
        })
        .then((res) => {
          let data = res.data;
          // console.log(data);
          this.resultGenerateReport = {
            params: {
              id_contract: this.idContract,
              id_section_room: this.idSectionChecked,
              create_by: localStorage.getItem("userid"),
              status: "draft",
              date_period: {
                start: this.filter_start_date_period,
                end: this.filter_end_date_period,
              },
              cleaning_images: {
                images_before_after: this.listSelectedImagesBeforeAfter,
                images_other: this.listSelectedImagesOther,
              },
              executive_summary: "",
            },
            contract_number: this.contract.contract_number,
            project_desc: this.contract.description,
            date_period: {
              start: this.filter_start_date_period,
              end: this.filter_end_date_period,
            },
            contract_start_date: this.contract.start_date,
            contract_end_date: this.contract.end_date,
            client_name: this.contract.client_name,
            client_phonenumber: this.contract.phone_number,
            client_email: this.contract.email,
            client_address: this.contract.office_address,
            section_room: this.SectionChecked,
            list_quantitative_absen: data.result_report_absen,
            list_quantitative_scan_barcode: data.result_report_scan_barcode,
            images_before_after: this.listSelectedImagesBeforeAfter,
            images_other: this.listSelectedImagesOther,
            notes: this.notes_default,
          };
          // window.scrollTo(0, 800);
          window.scrollTo({ top: 1000, behavior: "smooth" });
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.state.loading.status = false;
          }, 1300);
        });
    },
  },
};
</script>
