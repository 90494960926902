<template>
    <div class="col-md-12 p-0">
        <!-- audio -->
        <audio id="sound" ></audio>
        <!-- ---- -->

        <div class="card">
            <div class="card-body" >
                 <div class="configuration">
                    
                    <ul>
                        <li>
                            <h5>Notification Alert  </h5>
                            <small class="text-disable">Ring tone collection alert.</small>
                        </li>
                        <li>
                            <span>Silent Sound </span>
                            <div @click="chooseAlert('', '')" :class="sound == '' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Jinggle</span>
                            <div @click="chooseAlert('sound/default.mp3')" :class="sound == 'sound/default.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - The Calling </span>
                            <div @click="chooseAlert('sound/the-calling.mp3')" :class="sound == 'sound/the-calling.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Demonstrtive </span>
                            <div @click="chooseAlert('sound/demonstrative.mp3')" :class="sound == 'sound/demonstrative.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Point</span>
                            <div @click="chooseAlert('sound/to-the-point.mp3')" :class="sound == 'sound/to-the-point.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                            <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Definite</span>
                            <div @click="chooseAlert('sound/definite.mp3')" :class="sound == 'sound/definite.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Not Bad</span>
                            <div @click="chooseAlert('sound/not-bad.mp3')" :class="sound == 'sound/not-bad.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Little Bunny </span>
                            <div @click="chooseAlert('sound/little-dwarf.mp3')" :class="sound == 'sound/little-dwarf.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Christmas</span>
                            <div @click="chooseAlert('sound/chirstmas.mp3')" :class="sound == 'sound/chirstmas.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Happy Year</span>
                            <div @click="chooseAlert('sound/happy-year.mp3')" :class="sound == 'sound/happy-year.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Olala</span>
                            <div @click="chooseAlert('sound/obey.mp3')" :class="sound == 'sound/obey.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Alarm - Love</span>
                            <div @click="chooseAlert('sound/loving-you.mp3')" :class="sound == 'sound/loving-you.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Alarm - Sunrise</span>
                            <div @click="chooseAlert('sound/early-sunrise.mp3')" :class="sound == 'sound/early-sunrise.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Alarm - Buzzer</span>
                            <div @click="chooseAlert('sound/alarm-buzzer.mp3')" :class="sound == 'sound/alarm-buzzer.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                            <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Alarm - Good Morning</span>
                            <div @click="chooseAlert('sound/good-morning.mp3')" :class="sound == 'sound/good-morning.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                        <li>
                            <span><i class="text-success ti ti-music-alt"></i> - Alarm - Cuckoo Clock</span>
                            <div @click="chooseAlert('sound/cuckoo-cuckoo-clock.mp3')" :class="sound == 'sound/cuckoo-cuckoo-clock.mp3' ? 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate  bootstrap-switch-on' : 'float-right bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-small bootstrap-switch-animate bootstrap-switch-off'"><div class="bootstrap-switch-container"><span class="bootstrap-switch-handle-on bootstrap-switch-success">ON</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default">OFF</span><input class="bootstrap-switch" type="checkbox" data-size="small" data-on-color="success" data-off-color="default"></div></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>

    import axios from 'axios';
    import $ from 'jquery';

    export default 
    {
        created()
        {
            this.$store.state.app = {
                title :'Configuration',
                icon  : 'ti ti-settings',
                breadcrumb : [
                    {name:'Notification Alert'}, 
                ],
            };
        },
        data() {
            return {
                sound : localStorage.getItem('NOTIFICATION_SOUND'),
                setting:{module_1:'off', module_2:'off', module_3:'off', module_4:'off'}
            };
        },
        methods: 
        {
            chooseAlert(path)
            {
                this.sound = path;
                var sys_audio  = document.getElementById('sound')
                sys_audio.src = this.sound;
                sys_audio.play();
                
                this.submit(path);
            },

            async submit(path_source) 
            {
                let dataUpdate = {notification_sound : path_source, userid: localStorage.getItem('userid')};
                await axios.post('setup/notification/setting', dataUpdate)
                .then((res) => {
                    localStorage.setItem('NOTIFICATION_SOUND', path_source);
                }).finally(() => {
                    this.$store.state.loading.status = false;
                    this.isEnableEquipment = true;
                });
            }
        }
    }
</script>


<style lang="scss" scoped>
.configuration ul {
  padding: 0;
  margin: 0;
}

.configuration ul li {
  width: 100%;
  list-style: none;
  border-top: 1px solid whitesmoke;
  padding: 12px;
  i.ti{
      font-size:14px;
  }
}
.configuration ul li:nth-child(1) {
  border-top: none;
  padding-top: 30px;
}
</style>
