
<template>
  <div class="cov-google">
    <div class="col-md-12 p-0">
      <textarea
        type="text"
        id="input_address"
        class="form-control"
        v-model="input_address"
        placeholder="Enter address"
        ref="autocomplete"
        v-on:keyup="resetPlace()"
        :disabled="disable"
      ></textarea>
      <a
        href="javascript:;"
        class="btn btn-primary"
        @click="find()"
        v-show="validation_false < 3"
        ><i class="ti ti-search"></i> Find Address</a
      >
      <!-- hidden element -->
      <small v-show="validation_false >= 3" class="text-secondary"
        >Enter / Copy the latitude from google maps</small
      >
      <input
        :type="validation_false > 2 ? 'text' : 'hidden'"
        id="input_lat"
        v-model="model.latitude"
        placeholder="Enter Latitude ..."
      />
      <small v-show="validation_false >= 3" class="text-secondary"
        >Enter / Copy the longitude from google maps</small
      >
      <input
        :type="validation_false > 2 ? 'text' : 'hidden'"
        id="input_lon"
        v-model="model.longitude"
        placeholder="Enter Longitude ..."
      />
    </div>
    <div class="col-md-12 p-0" v-show="validation_false < 3">
      <div class="google-map" :id="mapName">
        <div class="p-3">Please enter valid address.</div>
      </div>
      <small
        ><i class="ti ti-info-alt text-info"></i> Drag the marker to help you
        find the detail address</small
      >
    </div>
  </div>
</template>

  
<script>
import gmapsInit from "../utils/gmaps";

export default {
  props: ["name", "default_model", "disable"],
  data: function () {
    return {
      
      mapName: this.name + "-map",
      input_address: "",
      validation_false: 0,
      map: null,
      marker: null,
      model: [],
    };
  },
  created(){
      // console.log(this.input_address);
  },
  async mounted() {
    // console.log(this.default_model)
    try {
      const google = await gmapsInit();
      this.model = this.default_model;
      if (this.default_model.latitude) {
        // console.log(this.default_model.latitude);
        this.initialMap();
        this.initialGeoDrag();
        this.input_address = this.default_model.address;
      }
      this.initialGeocomplete();
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    initialMap() {
      const mapCentre = this.model;
      this.map = new google.maps.Map(document.getElementById(this.mapName), {
        center: { lat: mapCentre.latitude, lng: mapCentre.longitude },
        zoom: 15,
        geocodeAfterResult: false,
      });
      this.marker = new google.maps.Marker({
        position: { lat: mapCentre.latitude, lng: mapCentre.longitude },
        map: this.map,
        draggable: true,
      });
    },

    initialGeoDrag() {
      google.maps.event.addListener(this.marker, "dragend", function (marker) {
        const latLng = marker.latLng;
        let new_lat = latLng.lat();
        let new_lon = latLng.lng();

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ latLng: latLng }, function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              // update
              var current_address = results[0].formatted_address;
              var current_lat = new_lat;
              var current_lon = new_lon;
              if (this.input_address !== "") {
                document.getElementById("input_address").value =
                  current_address;
                document.getElementById("input_lat").value = current_lat;
                document.getElementById("input_lon").value = current_lon;
              } else {
                this.resetInput();
              }
            }
          }
        });
      });
    },

    initialGeocomplete() {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.autocomplete,
        { types: ["geocode"] }
      );
    },

    resetPlace() {
        
      //this.autocomplete.set('place', null);
    },

    find() {
      if (this.input_address == "") {
        alert("Please insert address!");
        this.resetInput();
        return;
      }

      let place = this.autocomplete.getPlace();
      if (place) {
        if (
          place.geometry.location.lat() !== "" &&
          place.geometry.location.lng() !== ""
        ) {
          let current_address = place.formatted_address;
          let current_lat = place.geometry.location.lat();
          let current_lon = place.geometry.location.lng();
          this.input_address = current_address;
          // recall map
          this.model = { latitude: current_lat, longitude: current_lon };
          this.initialMap();
        } else {
          this.validation_false++;
          alert(
            "Google cannot found the address, please try another address or drag marker !"
          );
        }
      } else {
        this.validation_false++;
        if (this.validation_false < 3) {
          alert(
            "Google cannot found the address, please try another address or drag marker !"
          );
          this.resetInput();
          return;
        }
      }

      if (this.validation_false > 2) {
        alert(
          "Sorry you already input more than 3 times, the address still not recognize in google, system suggests you to input manual Address, Latitude and Longitude"
        );
        return;
      }
    },

    resetInput() {
      document.getElementById("input_address").value = "";
      document.getElementById("input_lat").value = "";
      document.getElementById("input_lon").value = "";
    },
  },
};
</script>

<style lang='scss' scoped>
.cov-google {
  float: left;
  width: 100%;
  input[type="text"] {
    padding: 5px;
    width: 100%;
    margin-bottom: 10px;
  }
  .google-map {
    width: 100%;
    height: 270px;
    margin: 0 auto;
    margin-bottom: 10px;
    background: #ececec;
  }
}

#input_address {
  float: left;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}
.btn {
  width: 100%;
  margin-bottom: 10px;
}
</style>