<template>
    <div class="">
        <div class="popup popup-md" >
            <div class="content">
                <small id="close-popup" @click="close()"><i class="ti ti-close"></i></small>
                <h5 class="text-left">Detail Contract<hr/></h5>
                <div class="row">
                    <div class="form-group mb-0">
                        <div class="col-sm-12">
                            <label class="text-primary mb-0"><i class="ti ti-info-alt"></i> Information Contract</label><br/>
                            <small class="text-secondary">Detail client information job contract.</small>
                            <hr/>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <label class="col-sm-4"><i><b>Contract Status</b></i></label>
                        <label class="col-sm-8">
                            <span :class="model.status | contractStatus" style="font-size:11px;">{{ model.status }}</span>
                        </label>
                    </div>
                    <div class="form-group mb-0" v-if="model.status == 'terminate'">
                        <label class="col-sm-4">&nbsp;</label>
                        <label class="col-sm-8">
                            <span class="text-secondary ml-2">{{ model.terminate_date  | moment("DD/MM/YYYY") }} - {{ model.terminate_desc }}</span>
                        </label>
                    </div>

                    <div class="form-group mb-0">
                        <label class="col-sm-4"><i><b>Number of Contract</b></i></label>
                        <label class="col-sm-8"><i> # {{model.contract_number }}</i></label>
                    </div>
                    <div class="form-group mb-0 mb-0">
                        <label class="col-sm-4"><i>Contract Value (IDR)</i></label>
                        <label class="col-sm-8">Rp.{{ model.contract_value }}</label>
                    </div>
                    <div class="form-group mb-0 mb-0">
                        <label class="col-sm-4"><i>Contract Date</i></label>
                        <label class="col-sm-8">{{ model.contract_date | moment("DD/MM/YYYY") }}</label>
                    </div>
                    <div class="form-group mb-0 mb-0">
                        <label class="col-sm-4"><i>Contract - <span class="text-success">Start Date</span> </i></label>
                        <label class="col-sm-8">{{ FormatDate(model.start_date) }}</label>
                    </div>
                    <div class="form-group mb-0 mb-0">
                        <label class="col-sm-4"><i>Contract - <span class="text-danger">End Date</span> </i></label>
                        <label class="col-sm-8">{{ FormatDate(model.end_date) }}</label>
                    </div>
                    <div class="form-group mb-0 mb-0">
                        <label class="col-sm-4"><i>Man Power Request</i></label>
                        <label class="col-sm-8" v-if="model.man_power !== undefined">
                            <span v-for="(initems, index) in JSON.parse(model.man_power)" :key="index">
                                {{ initems.total }} ( {{ initems.job }} )
                                <span v-if="(index % 2== 0)">,</span>
                            </span>
                        </label>
                    </div>
                    <div class="form-group mb-0">
                        <label class="col-sm-4"><i>Contract Description</i></label>
                        <label class="col-sm-8">{{model.description !== null ?  model.description : '....' }}</label>
                    </div>
                    <div class="form-group mb-0">
                        <div class="col-sm-12">
                            <label class="text-primary mb-0"><i class="ti ti-info-alt"></i> Client Base</label><br/>
                            <small class="text-secondary">Detail information client.</small>
                            <hr/>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <label class="col-sm-4"><i>Client Name</i></label>
                        <label class="col-sm-8">{{model.client_name }}</label>
                    </div>
                    <div class="form-group mb-0">
                        <label class="col-sm-4"><i>Email Address</i></label>
                        <label class="col-sm-8">{{model.email }}</label>
                    </div>
                    <div class="form-group mb-0">
                        <label class="col-sm-4"><i>Phone Number</i></label>
                        <label class="col-sm-8">{{model.phone_number }}</label>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4"><i>Client Address / Station Base</i></label>
                        <label class="col-sm-8">
                            {{model.office_address }}<br/>
                            <span class="text-secondary">{{ model.proname }} - {{ model.regname }}</span>
                        </label>
                    </div>

                    <div class="form-group">
                        <div class="col-sm-12">
                            <label class="text-primary mb-0"><i class="ti ti-info-alt"></i> Detail Area Client Base</label><br/>
                            <small class="text-secondary">Detail information area station.</small>
                            <hr/>
                        </div>
                    </div>
                    <div class="form-group mt-0 mb-0" v-for="(items, idx) in section" :key="idx">
                        <span class="ml-2"># Section {{ idx  }}</span><br/><br/>
                        <div class="row" v-for="(row, idx) in items" :key="idx">
                            <label class="col-sm-4 mb-2"><i class="ti ti-check text-success"></i> {{ row.area_name }}</label>
                            <label class="col-sm-5"><i>{{ row.area_task !== null ? row.area_task : '.........' }}</i></label>
                            <label class="col-sm-3">
                                Floor <b>{{ row.area_floor }} ( {{ row.area_type }} )</b>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import axios from 'axios';
    import { mapState } from 'vuex';

    export default 
    {
        props:['id_contract'],
        data() {
            return {
                meta_url : 'contract',
                sdate: new Date( Date.now() + -6*24*3600*1000 ).toISOString().slice(0,10),
                model: [],
                section:[]
            }
        },
        computed:{
           
        },
        created() {
            this.DataQuery()
        },
      
        methods: {
            close(){
                this.$emit('exit');
            },

            FormatDate(date)
            {
                return this.$moment(date).format("DD/MM/YYYY")
            },

            async DataQuery()
            {
                this.$store.state.loading.msg = 'Query Detail Contract';
                this.$store.state.loading.status = false;

                await axios.get( this.meta_url+'/find/'+this.id_contract)
                .then((res) => {
                    let query    = res.data;
                    this.model   = query.data;
                    this.section = query.section;
                }).finally(() => {
                    this.$store.state.loading.status = false;
                });
            },
        },
    }
</script>

<style scoped>
.popup .content .form-group label{
    display: inline-block;
}
.popup .content .form-group{padding:5px; margin-bottom:0;}
</style>
