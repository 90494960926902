<template>
  <div class="popup popup-lg">
    <div class="content">
      <small id="close-popup" @click="close()"
        ><i class="ti ti-close"></i
      ></small>
      <h5>
        Data Section <br /><small
          >Please select section to continue process.</small
        >
        <hr />
      </h5>
      <div class="row p-0">
        <div class="col-md-12">
          <div class="table-header">
            <h5>
              Data Data Section <br />
              <small
                >Total Records : <b>{{ model.total | formatPrice }}</b></small
              >
              <br />
              <small
                >Total Section Checked :
                <b>{{ model_group.length | formatPrice }}</b></small
              >
            </h5>
            <div class="group-search pull-right">
              <a class="btn" @click="isActiveSearch()"
                ><i
                  :class="
                    isEnableTools == false ? 'ti ti-search' : 'ti ti-close'
                  "
                ></i
              ></a>
              <div class="select" v-if="isEnableTools">
                <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                  <option value="">-- Choose Field --</option>
                  <option value="area_name">Area Name</option>
                </select>
              </div>
              <div class="group" v-if="isEnableTools">
                <input
                  type="date"
                  v-model="meta_data.sval"
                  v-if="
                    meta_data.skey == 'created_at' ||
                    meta_data.skey == 'updated_at'
                  "
                />
                <input
                  type="text"
                  v-model="meta_data.sval"
                  placeholder="Enter Keyword ...."
                  v-else
                />
                <a class="btn" @click="tableSearch()"> SEARCH DATA </a>
              </div>
            </div>
          </div>

          <div class="table-cover" style="height: 500px">
            <table class="table-hover">
              <thead>
                <tr>
                  <th class="no-filter text-center" style="width: 60px">
                    <input
                      type="checkbox"
                      @change="collectGroupAll()"
                      :id="'checkbox-all'"
                    />
                    <span style="margin-left: 5px">check all</span>
                  </th>
                  <th class="no-filter text-center">No</th>
                  <th class="text-center no-filter">Section</th>
                  <th class="no-filter text-center">Floor</th>
                  <th class="no-filter text-center">Area Type</th>
                  <th class="no-filter text-center">Area Name</th>
                  <th class="no-filter text-center">Task</th>
                </tr>
              </thead>
              <tbody v-if="model.total > 0">
                <tr v-for="(items, idx) in model.data" :key="idx">
                  <td class="text-center">
                    <input
                      type="checkbox"
                      @change="collectGroup(idx)"
                      :checked="validateChecked(items.id)"
                      :id="'checkbox-' + items.id"
                    />
                  </td>
                  <td width="50" class="text-center">
                    {{ idx + 1 }}
                  </td>
                  <td width="100">{{ items.section }}</td>
                  <td width="100">{{ items.area_floor }}</td>
                  <td width="100" class="text-left">{{ items.area_type }}</td>
                  <td lass="text-left">{{ items.area_name }}</td>
                  <td>{{ items.area_task }}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="19">
                    <i class="ti ti-info-alt text-danger"></i> Section in
                    Contract Project not found.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div class="pagination" v-if="model.total > 0">
            <small class="info"
              ><i class="ti ti-split-h"></i> Scroll horizontal to view
              data.</small
            >
            <paginate
              :page-count="parseInt(model.last_page)"
              :click-handler="tableData"
              :container-class="'pagination'"
              :page-class="'page-item'"
            >
            </paginate>
          </div> -->
        </div>
        <div class="col-md-12">
          <hr />
          <a
            v-if="model.total > 0"
            href="javascript:;"
            class="btn btn-primary btn-execute float-right"
            @click="applyData()"
            >Apply</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";

export default {
  props: ["id_contract"],

  data() {
    return {
      isEnableTools: false,
      isEnableOrder: false,
      isEnablePopup: false,
      model_collection: [],
      model_group: [],

      model: [],
      model_job_position: [],
      model_province: [],
      model_kota: [],
      model_kecamatan: [],

      meta_url: "contract/get-section",
      meta_filter: {
        job_position: "all",
        gender: "all",
        activity_status: "active",
        id_propinsi: "all",
        id_kota: "all",
        id_kecamatan: "all",
        id_branch: "all",
      },
      meta_data: {
        okey: "created_at",
        oval: "desc",
        wkey: "",
        wval: "",
        skey: "",
        sval: "",
      },
    };
  },

  computed: {},
  created() {
    this.meta_filter.id_branch = localStorage.getItem("userBranchId");

    this.tableData();
    // this.QueryJobPosition();
    // this.fetchProvinces();
  },

  methods: {
    close() {
      this.$emit("exit");
    },
    applyData() {
      if (this.model_group.length < 1) {
        alert("Please choose section room !");
        return false;
      }
      this.$emit("exit");
      this.$emit("applyData", this.model_group);
    },
    validateChecked(id) {
      var model = this.model_collection;
      return model.includes(id) ? true : false;
    },
    collectGroupAll() {
      if ($("#checkbox-all").is(":checked")) {
        let model = this.model.data;
        this.model_group = [];
        this.model_collection = [];
        for (let i = 0; i < model.length; i++) {
          this.model_collection.push(model[i].id);
          this.model_group.unshift(model[i]);
        }
      } else {
        this.model_collection = [];
        this.model_group = [];
      }
    },
    collectGroup(idx) {
      let model = this.model.data[idx];

      if ($("#checkbox-" + model.id).is(":checked")) {
        this.model_collection.push(model.id);

        if (this.model_group.length < 1) {
          this.model_group = [];
        }
        // console.log(model);
        this.model_group.unshift(model);
        // console.log(this.model_group)
      } else {
        this.disCollecGroup(idx);
      }
    },
    disCollecGroup(idx) {
      let model = this.model.data[idx];
      var model_collection = this.model_collection;
      var index_collection = model_collection.indexOf(model.hk_id);
      this.model_collection.splice(index_collection, 1);

      var model_group = this.model_group;
      var no = 0;
      for (let key in model_group) {
        if (model_group[key].hk_id == model.hk_id) {
          this.model_group.splice(no, 1);
        }
        no++;
      }
    },

    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
      if (this.isEnableTools) this.meta_data.sval = "";
    },
    tableSearch() {
      alert("soon");
      //   if (this.meta_data.skey == "") {
      //     alert("🙏, Please choose target field");
      //     return;
      //   }
      //   if (this.meta_data.sval == "") {
      //     alert("🙏, Please enter the keyword");
      //     return;
      //   }
      //   this.tableData();
    },

    tableData(pageNum) {
      this.page = pageNum == undefined ? 1 : pageNum;
      axios
        .post(this.meta_url + "/?page=" + this.page, {
          id: this.id_contract,
        })
        .then((res) => {
          this.model = res.data;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    tableRefresh() {
      this.meta_data.skey = "";
      this.meta_data.sval = "";
      this.meta_filter = {
        job_position: "all",
        gender: "all",
        activity_status: "active",
        id_propinsi: "all",
        id_kota: "all",
        id_kecamatan: "all",
      };
      this.tableData();
    },
  },
};
</script>
