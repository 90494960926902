<template>
    <div class="col-md-12 p-0">
        <div class="card">
            <div class="card-body">
                <form class="form-horizontal mt-lg" @submit="submit">
                     <div class="form-group">
                        <label class="col-sm-3">Branch Station <small class="text-danger">*</small></label>
                        <div class="col-sm-3" v-if="isRoot">
                            <SelectBranch  v-on:processBranch="processBranch" :title="'disable'"/>
                            <small class="text-danger" v-if="msg_error.id_branch">{{ msg_error.id_branch[0] }}</small>
                        </div>
                        <div class="col-sm-9" v-else>
                            <input type="text" class="form-control"  placeholder="...." v-model="branch_name" readonly>
                            <small class="text-danger" v-if="msg_error.name">{{ msg_error.name[0] }}</small>
                        </div>
                     </div>
                     <div class="form-group">
                            <label class="col-sm-3 ">Client Name <small class="text-danger">*</small></label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control"  placeholder="...." v-model="model.name">
                                <small class="text-danger" v-if="msg_error.name">{{ msg_error.name[0] }}</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-3 ">Email Address <small class="text-danger">*</small></label>
                            <div class="col-sm-9">
                                <input type="email" class="form-control"  placeholder="...." v-model="model.email">
                                <small class="text-danger" v-if="msg_error.email">{{ msg_error.email[0] }}</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-3 ">Phone Number <small class="text-danger">*</small></label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control"  placeholder="...." v-model="model.phone_number">
                                <small class="text-danger" v-if="msg_error.phone_number">{{ msg_error.phone_number[0] }}</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-3 ">Client Adddress <small class="text-danger">*</small></label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" v-model="provinces_name" readonly/>
                                <small class="text-danger" v-if="msg_error.id_propinsi">{{ msg_error.id_propinsi[0] }}</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-3 "></label>
                            <div class="col-sm-9">
                                <select v-model="model.id_kota"  class="form-control" :disabled="model_kota.length > 0 ? false : true">
                                    <option disabled value="">-- Choose Regencies --</option>
                                    <option v-for="(items, idx) in model_kota" :value="items.id" :key="idx">{{ items.name }}</option>
                                </select>
                                <small class="text-danger" v-if="msg_error.id_kota">{{ msg_error.id_kota[0] }}</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-3"></label>
                            <div class="col-sm-9" >
                                <Gmap name="example" :default_model="markerCoordinates"  ></Gmap>
                                <small class="text-danger" v-if="msg_error.office_address">{{ msg_error.office_address[0] }}</small>
                                <small class="text-danger" v-if="msg_error.lat">{{ msg_error.lat[0] }}</small>
                                <small class="text-danger" v-if="msg_error.lng">{{ msg_error.lng[0] }}</small>
                            </div>
                        </div>
                        <hr/>
                        <div class="form-group">
                            <div class="col-md-12 text-center">
                                <a href="javascript:;" class="btn btn-secondary mr-3" @click="$router.go(-1)">CANCEL</a>
                                <button type="submit" class="btn btn-primary btn-full-screen">Submit Data</button>
                            </div>
                        </div>
                </form>
            </div> 
        </div> 
    </div> 
</template>

<script>
    import { mapState } from 'vuex';
    import Gmap from '../../../services/Gmap';
    import $ from 'jquery';
    import axios from 'axios';
    import SelectBranch from '../../Branch/@SelectBranch';

    export default 
    {   
         components: {
            Gmap,
            SelectBranch
        },
        data() {
            return {
                isRoot : false,
                meta_url : 'client',
                provinces_name : '',
                model: {id_propinsi : '', id_kota : ''},
                model_kota : [],
                markerCoordinates: [],

                branch_name :'',
                coll_branch : ''
            };
        },
        computed: {
            ...mapState('crud', {
                msg_error: state => state.error,
            }),
        },
        created() {

            // default branch
            if(localStorage.getItem('userPrivilegesName') == 'root'){
                this.isRoot = true;
            }else{
                this.branch_name = localStorage.getItem("userBranchName");
                this.model.id_branch = localStorage.getItem('userBranchId');
            }

            this.model.id_propinsi = localStorage.getItem('id_provinces');
            this.provinces_name = localStorage.getItem('provinces_name');
            this.fetchKota();
        },
        
        methods: 
        {
            processBranch(data)
            {
                this.model.id_branch = data;
            },
            async fetchKota()
            {
                this.model_kota = JSON.parse(localStorage.getItem('data_regencies'));
            },

            submit(e) 
            {
                e.preventDefault();
                this.$store.state.loading.msg = 'Processing submit data...';
                this.$store.state.loading.status = true;

                // google lat and lng
                this.model.office_address = document.getElementById('input_address').value;
                this.model.lat = document.getElementById('input_lat').value;
                this.model.lng = document.getElementById('input_lon').value; 
             
                
                let data = { url: this.meta_url + '/store', callback_url:this.meta_url + '/data', form_data: this.model };
                this.$store.dispatch('crud/storeData', data );
            }
        }
    }
</script>
