<template>
  <div class="popup popup-lg">
    <div class="content">
      <small id="close-popup" @click="close()"
        ><i class="ti ti-close"></i
      ></small>
      <h5>
        Master Data Employee <br /><small
          >Please select employee to assign data.</small
        >
        <hr />
      </h5>
      <div class="row p-0">
        <div class="col-md-12">
          <div class="table-header">
            <h5>
              Master Data <br />
              <small
                >Total Records : <b>{{ model.total | formatPrice }}</b></small
              >
            </h5>
            <div class="group-search pull-right">
              <a class="btn" @click="tableRefresh()"
                ><i class="ti ti-reload"></i
              ></a>
              <a class="btn" @click="isActiveSearch()"
                ><i
                  :class="
                    isEnableTools == false ? 'ti ti-search' : 'ti ti-close'
                  "
                ></i
              ></a>
              <div class="select" v-if="isEnableTools">
                <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                  <option value="">-- Choose Field --</option>
                  <option value="firstname">Firstname</option>
                  <option value="lastname">Lastname</option>
                  <option value="title">Job Title</option>
                  <option value="description">Description</option>
                </select>
              </div>
              <div class="group" v-if="isEnableTools">
                <input
                  type="date"
                  v-model="meta_data.sval"
                  v-if="
                    meta_data.skey == 'created_at' ||
                    meta_data.skey == 'updated_at'
                  "
                />
                <input
                  type="text"
                  v-model="meta_data.sval"
                  placeholder="Enter Keyword ...."
                  v-else
                />
                <a class="btn" @click="tableSearch()"> SEARCH DATA </a>
              </div>
              <div class="group-filter mb-3">
                <div class="row">
                  <div class="form-group mb-0 col-md-3 ml-2 p-0">
                    <div class="form-group" v-if="isRoot">
                      <label class="text-secondary">Branch Station</label>
                      <SelectBranch v-on:processBranch="processBranch" />
                    </div>
                  </div>
                </div>

                <div class="form-group mb-0 col-md-2 p-0">
                  <label class="text-secondary">Job Position</label>
                  <select
                    class="form-control"
                    v-model="meta_filter.job_position"
                    @change="tableData()"
                    :disabled="model_job_position ? false : true"
                  >
                    <option value="all">-- All Job Position --</option>
                    <option
                      v-for="(items, idx) in model_job_position"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.title }}
                    </option>
                  </select>
                </div>
                <div class="form-group mb-0 ml-2 col-md-2 p-0">
                  <label class="text-secondary">Gender</label>
                  <select
                    class="form-control"
                    v-model="meta_filter.gender"
                    @change="tableData()"
                  >
                    <option value="all">-- All Gender --</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>

                <div class="form-group mb-0 ml-2 col-md-2 p-0">
                  <label class="text-secondary">Kecamatan</label>
                  <select
                    class="form-control"
                    v-model="meta_filter.id_kecamatan"
                    @change="tableData()"
                  >
                    <option value="all">-- All Kecamatan --</option>
                    <option
                      v-for="(items, idx) in model_kecamatan"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.nama_kecamatan }}
                    </option>
                  </select>
                </div>
                <div class="form-group mb-0 ml-2 col-md-2 p-0">
                  <label class="text-secondary">Kota</label>
                  <select
                    class="form-control"
                    v-model="meta_filter.id_kota"
                    @change="fetchKecamatan()"
                  >
                    <option value="all">-- All Kota --</option>
                    <option
                      v-for="(items, idx) in model_kota"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.nama_kota }}
                    </option>
                  </select>
                </div>
                <div class="form-group mb-0 ml-2 col-md-2 p-0">
                  <label class="text-secondary">Propinsi</label>
                  <select
                    class="form-control"
                    v-model="meta_filter.id_propinsi"
                    @change="fetchKota()"
                  >
                    <option value="all">-- All Propinsi --</option>
                    <option
                      v-for="(items, idx) in model_province"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.nama_provinsi }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="table-cover">
            <table class="table-hover" style="width: 2300px">
              <thead>
                <tr>
                  <th>No</th>
                  <th class="no-filter">Tools</th>
                  <th class="text-center">Photo</th>
                  <th
                    :class="
                      meta_data.okey == 'employee.activity_status'
                        ? 'active'
                        : ''
                    "
                    v-on:click="tableOrdering('employee.activity_status')"
                  >
                    Employee Status
                  </th>
                  <th
                    :class="meta_data.okey == 'employee.nik' ? 'active' : ''"
                    v-on:click="tableOrdering('employee.nik')"
                  >
                    NIK
                  </th>
                  <th
                    :class="
                      meta_data.okey == 'employee.firstname' ? 'active' : ''
                    "
                    v-on:click="tableOrdering('employee.firstname')"
                  >
                    Complete Name
                  </th>
                  <th
                    :class="
                      meta_data.okey == 'employee.job_title' ? 'active' : ''
                    "
                    v-on:click="tableOrdering('employee.job_title')"
                  >
                    Job Title
                  </th>
                  <th
                    :class="meta_data.okey == 'employee.email' ? 'active' : ''"
                    v-on:click="tableOrdering('employee.email')"
                  >
                    Email
                  </th>
                  <th
                    :class="
                      meta_data.okey == 'employee.phone_number' ? 'active' : ''
                    "
                    v-on:click="tableOrdering('employee.phone_number')"
                  >
                    Phone Number
                  </th>
                  <th
                    :class="
                      meta_data.okey == 'employee.indentity_number'
                        ? 'active'
                        : ''
                    "
                    v-on:click="tableOrdering('employee.indentity_number')"
                  >
                    Identify Card
                  </th>
                  <th
                    :class="
                      meta_data.okey == 'employee.nationality' ? 'active' : ''
                    "
                    v-on:click="tableOrdering('employee.nationality')"
                  >
                    Nationality
                  </th>
                  <th
                    :class="
                      meta_data.okey == 'employee.marital_status'
                        ? 'active'
                        : ''
                    "
                    v-on:click="tableOrdering('employee.marital_status')"
                  >
                    Marital Status
                  </th>
                  <th
                    :class="meta_data.okey == 'employee.gender' ? 'active' : ''"
                    v-on:click="tableOrdering('employee.gender')"
                  >
                    Marital Status
                  </th>
                  <th
                    :class="
                      meta_data.okey == 'employee.birth_place' ? 'active' : ''
                    "
                    v-on:click="tableOrdering('employee.birth_place')"
                  >
                    Birth Place
                  </th>
                  <th
                    :class="
                      meta_data.okey == 'employee.birth_date' ? 'active' : ''
                    "
                    v-on:click="tableOrdering('employee.birth_date')"
                  >
                    Birth Date
                  </th>
                  <th
                    :class="
                      meta_data.okey == 'employee.address' ? 'active' : ''
                    "
                    v-on:click="tableOrdering('employee.address')"
                  >
                    Address
                  </th>
                  <th
                    :class="
                      meta_data.okey == 'employee.created_at' ? 'active' : ''
                    "
                    v-on:click="tableOrdering('employee.created_at')"
                  >
                    Created Date
                  </th>
                  <th
                    :class="
                      meta_data.okey == 'employee.udpated_at' ? 'active' : ''
                    "
                    v-on:click="tableOrdering('employee.udpated_at')"
                  >
                    Updated Date
                  </th>
                </tr>
              </thead>
              <tbody v-if="model.total > 0">
                <tr v-for="(items, idx) in model.data" :key="idx">
                  <td>{{ idx + model.from }}</td>
                  <td class="text-center">
                    <input
                      type="checkbox"
                      @change="collectGroup(idx)"
                      :checked="validateChecked(items.id)"
                      :id="'checkbox-' + items.id"
                    />
                  </td>
                  <td class="text-center">
                    <img
                      :src="$store.state.api_resource + items.photo"
                      class="table-picture-profile"
                      v-show="items.photo !== null"
                    />
                    <div
                      class="table-avatar"
                      v-show="items.photo == null"
                    ></div>
                  </td>
                  <td>
                    <span :class="items.activity_status | activityStatus">{{
                      items.activity_status
                    }}</span>
                  </td>
                  <td># {{ items.nik }}</td>
                  <td>{{ items.firstname }} {{ items.lastname }}</td>
                  <td>{{ items.job_title }}</td>
                  <td>{{ items.email }}</td>
                  <td>{{ items.phone_number }}</td>
                  <td>
                    {{ items.identity_card }}<br />
                    {{ items.identity_number }}
                  </td>
                  <td>{{ items.nationality }}</td>
                  <td>{{ items.marital_status }}</td>
                  <td>{{ items.gender }}</td>
                  <td>{{ items.birth_place }}</td>
                  <td>{{ items.birth_date }}</td>
                  <td width="250">{{ items.address }}</td>
                  <td>
                    {{ items.created_at | moment("DD/MM/YYYY - h:mm:ss") }}
                  </td>
                  <td>
                    {{ items.updated_at | moment("DD/MM/YYYY - h:mm:ss") }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="19">
                    <i class="ti ti-info-alt text-danger"></i> Resource employee
                    not found.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pagination" v-if="model.total > 0">
            <small class="info"
              ><i class="ti ti-split-h"></i> Scroll horizontal to view
              data.</small
            >
            <paginate
              :page-count="parseInt(model.last_page)"
              :click-handler="tableData"
              :container-class="'pagination'"
              :page-class="'page-item'"
            >
            </paginate>
          </div>
        </div>
        <div class="col-md-12">
          <hr />
          <a
            v-if="model.total > 0"
            href="javascript:;"
            class="btn btn-primary btn-execute float-right"
            @click="applyData()"
            >Continue Assign</a
          >
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import $ from "jquery";
import SelectBranch from "../Branch/@SelectBranch";
export default {
  props: ["model_nik"],
  components: {
    SelectBranch,
  },
  data() {
    return {
      isEnableTools: false,
      isEnableOrder: false,
      isEnablePopup: false,
      model_collection: [],
      model_group: [],
      isRoot: false,
      model: [],
      model_job_position: [],
      model_province: [],
      model_kota: [],
      model_kecamatan: [],

      meta_url: "employee",
      meta_filter: {
        job_position: "all",
        gender: "all",
        activity_status: "active",
        id_propinsi: "all",
        id_kota: "all",
        id_kecamatan: "all",
        id_branch: "all",
      },
      meta_data: {
        okey: "created_at",
        oval: "desc",
        wkey: "",
        wval: "",
        skey: "",
        sval: "",
      },
    };
  },

  computed: {},
  created() {
    this.meta_filter.id_branch = localStorage.getItem("userBranchId");

    this.tableData();
    this.QueryJobPosition();
    this.fetchProvinces();
    if (localStorage.getItem("userPrivilegesName") == "root") {
      this.isRoot = true;
    }
  },

  methods: {
    close() {
      this.$emit("exit");
    },
    applyData() {
      if (this.model_group.length < 1) {
        alert("Please choose some employee !");
        return false;
      }
      this.$emit("exit");
      this.$emit("applyData", this.model_group);
    },
    validateChecked(id) {
      var model = this.model_collection;
      return model.includes(id) ? true : false;
    },
    collectGroup(idx) {
      let model = this.model.data[idx];

      if ($("#checkbox-" + model.id).is(":checked")) {
        this.model_collection.push(model.id);

        if (this.model_group.length < 1) {
          this.model_group = [];
        }

        this.model_group.unshift({
          id: model.id,
          activity_status: model.activity_status,
          firstname: model.firstname,
          lastname: model.lastname,
          nik: model.nik,
          photo: model.photo,
          phone_number: model.phone_number,
          identity_card: model.identity_card,
          identity_number: model.identity_number,
          job_title: model.job_title,
          email: model.email,
          sallary: model.sallary,
          id_job_position: model.id_job_position,
          address: model.address,
        });
        // console.log(this.model_group)
      } else {
        this.disCollecGroup(idx);
      }
    },

    disCollecGroup(idx) {
      let model = this.model.data[idx];
      var model_collection = this.model_collection;
      var index_collection = model_collection.indexOf(model.hk_id);
      this.model_collection.splice(index_collection, 1);

      var model_group = this.model_group;
      var no = 0;
      for (let key in model_group) {
        if (model_group[key].hk_id == model.hk_id) {
          this.model_group.splice(no, 1);
        }
        no++;
      }
    },

    async fetchProvinces() {
      await axios
        .get("location/propinsi/data")
        .then((response) => {
          this.model_province = response.data;
          this.model_kota = [];
          this.model_kecamatan = [];
          this.tableData();
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    async fetchKota() {
      this.meta_filter.id_kota = "all";
      this.meta_filter.id_kecamatan = "all";
      await axios
        .post("location/kota/data", {
          where: { key: "provinsi_id", value: this.meta_filter.id_propinsi },
          order: { key: "id", value: "asc" },
        })
        .then((response) => {
          this.model_kota = response.data;
          this.tableData();
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    async fetchKecamatan() {
      this.meta_filter.id_kecamatan = "all";
      await axios
        .post("location/kecamatan/data", {
          where: { key: "kota_id", value: this.meta_filter.id_kota },
          order: { key: "id", value: "asc" },
        })
        .then((response) => {
          this.model_kecamatan = response.data;
          this.tableData();
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    async QueryJobPosition() {
      await axios
        .get("job_position/all")
        .then((res) => {
          this.model_job_position = res.data;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
          this.isEnableEquipment = true;
        });
    },
    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
      if (this.isEnableTools) this.meta_data.sval = "";
    },
    tableSearch() {
      if (this.meta_data.skey == "") {
        alert("🙏, Please choose target field");
        return;
      }
      if (this.meta_data.sval == "") {
        alert("🙏, Please enter the keyword");
        return;
      }
      this.tableData();
    },
    tableOrdering(key) {
      this.isEnableOrder = !this.isEnableOrder;
      this.meta_data.okey = key;
      this.meta_data.oval = this.isEnableOrder ? "ASC" : "DESC";
      this.tableData(this.page);
    },
    processBranch(data) {
      this.meta_filter.id_branch = data;
      this.tableData();
    },
    tableData(pageNum) {
      this.page = pageNum == undefined ? 1 : pageNum;
      let data = {
        offset: this.page,
        search: { field: this.meta_data.skey, keyword: this.meta_data.sval },
        order: { key: this.meta_data.okey, value: this.meta_data.oval },
        filter: [
          { key: "id_job_position", val: this.meta_filter.job_position },
          { key: "gender", val: this.meta_filter.gender },
          //{key : 'activity_status', val:this.meta_filter.activity_status},
          { key: "teritori_provinsi.id", val: this.meta_filter.id_propinsi },
          { key: "teritori_kota.id", val: this.meta_filter.id_kota },
          { key: "teritori_kecamatan.id", val: this.meta_filter.id_kecamatan },
          { key: "employee.id_branch", val: this.meta_filter.id_branch },
        ],
      };

      axios
        .post(this.meta_url + "/available?page=" + this.page, {
          search: data.search,
          order: data.order,
          filter: data.filter,
          pospoint: data.pospoint,
          model_nik: this.model_nik,
        })
        .then((response) => {
          this.model = response.data;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    tableRefresh() {
      this.meta_data.skey = "";
      this.meta_data.sval = "";
      this.meta_filter = {
        job_position: "all",
        gender: "all",
        activity_status: "active",
        id_propinsi: "all",
        id_kota: "all",
        id_kecamatan: "all",
      };
      this.tableData();
    },
  },
};
</script>
