<template>
 <div class="popup popup-md">
    <div class="content" v-if="model.client_name !== null && !isFinishLoad">
        <small id="close-popup" @click="close()"><i class="ti ti-close"></i></small>
        <div class=" mt-5">
             <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-5 p-3 pl-4">
                                <div class="detail-picture">
                                    <img :src="$store.state.api_resource + model.photo">
                                    <h6>{{ model.firstname+' '+model.lastname }}</h6>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <ul class="list-area">
                                    
                                    <li>
                                        <span class="text-secondary">NIK</span>
                                        <span class="float-right">{{ model.nik }}</span>
                                    </li>
                                    <li>
                                        <span class="text-secondary">Email</span>
                                        <span class="float-right">{{ model.email }}</span>
                                    </li>
                                     <li>
                                        <span class="text-secondary">Phone</span>
                                        <span class="float-right">{{ model.phone_number }}</span>
                                    </li>
                                    <li>
                                        <span class="text-secondary">Gender</span>
                                        <span class="float-right">{{ model.gender }}</span>
                                    </li>
                                    <li>
                                        <span class="text-secondary">Address</span><br/>
                                        <span class="" >
                                            {{ model.address }} 
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                     <div class="form-group mt-5 mb-2">
                        <div class="row">
                            <div class="col-md-12">
                                
                                <select class="form-control float-left col-md-5" v-model="filter.client_idx" @change="FilterClientBase()">
                                    <option value="">-- Choose Client Base --</option>
                                    <option v-for="(items, idx) in model_client" :key="idx"  :value="idx">{{ items.contract_number }} - {{ items.client_name }}</option>
                                </select>
                                  <select class="form-control float-right col-md-3 mr-2" v-model="filter.month" @change="QueryContractPeriod()">
                                    <option value="all">-- All Month --</option>
                                    <option v-for="(items, index) in collection_month" :key="index" :value="items" >{{ parseInt(items) | convertStringMonth }}</option>
                                </select>
                                <select class="form-control float-right col-md-3 mr-2" v-model="filter.year" @change="FilterYear()">
                                    <option v-for="(items, index) in collection_year" :key="index" :value="items" >{{ items }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="subtitle">Attendace </label>
                            </div>
                            <div class="col-md-8 mt-3" v-if="filter.month == 'all'">
                                <div id="chart-attendance"></div>
                            </div>
                             <div class="col-md-8 mt-3" v-if="filter.month !== 'all'">
                                <div class="chart-list" id="text-attendance">
                                    <ul></ul>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="rpt">
                                    <h6>{{ filter.client_name }}</h6>
                                    <div class="grade"><span>{{ chart_attendance.present > 0 ? chart_attendance.average_grade : '-' }}</span></div>
                                    <label>Grade Level</label><br/><br/>
                                    <span class="info">Present : <b>{{ chart_attendance.present }} </b> &nbsp; Absent : <b>{{ chart_attendance.absent }}</b></span><br/>
                                    <p>Total Attendance Score <span class="text-primary">{{ chart_attendance.average_score }}</span></p>
                                </div>
                            </div>
                        </div>
                         <div class="row mt-4">
                            <div class="col-md-12">
                                <label class="subtitle">Diligently</label>
                            </div>
                            <div class="col-md-8 mt-3">
                                <div class="chart-list" id="text-attendance">
                                    <ul v-if="chart_diligently.checklist ">
                                        <li v-for="(items, idx) in chart_diligently.collection" :key="idx">
                                            <span v-if="filter.month == 'all'"><i class="ti ti-calendar text-success"></i> {{ idx }}/{{ filter.year }}</span>
                                            <span v-if="filter.month !== 'all'">{{ idx }}/{{ filter.month }}/{{ filter.year }}</span>
                                            <span class="float-right text-muted">Checklist Area <span class="text-primary">{{ items }} x</span> </span>
                                        </li>
                                    </ul>
                                    <div v-else>
                                        <p class="">This employee has no record of attendance at the client station !</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="rpt">
                                    <h6>{{ filter.client_name }}</h6>
                                    <div class="grade"><span>{{ chart_diligently.checklist > 0 ? chart_diligently.average_grade : '-'}}</span></div>
                                    <label>Grade Level</label><br/><br/>
                                    <span class="info">CP : <b>{{ chart_diligently.checklist }} x</b> &nbsp; Total Data  : <b>{{ chart_diligently.total }}</b></span><br/>
                                    <p>Total Diligently Score <span class="text-primary">{{ chart_diligently.average_score }}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content" v-else>
        <small id="close-popup" @click="close()"><i class="ti ti-close"></i></small>
        <h4>Sorry, Data is empty ...</h4>
        <p>This employee has no record of attendance at the client station !</p>
    </div>

</div>
</template>

<script>
    import { mapState } from 'vuex';
    import axios from 'axios';
    import Highcharts from 'highcharts';
    import $ from 'jquery';
    
    export default 
    {
        data() {
            return {
                isFinishLoad :true,
                model:{},
                model_client:[],
                meta_url : 'employee',
                chart_attendance : {
                    params: [],
                    value : [],
                    absent : 0,
                    attendance : 0,
                    average_score : 0,
                    average_grade : '',
                },

                chart_diligently : {
                    collection : [],
                    checklist : 0,
                    total : 0,
                    average_score : 0,
                    average_grade : '',
                },

                collection_year : [],
                collection_month : [],
                filter : {
                    client_idx : 0,
                    client_name : '',
                    id_contract : 0,
                    year : 'all',
                    month : 'all',
                }
            }
        },

        created()
        {
            this.QueryDetail();
        },

        methods:
        {
            close(){
                this.$emit('exit');
            },
            FilterClientBase(idx)
            {
                let model = this.model_client[this.filter.client_idx];
                this.filter.id_contract = model.id_contract;
                this.filter.client_name = model.client_name;
                this.QueryContractPeriod();
            },
            FilterYear()
            {
                this.filter.month = 'all';
                this.QueryContractPeriod();
            },
           
            async QueryClientBase()
            {
                await axios.post( 'employee/contract', {
                    nik :localStorage.getItem('QUERY_NIK')
                })
                .then((res) => {
                    this.model_client = res.data;
                    this.filter.id_contract = this.model_client[0].id_contract;
                    this.QueryContractPeriod();
                    

                }).finally(() => {
                    this.$store.state.loading.status = false;
                });
            },

            async QueryContractPeriod()
            {
                this.collection_year  = [];
                this.collection_month = [];

                await axios.get( 'contract/period/'+this.filter.id_contract)
                .then((res) => {

                    let query = res.data;

                    let default_date = query.default;
                    if( this.filter.year == 'all' ){
                        let dta = default_date.split('/');
                        this.filter.year  = dta[1];
                    }
                    
                    let month_period = query.month_period;
                    for(let key in month_period)
                    {
                        let dtb = month_period[key].split('/');
                        if( ! this.collection_year.includes(dtb[1]) ){
                            this.collection_year.push(dtb[1]);
                        }

                        if( dtb[1] == this.filter.year ){
                            this.collection_month.push(dtb[0]);
                        }
                    }

                    this.QueryPerformance();
                    this.QueryDiligently();

                }).finally(() => {
                    this.$store.state.loading.status = false;
                });
            },
            
            async QueryPerformance()
            {
                await axios.post( 'employee/summary-performance', {
                    nik : localStorage.getItem('QUERY_NIK'),
                    id_contract : this.filter.id_contract,
                    year : this.filter.year,
                    month : this.filter.month,
                })
                .then((res) => {

                    this.chart_attendance.absent        = res.data.absent;
                    this.chart_attendance.present       = res.data.present;
                    this.chart_attendance.average_score = res.data.average_score;
                    this.chart_attendance.average_grade = res.data.average_grade;

                   // console.log('hasil', this.chart_attendance.params);
                    if(this.filter.month == 'all'){
                        this.chart_attendance.params = res.data.params;
                        this.chart_attendance.value  = res.data.value;
                        this.ChartAttendance();
                    }else{
                        let model_params = res.data.params;
                        let model_value = res.data.value;

                        var html = '';
                        for(let key in model_params)
                        {
                            var att = model_value[key];

                            var str_att = '-';
                            var classes = 'float-right text-muted';
                            if(att != 0){

                                if(att == 'Absent'){
                                    str_att = 'Absent';
                                    classes = 'float-right text-danger';
                                }
                                if(att !== 'Absent' && att !== '0'){
                                    str_att = 'Present';
                                    classes = 'float-right text-success';
                                }
                            }
                            
                            html += '<li style="list-style:none;float:left;width:100%;padding:5px;border-bottom:1px solid whitesmoke; "><span class="text-muted">'+model_params[key]+'/'+this.filter.month+'/'+this.filter.year+'</span> <span class="'+classes+'">'+str_att+'</span></li>';
                        }

                        $('#text-attendance').find('ul').html(html);
                    }

                }).finally(() => {
                    this.$store.state.loading.status = false;
                });
            },


            async QueryDiligently()
            {
                await axios.post( 'employee/summary-diligently', {
                    nik : localStorage.getItem('QUERY_NIK'),
                    id_contract : this.filter.id_contract,
                    year : this.filter.year,
                    month : this.filter.month,
                })
                .then((res) => {
                    

                    this.chart_diligently.average_score = res.data.average_score;
                    this.chart_diligently.average_grade = res.data.average_grade;
                    this.chart_diligently.checklist = res.data.checklist;
                    this.chart_diligently.collection = res.data.collection;
                    this.chart_diligently.total = res.data.total;


                }).finally(() => {
                    this.$store.state.loading.status = false;
                });
            },

            async QueryDetail()
            {
                await axios.get("employee/find/"+localStorage.getItem('QUERY_ID'))
                .then((response) => {
                    this.model = response.data;
                    if( this.model.client_name !== null ){
                        this.QueryClientBase();
                    }
                    this.isFinishLoad = false;
                })
                .catch((error => {
                })).finally(() => {
                    this.$store.state.loading.status = false;
                });
            },


            ChartAttendance()
            {
                setTimeout(() => {
                    Highcharts.chart('chart-attendance', {
                    credits:{
                        enabled:false
                    },
                    chart: {
                        type: 'area'
                    },
                    title: {
                        text: ''
                    },
                   
                    legend: {
                        layout: 'vertical',
                        align: 'left',
                        verticalAlign: 'top',
                        x: 100,
                        y: 70,
                        floating: true,
                        borderWidth: 1,
                        backgroundColor:
                            Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF'
                    },
                    xAxis: {
                        categories: this.chart_attendance.params
                    },
                    yAxis: {
                        title: {
                            text: ''
                        }
                    },
                    plotOptions: {
                        area: {
                            fillOpacity: 0.5
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series: [ {
                        name: 'Attendance',
                        data: this.chart_attendance.value
                    }]
                });
                        
                }, 100);
            },

           
        }
    }
</script>

<style lang="scss" scoped>

ul.list-area{
    float:right;
    width:100%;
    margin:0;
    padding:0;
    li{
        list-style: none;
        float:left;
        width:100%;
        padding:5px;
        border-bottom:1px solid whitesmoke;
    }
}
div.avatar{
    margin:0px !important;
    margin-bottom:20px !important;
    margin-top:20px !important;
}

.detail-picture{
    position:relative;
    margin:0 auto;
    width:150px;
    border-radius:5px;
    overflow:hidden;
    text-align:center;
    img{
        width:100%;
    }
    h6{
        margin-top:15px;
        font-size:13px;
    }
}


.subtitle{
    font-size:13px;
    font-weight: 100;
    border-bottom:1px solid gray;
}
.rpt{
    text-align:center;
    padding-top:40px;

    h6{
        text-align:center;
        margin-bottom:20px;
    }
    .grade{
        width:70px;
        height:70px;
        padding:5px;
        background-color:whitesmoke;
        text-align:center;
        border:1px solid #a6beff;
        margin:0 auto;
        margin-bottom:10px;
        border-radius:70px;
        -moz-border-radius:70px;
        -webkit-border-radius:70px;
        span{
            font-size:30px;
            font-weight: bold;
            position:relative;
            top:5px;
            color:#437bfb;
        }
    }
    span.info{
        color:gray;
        b{
            color:#0070ff;
        }
    }
}

#chart-attendance, #chart-work{
    width:100%;
    height:300px;
}

.chart-list{
    width:100%;
    border-right:1px solid whitesmoke;
    height:260px;
    overflow-y:scroll;
    margin-bottom:20px;
    li{
        list-style:none;float:left;width:100%;padding:5px;border-bottom:1px solid whitesmoke; 
    }
}



</style>