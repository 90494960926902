import "bootstrap/dist/css/bootstrap.min.css";
import Vue from "vue";
import axios from "axios";
import router from "./router";
import store from "./modules/index";
import excel from "vue-excel-export";
import VueNumeric from "vue-numeric";
import VueWysiwyg from "@mycure/vue-wysiwyg";
import VueHtmlToPaper from "vue-html-to-paper";
export const bus = new Vue();

// global component
import App from "./App.vue";
import Paginate from "vuejs-paginate";
import TableApp from "./components/parts/Table-App";
import TableTools from "./components/parts/Table-Tools";
import ScreenApp from "./components/parts/Screen-App";
import PopupMsg from "./components/parts/PopupMessage";
// import * as firebase from "firebase/app";

/** vue loader */
import "vue-loaders/dist/vue-loaders.css";
import VueLoaders from "vue-loaders";

require("./filter");
Vue.use(require("vue-moment"));
Vue.use(VueLoaders);
Vue.component("paginate", Paginate);
Vue.component("TableApp", TableApp);
Vue.component("TableTools", TableTools);
Vue.component("ScreenApp", ScreenApp);
Vue.component("PopupMsg", PopupMsg);
Vue.use(excel);
Vue.use(VueNumeric);
Vue.use(VueWysiwyg);

// let basePath = "http://localhost:8080"; // Local
let basePath = "http://station.tukangbersih.com"; // Production
// let basePath = "http://stationtukangbersih.ecocare.co.id" // Staging
// let basePath = "http://stationtukangbersihdev.ecocare.co.id" // Development

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    `${basePath}/print.css`,
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: "Generate Report", // override the window title
};

Vue.use(VueHtmlToPaper, options);

/* firebase */
// Disable Sementara - Rudy
// var config_firebase = {
//   apiKey: "AIzaSyBTm_VCIYOot3InS5612qLim4q9ZBpEUxk",
//   authDomain: "tb-station.firebaseapp.com",
//   databaseURL: "https://tb-station.firebaseio.com",
//   projectId: "tb-station",
//   storageBucket: "tb-station.appspot.com",
//   messagingSenderId: "21203679580",
//   appId: "1:21203679580:web:2a7e914da64448bdfd2999",
// };
// firebase.initializeApp(config_firebase);

Vue.config.productionTip = false;

/* axios */
axios.defaults.baseURL = store.state.api_resource + "index.php/api/";
axios.interceptors.request.use(
  (config) => {
    if (config.url !== "auth/login") {
      let token = localStorage.getItem("jwt-token");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// RESPONSE
axios.interceptors.response.use((response) => {
  // bisa di gunakan untuk keperluan beberapa logic

  // untuk informasi callback update module

  // untuk relogin

  //

  if (response.data.status == "token_expired") {
    store.dispatch("authenticate/logout");
    /*store.state.alert = {
            status : true,
            type : 'danger',
            msg    : 'Token Expired, Please relogin for a while. ',
            close_redirect : '/'
        }*/
  } else {
    return response;
  }
});

new Vue({
  el: "#app",
  router,
  store,
  template: "<App/>",
  components: { App },
});
