<template>
  <div>
    <audio id="audio-notification"></audio>
    <a href="#" class="nav-link">
      <i
        :class="
          total > 0
            ? 'ti ti-bell notification active-notif'
            : 'ti ti-bell notification'
        "
      ></i>
      <div class="dropdown dropdown" style="right: 120px" v-if="total > 0">
        <h5>
          Notification Message
          <span class="float-right text-primary">{{
            total > 0 ? total : ""
          }}</span>
        </h5>
        <div class="scrollbox">
          <ul>
            <li v-for="(items, idx) in notifications" :key="idx">
              <router-link :to="{ name: 'notification/message' }">
                <div class="icon"><i class="ti ti-info-alt"></i></div>
                <div class="info">
                  <span>{{ items.title }} </span><br />
                  <span class="text-muted"
                    >{{ items.message | limit(50, "...") }} </span
                  ><br />
                  <small class="">{{
                    items.created_at | moment("DD/MM/YYYY")
                  }}</small>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
// import firebase from "firebase";
import axios from "axios";
// import $ from 'jquery';
// import router from '../../router';

export default {
  data() {
    return {
      total: 0,
      notifications: [],
      fbpath: "/message",
      //   fbcon: firebase.database(),
    };
  },
  mounted() {
    this.listen_notification();
  },
  created() {
    setTimeout(() => {
      document.getElementById("audio-notification").src =
        localStorage.getItem("NOTIFICATION_SOUND");
      //   var sys_audio = document.getElementById("audio-notification");
    }, 200);
  },
  methods: {
    listen_notification() {
      // Disable Sementara - Rudy
      // this.fbcon.ref(this.fbpath).on('value', snapshot =>
      // {
      //     let model = snapshot.val();
      //     for(let key in model){
      //         this.total++;
      //     }
      //     if(this.total > 0)
      //     {
      //         this.QueryMessage();
      //     }
      // }, errorObject => {
      //     console.log('Error retrieving thoughts: ' + errorObject.code);
      // });
    },

    async QueryMessage() {
      await axios
        .post("notification/message", {
          where: { key: "status", value: "unread" },
        })
        .then((res) => {
          let query = res.data;
          this.notifications = query.data;

          var sys_audio = document.getElementById("audio-notification");
          sys_audio.load();
          sys_audio.play();

          // clean firebase
          //   this.fbcon.ref(this.path).remove(); disable sementara - Rudy
        })
        .finally(() => {});
    },
  },
};
</script>

