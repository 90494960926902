<template>
  <div class="col-md-12 p-0">
    <div class="card">
      <div class="card-body">
        <div class="table-header">
          <h5>
            Master Data <br />
            <small
              >Total Records :
              <b>{{ data_model.total | formatPrice }}</b></small
            >
          </h5>
          <div class="group-search pull-right">
            <a class="btn" @click="tableRefresh()"
              ><i class="ti ti-reload"></i
            ></a>
            <a class="btn" @click="isActiveSearch()"
              ><i
                :class="isEnableTools == false ? 'ti ti-search' : 'ti ti-close'"
              ></i
            ></a>
            <div class="select" v-if="isEnableTools">
              <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                <option value="">-- Choose Field --</option>
                <option value="title">Job Title</option>
                <option value="description">Description</option>
              </select>
            </div>
            <div class="group" v-if="isEnableTools">
              <input
                type="date"
                v-model="meta_data.sval"
                v-if="
                  meta_data.skey == 'created_at' ||
                  meta_data.skey == 'updated_at'
                "
              />
              <input
                type="text"
                v-model="meta_data.sval"
                placeholder="Enter Keyword ...."
                v-else
              />
              <a class="btn" @click="tableSearch()"> SEARCH DATA </a>
            </div>
            <router-link
              :to="{ name: 'jobcon/contract-store' }"
              class="btn"
              v-if="$store.state.app.access.includes('insert')"
              ><i class="ti ti-plus"></i> Create New</router-link
            >
          </div>
          <div class="group-filter" v-if="isRoot">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group float-right">
                  <SelectBranch v-on:processBranch="processBranch" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-cover">
          <table class="table-hover">
            <thead>
              <tr>
                <th
                  width="70"
                  class="no-filter"
                  v-if="$store.state.app.access.includes('detail')"
                >
                  Action
                </th>
                <th
                  :class="
                    meta_data.okey == 'contract.contract_number' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('contract.contract_number')"
                >
                  Contract Number
                </th>
                <th
                  :class="meta_data.okey == 'client.name' ? 'active' : ''"
                  v-on:click="tableOrdering('client.name')"
                >
                  Client Name
                </th>
                <th
                  :class="
                    meta_data.okey == 'contract.start_date' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('contract.start_date')"
                >
                  Contract Period
                </th>
                <th
                  :class="
                    meta_data.okey == 'contract.man_power' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('contract.man_power')"
                >
                  Total Man Power
                </th>
              </tr>
            </thead>
            <tbody v-if="data_model.total > 0">
              <tr v-for="(items, idx) in data_model.data" :key="idx">
                <td
                  width="150"
                  v-if="$store.state.app.access.includes('detail')"
                >
                  <a href="javascript:;" @click="DetailAttendance(items.id)"
                    ><i class="ti ti-time text-success"></i> Detail
                    Attendance</a
                  >
                </td>
                <td width="160">{{ items.contract_number }}</td>
                <td width="350">
                  <b>{{ items.client_name }}</b
                  ><br />
                  <small class="text-secondary"
                    >{{ items.office_address }}, {{ items.nama_provinsi }} -
                    {{ items.nama_kota }}</small
                  >
                </td>
                <td class="pr-3">
                  <span :class="items.status | contractStatus">{{
                    items.status | cleanString
                  }}</span>
                  <br />
                  {{ items.start_date | moment("DD/MM/YYYY") }} -
                  {{ items.end_date | moment("DD/MM/YYYY") }}
                </td>
                <td class="pr-3">
                  <span class="text-danger">{{
                    GetTotalManPower(items.man_power)
                  }}</span>
                  - Employee
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">
                  <div class="no-data" v-if="!$store.state.loading.status">
                    Sorry data is not available
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          <small class="info"
            ><i class="ti ti-split-h"></i> Scroll horizontal to view
            data.</small
          >
          <paginate
            :page-count="parseInt(data_model.last_page)"
            :click-handler="tableQuery"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../../router";
import PopupDetailContract from "../../JobContract/@PopupDetailContract";
import { mapState } from "vuex";
import MixServerDate from "../../../mixin/mix_serverdate.js";
import SelectBranch from "../../Branch/@SelectBranch";

export default {
  mixins: [MixServerDate],
  data() {
    return {
      isRoot: false,

      isEnableTools: false,
      isEnableOrder: false,
      id_contract: null,

      meta_url: "contract",
      meta_data: {
        okey: "created_at",
        oval: "desc",
        wkey: "",
        wval: "",
        skey: "",
        sval: "",
      },
      meta_filter: { id_branch: "all" },
    };
  },
  components: {
    PopupDetailContract,
    SelectBranch,
  },
  computed: {
    ...mapState("crud", {
      data_model: (state) => state.data_model,
      msg_error: (state) => state.error,
    }),
  },
  created() {
    const roleUserPrivilegesName = ["root", "hrd nasional"];

    // filter branch
    if (
      !roleUserPrivilegesName.includes(
        localStorage.getItem("userPrivilegesName")
      )
    ) {
      this.meta_filter.id_branch = localStorage.getItem("userBranchId");
    } else {
      this.isRoot = true;
    }
    this.tableQuery();
  },
  methods: {
    processBranch(data) {
      this.meta_filter.id_branch = data;
      this.tableQuery();
    },
    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
      if (this.isEnableTools) this.meta_data.sval = "";
    },
    GetTotalManPower(data) {
      let res = JSON.parse(data);
      let total = 0;
      for (let key in res) {
        total = total + parseInt(res[key].total);
      }
      return total;
    },
    tableSearch() {
      if (this.meta_data.skey == "") {
        alert("🙏, Please choose target field");
        return;
      }
      if (this.meta_data.sval == "") {
        alert("🙏, Please enter the keyword");
        return;
      }
      this.tableQuery();
    },
    tableOrdering(key) {
      this.isEnableOrder = !this.isEnableOrder;
      this.meta_data.okey = key;
      this.meta_data.oval = this.isEnableOrder ? "ASC" : "DESC";
      this.tableQuery(this.page);
    },
    tableQuery(pageNum) {
      console.log(pageNum);
      this.page = pageNum;
      let data = {
        url: this.meta_url + "/report-list",
        offset: this.page,
        search: { key: this.meta_data.skey, value: this.meta_data.sval },
        order: { key: this.meta_data.okey, value: this.meta_data.oval },
        where: { key: this.meta_data.wkey, value: this.meta_data.wval },
        filter: [
          { key: "contract.id_branch", val: this.meta_filter.id_branch },
          {
            key: "contract-station",
            val: localStorage.getItem("userContract"),
          },
        ],
      };

      this.$store.state.loading.msg = "Processing Fetching Data...";
      this.$store.state.loading.status = true;
      this.$store.dispatch("crud/getData", data);
    },
    tableRefresh() {
      this.meta_data.skey = "";
      this.meta_data.sval = "";
      this.$store.state.loading.msg = "Refreshing Data...";
      this.$store.state.loading.status = true;
      this.$store.dispatch("crud/getData", { url: this.meta_url + "/data" });
    },
    DetailAttendance(id) {
      localStorage.setItem("QUERY_ID", id);
      router.push({ name: "report/attendance-detail" });
    },
  },
};
</script>
