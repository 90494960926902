<template>
     <div class="card">
        <div class="card-body">
            <div class="table-header">
                <h5>Master Data <br/> <small>Total Records : <b>{{ data_model.total | formatPrice }}</b></small></h5>
                
                <div class="group-search pull-right">
                    
                    <a class="btn" @click="tableQuery()">
                        <i class="ti ti-reload"></i>
                    </a>
                    <a class="btn" @click="isActiveSearch()">
                        <i :class="isEnableTools == false  ? 'ti ti-search' : 'ti ti-close'"></i>
                    </a>

                    <div class="select" v-if="isEnableTools">
                        <select v-model="skey" @change="sval = ''">
                            <option value="">-- Pilih Kolom --</option>
                            <option v-for="(items, idx) in meta_data.field" v-bind:key="idx" :value="items.key" >{{ items.value }}</option>
                        </select>
                    </div>
                    <div class="group" v-if="isEnableTools">
                        <input type="date" v-model="sval" v-if="skey == 'created_at' || skey == 'updated_at'"/>
                        <input type="text" v-model="sval" placeholder="Masukan Keyword ...." v-else/>
                        <a class="btn" @click="tableSearch()">
                            CARI DATA 
                        </a>
                    </div>  
                    <router-link :to="{ name: meta_data.api + '/store' }" class="btn" v-if="$store.state.access.insert"><i class="ti ti-plus"></i> Tambah Data</router-link>
                </div>
            </div>
            <div class="table-cover">
                <table class="table-hover" :width="width !== undefined ? width : '1200'">
                    <thead>
                        <tr>
                            <th>Action</th>
                            <th v-for="(items, idx) in meta_data.field" v-bind:key="idx" :class="filter_key == items.key ? 'active' : ''" v-on:click="tableOrdering(items.key)">{{ items.value }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) in data_model.data" :key="index">
                            <td>
                                <div class="tools">
                                    <a href="javascript:;">Tools</a>
                                    <ul class="">
                                        <li>
                                            <router-link :to="{ name: meta_data.api + '/update/' + row.id }"  v-if="$store.state.access.update">Update</router-link>
                                        </li>
                                        <li>
                                            <a href="javascript:;" @click="tableRemove(row.id)">Remove</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td v-for="(items, idx) in meta_data.field" v-bind:key="idx">
                                <span>{{ row[items.key] }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="pagination">
                <paginate
                :page-count="parseInt(data_model.last_page)"
                :click-handler="tableQuery"
                :container-class="'pagination'"
                :page-class="'page-item'" >
                </paginate>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex';

    export default {
        props:['width', 'meta_data'],
        data() {
            return {
                filter_key : '',
                isEnableTools : false, 
                wkey : '',
                wval : '',
                skey : '',
                sval : '',
            }
        },
        created(){
            this.tableQuery();
        },
        computed: {
            ...mapState('crud', {
                data_model: state => state.data_model,
            })
        },
        methods:
        {
            
            isActiveSearch(){
                this.isEnableTools =! this.isEnableTools;
                if(this.isEnableTools) this.sval = '';
            },
           
            tableSearch()
            {
                if(this.skey == ''){
                    alert('Silahkan pilih target kolom pencarian terlebih dahulu.');
                    return;
                }
                if(this.sval == ''){
                    alert('Silahkan isikan keyword terlebih dahulu.');
                    return;
                }
                this.data = { url:this.meta_data.api+'/data', where : { key : this.meta_data.where.key, value : this.meta_data.where.value }, search : { field : this.skey, keyword : this.sval } };
                this.$store.dispatch('crud/getData', this.data );
            },
            tableQuery(pageNum)
            {
                this.page = pageNum;
                let data = { 
                    url: this.meta_data.api+'/data',
                    offset : this.page, 
                    order_by : this.order_by, 
                    order_value : this.order_value,
                    where : { key : this.wkey, value : this.wval },
                };
                this.$store.dispatch('crud/getData', data );
            },  
            tableOrdering(key)
            {
                this.isEnableOrder =! this.isEnableOrder;
                this.filter_key  = key;
                this.order_by    = key;
                this.order_value = this.isEnableOrder ? 'ASC' : 'DESC';
                this.tableQuery(this.page);
            },
            tableRemove(id)
            {
                if( confirm( 'Are you sure want to remove this data ?' ) )
                { 
                    let data = { url: this.meta_data.api+'-destroy/'+id, callback_url : this.meta_data.api+'/data' };
                    this.$store.dispatch('crud/deleteData', data );
                }
            }
        }
    }
</script>


