<template>
  <div class="col-md-12 p-0">
    <div class="popup popup-sm" v-if="isEnablePopup">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <h5>
          {{ model_contract.client_name }}<br /><small>
            QR-CODE client base area section room</small
          >
          <hr />
        </h5>
        <div class="row">
          <div class="col-md-12">
            <div class="qr-code">
              <ul>
                <li v-for="(items, idx) in model_section" :key="idx">
                  <VueQRCodeComponent
                    :text="items.id_contract + '-' + items.id"
                    :size="100"
                  >
                  </VueQRCodeComponent>
                  <div class="desc">
                    <p>
                      <span class="text-secondary">Section</span> :
                      {{ items.section }}
                    </p>
                    <p>
                      <span class="text-secondary">Area Floor</span> :
                      {{ items.area_floor }}
                    </p>
                    <p>
                      <span class="text-secondary">Area Type</span> :
                      {{ items.area_type }}
                    </p>
                    <p>
                      <span class="text-secondary">Area Name</span> :
                      {{ items.area_name }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-12">
            <hr />
            <a
              href="javascript:;"
              class="float-right btn btn-primary"
              @click="PrintQrCode()"
              ><i class="ti-printer"></i> PRINT QR-CODE</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body" v-if="!isLoading">
        <div class="form-group">
          <div class="row p-2">
            <div class="col-md-8 p-3 pl-4">
              <p>
                <span
                  :class="model_contract.status | contractStatus"
                  style="font-size: 12px"
                  >{{ model_contract.status | cleanString }}</span
                >
              </p>
              <h5>{{ model_contract.client_name }}</h5>
              <p>
                <span class="text-secondary">{{ model_contract.email }}</span
                ><br /><span class="">{{
                  model_contract.phone_number | formatPhone
                }}</span>
              </p>
              <p>
                {{ model_contract.office_address }} <br />
                {{ model_contract.nama_provinsi }},
                {{ model_contract.nama_kota }}
              </p>
            </div>
            <div class="col-md-4">
              <ul class="list-area">
                <li>
                  <span class="">Contract Number</span>
                  <span class="float-right"
                    ><b style="font-size: 16px"
                      ># {{ model_contract.contract_number }}</b
                    ></span
                  >
                </li>
                <li>
                  <span class="">Contract Period</span>
                  <span class="float-right"
                    ><b
                      >{{ model_contract.start_date | moment("DD/MM/YYYY") }} -
                      {{ model_contract.end_date | moment("DD/MM/YYYY") }}</b
                    ></span
                  >
                </li>
                <li v-if="model_contract.man_power !== undefined">
                  <span class="">MP Request</span><br /><br />
                  <span
                    class="text-muted"
                    v-for="(initems, index) in JSON.parse(
                      model_contract.man_power
                    )"
                    :key="index"
                  >
                    {{ initems.total }} ( {{ initems.job }} )
                    <span v-if="index % 2 == 0">,</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-sm-12">
            <div class="float-left">
              <label class="text-primary mb-0"
                ><i class="ti ti-info-alt"></i> Section Room </label
              ><br />
              <small class="text-secondary" v-if="model_last_created !== ''"
                >Last Update :
                {{ model_last_created | moment("DD/MM/YYYY HH:mm") }}</small
              >
              <br />
              <label
                class="text-default mb-0"
                v-if="model_date_range.length > 0"
              >
                Displaying data from
                {{
                  model_date_range[0].name_of_day +
                  " " +
                  model_date_range[0].day +
                  "/" +
                  model_date_range[0].month +
                  "/" +
                  model_date_range[0].year
                }}
                -
                {{
                  model_date_range[model_date_range.length - 1].name_of_day +
                  " " +
                  model_date_range[model_date_range.length - 1].day +
                  "/" +
                  model_date_range[model_date_range.length - 1].month +
                  "/" +
                  model_date_range[model_date_range.length - 1].year
                }}
              </label>
            </div>

            <div class="float-right">
              <!-- style="width:200px;"  disable sementara-->
              <!-- <select class="form-control" v-model="filter_date" @change="FilterModelDate()">
                                <option v-for="(items, index) in collection_month" :key="index" :value="items" >{{ items }}</option>
                            </select> -->
              <date-picker
                v-model="filter_date"
                :default-value="filter_date"
                :disabled-date="disabledBeforeAfterFilterDate"
                :clearable="false"
                value-type="format"
                type="month"
                format="MM/YYYY"
                placeholder="Pilih Range Tanggal"
                width="100%"
                @change="FilterModelDate()"
                confirm
              >
              </date-picker>
            </div>
            <div
              class="float-right mr-2"
              v-if="$store.state.app.access.includes('print_qrcode')"
            >
              <a
                href="javascript:;"
                class="btn btn-primary"
                @click="isEnablePopup = true"
                ><i class="ti-rss"></i> QR-CODE
              </a>
            </div>
          </div>
          <div class="clearfix"></div>
          <hr />
        </div>

        <div class="row mt-5">
          <div class="col-sm-2 pr-0">
            <div class="table-cover">
              <table class="table-hover">
                <thead>
                  <tr>
                    <th class="no-filter" width="80">
                      <small><b class="text-danger">Section No</b></small>
                    </th>
                  </tr>
                  <tr>
                    <th class="text-danger" width="80">Floor (Area Type)</th>
                  </tr>
                  <tr>
                    <th class="no-filter text-center" width="80">Date</th>
                  </tr>
                </thead>
                <tbody v-if="model_group_employee.length > 0">
                  <tr
                    v-for="(items, index_date) in model_date_range"
                    :key="index_date"
                    :class="'trdate ' + items.month + '-' + items.year"
                  >
                    <td class="tdp" width="80">
                      <span
                        :class="items.name_of_day == 'Sun' ? 'text-danger' : ''"
                        >{{ items.name_of_day }}</span
                      >
                      <small class="text-secondary">{{
                        items.day + "/" + items.month + "/" + items.year
                      }}</small>
                      <div class="tools-master">
                        <a href="javascript:;"
                          ><i class="ti ti-menu-alt text-secondary"></i
                        ></a>
                        <ul>
                          <li>
                            <a
                              href="javascript:;"
                              class=""
                              @click="
                                CopyLine(
                                  index_date,
                                  items.day +
                                    '/' +
                                    items.month +
                                    '/' +
                                    items.year
                                )
                              "
                              >Copy Line</a
                            ><br />
                            <a
                              href="javascript:;"
                              class=""
                              @click="
                                PasteLine(
                                  index_date,
                                  items.day +
                                    '/' +
                                    items.month +
                                    '/' +
                                    items.year
                                )
                              "
                              v-if="model_copy_line.isCopy"
                              >Paste From
                              <b class="text-secondary">{{
                                model_copy_line.date
                              }}</b></a
                            >
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td>--</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-sm-10 pl-0 pr-1">
            <div class="table-cover">
              <table
                class="table-hover"
                :style="'min-width:' + table_cover_width + 'px;'"
              >
                <thead>
                  <tr>
                    <th
                      class="text-center no-filter"
                      v-for="(items, index) in GetThSection(model_section)"
                      :key="index"
                      style="position: relative"
                      :colspan="GetColspanSection(model_section, items)"
                    >
                      Section {{ items }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      class="no-filter"
                      v-for="(items, index) in model_section"
                      :key="index"
                      style="position: relative"
                    >
                      <a href="javascript:;" :title="items.area_name"
                        ><span class="text-primary"
                          >Floor {{ items.area_floor }} ({{
                            items.area_type
                          }})</span
                        ></a
                      >
                    </th>
                  </tr>
                  <tr>
                    <th
                      class="text-center no-filter"
                      v-for="(items, index) in model_section"
                      :key="index"
                      style="position: relative"
                    >
                      <a href="javascript:;" :title="items.area_name"
                        ><span class="text-secondary">{{
                          items.area_name | limit(15, " ...")
                        }}</span>
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="model_group_employee.length > 0">
                  <tr
                    v-for="(items_date, index_date) in model_date_range"
                    :key="index_date"
                    :class="
                      'trdate ' + items_date.month + '-' + items_date.year
                    "
                    v-if="model_group_employee.length > 0"
                  >
                    <td
                      v-for="(items, index_section) in model_section"
                      :key="index_section"
                      class="text-center"
                      style="padding: 16px"
                      width="200"
                    >
                      <div class="tools">
                        <a
                          ><i class="ti-user text-success"></i>
                          <small class="tools-plus">+</small></a
                        >
                        <ul>
                          <li
                            v-for="(in_items, idx) in model_group_employee"
                            :key="idx"
                          >
                            <div v-if="idx == 0">
                              <a
                                href="javascript:;"
                                class="text-secondary float-left"
                                @click="
                                  CopyEmployee(
                                    index_date + '-' + items.id,
                                    items_date.day +
                                      '/' +
                                      items_date.month +
                                      '/' +
                                      items_date.year,
                                    items.area_name
                                  )
                                "
                                ><i class="ti-file text-primary"></i> Copy
                                Employee</a
                              ><br />
                              <a
                                href="javascript:;"
                                class="text-secondary float-left mt-1"
                                @click="
                                  PasteEmployee(index_date + '-' + items.id)
                                "
                                v-if="model_copy.isCopy"
                                ><i class="ti-files text-danger"></i> Paste from
                                {{ model_copy.date }}</a
                              >
                              <div class="clearfix"></div>
                              <hr />
                            </div>
                            <a
                              href="javascript:;"
                              class="btn btn-light text-success"
                              @click="
                                AssignGroupSection(
                                  index_date + '-' + items.id,
                                  model_group_employee[idx]
                                )
                              "
                              v-if="
                                CheckGroupSection(
                                  index_date + '-' + items.id,
                                  model_group_employee[idx]
                                )
                              "
                              >Assign</a
                            >
                            <a
                              href="javascript:;"
                              class="btn btn-light text-danger"
                              @click="
                                DeleteGroupSection(
                                  index_date + '-' + items.id,
                                  model_group_employee[idx].nik,
                                  items_date.day +
                                    '/' +
                                    items_date.month +
                                    '/' +
                                    items_date.year
                                )
                              "
                              v-else
                              >Delete</a
                            >
                            <a class="group-profile">
                              <img
                                :src="
                                  $store.state.api_resource + in_items.photo
                                "
                                class="photo-profile"
                              />
                              <span class="text-secondary">{{
                                in_items.nik
                              }}</span
                              ><br />
                              {{ in_items.firstname + " " + in_items.lastname }}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- total Employee Assign-->
                      <div class="list-officer" v-if="isReAassign">
                        <div class="box">
                          <span
                            :class="
                              GetTotalAssign(index_date + '-' + items.id) > 0
                                ? 'count-all text-danger'
                                : 'count-all text-light'
                            "
                            >+
                            {{
                              GetTotalAssign(index_date + "-" + items.id)
                            }}</span
                          >
                        </div>
                      </div>
                      <!-- total Employee Assign-->
                    </td>
                  </tr>
                  <tr v-else>
                    <td
                      v-for="(items, index_section) in model_section"
                      :key="index_section"
                      class="text-center"
                      style="padding: 18px"
                    >
                      ...
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td :colspan="model_section.length" class="text-danger">
                      Please manage employee contract !
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-sm-12">
            <button
              class="btn btn-info"
              v-if="pageNum > 1"
              @click="nextPage(pageNum - 1)"
            >
              Previous Week
            </button>
            <button
              class="btn btn-info"
              style="float: right"
              v-if="
                totalPageInMonth < daysInMonth &&
                this.model_date_range.length == 7
              "
              @click="nextPage(pageNum + 1)"
            >
              Next Week
            </button>
          </div>
        </div>

        <hr />

        <!-- <div
          class="form-group"
          v-if="model_group_employee.length > 0 && isUpdateTools"
        >
          <div class="col-sm-12 text-right">
            <button class="btn btn-execute btn-primary" @click="Submit()">
              Update Data
            </button>
          </div>
        </div> -->
      </div>
      <div class="card-body" v-else>
        <div class="row">
          <div class="col-md-6">
            <ListLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-6">
            <ListLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-12">
            <ContentLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="2" ry="2" width="400" height="400" />
            </ContentLoader>
          </div>
        </div>
        <!-- <h5>Please Wait ...</h5>
                <h6 class="text-secondary">System adjustment layout for a while !</h6> -->
      </div>
    </div>
  </div>
</template>

<script>
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";
import DatePicker from "vue2-datepicker";
import { mapState } from "vuex";
import { ContentLoader, ListLoader, CodeLoader } from "vue-content-loader";
import VueQRCodeComponent from "vue-qrcode-component";
import router from "../../router";
import $ from "jquery";
import axios from "axios";

export default {
  components: {
    ContentLoader,
    ListLoader,
    CodeLoader,
    VueQRCodeComponent,
    DatePicker,
  },
  data() {
    return {
      isLoading: true,
      isUpdateTools: false,
      isEnablePopup: false,
      isReAassign: false,
      table_cover_width: 0,
      model_contract: {},
      model_section: [],
      model_date_range: [],
      model_temporary_date: [],
      model_group_employee: [],
      model_assign: [],
      model_task: [],
      model_copy: {
        isCopy: false,
        date: "",
        data: [],
      },
      model_copy_line: {
        isCopy: false,
        date: "",
        data: [],
      },

      collection_emp: [],
      model_last_created: "",

      collection_date: [],
      // filter date
      collection_month: [],
      filter_date: "",
      filter_year: "",
      filter_month: "",
      pageNum: 1,
      daysInMonth: 0,
      totalPageInMonth: 0,
    };
  },
  created() {
    this.QueryStartFromPeriod();
  },
  methods: {
    disabledBeforeAfterFilterDate(date) {
      const start_date = new Date(this.model_contract.start_date);
      start_date.setHours(0, 0, 0, 0);

      const end_date = new Date(this.model_contract.end_date);
      end_date.setHours(0, 0, 0, 0);

      let dateNow = new Date(date.getFullYear(), date.getMonth());
      let dateStart = new Date(start_date.getFullYear(), start_date.getMonth());
      let dateEnd = new Date(end_date.getFullYear(), end_date.getMonth());

      return dateNow < dateStart || dateNow > dateEnd;
    },
    CopyLine(i, date) {
      let data = [];
      let model = this.model_assign;
      for (let key in model) {
        let idx = key.split("-");
        let index_date = idx[0];
        let id_section = idx[1];

        if (index_date == i) {
          data.push(model[key]);
        }
      }

      this.model_copy_line.data = data;
      this.model_copy_line.isCopy = true;
      this.model_copy_line.date = date;
      // console.log(this.model_copy_line);
    },
    PasteLine(i, date) {
      let model_copy = this.model_copy_line.data;

      let getDate = date.split("/");
      let gTgl = getDate[0];
      let gBln = getDate[1];
      let gThn = getDate[2];
      // date

      let params = [];
      for (let key in model_copy) {
        let pasteDayLength = model_copy[key].length;
        if (pasteDayLength > 0) {
          for (let i in model_copy[key]) {
            let param = {
              id_contract: localStorage.getItem("QUERY_ID"),
              nik: model_copy[key][i].nik,
              id_section_room: model_copy[key][i].id_section_room,
              day: gTgl,
              month: gBln,
              year: gThn,
            };
            params.push(param);
          }
        }
      }

      if (params.length > 0) {
        this.AssignPerLine(params, i);
      }
    },
    MappingModelCopyPasteLine(i) {
      this.isReAassign = false;

      let model_assign = this.model_assign;

      // process mapping to view
      var a = 0;
      for (let index_assign in model_assign) {
        let idx = index_assign.split("-");
        let index_date = idx[0];

        if (index_date == i) {
          // reset
          model_assign[index_assign] = [];
          this.collection_emp[index_assign] = [];

          let model_copy = this.model_copy_line.data[a];

          for (let key in model_copy) {
            if (
              !this.collection_emp[index_assign].includes(model_copy[key].nik)
            ) {
              this.collection_emp[index_assign].push(model_copy[key].nik);
              this.model_assign[index_assign].push({
                nik: model_copy[key].nik,
                complete_name: model_copy[key].complete_name,
                photo: model_copy[key].photo,
                id_section_room: model_copy[key].id_section_room,
                day: this.model_date_range[i].day,
                month: this.model_date_range[i].month,
                year: this.model_date_range[i].year,
              });
            }
          }
          a++;
        }
      }
      // end process mapping
      this.isReAassign = true;
    },
    CopyEmployee(index_assign, date, area_name) {
      let model_assign = this.model_assign[index_assign];
      if (model_assign.length < 1) {
        alert("Please assign some employee");
        return;
      }
      // reset model copy
      this.model_copy = {
        isCopy: false,
        date: "",
        data_assign: "",
      };

      this.model_copy = {
        isCopy: true,
        date: '"' + date + '"' + " - " + area_name,
        data_assign: model_assign,
      };
    },
    PasteEmployee(index_assign) {
      // reset
      this.model_assign[index_assign] = [];
      this.collection_emp[index_assign] = [];
      let model_copy = this.model_copy.data_assign;

      let params = [];
      for (let key in model_copy) {
        if (!this.collection_emp[index_assign].includes(model_copy[key].nik)) {
          // id section room
          var univ = index_assign.split("-");
          var id_section_room = univ[1];
          // date
          var dt = this.model_date_range[univ[0]];
          let param = {
            id_contract: localStorage.getItem("QUERY_ID"),
            nik: model_copy[key].nik,
            id_section_room: id_section_room,
            day: dt.day,
            month: dt.month,
            year: dt.year,
          };
          params.push(param);
        }
      }

      if (params.length > 0) {
        this.AssignPerEmployee(params, index_assign);
      }
    },
    MappingModelCopyPasteEmployee(index_assign) {
      this.isReAassign = false;
      this.model_assign[index_assign] = [];
      this.collection_emp[index_assign] = [];
      let model_copy = this.model_copy.data_assign;
      for (let key in model_copy) {
        if (!this.collection_emp[index_assign].includes(model_copy[key].nik)) {
          // id section room
          var univ = index_assign.split("-");
          var id_section_room = univ[1];
          // date
          var dt = this.model_date_range[univ[0]];

          this.collection_emp[index_assign].push(model_copy[key].nik);
          this.model_assign[index_assign].push({
            nik: model_copy[key].nik,
            complete_name: model_copy[key].complete_name,
            photo: model_copy[key].photo,
            id_section_room: id_section_room,
            day: dt.day,
            month: dt.month,
            year: dt.year,
          });
        }
      }
      this.isReAassign = true;
    },
    PrintQrCode() {
      localStorage.setItem("QUERY_ID", this.model_contract.id);

      let current_url = window.location.href;
      let print_url = current_url.replace("task", "print-qrcode");

      window.open(print_url);
    },
    async QueryStartFromPeriod() {
      await axios
        .get("contract/period/" + localStorage.getItem("QUERY_ID"))
        .then((response) => {
          let query = response.data;
          let default_date = query.default;
          // let dt = default_date.split('/');

          const d = new Date();

          this.collection_month = query.month_period;
          // default
          // this.filter_month = dt[0];
          // this.filter_year  = dt[1];
          this.filter_month = ("0" + (d.getMonth() + 1)).slice(-2);
          this.filter_year = d.getFullYear().toString();

          this.filter_date = this.filter_month + "/" + this.filter_year;

          this.DataQuery();
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    nextPage(pageNum) {
      this.DataQuery(pageNum);
    },
    async DataQuery(page = 1) {
      this.isLoading = true;
      this.pageNum = page;

      let d = new Date();
      d.setMonth(this.filter_month);
      d.setFullYear(this.filter_year);

      await axios
        .post("contract/detail-v2", {
          id_contract: localStorage.getItem("QUERY_ID"),
          month: this.filter_month,
          year: this.filter_year,
          page: this.pageNum,
        })
        .then((response) => {
          let query = response.data;

          this.model_contract = query.contract;
          this.model_group_employee = query.employee;
          this.model_date_range = query.date_range;
          if (query.task.length > 0) {
            this.model_task = query.task;
            this.model_last_created = query.task[0].updated_at;
          }

          this.totalPageInMonth = this.pageNum * this.model_date_range.length;
          this.daysInMonth = new Date(
            d.getFullYear(),
            d.getMonth(),
            0
          ).getDate();

          // enable tools
          let block_status = ["terminate", "out_of_date"];
          if (!block_status.includes(this.model_contract.status)) {
            this.isUpdateTools = true;
          }

          // reset
          this.model_section = [];

          // re-assign section
          let model_section = query.section;

          let count = 0;
          for (let key in model_section) {
            for (let inkey in model_section[key]) {
              this.model_section.push(model_section[key][inkey]);
              count++;
            }
          }

          this.table_cover_width = count * 150;

          this.isReAassign = false;
          let model_date_range = this.model_date_range;
          let m_section = this.model_section;

          for (let key in model_date_range) {
            for (let insec in m_section) {
              var index_assign = key + "-" + m_section[insec].id;
              this.model_assign[index_assign] = [];
              // this.$store.state.modelAssignEmployee[index_assign] = [];
              this.collection_emp[index_assign] = [];
              this.FramingModelAssign(index_assign);
            }
          }
          // console.log(JSON.stringify(this.model_assign));
          // localStorage.setItem('modelAssignEmployee', this.model_assign);
          this.isReAassign = true;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.statusText);
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    FilterModelDate() {
      let fdate = this.filter_date.split("/");
      this.filter_month = fdate[0];
      this.filter_year = fdate[1];
      this.DataQuery();
    },
    FramingModelAssign(index_assign) {
      let idx = index_assign.split("-");
      let dt = this.model_date_range[idx[0]];
      let date = dt.day + "-" + dt.month + "-" + dt.year;
      let id_section_room = parseInt(idx[1]);

      let model_task = this.model_task;
      for (let key in model_task) {
        var date_task =
          model_task[key].day +
          "-" +
          model_task[key].month +
          "-" +
          model_task[key].year;

        if (
          date_task == date &&
          model_task[key].id_section_room == id_section_room
        ) {
          this.collection_emp[index_assign].push(model_task[key].nik);

          this.model_assign[index_assign].push({
            nik: model_task[key].nik,
            complete_name:
              model_task[key].firstname + " " + model_task[key].lastname,
            photo: model_task[key].photo,
            id_section_room: id_section_room,
            day: model_task[key].day,
            month: model_task[key].month,
            year: model_task[key].year,
          });

          // this.$store.state.modelAssignEmployee[index_assign].push({
          //     nik : model_task[key].nik,
          //     complete_name : model_task[key].firstname +' '+model_task[key].lastname,
          //     photo: model_task[key].photo,
          //     id_section_room : id_section_room,
          //     day : model_task[key].day,
          //     month : model_task[key].month,
          //     year : model_task[key].year
          // })
        }
      }
    },
    GetTotalAssign(index_assign) {
      // console.log(this.$store.state.modelAssignEmployee[index_assign])
      // console.log(this.model_assign[index_assign])
      let model = this.model_assign[index_assign];
      return model.length;
    },
    CheckGroupSection(index_assign, data_employee) {
      let model_assign = this.model_assign[index_assign];
      if (this.collection_emp[index_assign].includes(data_employee.nik)) {
        return false;
      } else {
        return true;
      }
    },
    AssignGroupSection(index_assign, data_employee) {
      this.isReAassign = false;
      let model_assign = this.model_assign[index_assign];
      if (!this.collection_emp[index_assign].includes(data_employee.nik)) {
        let params = [];
        // id section room
        var univ = index_assign.split("-");
        var id_section_room = univ[1];
        // date
        var dt = this.model_date_range[univ[0]];
        this.collection_emp[index_assign].push(data_employee.nik);
        model_assign.push({
          nik: data_employee.nik,
          complete_name: data_employee.firstname + " " + data_employee.lastname,
          photo: data_employee.photo,
          id_section_room: id_section_room,
          day: dt.day,
          month: dt.month,
          year: dt.year,
        });
        let param = {
          id_contract: localStorage.getItem("QUERY_ID"),
          nik: data_employee.nik,
          id_section_room: id_section_room,
          day: dt.day,
          month: dt.month,
          year: dt.year,
        };
        params.push(param);
        this.AssignPerEmployee(params);
        this.isReAassign = true;
      } else {
        alert("Employee is already exists");
      }
    },
    async AssignPerEmployee(params, index_assign) {
      this.$store.state.loading.msg = "Assing Employee...";
      this.$store.state.loading.status = true;
      let data = {
        employee_assign: params,
      };
      console.log(data);
      await axios
        .post("jobtask/assign-per-employee", data)
        .then((res) => {
          let response = res.data;

          if (response.result == "ok") {
            this.MappingModelCopyPasteEmployee(index_assign);
            this.$store.state.loading.msg = response.msg;
          } else {
            alert(response.msg);
            // console.log(response.error);
          }
        })
        .catch((error) => {
          this.$store.state.loading.msg =
            "Failed update data, Please check Connection .. ";
          setTimeout(() => (this.$store.state.loading.status = false), 750);
        })
        .finally(() => {
          setTimeout(() => (this.$store.state.loading.status = false), 750);
        });
    },
    async AssignPerLine(params, i) {
      this.$store.state.loading.msg = "Assing Employee...";
      this.$store.state.loading.status = true;
      let data = {
        employee_assign: params,
      };
      console.log(data);
      await axios
        .post("jobtask/assign-per-line", data)
        .then((res) => {
          let response = res.data;
          if (response.result == "ok") {
            this.MappingModelCopyPasteLine(i);
            this.$store.state.loading.msg = response.msg;
          } else {
            alert(response.msg);
            // console.log(response.error);
          }
        })
        .catch((error) => {
          this.$store.state.loading.msg =
            "Failed update data, Please check Connection .. ";
          setTimeout(() => (this.$store.state.loading.status = false), 750);
        })
        .finally(() => {
          setTimeout(() => (this.$store.state.loading.status = false), 750);
        });
    },
    async DeleteGroupSection(index_assign, nik, date) {
      this.$store.state.loading.msg = "Remove Task Employee...";
      this.$store.state.loading.status = true;

      await axios
        .post("jobtask/remove_taks", {
          id_contract: this.model_contract.id,
          nik: nik,
          date: date,
        })
        .then((result) => {
          let res = result.data;
          this.$store.state.loading.msg = res.msg;
          if (res.result) {
            this.isReAassign = false;
            let model_assign = this.model_assign[index_assign];

            if (this.collection_emp[index_assign].includes(nik)) {
              // remove index collection_emp
              let idx = this.collection_emp[index_assign].indexOf(nik);
              this.collection_emp[index_assign].splice(idx, 1);

              // remove index model_assign
              for (let key in model_assign) {
                if (model_assign[key].nik == nik) {
                  model_assign.splice(key, 1);
                }
              }
            }

            this.isReAassign = true;
          } else {
            alert(res.msg);
          }
        })
        .catch((error) => {
          if (!error.status) {
            // network error
            console.log("Error Connection Network !");
          }
        })
        .finally(() => {
          setTimeout(() => (this.$store.state.loading.status = false), 2000);
        });
    },
    GetThSection(model) {
      var data = [];
      for (let key in model) {
        if (!data.includes(model[key].section)) {
          data.push(model[key].section);
        }
      }

      return data;
    },
    GetColspanSection(model, section_number) {
      var cols = 0;
      for (let key in model) {
        if (model[key].section == section_number) {
          cols++;
        }
      }
      return cols;
    },
    AssignData(data) {
      this.model_group_employee = data;
    },
    GetClassGr(idx) {
      if (idx % 2 == 0) return "gr-left";
      else return "";
    },
    async Submit() {
      alert("submit disable sementara");
      let data_model = {
        id_contract: localStorage.getItem("QUERY_ID"),
        month: this.filter_month,
        year: this.filter_year,
        employee: [],
        section: [],
      };

      //  assign task
      let model_assign = this.model_assign;

      for (let key in model_assign) {
        if (model_assign[key].length > 0) {
          for (let inkey in model_assign[key]) {
            data_model.section.push({
              id_contract: localStorage.getItem("QUERY_ID"),
              nik: model_assign[key][inkey].nik,
              id_section_room: model_assign[key][inkey].id_section_room,
              day: model_assign[key][inkey].day,
              month: model_assign[key][inkey].month,
              year: model_assign[key][inkey].year,
            });
          }
        }
      }

      // employee
      /*
                let model_employee = this.model_group_employee;
                for(let key in model_employee)
                {
                    data_model.employee.push({
                        id_contract : localStorage.getItem('QUERY_ID'),
                        nik : model_employee[key].nik,
                    })
                }*/

      let data = {
        url: "jobtask/assign",
        callback_url: "jobcon/task",
        form_data: data_model,
      };
      // console.log(data);
      // let res = await this.$store.dispatch("crud/storeData", data);

      // if (res) {
      //   if (res.result == "failed") {
      //     this.$store.state.alert.status = true;
      //     this.$store.state.alert.msg = res.msg;
      //     this.$store.state.alert.type = "warning";

      //     setTimeout(() => this.DataQuery(this.pageNum), 2000);
      //   }
      // }
    },
  },
};
</script>

<style lang='scss' scoped>
.tools-master {
  position: absolute;
  top: 15px;
  left: 130px;
  margin: 0 auto;
  text-align: left;
  width: auto;
  padding: 2px 0px;
  white-space: nowrap;
  a {
    padding: 7px;
    font-size: 12px;
    text-decoration: none;
  }
  ul {
    display: none;
    width: auto;
    position: absolute;
    z-index: 9;
    right: 0px;
    top: -1px;
    background-color: #fff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border: 1px solid whitesmoke;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);

    li {
      float: none;
      padding: 5px;
      list-style: none;
      font-size: inherit;
      color: gray;
      text-align: left;
      a {
        color: gray;
        b {
          font-size: 11px;
        }
      }
      a:hover {
        color: #369dff;
      }
    }

    li:last-child {
      border-bottom: none;
    }
  }
}
div.tools-master:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 whitesmoke;
  ul {
    display: block;
  }
}
.tdp {
  // padding: 9px;
  padding: 8.6px;
  span {
    width: 100%;
    float: left;
  }
}

.table-picture-profile {
  float: left;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  position: relative;
  z-index: 1;
}

.table-avatar {
  float: left;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  z-index: 2;
  border: 1px solid #c3c3c3;
}
.table-avatar:after {
  position: relative;
  top: -6px;
  left: -1px;
}

div.tools {
  ul {
    max-height: 400px;
    overflow-y: scroll;
    width: 300px;
    li {
      float: left;
      width: 100%;
      border-bottom: 1px solid whitesmoke;
    }
  }

  small.tools-plus {
    color: green;
    position: relative;
    left: 3px;
    top: -4px;
  }
  .group-profile {
    position: relative;
    top: -7px;
    .photo-profile {
      position: relative;
      float: left;
      width: 35px;
      height: 35px;
      border-radius: 35px;
      -moz-border-radius: 35px;
      -webkit-border-radius: 35px;
      margin: 0;
      margin-right: 10px;
    }
  }
}
div.list-officer {
  padding: 0;
  margin: 0;
  float: left;
  list-style: none;
  padding-right: 7px;
  position: relative;
  left: 20px;
  .box {
    position: relative;
    float: left;
    span {
      margin-left: 3px;
      font-size: 12px;
    }
    .table-avatar {
      float: left;
      width: 30px;
      height: 30px;
      background-color: #d0d0d0;
      position: relative;
      margin-right: 5px;
      margin-top: -3px;
      margin-bottom: -5px;
    }
    .table-group-avatar {
      position: absolute !important;
      left: 80px;
      z-index: 2;
    }
    .table-avatar::after {
      font-size: 12px;
      position: relative;
      top: -7px;
      left: -4px;
      color: #fff;
    }
    .table-picture-profile {
      width: 30px;
      height: 30px;
      position: absolute;
      top: -3px;
      left: -5px;
      border: 1px dotted #afafaf;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }
    .table-group-picture {
      position: absolute !important;
      left: 80px;
      z-index: 2;
    }
    .count-all {
      position: relative;
      float: left;
      z-index: 4;
    }

    .plus-minimize {
      position: absolute;
      z-index: 3;
      left: 107px;
      top: -6px;
      font-size: 9px;
    }
  }
}

ul.list-area {
  float: right;
  width: 100%;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    float: left;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid whitesmoke;
  }
}

.qr-code {
  height: 400px;
  overflow-y: scroll;
  ul {
    padding: 0;
    margin: 0;
    li {
      float: left;
      width: 100%;
      padding: 10px;
      margin: 10px;
      border-bottom: 1px solid whitesmoke;
      div {
        float: left;
      }
      div.desc {
        float: left;
        padding: 10px;
        p {
          line-height: 10px;
        }
      }
    }
  }
}
</style>
