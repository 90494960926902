export default {

    data() {
        return {
            start_year : 2018
        }
    },
   
    created: function () {
       console.log('Mixin server date is ready')
    },
    methods: {
        
        MX_ServerDate(initial)
        {
            let list_year = [];
            let date = JSON.parse(localStorage.getItem('server-date'));
            for( var i = this.start_year; i <= parseInt(date.year); i++ ){ list_year.push(i); }

            if( initial == 'active_year' ) return date.year;
            if( initial == 'active_month' ) return date.month;
            if( initial == 'active_day' ) return parseInt(date.day);
            if( initial == 'list_year' ) return list_year;
        },
    }
  }