<style scoped>
.table-wrapper {
  width: 100%;
  max-height: 400px;
  overflow: auto;
  display: inline-block;
}
</style>
<template>
  <div class="popup popup-lg">
    <div class="content">
      <small id="close-popup" @click="close()"
        ><i class="ti ti-close"></i
      ></small>
      <h5>
        Revisi Custom Report
        <hr />
      </h5>
      <div class="row p-0">
        <div class="col-md-12 p-0">
          <div class="form-group">
            <label>Notes </label><br />
            <textarea
              type="text"
              class="form-control"
              v-model="revisi_note"
            ></textarea>
          </div>
          <div class="form-group">
            <br />
            <a
              href="javascript:;"
              class="btn btn-secondary"
              @click="submitRevisi()"
              >Sumit</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";

export default {
  props: ["id_customreport"],
  data() {
    return {
      revisi_note: null,
    };
  },

  computed: {},
  created() {
    this.customreport_id = this.id_customreport ? this.id_customreport : 0;
  },

  methods: {
    close() {
      this.$emit("exit");
    },
    async submitRevisi() {
      //   console.log(this.customreport_id, this.revisi_note);
      this.$store.state.loading.msg = "Update Revisi Report ..";
      this.$store.state.loading.status = true;
      axios
        .post("report/generate/revisi", {
          id: this.customreport_id,
          note_revisi: this.revisi_note,
        })
        .then((res) => {
          let response = res.data;
          if (response.status) {
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "success";
            this.$store.state.alert.msg = response.msg;
          } else {
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "trash";
            this.$store.state.alert.msg = response.msg;
          }
        })
        .catch((error) => {
          console.log(error.statusText);
          location.reload();
        })
        .finally(() => {
          this.$store.state.loading.status = false;
          location.reload();
        });
    },
  },
};
</script>
