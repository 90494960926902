<template>
    <div class="" v-if="isEnablePopup">
        <div class="popup popup-sm" >
            <div class="content success">
                <small id="close-popup" @click="close()" v-if="$store.state.alert.close_redirect == ''"><i class="ti ti-close"></i></small>
                <div class="row p-n">
                    <div class="col-md-2">
                        <div class="icon" >
                            <lottie-player src="https://assets9.lottiefiles.com/packages/lf20_Dcbowq.json"  background="transparent"  speed="1" class="lottie-checked" autoplay v-if="$store.state.alert.type == 'success'"></lottie-player>
                            <lottie-player src="https://assets1.lottiefiles.com/packages/lf20_WdbegN.json"  background="transparent"  speed="1" class="lottie-checked" autoplay v-if="$store.state.alert.type == 'warning'"></lottie-player>
                            <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_Mt38fp.json"  background="transparent"  speed="1" class="lottie-checked" autoplay  v-if="$store.state.alert.type == 'trash'"></lottie-player>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="msg">
                            <h5 v-if="$store.state.alert.type == 'success'">Congratulations</h5>
                            <h5 v-if="$store.state.alert.type == 'trash'">Failed</h5>
                            <h5 v-if="$store.state.alert.type == 'warning'">Warning</h5>
                            <p>{{ $store.state.alert.msg }}</p>
                        </div>
                    </div>
                    <div class="col-md-12 mb-2" v-if="$store.state.alert.close_redirect !== ''">
                        <a :href="$store.state.alert.close_redirect" class="btn btn-execute float-right">RELOGIN</a>
                    </div>
                    <div class="col-md-12 mb-2" v-if="$store.state.alert.yes_redirect !== '' || $store.state.alert.no_redirect !== ''">
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>

    export default 
    {
        data() 
        {
            return {
                isEnablePopup : true,
            }
        },
        created() {

            window.scrollTo(500, 0);

            setTimeout(() => {
                this.close();
            }, 7000);
        },
        methods: {
            close(){
                this.$store.state.alert.type = 'success';
                this.$store.state.alert.status = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
.popup{
    top:120px !important;
}
div.msg{
    text-align:left;
    padding-left:20px;
    margin-top:20px;
    padding-bottom:15px;

    h5{
        width:100%;
        float:left;
        text-align:left;
        font-size:17px;
    }
    p{
        font-size:14px;
        color:gray;
    }  
}
div.icon{
    i{
         position: relative;
        top:7px;
        left:10px;
    }
    i.ti-check{
        top:0;
        font-size:45px;
    }
    i.ti-info-alt{
        font-size:50px;
    }
    i.ti-heart-broken{
        font-size:45px;
    }
    lottie-player{
        position:relative;
        top:-8px;
        width:100px;
        height:100px;
    }
}
div.success{
    background-color:#fff;
    #close-popup{
        .ti{
            color:gray;
        }
    }
}
</style>
