<template>
    <div class="col-md-12 p-0">
        <PopupDetailContract :id_contract="initial_id" v-on:exit="UpdateStatusRead" v-if="isEnablePopup == 'contract'"/>
        <PopupDetailEmployee  v-on:exit="UpdateStatusRead" v-if="isEnablePopup == 'employee'" />

         <div class="card">
            <div class="card-body">
                <div class="table-header">
                    <h5 > <small>Total Records : <b>{{ data_model.total | formatPrice }}</b></small></h5>
                    <div class="group-search pull-right">
                        <a class="btn" @click="tableRefresh()" ><i class="ti ti-reload"></i></a>
                        <a class="btn" @click="isActiveSearch()" ><i :class="isEnableTools == false  ? 'ti ti-search' : 'ti ti-close'"></i></a>
                        <div class="select" v-if="isEnableTools" >
                            <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                                <option value="">-- Choose Field  --</option>
                                <option v-for="(items, idx) in meta_field" v-bind:key="idx" :value="items.key" >{{ items.value }}</option>
                            </select>
                        </div>
                         <div class="group" v-if="isEnableTools">
                            <input type="date" v-model="meta_data.sval" v-if="meta_data.skey == 'created_at' || meta_data.skey == 'updated_at'"/>
                            <input type="text" v-model="meta_data.sval" placeholder="Enter Keyword ...." v-else/>
                            <a class="btn" @click="tableSearch()">
                                SEARCH DATA 
                            </a>
                        </div>   
                    </div>
                    <div class="group-filter" v-if="isRoot">
                        <div class="row">
                             <div class="col-md-12">
                                <div class="form-group float-right" >
                                    <SelectBranch  v-on:processBranch="processBranch"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-cover">
                    <table class="table-hover">
                        <thead>
                            <tr>
                                <th v-if="JSON.parse($store.state.app.access).length > 1">Action</th>
                                <th v-for="(items, idx) in meta_field" v-bind:key="idx" :class="meta_data.okey == items.key ? 'active' : ''" v-on:click="tableOrdering(items.key)">{{ items.value }}</th>
                            </tr>
                        </thead>
                        <tbody v-if="data_model.total > 0">
                             <tr  v-for="(items, idx) in data_model.data" :key="idx">
                                <td><a href="javascript:;" @click="DetailContract(items.type, items.id, items.initial_id)" class="table-link"><i :class="items.status == 'unread' ? 'ti ti-email text-primary mr-2' : 'ti ti-file text-secondary mr-2' "></i> {{ items.title }} - {{ items.message }}</a></td>
                                <td><span :class="generateLabelStatus(items.status)">{{ items.status }}</span></td>
                                <td width="120">{{ items.created_at | moment("DD/MM/YYYY") }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="11"><div class="no-data" v-if="!$store.state.loading.status">Sorry, data is not available</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="pagination" >
                    <small class="info"><i class="ti ti-split-h"></i> Scroll horizontal to view data.</small>
                    <paginate
                    :page-count="parseInt(data_model.last_page)"
                    :click-handler="tableQuery"
                    :container-class="'pagination'"
                    :page-class="'page-item'" >
                    </paginate>
                </div>
            </div>
        </div>

    </div> 
</template>
<script>

    import { mapState } from 'vuex';
    import PopupDetailContract from '../JobContract/@PopupDetailContract';
    import PopupDetailEmployee from '../Employment/@popup_detail';
    import SelectBranch from '../Branch/@SelectBranch';

    import axios from 'axios';
    import $ from 'jquery';

    export default {
        data() 
        {
            return {
                isRoot : false,
                isEnablePopup : false,
                isEnableTools : false, 
                isEnableOrder : false,
                initial_id : null,
                id_notification : null,

                filter_key : '',
                meta_url : 'notification',
                meta_data :{ okey: 'id', oval:'desc', skey : '', sval : ''},
                meta_field : [
                    {key : 'message', value : 'Notification Message'},
                    {key : 'status', value : 'Status'},
                    {key : 'created_at', value : 'Created Date'},
                    {key : 'contract-station', val:localStorage.getItem('userContract')}
                ],
                meta_filter :{ id_branch:'all' }

            }
        },
        components: 
        {
            PopupDetailContract,
            PopupDetailEmployee,
            SelectBranch
        },
        computed: {
        
            ...mapState('crud', {
                data_model: state => state.data_model,
            })
        },
        created()
        {
            this.tableQuery();
            // filter branch
            if(localStorage.getItem('userPrivilegesName') !== 'root'){
                this.meta_filter.id_branch = localStorage.getItem('userBranchId');
            }else{
                this.isRoot = true;
            }
        },
        
        methods:
        {
            processBranch(data)
            {
                this.meta_filter.id_branch = data;
                this.tableQuery();
            },
            DetailContract(type, id_notification, initial_id)
            {
                this.id_notification        = id_notification;
                this.initial_id             = initial_id;
                if( type == 'employee' ){
                    localStorage.setItem('QUERY_ID', initial_id);
                }
                this.isEnablePopup    = type;
            },
            isActiveSearch(){
                this.isEnableTools =! this.isEnableTools;
                if(this.isEnableTools) this.sval = '';
            },
            generateLabelStatus(str)
            {
                let cl = '';
                switch(str)
                {
                    case 'unread':
                        cl = 'badge-success';
                    break;
                    case 'read':
                        cl = 'badge-secondary';
                    break;
                }

                return 'badge ' + cl;
            },
            async UpdateStatusRead()
            {
                this.$store.state.loading.msg = 'Query Detail Contract';
                this.$store.state.loading.status = false;

                await axios.get( 'notification/status/'+this.id_notification)
                .then((res) => 
                {
                    if(res)
                    {
                        this.tableQuery();
                        this.isEnablePopup = false;
                    }

                }).finally(() => {
                    this.$store.state.loading.status = false;
                });
            },
            tableRefresh()
            {
                this.meta_data.skey = '';
                this.meta_data.sval = '';
                this.$store.state.loading.msg = 'Refreshing Data...';
                this.$store.state.loading.status = true;
                this.$store.dispatch('crud/getData', { url: this.meta_url+'/data'});
            },
           
            tableSearch()
            {
                if(this.meta_data.skey == ''){
                    alert('🙏, Please choose target field');
                    return;
                }
                if(this.meta_data.sval == ''){
                    alert('🙏, Please enter the keyword');
                    return;
                }
                this.tableQuery();
            },
            tableQuery(pageNum)
            {
                this.page = pageNum;
                let data = { 
                    url: this.meta_url+'/message',
                    offset : this.page,
                    search: { key : this.meta_data.skey, value : this.meta_data.sval },
                    order: { key : this.meta_data.okey, value : this.meta_data.oval },
                    filter : [
                        {key : 'id_branch', val: this.meta_filter.id_branch}
                    ]
                };

                this.$store.dispatch('crud/getData', data );
            },  
            tableOrdering(key)
            {
                this.isEnableOrder =! this.isEnableOrder;
                this.meta_data.okey = key;
                this.meta_data.oval = this.isEnableOrder ? 'ASC' : 'DESC';
                this.tableQuery(this.page);
            },
            
        }
    }
</script>
<style scoped lang="scss">
.table-link{
    color:#000;
    &:hover{
        color:blue;
    }
}
</style>