<template>
    <div class="popup popup-lg">
        <div class="content">
            <small id="close-popup" @click="close()"><i class="ti ti-close"></i></small>
            <h5>Master Data Contract Station <br/><small>Please select data contract station.</small><hr/></h5>
            <div class="row p-0">
                <div class="col-md-12">
                    <div class="table-header">
                        <h5 >Master Data <br/> <small>Total Records : <b>{{ model.total | formatPrice }}</b></small></h5>
                        <div class="group-search pull-right">
                            <a class="btn" @click="tableRefresh()" ><i class="ti ti-reload"></i></a>
                            <a class="btn" @click="isActiveSearch()" ><i :class="isEnableTools == false  ? 'ti ti-search' : 'ti ti-close'"></i></a>
                            <div class="select" v-if="isEnableTools" >
                                <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                                    <option value="">-- Choose Field --</option>
                                    <option value="title">Job Title</option>
                                    <option value="description">Description</option>
                                </select>
                            </div>
                            <div class="group" v-if="isEnableTools">
                                <input type="date" v-model="meta_data.sval" v-if="meta_data.skey == 'created_at' || meta_data.skey == 'updated_at'"/>
                                <input type="text" v-model="meta_data.sval" placeholder="Enter Keyword ...." v-else/>
                                <a class="btn" @click="tableSearch()">
                                    SEARCH DATA 
                                </a>
                            </div> 
                             <div class="group-filter mb-3">
                                
                            </div> 
                        </div>
                    </div>
                    <div class="table-cover">
                        <table class="table-hover" style="min-width:1900px;" >
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th width="70" class="no-filter text-center">Action</th>
                                    <th :class="meta_data.okey == 'client.name' ? 'active' : ''" v-on:click="tableOrdering('client.name')">Client Name</th>
                                    <th :class="meta_data.okey == 'contract.contract_number' ? 'active' : ''" v-on:click="tableOrdering('contract.contract_number')">Contract Number</th>
                                    <th :class="meta_data.okey == 'contract.status' ? 'active' : ''" v-on:click="tableOrdering('contract.status')">Contract Status</th>
                                    <th :class="meta_data.okey == 'contract.start_date' ? 'active' : ''" v-on:click="tableOrdering('contract.start_date')">Contract Period</th>
                                    <th :class="meta_data.okey == 'contract.man_power' ? 'active' : ''" v-on:click="tableOrdering('contract.man_power')">MP Request</th>
                                    <th :class="meta_data.okey == 'contract.description' ? 'active' : ''" v-on:click="tableOrdering('contract.description')">Description</th>
                                    <th :class="meta_data.okey == 'contract.contract_date' ? 'active' : ''" v-on:click="tableOrdering('contract.contract_date')">Contract Date</th>
                                    <th :class="meta_data.okey == 'contract.created_at' ? 'active' : ''" v-on:click="tableOrdering('contract.created_at')">Created Date</th>
                                    <th :class="meta_data.okey == 'contract.updated_at' ? 'active' : ''" v-on:click="tableOrdering('contract.updated_at')">Updated Date</th>
                                </tr>
                            </thead>
                            <tbody v-if="data_model.total > 0">
                                <tr  v-for="(items, idx) in data_model.data" :key="idx">
                                    <td>{{ idx + data_model.from }}</td>
                                    <td width="100" v-if="id_privileges == 15 || id_privileges == 13 || id_privileges == 14">
                                        <a href="javascript:;" class="btn btn-primary" @click="applyData('single', data_model.data[idx])"><i class="ti ti-check text-light "></i> Choose</a>
                                    </td>
                                    <td class="text-center" v-if="id_privileges == 12">
                                        <input type="checkbox"  @change="collectGroup(idx)" :checked="validateChecked(items.id)" :id="'checkbox-'+items.id" >
                                    </td>
                                    <td><b>{{ items.client_name }}</b></td>
                                    <td width="160"> {{ items.contract_number }}</td>
                                    <td ><span :class="items.status | contractStatus">{{ items.status | cleanString }}</span></td>
                                    <td width="200"> 
                                        {{ items.start_date | moment("DD/MM/YYYY") }} - {{ items.end_date | moment("DD/MM/YYYY") }}
                                    </td>
                                    <td class="pr-3" width="400" v-if="items.man_power">
                                        <span class="" v-for="(initems, index) in JSON.parse(items.man_power)" :key="index">
                                            {{ initems.total }} ( {{ initems.job }} )
                                            <span v-if="(index % 2== 0)">,</span>
                                        </span>
                                    </td>
                                    <td width="400">{{ items.description !== null ? items.description : '.....' }}</td>
                                    <td width="120"> {{ items.contract_date | moment("DD/MM/YYYY") }}</td>
                                    <td>{{ items.created_at | moment("DD/MM/YYYY") }}</td>
                                    <td>{{ items.updated_at | moment("DD/MM/YYYY") }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="11"><div class="no-data" v-if="!$store.state.loading.status">Sorry data is not available</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination" >
                        <small class="info"><i class="ti ti-split-h"></i> Scroll horizontal to view data.</small>
                        <paginate
                        :page-count="parseInt(model.last_page)"
                        :click-handler="tableQuery"
                        :container-class="'pagination'"
                        :page-class="'page-item'" >
                        </paginate>
                    </div>

                </div>
                <div class="col-md-12" v-if="id_privileges == 12">
                    <hr/>
                    <a href="javascript:;" class="btn btn-primary btn-execute float-right" @click="applyData('multi', model_group)">Continue Assign</a>
                </div>   
            </div> 
        </div>
    </div>
</template>


<script>

    import axios from 'axios';
    import $ from 'jquery';
    import { mapState } from 'vuex';

    export default {
        props:['id_privileges', 'id_branch'],
        data() 
        {
            return {
                isEnableTools : false, 
                isEnableOrder : false,
                isEnablePopup : false,
                model_collection:[],
                model_group:[],
                model :[],

                meta_url : 'contract',
                meta_data :{ okey: 'created_at', oval:'desc', wkey : '', wval : '', skey : '', sval : ''},
                meta_filter :{ status : 'all', sd_year: 'all', sd_month : 'all', ed_year : 'all', ed_month : 'all', id_branch:'all' }
            }
        },
       
        computed: {
            ...mapState('crud', {
                data_model: state => state.data_model,
                msg_error: state => state.error,
            })
        },
        created()
        {
            if( this.id_branch !== '')
            {
                this.meta_filter.id_branch = this.id_branch;
            }
            
            this.tableQuery();
        },

        methods:
        {
            close(){
                this.$emit('exit');
            },
            applyData(initial, data){

                if(initial == 'single'){
                    this.$emit('applyData', [data]);
                }
                if(initial == 'multi'){
                    this.$emit('applyData', data);
                }

                this.$emit('exit');
            },
            validateChecked(id)
            {
                var model = this.model_collection;
                return model.includes(id) ? true : false;
            },
            collectGroup(idx)
            {
                let model = this.data_model.data[idx];
                if($('#checkbox-'+model.id).is(":checked") )
                {
                    this.model_collection.push(model.id);
                    if( this.model_group.length < 1 ){
                        this.model_group = [];
                    }
                    this.model_group.unshift(model);
                }else{
                    this.disCollecGroup(idx);
                }
            },

            disCollecGroup(idx)
            {
                let model = this.data_model.data[idx];
                var model_collection = this.model_collection;
                var index_collection = model_collection.indexOf(model.id);
                this.model_collection.splice(index_collection, 1);
                
                var model_group = this.model_group;
                var no = 0;
                for(let key in model_group){
                    if(model_group[key].id == model.id){
                        this.model_group.splice(no, 1);
                    }
                    no++;
                }
            },

            isActiveSearch()
            {
                this.isEnableTools =! this.isEnableTools;
                if(this.isEnableTools) this.meta_data.sval = '';
            },
            tableSearch()
            {
                if(this.meta_data.skey == ''){
                    alert('🙏, Please choose target field');
                    return;
                }
                if(this.meta_data.sval == ''){
                    alert('🙏, Please enter the keyword');
                    return;
                }
                this.tableQuery();
            },
            tableOrdering(key)
            {
                this.isEnableOrder =! this.isEnableOrder;
                this.meta_data.okey = key;
                this.meta_data.oval = this.isEnableOrder ? 'ASC' : 'DESC';
                this.tableQuery(this.page);
            },
            tableQuery(pageNum)
            {
                this.page = pageNum;
                let data = { 
                    url: this.meta_url+'/data',
                    offset : this.page,
                    search: { key : this.meta_data.skey, value : this.meta_data.sval },
                    order: { key : this.meta_data.okey, value : this.meta_data.oval },
                    where : { key : this.meta_data.wkey, value : this.meta_data.wval },
                    filter : [
                        {key : 'contract.status', val:this.meta_filter.status},
                        {key : 'sd_year', val:this.meta_filter.sd_year},
                        {key : 'sd_month', val:this.meta_filter.sd_month},
                        {key : 'ed_year', val:this.meta_filter.ed_year},
                        {key : 'ed_month', val:this.meta_filter.ed_month},
                        {key : 'contract.id_branch', val: this.meta_filter.id_branch},
                    ]
                };

                this.$store.state.loading.msg = 'Processing Fetching Data...';
                this.$store.state.loading.status = true;
                this.$store.dispatch('crud/getData', data );
            },  
            tableRefresh()
            {
                this.meta_data.skey = '';
                this.meta_data.sval = '';
                this.meta_filter =  { status : 'all', sd_year: 'all', sd_month : 'all', ed_year : 'all', ed_month : 'all', id_branch:'all' }
                this.tableQuery();
            },
        }
    }
</script>
